import React, { useMemo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFF } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { columnThemes } from '@atlassian/jira-platform-board-kit/src/common/ui/column/index.tsx';
import { useDependencyLinks } from '@atlassian/jira-portfolio-3-plan-increment-common/src/hooks/use-dependency-links/index.tsx';
import {
	isIssueEntryIssue,
	isIssueEntryGroup,
	getIssueEntryGroupKey,
	DEFAULT_CARD_HEIGHT,
	CARD_LEFT_RIGHT_MARGIN,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import { useRemeasureOnResize } from '@atlassian/jira-software-fast-virtual/src/services/use-remeasure-on-resize/index.tsx';
import { useVirtual } from '@atlassian/jira-software-fast-virtual/src/services/use-virtual/index.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../state/state-hooks/capabilities/index.tsx';
import { useRowRenderer } from './row-renderer/index.tsx';
import type { FastVirtualListProps } from './types.tsx';
import { useAutoScrollCard } from './use-auto-scroll-card/index.tsx';
import { useCardHeightCache } from './use-card-height-cache/index.tsx';
import { useRowCount } from './use-row-count/index.tsx';
import { useScrollToUnscheduledColumnICC } from './use-scroll-to-unscheduled-column-icc/index.tsx';

export const FastVirtualList = ({
	name,
	offsetTop,
	issueEntries = [],
	issuesWithIssueLinksIds = [],
	footer,
	placeholder,
	renderCard,
	showFooter,
	columnId,
	swimlaneId,
	isUnscheduledWorkColumnPanel,
}: FastVirtualListProps) => {
	const isIpBoard = useIsIncrementPlanningBoard();
	const cardHeightCache = useCardHeightCache();
	const cacheOptions = useMemo(
		() => ({
			cache: cardHeightCache,
			getCacheKey: (index: number) => {
				const issueEntry = issueEntries[index];
				if (!issueEntry) {
					return undefined;
				}
				if (isIssueEntryGroup(issueEntry)) {
					return getIssueEntryGroupKey(issueEntry);
				}
				return issueEntry.issueId;
			},
		}),
		[issueEntries, cardHeightCache],
	);

	const rowCount = useRowCount({
		placeholder,
		issues: issueEntries,
	});

	/**
	 * versioning by the order of issue ids order excluding subtasks
	 */
	const version = useMemo(
		() =>
			issueEntries
				.map((issueEntry) =>
					isIssueEntryIssue(issueEntry) ? issueEntry.issueId : issueEntry.parentId,
				)
				.toString(),
		[issueEntries],
	);

	const { rows, totalSize, scrollTo, isScrolling, rowStates } = useVirtual({
		rowCount,
		overscanHeight: DEFAULT_CARD_HEIGHT,
		getDefaultRowSize: (index) => (index === issueEntries.length ? 0 : DEFAULT_CARD_HEIGHT),
		offsetTop,
		cacheOptions,
		version,
	});

	useRemeasureOnResize({ rows });

	useAutoScrollCard(issueEntries, scrollTo);

	useScrollToUnscheduledColumnICC(columnId, swimlaneId, issueEntries.length, scrollTo);

	const [_, { updateIssuesWithIssueLinkPositions }] = useDependencyLinks();

	useEffect(() => {
		if (isIpBoard && fg('dependency_visualisation_program_board_fe_and_be')) {
			updateIssuesWithIssueLinkPositions(
				issueEntries,
				issuesWithIssueLinksIds,
				rowStates,
				isUnscheduledWorkColumnPanel,
				swimlaneId,
			);
		}
	}, [
		isIpBoard,
		isUnscheduledWorkColumnPanel,
		issueEntries,
		issuesWithIssueLinksIds,
		swimlaneId,
		rowStates,
		updateIssuesWithIssueLinkPositions,
	]);

	// If there are no cards in a swimlane, it will be hidden. However, when we do render
	// columns and there is no ICC, placeholder or cards we want it to have a minimum
	// height to make it render in a cleaner fashion.
	const height: number = Math.max(DEFAULT_CARD_HEIGHT, totalSize);

	const renderer = useRowRenderer({
		cardEntries: issueEntries,
		footer,
		footerIndex: issueEntries.length,
		placeholder,
		renderCard,
		showFooter,
	});

	const style = useMemo(
		() => ({
			minHeight: height,
			backgroundColor: columnThemes.default.background,
			margin: `${token('space.050', '4px')} ${CARD_LEFT_RIGHT_MARGIN}px`,
		}),
		[height],
	);

	const convertedName = name?.split(' ').join('-').toLowerCase() ?? '';
	return (
		<FastVirtualListWrapper
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
			style={style}
			{...(ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') && {
				'aria-labelledby': `platform-board-kit.ui.column-title-${convertedName}`,
			})}
			data-testid="software-board.board-container.board.virtual-board.fast-virtual-list.fast-virtual-list-wrapper"
		>
			{rows.map(({ index, top, forceRemeasure, measure }) =>
				renderer({
					index,
					isScrolling,
					forceRemeasure,
					measure,
					offsetTop: top,
					style: {
						position: 'absolute',
						top: top - offsetTop,
						width: '100%',
						contain: 'style',
						...(ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') && {
							marginTop: '0px',
						}),
					},
				}),
			)}
		</FastVirtualListWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FastVirtualListWrapperOld = styled.div({
	position: 'relative',
	contain: 'size style',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FastVirtualListWrapperNew = styled.ul({
	position: 'relative',
	contain: 'size style',
	padding: '0px',
	listStyleType: 'none',
});

const FastVirtualListWrapper = componentWithFF(
	'backlog-active-sprints-list-markup-accessibility-fix_imavd',
	FastVirtualListWrapperNew,
	FastVirtualListWrapperOld,
);
