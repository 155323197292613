export const ANALYTICS_SOURCE_BOARD = 'board' as const;
export const ANALYTICS_SOURCE_PROGRAM_BOARD = 'programBoard' as const;
export const MAX_AVATARS = 6;

export const CMP_SCRUM_BOARD_TYPE = 'Scrum' as const;
export const CMP_KANBAN_BOARD_TYPE = 'Kanban' as const;
// Ten Minutes
export const TMP_BOARD_POLLING_INTERVAL = 600000;
export const AGILITY_BOARD_TYPE = 'Agility' as const;
export const IP_BOARD_TYPE = 'IP' as const;

export const CUSTOM_FIELD_LABELS = 'labels';

export const INCREMENT_PLANNING_BOARD_TOP_OFFSET = '105px';
export const IP_BOARD_TIME_ESTIMATE_FIELD_ID = 'timeEstimate';
export const IP_BOARD_STORY_POINTS_FIELD_ID = 'storyPoints';
export const IP_BOARD_STATUS_FIELD_ID = 'status';

export const ACTIVE_SPRINT_STATE = 'active' as const;
