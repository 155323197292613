import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noParentIssues: {
		id: 'software-filters.filters.parent.no-parent-issues',
		defaultMessage: 'Your project has no {parentName}',
		description:
			'This message is shown in the parent issue quick filter when there are no parent issue to select from',
	},
	toggleLabel: {
		id: 'software-filters.filters.parent.toggle-label',
		defaultMessage: 'Show {parentName} panel',
		description: 'The label of toggle to show and hide the epic panel',
	},
	placeholder: {
		id: 'software-filters.filters.parent.placeholder',
		defaultMessage: 'Search {parentName} filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
