import React, { type ComponentPropsWithoutRef, forwardRef, useRef, useEffect } from 'react';
import { xcss, Box, Flex } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { COMMAND_BODY_TEST_ID, COMPAL_MENU_ID } from '../constants.tsx';
import { messages } from './messages.tsx';
import { useSmoothExpand } from './utils.tsx';

export const CommandPaletteSearchResultListWrapper = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Box> & {
		noResultsFallback?: string | JSX.Element | null | undefined;
	}
>(({ noResultsFallback, children, ...props }, ref) => {
	const { formatMessage } = useIntl();
	const resizerRef = useRef<HTMLDivElement>(null);
	const height = useSmoothExpand(resizerRef);

	useEffect(() => {
		if (ref && 'current' in ref && ref.current) {
			const currentRef = ref.current;
			currentRef.style.height = `${height}px`;
		}
	}, [height, ref]);

	return (
		<Box
			xcss={wrapperStyles}
			testId={COMMAND_BODY_TEST_ID}
			id={COMPAL_MENU_ID}
			role={noResultsFallback ? 'group' : 'menu'}
			ref={ref}
			aria-label={formatMessage(messages.commandPaletteSearchResultListBoxLabel)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<Flex ref={resizerRef} direction="column" gap="space.050">
				{children}
			</Flex>
		</Box>
	);
});

const wrapperStyles = xcss({
	padding: '0',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	transition: 'height 0.15s ease-out',
	overflowY: 'auto',
	maxHeight: '376px',
});
