import { useState, useCallback, useEffect } from 'react';
import memoize from 'lodash/memoize';
import type { ModalDialogProps } from '@atlaskit/modal-dialog/types';
import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanAdministerProject } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { isCurrentUserProjectAdmin } from '@atlassian/jira-router-resources-project-context/src/services/project-context/get-data/get-permission-data/index.tsx';
import type { GetProjectSettingsUrl, Project } from './types.tsx';

export const getProjectSettingsIssueTypesUrl = ({
	projectKey,
	projectType,
}: GetProjectSettingsUrl) => {
	switch (projectType?.toLowerCase()) {
		case 'service_desk':
			return `/plugins/servlet/project-config/${projectKey}/issueTypes`;
		case 'business':
			return `/jira/core/projects/${projectKey}/settings/issuetypes`;
		default:
			return `/jira/software/projects/${projectKey}/settings/issuetypes`;
	}
};

export const defaultModalProps: ModalDialogProps = {
	width: 'small',
};

export const useCanConfigureStatus = (projectKey: string) => {
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const isTeamManaged = useIsSimplifiedProject(projectKey);

	return isProjectAdmin && isTeamManaged;
};

export const shouldShowConfigureIssueTypeOrStatusOption = (projectType: ProjectType | null) => {
	return projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT;
};

export const useIsCurrentUserAdminOfProject = ({ projectKey }: { projectKey?: string }) => {
	const [
		isCurrentUserProjectAdminOfSelectedProject,
		setIsCurrentUserProjectAdminOfSelectedProject,
	] = useState<null | boolean>(null);

	const previousProjectKey = usePrevious(projectKey);

	const validateIsCurrentUserProjectAdminOfSelectedProject = useCallback(async () => {
		if (!projectKey) {
			setIsCurrentUserProjectAdminOfSelectedProject(false);
			return;
		}
		setIsCurrentUserProjectAdminOfSelectedProject(null);
		const result = await isCurrentUserProjectAdmin(projectKey);
		setIsCurrentUserProjectAdminOfSelectedProject(result);
	}, [projectKey]);

	useEffect(() => {
		if (previousProjectKey !== projectKey) {
			validateIsCurrentUserProjectAdminOfSelectedProject();
		}
	}, [projectKey, previousProjectKey, validateIsCurrentUserProjectAdminOfSelectedProject]);

	return isCurrentUserProjectAdminOfSelectedProject;
};

export const canConfigureIssueTypeOrStatusForProject = memoize(
	({ project, isProjectAdmin }: { project: Project | null; isProjectAdmin: boolean }) => {
		return (
			isProjectAdmin &&
			(project?.isSimplified ?? false) &&
			shouldShowConfigureIssueTypeOrStatusOption(project?.projectType ?? null)
		);
	},
);
