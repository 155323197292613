import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagConfiguration, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	type MenuNode,
	MenuNodeType,
} from '@atlassian/jira-software-context-menu/src/common/types.tsx';
import { COPY_ISSUE_LINK_MENU_ID } from '../../constants.tsx';
import messages from './messages.tsx';
import type { ContentProps, Props } from './types.tsx';

const copySuccessfulFlagConfiguration: FlagConfiguration = {
	type: 'success',
	title: messages.copySuccessTitle,
	description: messages.copySuccessMessage,
};

const copyFailureFlagConfiguration: FlagConfiguration = {
	type: 'error',
	title: messages.copyFailureTitle,
	description: messages.copyFailureMessage,
};

const copySuccessfulFlagConfigurationIssueTerminologyRefresh: FlagConfiguration = {
	type: 'success',
	title: messages.copySuccessTitleIssueTermRefresh,
	description: messages.copySuccessMessageIssueTermRefresh,
};

const copyFailureFlagConfigurationIssueTerminologyRefresh: FlagConfiguration = {
	type: 'error',
	title: messages.copyFailureTitleIssueTermRefresh,
	description: messages.copyFailureMessage,
};

export const useCopyIssueLinkContent = ({
	isCopyLinkVisible,
	onCopyIssueLink,
}: ContentProps): MenuNode[] => {
	const { formatMessage } = useIntl();

	if (!isCopyLinkVisible) return [];

	return [
		{
			type: MenuNodeType.Item,
			label: formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.copyIssueLinkIssueTermRefresh
					: messages.copyIssueLink,
			),
			id: COPY_ISSUE_LINK_MENU_ID,
			onClick: onCopyIssueLink,
		},
	];
};

export const useCopyIssueLink = ({ isMultiSelectMode, issueKey }: Props) => {
	const { showFlag } = useFlagsService();

	const onCopyIssueLink = async () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const issueUrl = `${window.location.protocol}//${window.location.hostname}/browse/${issueKey}`;
		try {
			if (fg('jfp-magma-undefined-navigator')) {
				await globalThis.navigator.clipboard.writeText(issueUrl);
			} else {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				await navigator.clipboard.writeText(issueUrl);
			}
			showFlag(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? copySuccessfulFlagConfigurationIssueTerminologyRefresh
					: copySuccessfulFlagConfiguration,
			);
		} catch (err) {
			showFlag(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? copyFailureFlagConfigurationIssueTerminologyRefresh
					: copyFailureFlagConfiguration,
			);
		}
	};

	return useCopyIssueLinkContent({
		isCopyLinkVisible: !isMultiSelectMode,
		onCopyIssueLink,
	});
};
