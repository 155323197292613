import React, { useCallback, useMemo } from 'react';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { asyncInlineEditStatusModal } from '@atlassian/jira-inline-edit-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import {
	ISSUE_VIEW_SOURCE,
	type ConfigureSource,
} from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { editStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

export type Props = {
	status: {
		id: string;
		statusName: string;
		statusCategory: StatusCategory;
	};
	projectId: string;
	projectKey: string;
	issueTypeId: string;
	statusNames?: string[];
	onClick: () => void;
	onClose: () => void;
	onStatusUpdated?: () => Promise<void>;
	source?: ConfigureSource;
};

export const EditStatusButton = ({
	status,
	projectId,
	projectKey,
	issueTypeId,
	statusNames = [],
	onClick,
	onClose,
	onStatusUpdated,
	source,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		onClick();
		event.stopPropagation(); // prevents transition from being selected
	};

	const onCloseModal = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineEditStatusModal',
				action: 'closed',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	const onClickFireAnalytics = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'editStatusButton',
		);
	};

	const entryPointProps = useMemo(
		() => ({
			onClose: onCloseModal,
			status,
			issueTypeId,
			projectId,
			projectKey,
			editStatusExperience,
			statusNames,
			source: source || ISSUE_VIEW_SOURCE,
			onStatusUpdated,
		}),
		[
			onCloseModal,
			status,
			issueTypeId,
			projectId,
			projectKey,
			statusNames,
			source,
			onStatusUpdated,
		],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineEditStatusModal}
			entryPointProps={entryPointProps}
			interactionName="edit-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Pressable
					tabIndex={0}
					xcss={iconButtonStyles}
					onClick={onButtonClick}
					{...(fg('jfp-a11y-team_buttons_not_focusable_with_keyboard') && {
						onKeyDown: (event) => event.stopPropagation(),
					})}
					aria-label={formatMessage(messages.statusNameButtonLabel, {
						statusName: status.statusName,
					})}
					data-show-on-hover
					// ref returned from entrypoint is of Type 'RefObject<HTMLElement>' is not assignable to type 'Ref<HTMLButtonElement>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as React.Ref<HTMLButtonElement>}
					onKeyDown={(event) => {
						event.stopPropagation();
						// Fire analytics when the button is accessed via keyboard
						if (event.code === 'Enter' || event.code === 'Space') {
							onClickFireAnalytics();
						}
					}}
					onMouseDown={(event) => {
						if (event.button === 0) {
							// Left mouse button was clicked
							onClickFireAnalytics();
						}
					}}
					onTouchStart={() => {
						// Touch interaction
						onClickFireAnalytics();
					}}
				>
					<Tooltip
						content={formatMessage(
							fg('update_edit_button_tooltip_text')
								? messages.editStatusTooltip
								: messages.statusEditNameTooltip,
						)}
						position="bottom"
					>
						<EditIcon label="" LEGACY_size="small" color={token('color.icon')} />
					</Tooltip>
				</Pressable>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const iconButtonStyles = xcss({
	borderRadius: 'border.radius',
	outlineOffset: 'space.0',
	padding: 'space.025',
	marginInline: 'space.100',
	opacity: 0,
	transition: 'opacity 0.3s ease',
	':focus': {
		boxShadow: 'elevation.shadow.overflow',
		opacity: 1,
	},
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		opacity: 1,
	},
});
