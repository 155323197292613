/**
 * For issue view, we moved similar constants to view-specific packages.
 * However, for issue-transition, such a move causes a circular dependency with @atlassian/jira-issue-transition-trigger package,
 * since view-specific/issue-transition imports trigger points related functionality from @atlassian/jira-issue-transition-trigger.
 * Therefore, for now, let's keep it here.
 */
import { metrics } from '@atlassian/browser-metrics';
import type { StopTTICustomData } from '@atlassian/ui-modifications-analytics/types';

const uimInIssueTransitionTTI = metrics.interaction({
	key: 'ui-modifications-in-issue-transition',
	timings: [
		{ key: 'issue-transition-tti-delta', startMark: 'issue-transition-tti' },
		{ key: 'script-execution', startMark: 'iframe-load' },
		{ key: 'loading-indicators-visibility', startMark: 'fields-registered' },
		{ key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
	],
});

export const startUimTTI = () => {
	uimInIssueTransitionTTI.start();
};

export const markIssueTransitionLoaded = () => uimInIssueTransitionTTI.mark('issue-transition-tti');

export const stopTTI = (customData: StopTTICustomData) => {
	uimInIssueTransitionTTI.stop({
		customData,
	});
};

export const cancelTTI = () => {
	uimInIssueTransitionTTI.cancel();
};

export const markIframeLoad = () => {
	uimInIssueTransitionTTI.mark('iframe-load');
};

export const markFieldsRegistered = () => {
	uimInIssueTransitionTTI.mark('fields-registered');
};

export const markOnInitResolved = () => {
	uimInIssueTransitionTTI.mark('oninit-resolved');
};
