/**
 * @generated SignedSource<<d0b94f5719e7a97afb70f69553669895>>
 * @relayHash 77d91dbfee3b856a161a1ae421080370
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2fe50dcd29cb00a1a4afb333e7d12de5e2a443c52e22c7942eb63f198cae9347

import type { ConcreteRequest, Query } from 'relay-runtime';
export type workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery$variables = {
  cloudId: string;
  isPullRequestMergeable: boolean;
  isPullRequestReview: boolean;
  issueId: string;
};
export type workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery$data = {
  readonly workSuggestions: {
    readonly suggestionsByIssues: {
      readonly recentPullRequests: {
        readonly mergeableSuggestions?: ReadonlyArray<{
          readonly __typename: "WorkSuggestionsPRMergeableTask";
          readonly id: string;
          readonly isMergeActionEnabled: boolean | null | undefined;
          readonly url: string;
        }> | null | undefined;
        readonly pullRequestReviewSuggestions?: ReadonlyArray<{
          readonly __typename: "WorkSuggestionsPullRequestReviewTask";
          readonly id: string;
          readonly url: string;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery = {
  response: workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery$data;
  variables: workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPullRequestMergeable"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isPullRequestReview"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueId"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "url"
},
v7 = [
  {
    "concreteType": "WorkSuggestions",
    "kind": "LinkedField",
    "name": "workSuggestions",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "items": [
              {
                "kind": "Variable",
                "name": "issueIds.0",
                "variableName": "issueId"
              }
            ],
            "kind": "ListValue",
            "name": "issueIds"
          }
        ],
        "concreteType": "WorkSuggestionsByIssuesResponse",
        "kind": "LinkedField",
        "name": "suggestionsByIssues",
        "plural": false,
        "selections": [
          {
            "concreteType": "WorkSuggestionsPullRequestSuggestionsResponse",
            "kind": "LinkedField",
            "name": "recentPullRequests",
            "plural": false,
            "selections": [
              {
                "condition": "isPullRequestMergeable",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "WorkSuggestionsPRMergeableTask",
                    "kind": "LinkedField",
                    "name": "mergeableSuggestions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "isMergeActionEnabled"
                      },
                      (v6/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "isPullRequestReview",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "concreteType": "WorkSuggestionsPullRequestReviewTask",
                    "kind": "LinkedField",
                    "name": "pullRequestReviewSuggestions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery",
    "selections": (v7/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "id": "2fe50dcd29cb00a1a4afb333e7d12de5e2a443c52e22c7942eb63f198cae9347",
    "metadata": {},
    "name": "workSuggestionsJiraBoardFetchPullRequestWorkSuggestionsForIssueQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "e2e0e8e1a09ee9dab89f375c355b6c85";

export default node;
