import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { StatusFieldValueType } from '../update/index.tsx';

export const SET_ISSUE_STATUS_INCREMENT_PLANNING =
	'state.actions.issue.status.SET_ISSUE_STATUS_INCREMENT_PLANNING' as const;

export type SetStatusIncrementPlanningAction = {
	type: typeof SET_ISSUE_STATUS_INCREMENT_PLANNING;
	payload: {
		issueId: IssueId;
		fieldValue: StatusFieldValueType;
	};
};

export const setIssueStatusIncrementPlanning = (
	issueId: IssueId,
	fieldValue: StatusFieldValueType,
): SetStatusIncrementPlanningAction => ({
	type: SET_ISSUE_STATUS_INCREMENT_PLANNING,
	payload: {
		issueId,
		fieldValue,
	},
});

export type Action = SetStatusIncrementPlanningAction;
