export const LOAD_START_MARK_KEY = 'WORKFLOW_READONLY_MODAL_LOAD_START_MARK_KEY';
export const LOAD_END_MARK_KEY = 'WORKFLOW_READONLY_MODAL_LOAD_END_MARK_KEY';
// there is no "END" for the diagram rendering as `READY_MARK_KEY` below is the same logical moment
export const DIAGRAM_RENDERING_START_MARK_KEY = 'DIAGRAM_RENDERING_START_MARK_KEY';
export const READY_MARK_KEY = 'WORKFLOW_READONLY_MODAL_READY_MARK_KEY';

export const ERROR_LOGGER_PATTERN = '#workflow-readonly-modal#';

export const APP_NAME = 'jira.fe.spa.transition.workflow-readonly-modal';

type TransitionEnum = 'INITIAL' | 'GLOBAL' | 'DIRECTED';

export const transitionType: {
	[key: string]: TransitionEnum;
} = {
	INITIAL: 'INITIAL',
	GLOBAL: 'GLOBAL',
	DIRECTED: 'DIRECTED',
};

export const ruleTemplateKeys = {
	CHANGE_ASSIGNEE: 'system:change-assignee' as const,
	UPDATE_FIELD: 'system:update-field' as const,
} as const;

export const WORKFLOW_EXPERIENCE_IDENTIFIER = 'workflowBentoModal';
export const EXPERIENCE_OPENED_START_EVENT = 'openedStart';
export const EXPERIENCE_OPENED_SUCCESS_EVENT = 'openedSuccess';
