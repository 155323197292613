import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import countBy from 'lodash/countBy';
import difference from 'lodash/difference';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import memoizeOne from 'memoize-one';
import {
	ColumnType,
	UNSCHEDULED_COLUMN_ID,
} from '@atlassian/jira-common-constants/src/column-types.tsx';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { DependencyFilterOption } from '@atlassian/jira-filters/src/ui/filters/dependencies/types.tsx';
import { swimlaneHeaderHeight } from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import { ColumnTheme } from '@atlassian/jira-platform-board-kit/src/common/types.tsx';
import type { CardProps } from '@atlassian/jira-platform-board-kit/src/ui/card/types.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import {
	SWIMLANE_TEAMLESS,
	TEAM,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { truncateUnscheduledWorkSwimlaneId } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import {
	toAccountIdArray,
	toIssueIdArray,
	toIssueTypeIdArray,
	toProjectIdArray,
	values,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	DEFAULT_CARD_HEIGHT,
	type IssueId,
	type IssueEntryGroup,
	type IssueEntry,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import { UNASSIGNED_USER_ID } from '@atlassian/jira-software-filters/src/common/constants.tsx';
import { Capability } from '../../../common/capability/index.tsx';
import { isUnscheduledColumn } from '../../../common/utils/column/index.tsx';
import type {
	Column,
	ColumnId,
	ColumnIdKey,
	DateRangeColumn,
	StatusColumn,
} from '../../../model/column/column-types.tsx';
import { CARD_DND_TYPE, SSR_CARD_LIMIT, SUBTASK_DND_TYPE } from '../../../model/constants.tsx';
import {
	ASSIGNEE,
	LABEL,
	TEXT,
	ISSUE_PARENT,
	ISSUE_PROJECT,
	ISSUE_TYPE,
	REQUEST_TYPE,
	STATUS,
	CUSTOM_FILTER,
	SPRINT,
	DEPENDENCIES,
	type Filters,
} from '../../../model/filter/filter-types.tsx';
import type { Issue, IssueKey, IssuesByColumnMap } from '../../../model/issue/issue-types.tsx';
import type { Person } from '../../../model/people/people-types.tsx';
import type {
	CardsTotalCountPerColumnMap,
	CardsTotalCountPerTeamSwimlaneColumnMap,
	RequestType,
} from '../../../model/software/software-types.tsx';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_BY_TEAM,
} from '../../../model/swimlane/swimlane-modes.tsx';
import {
	ChildlessSwimlane,
	type IssueIdsBySwimlanes,
	ParentlessSwimlane,
	type PlatformSwimlane,
	type SwimlaneId,
	UnassignedSwimlane,
} from '../../../model/swimlane/swimlane-types.tsx';
import type { EstimationStatisticFieldId, WorkData } from '../../../model/work/work-types.tsx';
import {
	getSwimlaneId,
	transformSoftwareToPlatformIssue,
} from '../../../services/issue/issue-data-transformer.tsx';
import type { State } from '../../reducers/types.tsx';
import type { FilteredCardsIds } from '../../reducers/ui/cards/filtered-cards/types.tsx';
import type { IssuesNotOnBoardState } from '../../reducers/ui/column/issues-not-on-board/types.tsx';
import type { FilterState } from '../../reducers/ui/work-filters/types.tsx';
import { getCapability } from '../capability/capability-selectors.tsx';
import { getDraggingCardId, getIssueIdWithAboveICCOpen } from '../card/card-selectors.tsx';
import {
	getColumnById,
	getColumns,
	isDoneColumn,
	isInitialColumn,
} from '../column/column-selectors.tsx';
import { createDeepEqualSelector } from '../common.tsx';
import { getIssueChildren, getParentIdByIssueChildSelector } from '../issue-children/index.tsx';
import { getIssueParents, issueParentIdsSelector } from '../issue-parent/index.tsx';
import { issueChildrenTypesSelector } from '../issue-type/issue-type-selectors.tsx';
import {
	boardIssuesSelector,
	boardOrderedIssueIdsSelector,
	boardIssuesAndChildrenSelector,
	makeGetIssueKeyFromIssueId,
} from '../issue/board-issue-selectors.tsx';
import { filter } from '../issue/card-filter.tsx';
import { getIssuesMedia } from '../issue/issue-media-selectors.tsx';
import {
	getOrderedIssuesByColumn,
	issueLabelsSelector,
	issueProjectIdsSelector,
	issueTypeFilterSelector,
	makeIssueDevStatusSelector,
	requestTypesSelector,
	statusesSelector,
} from '../issue/issue-selectors.tsx';
import {
	getCurrentUserAccountId,
	getPeople,
	getAssigneeAccountIds,
} from '../people/people-selectors.tsx';
import {
	getEntities,
	getBoardEntity,
	getBoardConfig,
	getIsCMPBoard,
	getIssues,
	getIssueTypes,
	getOrderedColumnIds,
	getTeams,
	getUi,
	getIsIncrementPlanningBoard,
	projectIdSelector,
	getStatuses,
	isBoardRankable,
	projectTypeSelector,
} from '../software/software-selectors.tsx';
import {
	hasNoActiveSprintStateSelector,
	activeSprintsSelector,
} from '../sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import {
	getSwimlanes,
	getIsSwimlaneCollapsed,
	getCollapsedSwimlanes,
	swimlaneByTeamSelector,
} from '../swimlane/swimlane-selectors.tsx';

export type PlatformIssue = CardProps['issue'];

export const isBoardConfigLoaded = (state: State): boolean => getBoardEntity(state).isConfigLoaded;
export const isCurrentUserLoaded = (state: State): boolean =>
	getBoardEntity(state).isCurrentUserLoaded;
export const getEstimationStatistic = (state: State): EstimationStatisticFieldId =>
	getEntities(state).estimationStatistic;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasAnyActiveOptimistic = (state: any): boolean => {
	const beforeState = state.entities && state.entities.beforeState;
	return beforeState !== undefined;
};

export const getColumnsUI = (state: State) => getUi(state).columns;
export const getWorkFilters = (state: State): FilterState =>
	getUi(state).workFilters ?? { values: {} };

export const getSelectedCustomFilters = createSelector(
	[getWorkFilters],
	(workFilters) => workFilters.values[CUSTOM_FILTER],
);

export const getCustomFilters = (state: State) => getEntities(state).customFilters.filters;

export const isFilterOnCurrentUserOrUnassignedOnly = (state: State) => {
	const assignees = getWorkFilters(state).values[ASSIGNEE];
	if (!assignees) return false;
	if (assignees.length === 1 && getCurrentUserAccountId(state) === assignees[0]) return true;
	if (
		assignees.length === 2 &&
		assignees.includes(getCurrentUserAccountId(state)) &&
		assignees.includes(UNASSIGNED_USER_ID)
	) {
		return true;
	}

	return false;
};

export const isFilterOnCurrentUserOnly = (state: State) => {
	const assignees = getWorkFilters(state).values[ASSIGNEE];
	return assignees && assignees.length === 1 && getCurrentUserAccountId(state) === assignees[0];
};

export const isInlineColumnEditEnabled = (state: State) =>
	getBoardConfig(state).isInlineColumnEditEnabled &&
	getCapability(state)(Capability.INLINE_COLUMN_EDIT);

export const isCardMediaEnabled = (state: State) =>
	getBoardConfig(state).isCardMediaEnabled || false;
export const isCardMediaAvailable = createSelector([getIssuesMedia], (issueMedia) =>
	values(issueMedia).some((media) => get(media, ['isHiddenByUser']) === false),
);
export const isReleasesFeatureEnabled = (state: State) =>
	getBoardConfig(state).isReleasesFeatureEnabled === true;

export const boardColumnConstraintTypeSelector = (state: State) =>
	getBoardConfig(state).columnConstraintType;

export const getNumberOfCardsWithCovers = (state: State): number =>
	values(getIssuesMedia(state)).filter((media) => get(media, ['isHiddenByUser']) === false).length;

export const getNumberOfCardsWithDueDates = createSelector(
	[getIssues],
	(allIssues) => values(allIssues).filter((issue) => Boolean(issue.dueDate)).length,
);

export const getTimeTrackingOptions = (state: State) => getBoardConfig(state).timeTrackingOptions;

// Remove when cleaning up `use_backend_tmp_icc_config_`
export const getIsAnyStatusInCreateEnabled = (state: State) =>
	getBoardConfig(state).isAnyStatusInCreateEnabled;

const getUiColumn = (state: State) => getUi(state).column;

const getColumnDeleteModal = (state: State) => getUiColumn(state).deleteModal;

export const getColumnDeleteModalColumnId = (state: State): number | undefined => {
	const deleteModalState = getColumnDeleteModal(state);
	return deleteModalState && 'columnId' in deleteModalState ? deleteModalState.columnId : undefined;
};

export const getColumnDeleteModalIsOpen = (state: State) => getColumnDeleteModal(state).isOpen;

export const isIssueDeleteModalOpen = (state: State): boolean =>
	Boolean(getUi(state).issueDeleteModal?.isOpen);
export const getIssueDeleteModalIssueId = (state: State): IssueId | undefined =>
	getUi(state).issueDeleteModal?.issueId;

export const isLastRemainingColumn = (state: State) =>
	Object.keys(getEntities(state).columns).length === 1;

export const getBoardHasFilteredIssues = (state: State): boolean =>
	getBoardEntity(state).hasFilteredIssues;

export const cardTypesSelector = createSelector([getIssueTypes], (issueTypes) =>
	Object.keys(issueTypes).map((id) => issueTypes[id]),
);

export const shouldRenderCardTypesSelector = createSelector(
	[getIssueTypes, issueChildrenTypesSelector],
	(issueTypes, issueChildrenTypes) =>
		Object.keys(issueTypes).length > 1 || issueChildrenTypes.length > 0,
);

export const workColumnsSelector = createSelector(
	[getColumns, getOrderedColumnIds],
	(columns, columnIds) => columnIds.map((columnId) => columns[String(columnId)]),
);

export const workIssuesSelector = createSelector(
	[boardIssuesSelector, boardOrderedIssueIdsSelector],
	(issueEntities, boardIssuesIds) =>
		boardIssuesIds.map((id) => issueEntities[String(id)]).filter(Boolean),
);

export const workIssuesCountSelector = createSelector(
	[workIssuesSelector],
	(issues) => issues?.length || 0,
);

export const workTotalCardCountPerColumnSelector = createSelector(
	[workColumnsSelector, workIssuesSelector, boardColumnConstraintTypeSelector, getIssueChildren],
	(columns, issues, columnConstraintType, issueChildren) => {
		const countMap: CardsTotalCountPerColumnMap = {};
		columns.forEach((column: Column) => {
			countMap[String(column.id)] = 0;
		});
		issues.forEach((issue: Issue) => {
			if (columnConstraintType === 'ISSUE_COUNT_EXCLUDE_SUBTASKS' && issue.id in issueChildren) {
				return;
			}
			countMap[String(issue.columnId)] += 1;
		});

		return countMap;
	},
);

export const getColumnTotalIssuesCount = (state: State, columnId: ColumnId): number =>
	workTotalCardCountPerColumnSelector(state)[String(columnId)];

/* This selector is used by the Increment Planning Board to get the total number
of issues in a column per Team Swimlane. */
export const workTotalCardCountPerTeamSwimlaneColumnSelector = createSelector(
	[workColumnsSelector, workIssuesSelector, swimlaneByTeamSelector],
	(columns, issues, swimlanesByTeam) => {
		const countMap: CardsTotalCountPerTeamSwimlaneColumnMap = {};

		columns.forEach((column: Column) => {
			countMap[String(column.id)] = {};
			swimlanesByTeam.forEach((swimlane) => {
				countMap[String(column.id)][swimlane.id] = 0;
			});
		});
		issues.forEach((issue: Issue) => {
			const teamId = issue.teamId || SWIMLANE_TEAMLESS;
			countMap[String(issue.columnId)][teamId] += 1;
		});

		return countMap;
	},
);

export const getTeamSwimlaneColumnTotalIssuesCount = (
	state: State,
	columnId: ColumnId,
	swimlaneId: SwimlaneId,
): number => workTotalCardCountPerTeamSwimlaneColumnSelector(state)[String(columnId)][swimlaneId];

export const getTeamSwimlaneColumnSprint = (
	state: State,
	columnId: ColumnId,
	swimlaneId: SwimlaneId,
): Sprint | undefined | null => {
	if (swimlaneId === SWIMLANE_TEAMLESS || columnId === UNSCHEDULED_COLUMN_ID) {
		return undefined;
	}
	const teams = getTeams(state);
	const sprintByIteration = teams[Number(swimlaneId)]?.sprintByIterationId;
	return sprintByIteration && sprintByIteration[Number(columnId)];
};

export const getIsUnscheduledColumnVisible = (state: State) =>
	state.ui.board.displayOptions.showUnscheduledColumn;

export const unscheduledColumnSelector = createSelector([workColumnsSelector], (columns) =>
	columns
		.filter(
			(column): column is DateRangeColumn =>
				column.type === ColumnType.DATE_RANGE && isUnscheduledColumn(column),
		)
		.map((column) => ({
			id: column.id,
			name: column.name,
			isDone: false,
			isInitial: false,
			limitJql: undefined,
		})),
);

export const columnsWithResolutionSelector = createSelector(
	[workColumnsSelector, getIsIncrementPlanningBoard],
	(columns, isIPBoard) => {
		if (isIPBoard) {
			return columns
				.filter(
					(column): column is DateRangeColumn =>
						column.type === ColumnType.DATE_RANGE && !isUnscheduledColumn(column),
				)
				.map((column) => ({
					id: column.id,
					name: column.name,
					isDone: false,
					isInitial: false,
					limitJql: undefined,
				}));
		}

		return columns
			.filter(
				(column): column is StatusColumn =>
					column.type === ColumnType.STATUS && column.statuses && column.statuses.length > 0,
			)
			.map((column) => {
				const isDone = isDoneColumn(column);

				return {
					id: column.id,
					name: column.name,
					isDone,
					isInitial: isInitialColumn(column),
					limitJql: column.limitJql,
				};
			});
	},
);

export const orderedWorkAssigneesByAccountIdSelector = createSelector(
	[getPeople, getCurrentUserAccountId, workIssuesSelector],
	(assignees, currentUserAccountId, allIssues) => {
		const currentUser = assignees[currentUserAccountId];
		const assigneeList = currentUser ? [currentUser] : [];

		const issuesWithAssignees = allIssues.filter(
			(i) =>
				i.assigneeAccountId &&
				i.assigneeAccountId !== currentUserAccountId &&
				assignees[i.assigneeAccountId],
		);

		const assigneeCounts = countBy(issuesWithAssignees, (i) => i.assigneeAccountId);
		Object.keys(assigneeCounts)
			.sort((a, b) => assigneeCounts[b] - assigneeCounts[a])
			.forEach((assigneeId) => assigneeList.push(assignees[assigneeId]));

		return freeze(assigneeList);
	},
);

export const orderedWorkAssigneesAlphabeticallySelector = createSelector(
	[getPeople, getCurrentUserAccountId],
	(assignees, currentUserAccountId) => {
		const currentUser = assignees[currentUserAccountId];
		const assigneeList = currentUser ? [currentUser] : [];

		const otherAssignees: Person[] = Object.values(assignees).filter(
			(assignee) => assignee.id !== currentUserAccountId,
		);

		const sortedOtherAssignees = otherAssignees.sort((a, b) =>
			a.displayName.localeCompare(b.displayName),
		);

		return freeze([...assigneeList, ...sortedOtherAssignees]);
	},
);

export const doesColumnHaveIssuesSelector = createSelector([workIssuesSelector], (issues) =>
	memoize((columnId) => issues.some((issue) => issue.columnId === columnId)),
);

const getIssuesNotOnBoard = (state: State): IssuesNotOnBoardState =>
	getUi(state).column.issuesNotOnBoard;

export const hasIssuesNotOnBoard = createSelector(
	[getIssuesNotOnBoard],
	(issuesNotOnBoard: IssuesNotOnBoardState) => (columnId: ColumnId) =>
		issuesNotOnBoard[String(columnId)],
);

export const getFilteredCardIds = (state: State): FilteredCardsIds =>
	state.ui.cards.filteredCards.filteredCardsIds;

export const isFilteredCardsLoading = (state: State): boolean =>
	state.ui.cards.filteredCards.isLoading;

export const workFilteredIssuesSelector = createSelector(
	[getWorkFilters, workIssuesSelector, getIssues, getPeople, getFilteredCardIds],
	(workFilters, issues, issueHash, people, filteredCardIds) =>
		filter(workFilters.values, issues, issueHash, people, filteredCardIds || undefined),
);

export const getUnAssignedIssueCount = createSelector(
	[workFilteredIssuesSelector],
	(issues) => issues.filter((issue) => !issue.assigneeAccountId).length,
);

// Take in children issues + all issue and then apply filter
// issue order does not preserve. Do not use it for card render. use for card count
// this is created specifically for CMP Stories swimlane
export const cmpEpicSwimlaneFilteredWorkIssueSelector = createSelector(
	[getWorkFilters, boardIssuesAndChildrenSelector, getIssues, getPeople, getFilteredCardIds],
	(workFilters, issues, issueHash, people, filteredCardIds) =>
		filter(
			workFilters.values,
			Object.values(issues),
			issueHash,
			people,
			filteredCardIds || undefined,
		),
);

export const columnIssueIdsSelector = createSelector(
	[workFilteredIssuesSelector, cmpEpicSwimlaneFilteredWorkIssueSelector, getIsCMPBoard],
	(issues, cmpEpicSwimlaneIssue, isCMPBoard) =>
		memoize((columnId) =>
			isCMPBoard
				? cmpEpicSwimlaneIssue
						.filter((issue) => issue.columnId === columnId)
						.map((issue) => issue.id)
				: issues.filter((issue) => issue.columnId === columnId).map((issue) => issue.id),
		),
);

export const getColumnIssuesCount = (state: State, columnId: ColumnId): number => {
	const issueIds = columnIssueIdsSelector(state)(columnId);

	const columnConstraintType = boardColumnConstraintTypeSelector(state);
	const issueChildren = getIssueChildren(state);

	const filteredIssueIds = issueIds.filter(
		(issueId) =>
			columnConstraintType !== 'ISSUE_COUNT_EXCLUDE_SUBTASKS' || !(issueId in issueChildren),
	);
	return filteredIssueIds.length;
};

// used to get projects from issues on board and also keep the default one
export const workProjectKeysSelector = createSelector(
	[workIssuesSelector, projectIdSelector],
	(issues, projectId) =>
		uniq(
			issues
				.map((issue) => Number(issue.projectId)) // get project ids
				.filter((id) => !isNil(id))
				.concat(projectId ? [projectId] : []),
		),
);

export const isLabelFilterApplied = createSelector(
	[getWorkFilters],
	(filters) => !isEmpty(get(filters, ['values', LABEL], null)),
);

const getIssueById = createSelector(
	[
		(
			state: State,
			props: {
				id: IssueId;
			},
		) => props.id,
		(state: State) => boardIssuesSelector(state),
	],
	(id, issues) => {
		const foundIssue = issues[String(id)];
		if (isNil(foundIssue)) {
			log.safeErrorWithoutCustomerData(
				'board.getIssueById.failure',
				'Failure to found issue by id',
				new Error(`Issue Id to find ${id} not found in context ${Object.keys(issues).join(',')}`),
			);
		}

		return foundIssue;
	},
);

// JSM uses request type name and icon as typeName and typeUrl.
const getIssueForProject = (
	issue: Issue,
	projectType: string,
	requestTypes: RequestType[] = [],
): Issue => {
	if (projectType === SERVICE_DESK_PROJECT) {
		const requestType = requestTypes.find((req) => req.id === issue.requestTypeId);
		const typeName = requestType?.name;
		const typeUrl = requestType?.iconUrl;

		return { ...issue, typeName, typeUrl };
	}

	return issue;
};

export const platformIssueSelector = createSelector(
	[
		boardIssuesSelector,
		getSwimlaneMode,
		getSwimlanes,
		getIsCMPBoard,
		projectTypeSelector,
		requestTypesSelector,
	],
	(boardIssues, swimlaneMode, availableSwimlanes, isCMPBoard, projectType, requestTypes) =>
		memoize((id) => {
			const issueToBeTransformed = boardIssues[String(id)];
			if (!issueToBeTransformed) return null;

			return transformSoftwareToPlatformIssue(
				getIssueForProject(issueToBeTransformed, projectType, requestTypes),
				swimlaneMode,
				availableSwimlanes,
				isCMPBoard,
				(issueId: string) => boardIssues[issueId],
			);
		}),
);

export const platformIssuesSelector = createSelector(
	[workFilteredIssuesSelector, getSwimlaneMode, getSwimlanes, getIsCMPBoard, boardIssuesSelector],
	(issues, swimlaneMode, availableSwimlanes, isCMPBoard, boardIssues) =>
		issues.map((issue) =>
			transformSoftwareToPlatformIssue(
				issue,
				swimlaneMode,
				availableSwimlanes,
				isCMPBoard,
				(issueId: string) => boardIssues[issueId],
			),
		),
);

/**
 * In the increment planning board,
 * there is a unscheduled column that can be hidden via the view settings menu.
 * The issues in this column should not be counted towards the swimlanes when the column is hidden
 */
export const visiblePlatformIssuesSelector = createSelector(
	[platformIssuesSelector, getIsIncrementPlanningBoard, getIsUnscheduledColumnVisible],
	(issues, isIPBoard, isUnscheduledColumnVisible) => {
		if (isIPBoard && !isUnscheduledColumnVisible) {
			return issues.filter(({ columnId }) => columnId !== UNSCHEDULED_COLUMN_ID);
		}

		return issues;
	},
);

export const getEstimateExtendedByIssueId = createSelector(
	[getIssueById],
	(issue) => issue?.estimate ?? null,
);

type IssueIdsByColumns = Record<string, IssueId[]>;

type IssueIdsBySwimlane = Record<string, IssueIdsByColumns>;

type IssueByColumns = Record<string, PlatformIssue[]>;

export type IssuesBySwimlane = Record<string, IssueByColumns>;

export const platformIssueIdsBySwimlaneSelector = createSelector(
	[visiblePlatformIssuesSelector, getSwimlaneMode, getSwimlanes, getIssueParents],
	(
		issues: PlatformIssue[],
		swimlaneMode: SwimlaneModeId | null | undefined,
		swimlanes,
		issueParents,
	): IssueIdsBySwimlane => {
		if (!swimlaneMode || swimlaneMode === NO_SWIMLANE.id) {
			return {};
		}

		const issuesBySwimlane = groupBy(issues, (issue) => issue.swimlaneId);
		if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id && ParentlessSwimlane.id in issuesBySwimlane) {
			// remove all the Epic issues as they should not be counted the parentless swimlane
			const issueParentIds = Object.values(issueParents).map((issueParent) => issueParent.id);
			const filteredIssues = issuesBySwimlane[ParentlessSwimlane.id]?.filter(
				(issue) => !issueParentIds.includes(issue.id),
			);
			issuesBySwimlane[ParentlessSwimlane.id] = filteredIssues || [];
		}
		swimlanes.forEach(({ id }) => {
			if (issuesBySwimlane[id] === undefined) {
				issuesBySwimlane[id] = [];
			}
		});

		return mapValues(issuesBySwimlane, (issuesInSwimlane) => {
			const issuesByColumnId = groupBy(issuesInSwimlane, (issue) => issue.columnId);
			return mapValues(issuesByColumnId, (issuesInColumn) =>
				issuesInColumn.map((issue) => issue.id),
			);
		});
	},
);

export interface SwimlaneByHeight {
	swimlaneId: string;
	height: number;
}

export type SwimlaneByOffset = Record<string, number>;

export const getSwimlaneHeight = (swimlaneColumns: IssueIdsByColumns): number => {
	let swimlaneHeight = 0;
	Object.values(swimlaneColumns).forEach((column: IssueId[]) => {
		if (column.length > swimlaneHeight) {
			swimlaneHeight = column.length;
		}
	});
	return swimlaneHeight * DEFAULT_CARD_HEIGHT;
};

export const swimlaneHeightsSelector = createSelector(
	[getSwimlanes, getIsSwimlaneCollapsed, platformIssueIdsBySwimlaneSelector],
	(swimlanes, isSwimlaneCollapsed, issueIdsBySwimlane) =>
		swimlanes.reduce(
			(result, swimlane) => {
				// eslint-disable-next-line no-param-reassign
				result[swimlane.id] =
					swimlaneHeaderHeight +
					(isSwimlaneCollapsed(swimlane.id)
						? 0
						: getSwimlaneHeight(issueIdsBySwimlane[swimlane.id]));
				return result;
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{} as Record<string, number>,
		),
);

export const makeGetFastVirtualSwimlaneOffsets = createSelector(
	[getSwimlanes, getIsSwimlaneCollapsed, platformIssueIdsBySwimlaneSelector],
	(swimlanes, isSwimlaneCollapsed, issueIdsBySwimlane) => (): SwimlaneByOffset => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const swimlaneHeights = [] as SwimlaneByHeight[];
		swimlanes.forEach((swimlane) => {
			// If an above swimlane is collapsed, don't count its height
			if (isSwimlaneCollapsed(swimlane.id)) {
				return;
			}

			swimlaneHeights.push({
				swimlaneId: swimlane.id,
				height: getSwimlaneHeight(issueIdsBySwimlane[swimlane.id]),
			});
		});

		const swimlaneOffsets: SwimlaneByOffset = {};
		swimlaneHeights.forEach(({ swimlaneId }, index) => {
			if (index === 0) {
				swimlaneOffsets[swimlaneId] = 0;
			} else {
				const previousId = swimlaneHeights[index - 1].swimlaneId;
				const previousHeight = swimlaneHeights[index - 1].height;
				const previousOffset = swimlaneOffsets[previousId];
				swimlaneOffsets[swimlaneId] = previousOffset + previousHeight;
			}
		});

		return swimlaneOffsets;
	},
);

const getCardAmount = (swimlaneId: SwimlaneId, issueIdsBySwimlane: IssueIdsBySwimlanes) =>
	values(issueIdsBySwimlane[swimlaneId] || {}).reduce(
		(amount, issueIdsInColumn) =>
			amount + (Array.isArray(issueIdsInColumn) ? issueIdsInColumn.length : 0),
		0,
	);

export const appliedWorkFiltersCountSelector = createSelector(
	[getWorkFilters],
	(filterObject) => Object.keys(filterObject.values).length,
);

const excludedFilters = [TEXT, ISSUE_TYPE];

export const filtersForInlineCardCreateCountSelector = createSelector(
	[getWorkFilters],
	(filterObject) =>
		Object.keys(filterObject.values).filter((type) => !excludedFilters.includes(type)).length,
);

export const hasWorkFiltersSelector = createSelector(
	[appliedWorkFiltersCountSelector],
	(appliedWorkFiltersCount) => appliedWorkFiltersCount > 0,
);

export const hasCustomFiltersSelector = createSelector(
	[getWorkFilters],
	(filterObject) =>
		filterObject.values[CUSTOM_FILTER] !== undefined &&
		filterObject.values[CUSTOM_FILTER].length > 0,
);

export const platformHighlightScopeSelector = createSelector([getWorkFilters], (filters) => {
	const filterText = get(filters, ['values', TEXT], null);
	const filterLabels = get(filters, ['values', LABEL], null);

	if (!filterText && !filterLabels) {
		return null;
	}

	return {
		text: filterText,
		labels: filterLabels,
	};
});

export const platformSwimlanesSelector = createSelector(
	[
		getSwimlanes,
		getSwimlaneMode,
		platformIssueIdsBySwimlaneSelector,
		workFilteredIssuesSelector,
		getIsCMPBoard,
		getIsIncrementPlanningBoard,
		hasWorkFiltersSelector,
	],
	(
		swimlanes,
		swimlaneMode,
		issueIdsBySwimlane,
		filteredIssue,
		isCMPBoard,
		isIncrementPlanningBoard,
		hasWorkFilters,
	) => {
		const subtaskSwimlaneFilter = ({ id, cardAmount }: PlatformSwimlane) => {
			if (isCMPBoard) {
				// do not render the bottom "Everything else" swimlane when there are no cards in it
				if (id === ChildlessSwimlane.id && cardAmount > 0) return true;
				// render the swimlane header even it is empty
				if (filteredIssue.map((issue) => String(issue.id)).includes(id)) return true;
				return false;
			}
			return id === ChildlessSwimlane.id;
		};

		/*
		 * When grouping by Epic.
		 * remove the "issue without epic" swimlane when empty on a CMP board
		 * keep it on a TMP board
		 */
		const epicSwimlaneFilter = ({ id, cardAmount }: PlatformSwimlane) =>
			id === ParentlessSwimlane.id && (isCMPBoard ? cardAmount > 0 : true);

		/*
		 * When grouping by Assignee.
		 * remove the "Unassigned" swimlane when empty on a CMP board
		 * keep it on a TMP board
		 */
		const assigneeSwimlaneFilter = ({ id, cardAmount }: PlatformSwimlane) =>
			id === UnassignedSwimlane.id && (isCMPBoard ? cardAmount > 0 : true);

		/*
		 * When grouping by Team.
		 * show empty swimlanes only if there is no filters applied
		 * and the team is a plan team
		 */
		const teamSwimlaneFilter = (
			{ values: swimlaneValues }: PlatformSwimlane,
			hasFilters: boolean,
		) => !hasFilters && swimlaneValues?.type === TEAM && swimlaneValues?.isPlanTeam;

		return swimlanes
			.map(
				(swimlane): PlatformSwimlane => ({
					id: swimlane.id,
					name: swimlane.name,
					issueKey: swimlane.issueKey,
					mode: swimlaneMode || '',
					message: swimlane.message,
					imageUrl: swimlane.imageUrl,
					cardAmount: getCardAmount(swimlane.id, issueIdsBySwimlane),
					parentId: swimlane.parentId || null,
					values: swimlane.values,
					assigneeAccountId: swimlane.assigneeAccountId ?? null,
					projectKey: swimlane.projectKey ?? null,
					isFlagged: swimlane.isFlagged,
				}),
			)
			.filter(
				(swimlane) =>
					((swimlaneMode === SWIMLANE_BY_ASSIGNEE.id ||
						swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id) &&
						assigneeSwimlaneFilter(swimlane)) ||
					(swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id && epicSwimlaneFilter(swimlane)) ||
					(swimlaneMode === SWIMLANE_BY_SUBTASK.id && subtaskSwimlaneFilter(swimlane)) ||
					(isIncrementPlanningBoard &&
						swimlaneMode === SWIMLANE_BY_TEAM.id &&
						teamSwimlaneFilter(swimlane, hasWorkFilters)) ||
					swimlane.cardAmount > 0,
			);
	},
);

export const platformSwimlaneSelector = createSelector(
	[platformSwimlanesSelector],
	(swimlanes: PlatformSwimlane[]) => {
		const platformSwimlane = (swimlaneId: SwimlaneId) =>
			swimlanes.find((swimlane) => swimlane.id === swimlaneId);
		return memoize(platformSwimlane);
	},
);

export const platformSwimlaneIdsSelector = createDeepEqualSelector(
	[
		(state: State) => {
			const swimlanes = platformSwimlanesSelector(state);
			return swimlanes.map(({ id }) => id);
		},
	],
	(swimlaneIds) => swimlaneIds,
);

export const getFirstOpenSwimlaneIdSelector = createSelector(
	[platformSwimlaneIdsSelector, getCollapsedSwimlanes],
	(swimlaneIds, collapsedSwimlanesIds) =>
		swimlaneIds.find((swimlaneId) => !collapsedSwimlanesIds.includes(swimlaneId)),
);

export const getColumnTheme = (state: State, columnId: ColumnId): ColumnTheme => {
	if (hasNoActiveSprintStateSelector(state)) {
		return ColumnTheme.Default;
	}

	const issuesCount = getColumnTotalIssuesCount(state, columnId);
	const column = getColumnById(state, columnId);
	if (
		column?.type === ColumnType.STATUS &&
		column?.maxIssueCount != null &&
		issuesCount > column?.maxIssueCount
	) {
		return ColumnTheme.Danger;
	}
	if (
		column?.type === ColumnType.STATUS &&
		column?.minIssueCount != null &&
		issuesCount < column?.minIssueCount
	) {
		return ColumnTheme.Warning;
	}
	return ColumnTheme.Default;
};

export const isColumnUpdating = createSelector([getColumnsUI], (columnUi) =>
	memoize((columnId) => !!get(columnUi, [columnId, 'isUpdating'], false)),
);

export const isAnyColumnUpdating = createSelector(
	[getColumnsUI],
	(columnUi) => columnUi && Object.keys(columnUi).some((key) => columnUi[key].isUpdating === true),
);

export const isCardDragDisabled = createSelector(
	[isAnyColumnUpdating],
	// Disable card drag if column updating or ICC is not complete
	(anyColumnsUpdating) => memoize((id) => anyColumnsUpdating || id <= 0),
);

export const getCardDndType = createSelector([getSwimlaneMode], (swimlaneMode) =>
	memoize((isLastSwimlane) =>
		!isLastSwimlane && swimlaneMode === SWIMLANE_BY_SUBTASK.id ? SUBTASK_DND_TYPE : CARD_DND_TYPE,
	),
);

export const isDraggingSubtaskSelector = createSelector(
	[getSwimlaneMode, getDraggingCardId, getIssueChildren],
	(swimlaneMode, draggingCardId, issueChildren) =>
		swimlaneMode !== SWIMLANE_BY_SUBTASK.id &&
		draggingCardId != null &&
		draggingCardId in issueChildren,
);

export const isSwimlaneTransitionZoneEnabledForSubtask = createSelector(
	[getSwimlanes, getSwimlaneMode, boardIssuesSelector, getDraggingCardId, getIsCMPBoard],
	(swimlanes, swimlaneMode, boardIssues, draggingCardId, isCMPBoard) =>
		memoize((isLastSwimlane) => {
			const issue = boardIssues[String(draggingCardId)];
			const draggingCardSwimlaneId = getSwimlaneId(swimlaneMode, swimlanes, issue);

			// For TMP: If parent issue is unmapped, subtasks will appear in last swimlane
			// subtask in last swimlane can transition only in that swimlane
			// also subtasks in other swimlane cannot transition to last swimlane

			return isLastSwimlane
				? isCMPBoard || draggingCardSwimlaneId === ChildlessSwimlane.id
				: draggingCardSwimlaneId !== ChildlessSwimlane.id;
		}),
);

export const getAppliedIssueTypeFilters = createSelector([getWorkFilters], (filters) =>
	get(filters, ['values', ISSUE_TYPE], undefined),
);

export const getLastFilteredIssueTypeId = createSelector(
	[getAppliedIssueTypeFilters],
	(issueTypes) => {
		if (issueTypes && issueTypes.length) {
			return Number(last(issueTypes));
		}

		return undefined;
	},
);

export const getAppliedProjectFilters = createSelector([getWorkFilters], (filters) =>
	get(filters, ['values', ISSUE_PROJECT], []),
);

export const getAppliedDependenciesFilters = createSelector([getWorkFilters], (filters) =>
	get(filters, ['values', DEPENDENCIES], []),
);

export const getLastFilteredProjectId = createSelector([getAppliedProjectFilters], (projects) => {
	if (projects && projects.length) {
		return Number(last(projects));
	}
	return undefined;
});

export const getHasAppliedEpicFilters = createSelector(
	[getWorkFilters],
	(filters) => !!get(filters, ['values', ISSUE_PARENT], undefined)?.length,
);

export const validFilterValuesSelector = createSelector(
	[
		getWorkFilters,
		getAssigneeAccountIds,
		issueLabelsSelector,
		issueParentIdsSelector,
		issueProjectIdsSelector,
		issueTypeFilterSelector,
		requestTypesSelector,
		statusesSelector,
		activeSprintsSelector,
		getCustomFilters,
		getIsCMPBoard,
	],
	(
		filters,
		validAssigneeIds,
		validLabels,
		validIssueParentIds,
		validIssueProjectIds,
		validIssueTypes,
		validRequestTypes,
		validStatuses,
		activeSprints,
		customFilters,
		isCMPBoard,
	) => {
		const filterTypeWhitelist = [
			LABEL,
			ASSIGNEE,
			TEXT,
			ISSUE_PARENT,
			ISSUE_PROJECT,
			ISSUE_TYPE,
			CUSTOM_FILTER, // intersection based validation not possible as it uses relay/not extracted clientside
			SPRINT,
			REQUEST_TYPE,
			STATUS,
			...(fg('dependency_visualisation_program_board_fe_and_be') ? [DEPENDENCIES] : []),
		];
		const validFilters: Partial<Filters> = {};
		if (filters.values[ASSIGNEE]) {
			const inter = intersection(validAssigneeIds, filters.values[ASSIGNEE]);
			if (inter.length > 0) {
				validFilters[ASSIGNEE] = toAccountIdArray(inter);
			}
		}

		if (filters.values[LABEL]) {
			const inter = intersection(validLabels, filters.values[LABEL]);
			if (inter.length > 0) {
				validFilters[LABEL] = inter;
			}
		}

		if (filters.values[ISSUE_PARENT]) {
			const inter = intersection(validIssueParentIds, filters.values[ISSUE_PARENT]);
			if (inter.length > 0) {
				validFilters[ISSUE_PARENT] = toIssueIdArray(inter);
			}
		}

		if (filters.values[ISSUE_PROJECT]) {
			const inter = intersection(validIssueProjectIds, filters.values[ISSUE_PROJECT]);
			if (inter.length > 0) {
				validFilters[ISSUE_PROJECT] = toProjectIdArray(inter);
			}
		}

		if (filters.values[ISSUE_TYPE]) {
			const inter = intersection(validIssueTypes, filters.values[ISSUE_TYPE]);
			if (inter.length > 0) {
				validFilters[ISSUE_TYPE] = toIssueTypeIdArray(inter);
			}
		}

		if (filters.values[REQUEST_TYPE]) {
			const inter = intersection(
				validRequestTypes.map(({ id }) => id),
				filters.values[REQUEST_TYPE],
			);
			if (inter.length > 0) {
				validFilters[REQUEST_TYPE] = inter;
			}
		}

		if (filters.values[STATUS]) {
			const inter = intersection(
				validStatuses.map(({ id }) => id),
				filters.values[STATUS],
			);
			if (inter.length > 0) {
				validFilters[STATUS] = inter;
			}
		}

		if (filters.values[SPRINT]) {
			const activeSprintIds = activeSprints?.map((sprint) => String(sprint.id)) ?? [];
			const inter = intersection(activeSprintIds, filters.values[SPRINT]);
			if (inter.length > 0) {
				validFilters[SPRINT] = inter;
			}
		}

		if (isCMPBoard && filters.values[CUSTOM_FILTER]) {
			const inter = intersection(
				customFilters.map(({ id }) => id),
				filters.values[CUSTOM_FILTER],
			);
			if (inter.length > 0) {
				validFilters[CUSTOM_FILTER] = inter;
			}
		}

		if (filters.values[DEPENDENCIES] && fg('dependency_visualisation_program_board_fe_and_be')) {
			const inter = intersection(
				Object.values(DependencyFilterOption),
				filters.values[DEPENDENCIES],
			);
			if (inter.length > 0) {
				validFilters[DEPENDENCIES] = inter;
			}
		}

		Object.keys(filters.values)
			.filter((filterType) => !filterTypeWhitelist.includes(filterType))
			.forEach((notAllowedFilter) => {
				throw new Error(`Please whitelist filter '${notAllowedFilter}' in work selectors`);
			});

		return validFilters;
	},
);

export const invalidFilterValuesSelector = createSelector(
	[
		getWorkFilters,
		getAssigneeAccountIds,
		issueLabelsSelector,
		issueParentIdsSelector,
		issueProjectIdsSelector,
		issueTypeFilterSelector,
		requestTypesSelector,
		statusesSelector,
		activeSprintsSelector,
		getCustomFilters,
		getIsCMPBoard,
	],
	(
		filters,
		validAssigneeIds,
		validLabels,
		validIssueParentIds,
		validIssueProjectIds,
		validIssueTypes,
		validRequestTypes,
		validStatuses,
		validSprints,
		validCustomFilters,
		isCMPBoard,
	) => {
		const filterTypeWhitelist = [
			LABEL,
			ASSIGNEE,
			TEXT,
			ISSUE_PARENT,
			ISSUE_PROJECT,
			ISSUE_TYPE,
			CUSTOM_FILTER, // difference based validation not possible as it uses relay/not extracted clientside
			SPRINT,
			REQUEST_TYPE,
			STATUS,
			...(fg('dependency_visualisation_program_board_fe_and_be') ? [DEPENDENCIES] : []),
		];
		const invalidFilters: Partial<Filters> = {};
		if (filters.values[ASSIGNEE]) {
			const diff = difference(filters.values[ASSIGNEE], validAssigneeIds);
			if (diff.length > 0) {
				invalidFilters[ASSIGNEE] = toAccountIdArray(diff);
			}
		}

		if (filters.values[LABEL]) {
			const diff = difference(filters.values[LABEL], validLabels);
			if (diff.length > 0) {
				invalidFilters[LABEL] = diff;
			}
		}

		if (filters.values[ISSUE_PARENT]) {
			const diff = difference(filters.values[ISSUE_PARENT], validIssueParentIds);
			if (diff.length > 0) {
				invalidFilters[ISSUE_PARENT] = toIssueIdArray(diff);
			}
		}

		if (filters.values[ISSUE_PROJECT]) {
			const diff = difference(filters.values[ISSUE_PROJECT], validIssueProjectIds);
			if (diff.length > 0) {
				invalidFilters[ISSUE_PROJECT] = toProjectIdArray(diff);
			}
		}

		if (filters.values[ISSUE_TYPE]) {
			const diff = difference(filters.values[ISSUE_TYPE], validIssueTypes);
			if (diff.length > 0) {
				invalidFilters[ISSUE_TYPE] = toIssueTypeIdArray(diff);
			}
		}

		if (filters.values[REQUEST_TYPE]) {
			const diff = difference(
				filters.values[REQUEST_TYPE],
				validRequestTypes.map(({ id }) => id),
			);
			if (diff.length > 0) {
				invalidFilters[REQUEST_TYPE] = diff;
			}
		}

		if (filters.values[STATUS]) {
			const diff = difference(
				filters.values[STATUS],
				validStatuses.map(({ id }) => id),
			);
			if (diff.length > 0) {
				invalidFilters[STATUS] = diff;
			}
		}

		if (filters.values[SPRINT]) {
			const activeSprintIds = validSprints?.map((sprint) => String(sprint.id)) ?? [];
			const diff = difference(filters.values[SPRINT], activeSprintIds);
			if (diff.length > 0) {
				invalidFilters[SPRINT] = diff;
			}
		}

		if (isCMPBoard && filters.values[CUSTOM_FILTER]) {
			const diff = difference(
				filters.values[CUSTOM_FILTER],
				validCustomFilters.map(({ id }) => id),
			);
			if (diff.length > 0) {
				invalidFilters[CUSTOM_FILTER] = diff;
			}
		}

		if (filters.values[DEPENDENCIES] && fg('dependency_visualisation_program_board_fe_and_be')) {
			const diff = difference(filters.values[DEPENDENCIES], Object.values(DependencyFilterOption));
			if (diff.length > 0) {
				invalidFilters[DEPENDENCIES] = diff;
			}
		}

		Object.keys(filters.values)
			.filter((filterType) => !filterTypeWhitelist.includes(filterType))
			.forEach((notAllowedFilter) => {
				throw new Error(`Please whitelist filter '${notAllowedFilter}' in work selectors`);
			});

		return invalidFilters;
	},
);

export const hasInvalidFilterValuesSelector = createSelector(
	[invalidFilterValuesSelector],
	(invalidFilterValues) => Object.keys(invalidFilterValues).length > 0,
);

export type IssueIdsByColumnMap = Record<ColumnIdKey, IssueId[]>;

export const getFilteredIssueIdsByColumn = createSelector(
	[getOrderedColumnIds, workFilteredIssuesSelector],
	(columnIds, issues) => {
		const issueIdsByColumnMap: IssueIdsByColumnMap = {};

		columnIds.forEach((columnId) => {
			const issuesIds = issues
				.filter((issue) => issue.columnId === columnId)
				.map((issue) => issue.id);
			issueIdsByColumnMap[String(columnId)] = issuesIds;
		});

		return issueIdsByColumnMap;
	},
);

export const platformIssuesBySwimlaneSelector = createSelector(
	[visiblePlatformIssuesSelector, getSwimlaneMode, getSwimlanes],
	(
		issues: PlatformIssue[],
		swimlaneMode: SwimlaneModeId | null | undefined,
		swimlanes,
	): IssuesBySwimlane => {
		if (!swimlaneMode || swimlaneMode === NO_SWIMLANE.id) {
			return {};
		}

		const issuesBySwimlane = groupBy(issues, (issue) => issue.swimlaneId);
		swimlanes.forEach(({ id }) => {
			if (issuesBySwimlane[id] === undefined) {
				issuesBySwimlane[id] = [];
			}
		});

		return Object.keys(issuesBySwimlane).reduce((accum: IssuesBySwimlane, swimlaneId: string) => {
			const issuesInSwimlane = issuesBySwimlane[swimlaneId];

			const issuesByColumnId = groupBy(issuesInSwimlane, (issue) => issue.columnId);
			// eslint-disable-next-line no-param-reassign
			accum[swimlaneId] = issuesByColumnId;

			return accum;
		}, {});
	},
);

// Note that the issues here are returned in their correct order as visible on the board
// even if swimlanes are enabled.
export const getFilteredIssueKeysAsRapidViewMap = createSelector(
	[getSwimlanes, getOrderedColumnIds, platformIssuesBySwimlaneSelector, platformIssuesSelector],
	(swimlanes, columnIds, issuesBySwimlane, platformIssues) => {
		// If there are any swimlanes, we need to call a different selector and handle it very differently
		if (swimlanes.length > 0) {
			// NOTE that we use the swimlanes array here instead of the dictionary returned. This
			// is to preserve the ordering of the swimlanes AS they are rendered/displayed on screen.
			return columnIds.map((columnId) =>
				swimlanes
					.filter((swimlane) => swimlane.id in issuesBySwimlane)
					.flatMap((swimlane) => issuesBySwimlane[swimlane.id][columnId] ?? [])
					.map((issue) => issue.key),
			);
		}

		// Otherwise we just grab all filtered issues and return them in the correct shape
		return columnIds.map((columnId) =>
			platformIssues.filter((issue) => issue.columnId === columnId).map((issue) => issue.key),
		);
	},
);

const getCardAmountByColumn = (
	issueCountPerColumn: Array<{
		amount: number;
		key: ColumnIdKey;
	}>,
	limit: number,
) => {
	let remainingCards = limit;
	let columnCount = issueCountPerColumn.length;
	return issueCountPerColumn.map(({ key, amount }) => {
		const columnLimit = Math.min(amount, Math.ceil(remainingCards / columnCount));
		columnCount -= 1;
		remainingCards -= columnLimit;
		return {
			key,
			amount: columnLimit,
		};
	});
};

const getTruncatedIssueIdsBySortedColumns = (
	issueIdsByColumn: IssueIdsByColumnMap,
	limit: number,
): IssueIdsByColumnMap => {
	// Sort column by issue count in ascending order so we handle the column with the least cards first.
	// This ensures that we do not have empty columns where there should be issues.
	const sortedIssueCountPerColumn = Object.keys(issueIdsByColumn)
		.map((column) => ({
			key: column,
			amount: values(issueIdsByColumn[column]).flat().length,
		}))
		.sort((a, b) => Number(a.amount) - Number(b.amount));
	// Calculate the number of cards allowed in each column
	const allowedCardCountPerColumn = getCardAmountByColumn(sortedIssueCountPerColumn, limit);
	// Populate mapping of swimlane to columns to issues with number of issues from the calculated limits
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const issueIdsByColumnWithLimit: Record<string, any> = {};
	allowedCardCountPerColumn
		.map(({ key, amount }) => ({ column: key, columnIssueLimit: amount }))
		.forEach(({ column, columnIssueLimit }) => {
			issueIdsByColumnWithLimit[column] = issueIdsByColumn[column].slice(0, columnIssueLimit);
		});

	return issueIdsByColumnWithLimit;
};

export const getTruncatedIssueIdsByColumn = createSelector(
	[getFilteredIssueIdsByColumn],
	(issueIdsByColumn) => getTruncatedIssueIdsBySortedColumns(issueIdsByColumn, SSR_CARD_LIMIT),
);

export const getTruncatedIssueIdsBySwimlane = createSelector(
	[platformIssueIdsBySwimlaneSelector, getSwimlanes],
	(issueIdsBySwimlane, swimlanes) => {
		// Get a list of pairings that contain swimlane id and the calculate the number of cards allowed
		// per swimlane considering the SSR card limit. We want to greedily assign the number of cards up
		// to the limit rather than equally spreading cards among swimlanes.
		let remainingCards = SSR_CARD_LIMIT;
		const allowedCardCountPerSwimlane = swimlanes
			.map(({ id }) => ({
				key: id,
				amount: values(issueIdsBySwimlane[id]).flat().length,
			}))
			.map(({ key, amount }) => {
				const swimlaneLimit = Math.min(amount, remainingCards);
				remainingCards -= swimlaneLimit;
				return {
					key,
					amount: swimlaneLimit,
				};
			});
		// For each swimlane, iterate through each column to calculate how many and which cards to select
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const truncatedIssueIdsBySwimlane: Record<string, any> = {};
		allowedCardCountPerSwimlane
			.map(({ key, amount }) => ({ swimlane: key, swimlaneIssueLimit: amount }))
			.forEach(({ swimlane, swimlaneIssueLimit }) => {
				truncatedIssueIdsBySwimlane[swimlane] = getTruncatedIssueIdsBySortedColumns(
					issueIdsBySwimlane[swimlane],
					swimlaneIssueLimit,
				);
			});

		return truncatedIssueIdsBySwimlane;
	},
);

export const getColumnIssuesCountWithLimit = (state: State, columnId: ColumnId): number => {
	const swimlaneMode = getSwimlaneMode(state);
	if (!swimlaneMode || swimlaneMode === NO_SWIMLANE.id) {
		return getTruncatedIssueIdsByColumn(state)[String(columnId)].length;
	}
	const issueIdsBySwimlane = getTruncatedIssueIdsBySwimlane(state);
	return Object.keys(issueIdsBySwimlane)
		.map((swimlaneId) => issueIdsBySwimlane[swimlaneId])
		.reduce((acc, issueIdsByColumn) => acc + (issueIdsByColumn[String(columnId)]?.length || 0), 0);
};

export const filteredIssueParentIdsSelector = createSelector(
	[getWorkFilters, getIsIncrementPlanningBoard],
	(filters, isIncrementPlanningBoard) =>
		get(filters, ['values', ISSUE_PARENT], []).map((issueParentId) =>
			// Scenario parent ids are strings on the program board
			isIncrementPlanningBoard ? issueParentId : Number(issueParentId),
		),
);

export const lastFilteredIssueParentIdSelector = createSelector(
	[filteredIssueParentIdsSelector, getIsIncrementPlanningBoard],
	(filteredIssueParentIds, isIncrementPlanningBoard) => {
		if (isIncrementPlanningBoard) {
			const lastFiltered = !isEmpty(filteredIssueParentIds) ? last(filteredIssueParentIds) : null;
			return !isNil(lastFiltered) ? lastFiltered : null;
		}
		return !isEmpty(filteredIssueParentIds) ? Number(last(filteredIssueParentIds)) : null;
	},
);

export const workDataWithDevStatusSelector = createSelector(
	makeIssueDevStatusSelector,
	(issueDevStatusSelector) =>
		memoizeOne((workData: WorkData) => ({
			...workData,
			issues: issueDevStatusSelector(workData.issues),
		})),
);

export const isIssueVisible = createSelector([workFilteredIssuesSelector], (issues) =>
	memoize((id: IssueId) => issues.some((issue) => issue.id === id)),
);

export const getInvisibleIssueIdsFromClientSideFiltersOnly = createSelector(
	[getWorkFilters, workIssuesSelector, getIssues, getPeople],
	(workFilters, issues, issueHash, people) => {
		const visibleIssueIds = filter(workFilters.values, issues, issueHash, people).map(
			(issue) => issue.id,
		);

		return memoize((issueIds: IssueId[]): IssueId[] =>
			issueIds.filter((issueId) => !visibleIssueIds.includes(issueId)),
		);
	},
);

export const getInvisibleIssueKeys = createSelector(
	workFilteredIssuesSelector,
	(workFilteredIssues) => {
		const visibleIssueKeys = workFilteredIssues.map((issue) => issue.key);

		return memoize((issueKeys: IssueKey[]): IssueKey[] =>
			issueKeys.filter((issueKey) => !visibleIssueKeys.includes(issueKey)),
		);
	},
);

export const getActiveIssueWithIcc = (state: State): IssueId | null =>
	state.ui.cards.cardWithIcc.mainActiveIssue;

export const isActiveCardSelector = createSelector(
	[
		(
			state: State,
			props: {
				issueId: IssueId;
			},
		) => props.issueId,
		(state: State) => getActiveIssueWithIcc(state),
	],
	(targetIssueId, activeIssueId) => targetIssueId === activeIssueId,
);

/**
 * Should render rich elements only for:
 * 1. The active (hovered/focused) card itself.
 * 2. The card that's one below the active card (if it exists).
 */
export const shouldRenderIcc = createSelector(
	[
		(
			state: State,
			props: {
				issueId: IssueId;
			},
		) => props.issueId,
		(state: State) => getIssueIdWithAboveICCOpen(state),
		(state: State) => getActiveIssueWithIcc(state),
		(state: State) => getOrderedIssuesByColumn(state),
		(state: State) => getFilteredIssueIdsByColumn(state),
	],
	(
		targetIssueId,
		issueIdWithAboveICCOpen,
		activeIssueId,
		orderedIssuesByColumn: IssuesByColumnMap,
		getVisibleIssuesForColumn,
	) => {
		if (issueIdWithAboveICCOpen != null && issueIdWithAboveICCOpen === targetIssueId) {
			return true;
		}

		if (!activeIssueId) {
			return false;
		}
		if (targetIssueId === activeIssueId) {
			return true;
		}

		const activeColumn = Object.entries(orderedIssuesByColumn).find(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			([, issues]: [any, any]) =>
				Array.isArray(issues) &&
				issues.find((issue) => {
					// Flow currently doesn't keep types when Object.entries() is used
					// https://github.com/facebook/flow/issues/2174
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					const typedIssue: Issue = issue as any;
					return typedIssue.id === activeIssueId;
				}),
		);
		if (!activeColumn) {
			return false;
		}

		const [activeColumnId] = activeColumn;
		const visibleIssuesOfActiveColumn = getVisibleIssuesForColumn[activeColumnId];
		if (!Array.isArray(visibleIssuesOfActiveColumn)) {
			return false;
		}

		const activeIssueIndex = visibleIssuesOfActiveColumn.indexOf(activeIssueId);
		const targetIssueIndex = visibleIssuesOfActiveColumn.indexOf(targetIssueId);
		return (
			activeIssueIndex !== -1 &&
			targetIssueIndex !== -1 &&
			targetIssueIndex === activeIssueIndex + 1
		);
	},
);

const getIssuesIdsByColumn = (
	state: State,
	{
		swimlaneId,
		isUnscheduledWorkColumn,
	}: {
		swimlaneId: SwimlaneId | null | undefined;
		columnId: ColumnId;
		isUnscheduledWorkColumn?: boolean;
	},
): IssueIdsByColumnMap | undefined => {
	// In the Program Board, the unscheduled work column adds an additional suffix to the id of the
	// swimlane so that the swimlane collapse behaviour can be independent between the board and the
	// unscheduled work column. For all other column behaviour, we want to truncate this suffix.
	const swimlaneIdTruncated = !isNil(swimlaneId)
		? truncateUnscheduledWorkSwimlaneId(swimlaneId, isUnscheduledWorkColumn)
		: swimlaneId;

	if (__SERVER__) {
		return isNil(swimlaneIdTruncated)
			? getTruncatedIssueIdsByColumn(state)
			: getTruncatedIssueIdsBySwimlane(state)[swimlaneIdTruncated];
	}
	return isNil(swimlaneIdTruncated)
		? getFilteredIssueIdsByColumn(state)
		: platformIssueIdsBySwimlaneSelector(state)[swimlaneIdTruncated];
};

export const getIssuesIdsForColumn = createSelector(
	[
		(
			state: State,
			props: {
				columnId: ColumnId;
				swimlaneId: SwimlaneId | null | undefined;
			},
		) => props.columnId,
		getIssuesIdsByColumn,
	],
	(columnId, issueIdsByColumn) => issueIdsByColumn && issueIdsByColumn[String(columnId)],
);

export const makeGetIssueEntriesForColumnSelector = () =>
	createDeepEqualSelector(
		[
			getIssuesIdsForColumn,
			getIsCMPBoard,
			getSwimlaneMode,
			getParentIdByIssueChildSelector,
			issueParentIdsSelector,
			isBoardRankable,
		],
		(issueIds, isCMPBoard, swimlaneMode, getParentIdByIssueChild, issueParentIds, isRankable) => {
			const skipGroupingChildren = !isCMPBoard || swimlaneMode === SWIMLANE_BY_SUBTASK.id;

			if (skipGroupingChildren) {
				return issueIds?.map<IssueEntry>((issueId) => ({ type: 'issue', issueId }));
			}

			const issueEntries: IssueEntry[] = [];

			if (!isRankable) {
				// Filter out issue children which has parent. As it should be rendered in a group
				const shouldRenderInCardGroup = (issueId: IssueId) => !!getParentIdByIssueChild(issueId);

				// An Epics are rendered as the swimlane headers when grouped by Epic
				// Otherwise Epics are rendered as cards
				const shouldRenderAsSwimlaneHeader = (issueId: IssueId) =>
					swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id && issueParentIds.includes(String(issueId));

				issueIds?.forEach((issueId) => {
					if (!shouldRenderInCardGroup(issueId) && !shouldRenderAsSwimlaneHeader(issueId)) {
						issueEntries.push({ type: 'issue', issueId });
						return;
					}

					const parentId = getParentIdByIssueChild(issueId);

					if (!parentId) return;

					const previousItem = issueEntries[issueEntries.length - 1];

					if (previousItem?.type === 'group' && previousItem.parentId === parentId) {
						previousItem.issueIds.push(issueId);
						return;
					}

					const group: IssueEntryGroup = {
						type: 'group',
						parentId,
						issueIds: [issueId],
						isAttachedToParent: isRankable
							? issueIds.includes(parentId)
							: previousItem?.type === 'issue' && previousItem.issueId === parentId,
					};

					issueEntries.push(group);
				});
			} else {
				// Filter out issue children and issue parents if swimlane by epic
				const nonChildIssueIds = (issueIds || []).filter(
					(issueId) =>
						!getParentIdByIssueChild(issueId) &&
						(swimlaneMode !== SWIMLANE_BY_PARENT_ISSUE.id ||
							!issueParentIds.includes(String(issueId))),
				);
				const childIssueIds = (issueIds || []).filter((issue) => !!getParentIdByIssueChild(issue));
				const childIdsByParent = groupBy(childIssueIds, getParentIdByIssueChild);

				issueIds?.forEach((issueId) => {
					if (nonChildIssueIds.includes(issueId)) {
						issueEntries.push({ type: 'issue', issueId });
						return;
					}

					const parentId = getParentIdByIssueChild(issueId);

					if (!parentId || !(parentId in childIdsByParent)) return;

					issueEntries.push({
						type: 'group',
						parentId,
						issueIds: childIdsByParent[parentId],
						isAttachedToParent: nonChildIssueIds.includes(parentId),
					});
					delete childIdsByParent[parentId];
				});
			}

			return issueEntries;
		},
	);

const filteredInIssuesById = createSelector([workFilteredIssuesSelector], (filteredIssues) =>
	keyBy(filteredIssues, 'id'),
);

/**
 * Used in the IP board for finding issues with issue links on both unscheduled column and the main board
 */
export const getIssuesEntriesWithIssueLinks = createSelector([filteredInIssuesById], (issues) => {
	const issuesWithIssueLinks = pickBy(
		issues,
		(issue) => issue.issueLinks && issue.issueLinks.length > 0,
	);
	return issuesWithIssueLinks;
});

/**
 * Used in the IP board for finding issues with issue links that are not part of the unscheduled work column
 */
export const getIssuesEntriesWithIssueLinksInBoard = createSelector(
	[filteredInIssuesById],
	(issues) => {
		const issuesWithIssueLinks = pickBy(
			issues,
			(issue) =>
				issue.issueLinks && issue.issueLinks.length > 0 && issue.columnId !== UNSCHEDULED_COLUMN_ID,
		);
		return issuesWithIssueLinks;
	},
);

/**
 * Used in the IP board for finding ids of issues with issue links that are not part of the unscheduled work column
 */
export const getIssuesWithIssueLinksInBoardIds = createSelector(
	[getIssuesEntriesWithIssueLinksInBoard],
	(issuesEntriesWithIssueLinksInBoard) => {
		return Object.keys(issuesEntriesWithIssueLinksInBoard);
	},
);

/**
 * Identifies a board list (swimlane / column pair or just column when there's no swimlane).
 */
export type ListKey = string;

export const getListKey = (columnId: ColumnId, swimlaneId?: string): ListKey =>
	`${columnId}:${swimlaneId ?? NO_SWIMLANE.id}`;

/**
 * Maps each board list to its issue IDs.
 */
export type IssueIdsByListKey = Record<ListKey, IssueId[]>;

export const issueColumnIdSelector = createSelector(
	[boardIssuesSelector],
	(issues) => (issueId: IssueId) => issues[String(issueId)]?.columnId,
);

export const issueSwimlaneIdSelector = createSelector(
	[boardIssuesSelector, getSwimlaneMode, getSwimlanes, getIsCMPBoard],
	(issues, swimlaneMode, availableSwimlanes, isCMPBoard) => (boardIssueId: IssueId) => {
		const issue = issues[String(boardIssueId)];
		if (!issue) return null;

		return getSwimlaneId(
			swimlaneMode,
			availableSwimlanes,
			issue,
			isCMPBoard,
			(issueId: string) => issues[issueId],
		);
	},
);

export const getStatusByIssueIdSelector = createSelector(
	[getIssueById, getStatuses],
	(issue: Issue, statuses) => statuses[issue.statusId],
);

export const getActiveIssueKey = createSelector(
	[getActiveIssueWithIcc, makeGetIssueKeyFromIssueId],
	(activeIssueId, issueKeyFromIssueId) => {
		if (isNil(activeIssueId)) {
			return null;
		}
		return issueKeyFromIssueId(activeIssueId);
	},
);

export const makeGetIssueParentForSwimlaneHeader = createSelector(
	[getIssueParents, platformSwimlaneSelector],
	(issueParents, swimlaneSelector) =>
		memoize((swimlaneId) => {
			const { mode, parentId } = swimlaneSelector(swimlaneId) || {};
			if (mode === SWIMLANE_BY_SUBTASK.id && parentId) return issueParents[parentId] || null;
			if (mode === SWIMLANE_BY_PARENT_ISSUE.id) return issueParents[swimlaneId] || null;
			return null;
		}),
);
