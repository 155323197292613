import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.component.label',
		defaultMessage: 'Components',
		description: 'This is the label of a dropdown menu to filter Jira issues based on components.',
	},
	placeholder: {
		id: 'software-filters.filters.component.placeholder',
		defaultMessage: 'Search component filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
