import { freeze } from 'icepick';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import uniqWith from 'lodash/uniqWith';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { categoryIdForStatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	CHILD_HIERARCHY_TYPE,
	PARENT_HIERARCHY_TYPE,
	BASE_HIERARCHY_TYPE,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { StatusTransition } from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import { getNextgenSettingsIssueTypesUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import type { IssueTypeId as IssueTypeIdString } from '@atlassian/jira-shared-types/src/general.tsx';
import { getIssueTypesUrl } from '../../../common/urls/index.tsx';
import type {
	CardTransition,
	IssueTypeTransitions,
} from '../../../model/card-transition/card-transition-types.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type { IssueTypeId, SwimlaneId } from '../../../model/issue/issue-types.tsx';
import type { CardType, TransitionId } from '../../../model/software/software-types.tsx';
import {
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
} from '../../../model/swimlane/swimlane-modes.tsx';
import { ChildlessSwimlane } from '../../../model/swimlane/swimlane-types.tsx';
import type { CardTransitionsState } from '../../reducers/entities/card-transitions/types.tsx';
import type { IssueProjectsState } from '../../reducers/entities/issue-projects/types.tsx';
import { getColumns, getStatusColumn, getStatusesByColumnId } from '../column/column-selectors.tsx';
import { createIsIccEnabledForCardType } from '../inline-create/inline-create-selectors.tsx';
import {
	issueBaseTypesSelector,
	issueChildrenTypesSelector,
	defaultIssueTypeIdSelector,
} from '../issue-type/issue-type-selectors.tsx';
import {
	getCardTransitions,
	projectKeySelector,
	getIsCMPBoard,
	getIssueProjects,
	projectIdSelector,
	getIsIncrementPlanningBoard,
	getIssueTypesByProject,
	getIssues,
} from '../software/software-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import {
	getHasAppliedEpicFilters,
	lastFilteredIssueParentIdSelector,
	getAppliedIssueTypeFilters,
} from '../work/work-selectors.tsx';

export const allowedCardTypesForIccSelector = createSelector(
	[
		getCardTransitions,
		issueBaseTypesSelector,
		issueChildrenTypesSelector,
		getHasAppliedEpicFilters,
		createIsIccEnabledForCardType,
		getIsCMPBoard,
		getSwimlaneMode,
		getAppliedIssueTypeFilters,
	],
	(
		cardTransitions: CardTransitionsState,
		baseIssueTypes: CardType[],
		issueChildrenIssueTypes: CardType[],
		hasAppliedEpicFilters: boolean,
		isIccEnabledForCardType,
		isCMPBoard: boolean,
		swimlaneMode?: SwimlaneModeId | null,
		issueTypeFilters?: IssueTypeIdString[],
	) =>
		memoize(
			(
				columnId: ColumnId,
				swimlaneId?: SwimlaneId | null,
				isAddingSubtaskToGroup?: boolean,
			): CardType[] => {
				let types = baseIssueTypes;
				let shouldFilterOutEpicIssueTypes = false;
				let shouldFilterOutChildIssueTypes = false;

				const isSubtaskSwimlane =
					swimlaneMode === SWIMLANE_BY_SUBTASK.id &&
					swimlaneId !== ChildlessSwimlane.id &&
					swimlaneId !== null;

				if (isSubtaskSwimlane || isAddingSubtaskToGroup) {
					types = issueChildrenIssueTypes;
				} else {
					shouldFilterOutEpicIssueTypes =
						hasAppliedEpicFilters || swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id;
					shouldFilterOutChildIssueTypes = true;
				}

				return types.filter((issueType) => {
					if (
						shouldFilterOutChildIssueTypes &&
						issueType.hierarchyLevelType === CHILD_HIERARCHY_TYPE
					) {
						return false;
					}

					if (
						shouldFilterOutEpicIssueTypes &&
						issueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE
					) {
						return false;
					}

					if (issueTypeFilters?.length && !issueTypeFilters.includes(issueType.id)) {
						return false;
					}

					// This is a temporary condition to fix a bug until use_backend_tmp_icc_config_ is rolled out
					// Delete this condition when cleaning up use_backend_tmp_icc_config_
					if (isCMPBoard) {
						return isIccEnabledForCardType(columnId, issueType.id);
					}

					return fg('use_backend_tmp_icc_config_')
						? isIccEnabledForCardType(columnId, issueType.id)
						: get(cardTransitions, ['columnIssueTypeTransitions', columnId, issueType.id], []).some(
								(transition) => transition.isInitial || transition.isGlobal,
							);
				});
			},
			(columnId, swimlaneId, isAddingSubtaskToGroup) =>
				`${columnId}:${swimlaneId || ''}${isAddingSubtaskToGroup ? ':subtask' : ''}}`,
		),
);
// @deprecated remove when cleanin up `use_backend_tmp_icc_config_`
export const transitionIDForCardCreateSelector = createSelector(
	getCardTransitions,
	(cardTransitions: CardTransitionsState) =>
		memoize(
			(columnId: ColumnId, issueTypeId: IssueTypeId) => {
				// check if there is an initial transition, if there is no need to pass anything
				const initialTransition = cardTransitions.columnIssueTypeTransitions[String(columnId)]?.[
					String(issueTypeId)
				]?.find((transition) => transition.isInitial);

				if (initialTransition)
					return {
						transitionId: null,
						statusId: initialTransition.destinationStatusId,
					};

				const globalTransition = cardTransitions.columnIssueTypeTransitions[String(columnId)]?.[
					String(issueTypeId)
				]?.find((transition) => transition.isGlobal);
				if (globalTransition)
					return {
						transitionId: String(globalTransition.id),
						statusId: globalTransition.destinationStatusId,
					};

				// this is an error state, card create is not possible without initial or global transition
				return null;
			},
			(columnId, issueTypeId) => `${columnId}:${issueTypeId}`,
		),
);

export const transitionIDForCardCreateSelectorNew = createSelector(
	getCardTransitions,
	(cardTransitions: CardTransitionsState) =>
		memoize(
			(columnId: ColumnId, issueTypeId: IssueTypeId) => {
				if (
					Object.values(cardTransitions.columnIssueTypeTransitions).every((issueTypeTransitions) =>
						isEmpty(issueTypeTransitions),
					)
				) {
					// User probably doesn't have transition permissions
					return null;
				}

				const columnIssueTypeTransitions =
					cardTransitions.columnIssueTypeTransitions[String(columnId)];

				// check if there is an initial transition, if there is no need to pass anything
				const initialTransition = columnIssueTypeTransitions?.[String(issueTypeId)]?.find(
					(transition) => transition.isInitial,
				);

				if (initialTransition)
					return {
						transitionId: null,
						statusId: initialTransition.destinationStatusId,
					};

				const globalTransition = columnIssueTypeTransitions?.[String(issueTypeId)]?.find(
					(transition) => transition.isGlobal,
				);
				if (globalTransition)
					return {
						transitionId: String(globalTransition.id),
						statusId: globalTransition.destinationStatusId,
					};

				fireErrorAnalytics({
					error: new Error(
						`No initial or global transition found for column ${columnId} and issue type ${issueTypeId}`,
					),
					meta: {
						// Remove `new` from id when cleaning up `use_backend_tmp_icc_config_`
						id: 'board.cardCreateSelectors.transitionIDForCardCreateSelectorNew.missing.transition',
						packageName: 'jiraSoftwareBoard',
						teamName: 'a4t-tanuki',
					},
				});
				// this is an error state, card create is not possible without initial or global transition
				return null;
			},
			(columnId, issueTypeId) => `${columnId}:${issueTypeId}`,
		),
);

const checkIfTransitionIsEqual = (source: StatusTransition, target: StatusTransition) =>
	source.statusId === target.statusId;

export const getInitialTransitionsForColumn = createSelector(
	[getCardTransitions],
	(cardTransitions) =>
		(columnId: ColumnId): IssueTypeTransitions =>
			cardTransitions.columnIssueTypeTransitions[String(columnId)],
);

export const makeGetStatusTransitionsForTypeOld = createSelector(
	[getStatusesByColumnId, defaultIssueTypeIdSelector, issueBaseTypesSelector],
	(statusesByColumnId, defaultIssueTypeId, projectIssueTypes) =>
		memoize(
			(
				columnId: ColumnId,
				issueTypeTransitions?: IssueTypeTransitions,
				selectedIssueTypeId?: string | number | null,
			): StatusTransition[] => {
				if (!issueTypeTransitions) return [];

				const statuses = statusesByColumnId(columnId);
				const issueTypeId = selectedIssueTypeId ?? defaultIssueTypeId ?? projectIssueTypes?.[0]?.id;

				if (!issueTypeId) {
					return [];
				}

				const transitionsForIssueType = issueTypeTransitions[String(issueTypeId)];

				const statusTransitions =
					transitionsForIssueType?.reduce((acc: StatusTransition[], transition: CardTransition) => {
						if (transition.isInitial || transition.isGlobal) {
							const toStatus = statuses.find(
								(status) => status.id === transition.destinationStatusId,
							);

							if (toStatus) {
								acc.push({
									transitionId: transition.id,
									name: toStatus.name,
									statusId: toStatus.id,
									isInitial: transition.isInitial,
									statusCategoryId: categoryIdForStatusCategory(toStatus.category),
								});
							}
						}

						return acc;
					}, []) || [];

				const deduplicatedTransitions = uniqWith(statusTransitions, checkIfTransitionIsEqual);

				return deduplicatedTransitions.length > 1 ? deduplicatedTransitions : [];
			},
			(columnId, _, selectedIssueTypeId) => `${columnId}:${selectedIssueTypeId}`,
		),
);

export const makeGetStatusTransitionsForType = createSelector(
	[getColumns, defaultIssueTypeIdSelector, issueBaseTypesSelector],
	(columns, defaultIssueTypeId, projectIssueTypes) =>
		memoize(
			(
				columnId: ColumnId,
				issueTypeTransitions?: IssueTypeTransitions,
				selectedIssueTypeId?: string | number | null,
			): StatusTransition[] => {
				if (!issueTypeTransitions) return [];

				const statuses = getStatusColumn(columns[String(columnId)])?.statuses ?? [];
				const issueTypeId = selectedIssueTypeId ?? defaultIssueTypeId ?? projectIssueTypes?.[0]?.id;

				if (!issueTypeId) {
					return [];
				}

				const transitionsForIssueType = issueTypeTransitions[String(issueTypeId)];

				const statusTransitions =
					transitionsForIssueType?.reduce((acc: StatusTransition[], transition: CardTransition) => {
						if (transition.isInitial || transition.isGlobal) {
							const toStatus = statuses.find(
								(status) => status.id === transition.destinationStatusId,
							);

							if (toStatus) {
								acc.push({
									transitionId: transition.id,
									name: toStatus.name,
									statusId: toStatus.id,
									isInitial: transition.isInitial,
									statusCategoryId: categoryIdForStatusCategory(toStatus.category),
								});
							}
						}

						return acc;
					}, []) || [];

				const deduplicatedTransitions = uniqWith(statusTransitions, checkIfTransitionIsEqual);

				return deduplicatedTransitions.length > 1 ? deduplicatedTransitions : [];
			},
			(columnId, _, selectedIssueTypeId) => `${columnId}:${selectedIssueTypeId}`,
		),
);

export const manageIssueTypesLinkSelector = createSelector(
	[getIsCMPBoard, projectKeySelector],
	(isCMPBoard, projectKey) =>
		isCMPBoard
			? getIssueTypesUrl(projectKey)
			: getNextgenSettingsIssueTypesUrl(SOFTWARE_PROJECT, projectKey),
);

export const getIssueProjectsForIssueCreation = createSelector(
	[getIssueProjects, getIssueTypesByProject, getIsIncrementPlanningBoard],
	(issueProjects, issueTypesByProject, isIPBoard) => {
		if (!isIPBoard) {
			return issueProjects;
		}
		const initialProjects: IssueProjectsState = {};
		// Filter out projects that don't have story or epic level issue types
		return Object.entries(issueProjects).reduce((acc, [projectKey, project]) => {
			const projectIssueTypes = issueTypesByProject[project.id];
			if (
				projectIssueTypes?.some(
					(issueType) =>
						issueType.hierarchyLevelType === PARENT_HIERARCHY_TYPE ||
						issueType.hierarchyLevelType === BASE_HIERARCHY_TYPE,
				)
			) {
				acc[projectKey] = project;
			}
			return acc;
		}, initialProjects);
	},
);

export const getDefaultProjectIdForIssueCreation = createSelector(
	[getIssueProjectsForIssueCreation, projectIdSelector],
	(allIssueProjects, projectIdFromConfiguration): number | undefined => {
		const projectFromConfiguration = allIssueProjects[projectIdFromConfiguration];
		if (!isNil(projectFromConfiguration)) {
			return projectIdFromConfiguration;
		}
		const projectKeys = Object.keys(allIssueProjects);
		return projectKeys.length > 0 ? Number(projectKeys[0]) : undefined;
	},
);

const emptyArray = freeze([]);

export const getIssueTypesForIssueCreation = createSelector(
	[getIssueTypesByProject, getIsIncrementPlanningBoard],
	(issueTypesByProject, isIPBoard) =>
		memoize((projectId: number): CardType[] => {
			if (isIPBoard && !isNil(issueTypesByProject)) {
				return issueTypesByProject[`${projectId}`] ?? emptyArray;
			}
			return emptyArray;
		}),
);

export const getParentIdForIssueCreation = createSelector(
	[lastFilteredIssueParentIdSelector, getIssues, getIsIncrementPlanningBoard],
	(lastFilteredIssueParentId, issues, isIPBoard) =>
		memoize((projectId: number): number | string | null => {
			if (!isIPBoard) {
				return lastFilteredIssueParentId;
			}
			// for the IP board, we should check if the epic belongs to the project
			const parentIssue = isNil(lastFilteredIssueParentId)
				? null
				: issues[lastFilteredIssueParentId];
			return parentIssue?.projectId === projectId ? lastFilteredIssueParentId : null;
		}),
);

export const getDestinationStatusSelector = createSelector(
	[getCardTransitions],
	(cardTransitions) =>
		(columnId: ColumnId, issueTypeId: IssueTypeId, transitionId: TransitionId) => {
			const { columnIssueTypeTransitions, issueTypeStatus } = cardTransitions;
			const transitionsByIssueType = columnIssueTypeTransitions[columnId];
			if (isNil(transitionsByIssueType)) {
				return null;
			}

			const transition = transitionsByIssueType[issueTypeId]?.find(
				(trans) => trans.id === transitionId,
			);
			if (
				isNil(transition) ||
				isNil(transition.destinationStatusId) ||
				isNil(issueTypeStatus[issueTypeId])
			) {
				return null;
			}
			return issueTypeStatus[issueTypeId][transition.destinationStatusId];
		},
);
