import React, { type ComponentType, memo } from 'react';
import { styled } from '@compiled/react';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import ErrorIcon from '@atlaskit/icon/core/error';
import ArrowRightIconOld from '@atlaskit/icon/glyph/arrow-right';
import ErrorIconOld from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';

import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';

import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type {
	MessageDescriptorV2 as MessageDescriptor,
	IntlShapeV2 as IntlShape,
} from '@atlassian/jira-intl/src/v2/types.tsx';
import RuleImage from '@atlassian/jira-icons/src/ui/lightning-bolt/index.tsx';
import type { IssueStatus } from '../../../../../common/types.tsx';

export type ColumnTransitionLabelProps = {
	isTransitionAvailable: boolean;
	isTransitionGlobal: boolean;
	target: string;
	source: string | null | undefined;
	sourceStatus: IssueStatus | null | undefined;
	targetStatusCategory: StatusCategory;
	showMessage: boolean;
	notAvailableMessage: MessageDescriptor;
};

type PropsWithIntl = ColumnTransitionLabelProps & {
	intl: IntlShape;
};

const ColumnTransitionLabel: ComponentType<PropsWithIntl> = memo(
	({
		source,
		target,
		targetStatusCategory,
		isTransitionAvailable,
		isTransitionGlobal,
		notAvailableMessage,
		showMessage,
		sourceStatus,
		intl: { formatMessage },
	}: PropsWithIntl) => {
		const shouldRenderSource = source || sourceStatus;

		/**
		 * check null against the source and the sourceStatus.
		 * if they are all null. Do not display the source and arrow
		 * e.g.
		 * label with source: TO DO -> IN PROGRESS
		 * label without source: IN PROGRESS
		 */
		return (
			<LabelPositioningWrapper data-testid="platform-board-kit.ui.column.column-transition-zones-container.transition-zone.label.label-positioning-wrapper">
				<TransitionRow>
					<InlineWrapper>
						{shouldRenderSource && isTransitionGlobal && sourceStatus?.category ? (
							<StatusLozenge category={sourceStatus.category} name={sourceStatus.name} />
						) : (
							<span>{source}</span>
						)}
					</InlineWrapper>

					{shouldRenderSource && (
						<InlineWrapper>
							<ArrowRightIcon
								label="arrow"
								spacing="spacious"
								color={token('color.icon', colors.N800)}
								LEGACY_fallbackIcon={ArrowRightIconOld}
								LEGACY_size="small"
								LEGACY_primaryColor={token('color.text', colors.N800)}
							/>
						</InlineWrapper>
					)}

					<InlineFlex>
						{!isTransitionAvailable && (
							<>
								<RuleImage
									label=""
									size="small"
									primaryColor={token('color.icon.subtlest', colors.N300)}
								/>
								<ErrorIcon
									label={formatMessage(notAvailableMessage)}
									color={token('color.text.danger', colors.R500)}
									spacing="spacious"
									LEGACY_fallbackIcon={ErrorIconOld}
									LEGACY_size="small"
									LEGACY_primaryColor={token('color.text.danger', colors.R500)}
								/>
							</>
						)}
						<StatusLozenge
							category={targetStatusCategory}
							name={target}
							isFullWidth={!shouldRenderSource}
						/>
					</InlineFlex>
				</TransitionRow>
				{!isTransitionAvailable && showMessage && (
					<LabelRow>
						<LabelMessage>{formatMessage(notAvailableMessage)}</LabelMessage>
					</LabelRow>
				)}
			</LabelPositioningWrapper>
		);
	},
);

export default injectIntl(ColumnTransitionLabel);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelRow = styled.div({
	display: 'flex',
	maxWidth: '100%',
	textOverflow: 'ellipsis',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelPositioningWrapper = styled.div({
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	maxWidth: '100%',
	textOverflow: 'ellipsis',
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineWrapper = styled.span({
	display: 'inline-flex',
	maxWidth: 'unset',
	whiteSpace: 'unset',
	textOverflow: 'ellipsis',
	verticalAlign: 'middle',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionRow = styled.span({
	textAlign: 'center',
	verticalAlign: 'middle',
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineFlex = styled.span({
	display: 'inline-flex',
	whiteSpace: 'unset',
	textOverflow: 'ellipsis',
	verticalAlign: 'middle',
	maxWidth: '100%',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelMessage = styled.div({
	marginTop: `${token('space.100', '8px')}`,
	textAlign: 'center',
	font: token('font.body.small'),
});
