import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { mapScoreToBgColor } from '../../../../common/utils.tsx';

export const Score = ({ score }: { score: number }) => (
	<ScoreContainer backgroundColor={mapScoreToBgColor(score)}>{score}</ScoreContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ScoreContainer = styled.div<{ backgroundColor: string }>({
	display: 'inline-flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.inverse', colors.N0)}`,
	borderRadius: '3px',
	marginLeft: token('space.050', '4px'),
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	height: '16px',
});
