import { useRef } from 'react';
import { useContainerContext } from '@atlassian/jira-providers-container-context/src/main.tsx';

/*
 * Use project context directly i.e.
 * const { loading, data: projectContext } = useProjectContext();
 * const isProjectType: boolean = !loading && projectContext != null;
 */
export const useIsProjectType_DEPRECATED_DO_NOT_USE = ({
	onIsProjectTypeUpdated,
}: {
	onIsProjectTypeUpdated: (arg1: { isProjectType: boolean }) => void;
}) => {
	const [{ data: containerContext }] = useContainerContext();
	const isProjectType = containerContext?.contextType === 'project';
	const previousIsProjectType = useRef<boolean | undefined>();
	if (previousIsProjectType.current !== isProjectType) {
		onIsProjectTypeUpdated({ isProjectType });
		previousIsProjectType.current = isProjectType;
	}
};
