import type { TemplateRecommendationId } from './types.tsx';

export const AUTOMATION_MORE_DETAILS_URL =
	'https://support.atlassian.com/cloud-automation/docs/jira-automation-triggers/#Manual-trigger-from-issue';

export const templateRecommendationPriority: TemplateRecommendationId[] = [
	'devops_template_6', // When a version is created → create a LaunchDarkly flag
	'security_template_1', // When a critical vulnerability is found → create an issue
	'devops_template_10', // When a deployment to production fails → then send a slack message
	'devops_template_8', // When a deployment to production is successful → then transition issue to done
];

const AUTOMATION_PREFIX = 'automation';
export const AUTOMATION_RECOMMENDATIONS_PREFIX = `${AUTOMATION_PREFIX}.recommendations`;

export const PACKAGE_NAME = 'jiraAutomationPlatform';
