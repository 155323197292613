import { useMemo } from 'react';
import {
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD_SETTINGS,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';

const userLocatedBoardRoutes = [
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD,
	ROUTE_NAMES_RAPIDBOARD_USER_BACKLOG,
	ROUTE_NAMES_RAPIDBOARD_USER_BOARD_SETTINGS,
];

export const useIsUserLocatedBoard = (): boolean => {
	const { name } = useCurrentRoute();

	return useMemo(() => userLocatedBoardRoutes.includes(name), [name]);
};
