import { useEffect } from 'react';
import { fireErrorAnalytic } from '../../common/utils.tsx';
import ScrollUpdateEmitter from '../scroll-update-emitter/index.tsx';

type ScrollToProps = {
	x: number;
	y: number;
};

/*
 * This hook performs scroll updates according event emitter's instructions.
 */
export const useScrollUpdateHandler = (scrollEl: HTMLElement | null) => {
	useEffect(() => {
		if (scrollEl == null) {
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		const showEventHandler = () => {
			Object.assign(scrollEl.style, {
				overflowX: 'auto',
				overflowY: 'auto',
			});
		};
		const hideEventHandler = () => {
			Object.assign(scrollEl.style, {
				overflowX: 'hidden',
				overflowY: 'hidden',
			});
		};

		const scrollToEventHandler = (payload: ScrollToProps = { x: 0, y: 0 }) => {
			Object.assign(scrollEl, {
				scrollLeft: payload.x,
				scrollTop: payload.y,
			});
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const errorHandler = (error: any) => {
			fireErrorAnalytic({ error });
		};

		ScrollUpdateEmitter.on('show', showEventHandler);
		ScrollUpdateEmitter.on('hide', hideEventHandler);
		ScrollUpdateEmitter.on('scrollTo', scrollToEventHandler);
		ScrollUpdateEmitter.on('error', errorHandler);

		return () => {
			ScrollUpdateEmitter?.removeListener('show', showEventHandler);
			ScrollUpdateEmitter?.removeListener('hide', hideEventHandler);
			ScrollUpdateEmitter?.removeListener('scrollTo', scrollToEventHandler);
			ScrollUpdateEmitter?.removeListener('error', errorHandler);
		};
	}, [scrollEl]);
};
