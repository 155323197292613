import { isFedRamp } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import type { TriggerPointKeyType } from '@atlassian/jira-issue-transition-common-types/src/common/types/index.tsx';

export const isNewTransitionScreenEnabled = (
	preferNewIssueTransitionScreen: boolean,
	triggerPointKey?: TriggerPointKeyType,
) => {
	if (isFedRamp()) return true;

	if (!fg('render-modern-issue-transition')) return false;

	if (!triggerPointKey) return false;

	return preferNewIssueTransitionScreen || fg('issue-transition-customisation-for-srb');
};
