import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { DEBOUNCE_CONFIG_MS } from './constants.tsx';

const cache: Map<string, ReturnType<typeof setTimeout>> = new Map();

// testing utility
export const isCacheEmpty = () => cache.size === 0;

export const assertDefined = <T,>(
	value: T,
	id: string,
	packageName: string,
	teamName: string,
	skipSentry = false,
	validator?: (value: T) => boolean,
) => {
	try {
		if (validator ? !validator(value) : value === undefined || value === null) {
			const cacheKey = `${packageName}.${id}`;
			if (cache.has(cacheKey)) {
				clearTimeout(cache.get(cacheKey));
			}

			const handle = setTimeout(() => {
				fireErrorAnalytics({
					error: new Error(`${cacheKey} failed assert`),
					meta: {
						id,
						packageName,
						teamName,
					},
					skipSentry,
				});

				cache.delete(cacheKey);
			}, DEBOUNCE_CONFIG_MS);

			cache.set(cacheKey, handle);
		}
	} catch (error) {
		// just in case
		// fireErrorAnalytics will throw if __SERVER__ is true
	}
};
