/** @jsx jsx */
import React, { memo, type MouseEvent } from 'react';
import { css, jsx } from '@compiled/react';
import type { ThemeTokens } from '@atlaskit/button';
import CustomThemeButton from '@atlaskit/button/custom-theme-button';
import AddIcon from '@atlaskit/icon/core/migration/add';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TriggerProps } from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { ModalTriggerButton } from '@atlassian/jira-software-modal-dialog/src/ui/modal-trigger-button/index.tsx';

const labelWrapperStyles = css({
	display: 'block',
	marginLeft: token('space.050', '4px'),
	overflowX: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		overflowX: 'hidden',
	},
});

const contentWrapperStyles = css({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'left',
});

export type Props = {
	isDisabled?: boolean;
	isSticky: boolean;
	label: string;
	ariaLabel?: string;
	ariaLabelledBy?: string;
	onClick: (arg1: MouseEvent<HTMLElement>) => void;
	isModalTrigger?: boolean;
	shouldFocusTrigger?: boolean;
};

const VisibleTrigger = ({
	isDisabled = false,
	isSticky = false,
	label,
	ariaLabel,
	ariaLabelledBy,
	onClick,
	isModalTrigger,
	shouldFocusTrigger = false,
}: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const iccButtonTheme = (currentTheme: any, props: any): ThemeTokens => {
		const themeProps = { ...props };
		const { buttonStyles, spinnerStyles } = currentTheme(themeProps);
		return {
			buttonStyles: {
				...buttonStyles,
				marginTop: token('space.025', '2px'),
				marginBottom: token('space.025', '2px'),
				height: `${gridSize * 4.5}px`,
				alignItems: 'center',
			},
			spinnerStyles,
		};
	};
	const iconColor = isDisabled
		? token('color.icon.disabled', colors.N70)
		: token('color.icon', colors.N500);

	const shouldShowTooltip = label.length > 16;

	const renderStickyContent = () => (
		<span css={contentWrapperStyles}>
			<AddIcon
				testId="platform-inline-card-create.ui.trigger.visible.add-icon"
				label=""
				color={iconColor}
				LEGACY_size="small"
			/>
			<div css={labelWrapperStyles}>
				<Box xcss={labelContextStyles} as="span">
					{label}
				</Box>
			</div>
		</span>
	);

	const ButtonComponent = isModalTrigger ? ModalTriggerButton : CustomThemeButton;

	const renderButtonComponent = (triggerProps?: TriggerProps) => (
		<ButtonComponent
			{...triggerProps}
			iconBefore={
				isSticky ? undefined : (
					<AddIcon
						testId="platform-inline-card-create.ui.trigger.visible.add-icon"
						label=""
						color={iconColor}
						LEGACY_size="small"
					/>
				)
			}
			appearance="subtle"
			theme={iccButtonTheme}
			shouldFitContainer
			onClick={onClick}
			isDisabled={isDisabled}
			testId="platform-inline-card-create.ui.trigger.visible.button"
			aria-labelledby={ariaLabelledBy}
			{...(fg('jfp-a11y-team_board_identical-create-issue-button')
				? { 'aria-label': ariaLabel || label }
				: { 'aria-label': label })}
			autoFocus={shouldFocusTrigger}
		>
			{isSticky ? (
				renderStickyContent()
			) : (
				<Box xcss={labelContextStyles} as="span">
					{label}
				</Box>
			)}
		</ButtonComponent>
	);

	return shouldShowTooltip ? (
		<Tooltip content={label} tag="span">
			{renderButtonComponent}
		</Tooltip>
	) : (
		renderButtonComponent()
	);
};

const labelContextStyles = xcss({
	display: 'block',
	textAlign: 'left',
	textOverflow: 'ellipsis',
	overflowX: 'hidden',
});

export { VisibleTrigger };
export default memo<Props>(VisibleTrigger);
