import { freeze } from 'icepick';
import assign from 'lodash/assign';
import isNil from 'lodash/isNil';
import type { CardProps } from '@atlassian/jira-platform-board-kit/src/ui/card/types.tsx';
import type { SwimlaneModeId } from '@atlassian/jira-platform-board-kit/src/ui/swimlane/types.tsx';
import type { CustomField } from '@atlassian/jira-platform-card/src/common/ui/custom-fields/index.tsx';
import {
	type DevStatusMap,
	type DevStatusResponsePayload,
	type IssueLoadResponsePayload,
	type Issue,
	TMP_ORIGINAL_TIME_ESTIMATE,
} from '../../model/issue/issue-types.tsx';
import {
	SWIMLANE_BY_ASSIGNEE,
	SWIMLANE_BY_PARENT_ISSUE,
	SWIMLANE_BY_SUBTASK,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_PROJECT,
	SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST,
	SWIMLANE_BY_TEAM,
	SWIMLANE_BY_REQUEST_TYPE,
} from '../../model/swimlane/swimlane-modes.tsx';
import {
	ParentlessSwimlane,
	ChildlessSwimlane,
	type SwimlaneValues,
	UnassignedSwimlane,
	TeamlessSwimlane,
	ASSIGNEE,
	type Swimlane,
	UnassignedRequestTypeSwimlane,
} from '../../model/swimlane/swimlane-types.tsx';

export type PlatformCard = CardProps['issue'];

export const getDevStatusFromLatestDevActivity = (
	latestDevActivity: DevStatusResponsePayload,
): DevStatusMap => {
	const { activity } = latestDevActivity;
	const devStatusMap: DevStatusMap = {};

	Object.keys(activity).forEach((issueIdString) => {
		const { dataType, count, stateCount, state: prState, open } = activity[issueIdString];

		if (stateCount && stateCount > 0) {
			if (open || prState === 'OPEN') {
				devStatusMap[issueIdString] = { activity: 'pullrequest', count: stateCount };
			} else if (prState === 'MERGED') {
				devStatusMap[issueIdString] = { activity: 'merged', count: stateCount };
			} else if (prState === 'DECLINED') {
				devStatusMap[issueIdString] = { activity: 'declined', count: stateCount };
			}
		} else if (count > 0) {
			if (dataType === 'branch') {
				devStatusMap[issueIdString] = { activity: 'branch', count };
			} else if (dataType === 'repository') {
				devStatusMap[issueIdString] = { activity: 'commit', count };
			} else if (dataType === 'deployment') {
				devStatusMap[issueIdString] = { activity: 'deployment', count };
			} else if (dataType === 'design') {
				devStatusMap[issueIdString] = { activity: 'design', count };
			}
		}
	});

	return devStatusMap;
};

export const getSwimlaneId = (
	swimlaneMode: SwimlaneModeId | null | undefined,
	availableSwimlanes: Swimlane[],
	issue: Issue,
	isCMPBoard?: boolean,
	getIssueById?: (issueId: string) => Issue,
): string | null => {
	if (
		swimlaneMode === SWIMLANE_BY_ASSIGNEE.id ||
		swimlaneMode === SWIMLANE_BY_ASSIGNEE_UNASSIGNED_FIRST.id
	) {
		return issue.assigneeAccountId || UnassignedSwimlane.id;
	}

	if (swimlaneMode === SWIMLANE_BY_PARENT_ISSUE.id) {
		// Try getting the epic from a subtask
		if (isCMPBoard && getIssueById && issue.parentId) {
			const issueParentIds = availableSwimlanes.map(({ id }) => id);
			const isChildOfIssueParent = issueParentIds.indexOf(String(issue.parentId)) > -1;
			if (!isChildOfIssueParent) {
				return String(getIssueById(String(issue.parentId))?.parentId || ParentlessSwimlane.id);
			}
		}
		return issue.parentId ? String(issue.parentId) : ParentlessSwimlane.id;
	}
	if (swimlaneMode === SWIMLANE_BY_SUBTASK.id) {
		// in the case the a story issue has been rendered as swimlane header,
		// we should not assign it to a swimlane
		if (isCMPBoard && availableSwimlanes.map(({ id }) => id).includes(String(issue.id))) {
			return null;
		}

		return issue.parentId &&
			availableSwimlanes.find((swimlane) => swimlane.id === String(issue.parentId))
			? String(issue.parentId)
			: ChildlessSwimlane.id;
	}

	if (swimlaneMode === SWIMLANE_BY_JQL.id) {
		return (
			availableSwimlanes.find(
				(swimlane) =>
					swimlane.values?.type === 'JQL' && swimlane.values?.issueIds.includes(issue.id),
			)?.id ?? null
		);
	}

	if (isCMPBoard && swimlaneMode === SWIMLANE_BY_PROJECT.id) {
		return String(issue.projectId);
	}

	if (swimlaneMode === SWIMLANE_BY_TEAM.id) {
		return issue.teamId || TeamlessSwimlane.id;
	}

	if (swimlaneMode === SWIMLANE_BY_REQUEST_TYPE.id) {
		return issue.requestTypeId ?? UnassignedRequestTypeSwimlane.id;
	}

	return null;
};

/**
 * Sometimes HTML field entries return just white-space. On those cases we want
 * to omit the fields from the DOM to avoid a waste of space.
 */
export function isEmptyField(field: CustomField): boolean {
	if (
		field.renderer === 'text' ||
		field.renderer === 'epiclink' ||
		field.renderer === 'status' ||
		field.renderer === 'url'
	) {
		return !field.text || !field.text.trim();
	}

	if (field.renderer === 'html' || field.renderer === 'date' || field.renderer === 'duration') {
		return !field.html || field.html?.trim() === '&nbsp;';
	}

	if (field.renderer === 'number') {
		return field.value == null;
	}

	if (field.renderer === 'sla') {
		return field.value === undefined;
	}

	// This is a type-assertion so all CustomField types are handled above
	const _invalid: never = field;
	// If we have an unknown custom field type assume it's non-empty
	return false;
}

export const transformSoftwareToPlatformIssue = (
	issue: Issue,
	swimlaneMode: SwimlaneModeId | null | undefined | null | undefined,
	availableSwimlanes: Swimlane[],
	isCMPBoard?: boolean,
	getIssueById?: (issueId: string) => Issue,
): PlatformCard => {
	const labels = {
		labels: Array.isArray(issue.labels) ? [...issue.labels] : [],
	};
	const card: PlatformCard = {
		id: issue.id,
		url: '',
		key: issue.key,
		summary: issue.summary,
		assigneeAccountId: issue.assigneeAccountId || null,
		typeId: String(issue.typeId),
		typeName: issue.typeName,
		typeUrl: issue.typeUrl,
		statusId: issue.statusId,
		columnId: issue.columnId,
		projectId: issue.projectId,
		swimlaneId: getSwimlaneId(swimlaneMode, availableSwimlanes, issue, isCMPBoard, getIssueById),
		isFlagged: issue.isFlagged,
		// @ts-expect-error isDone is not a part of PlatformCard
		isDone: issue.isDone,
		parentId: issue.parentId,
		estimateFieldId: issue.estimate?.fieldId,
		estimate:
			issue.estimate?.type === TMP_ORIGINAL_TIME_ESTIMATE
				? issue.estimate?.valueAsText
				: issue.estimate?.value,
		estimateUnit: issue.estimate?.displayName,
		dueDate: issue.dueDate,
		priority: issue.priority ? { ...issue.priority } : null,
		numCompleteChildren: issue.numCompleteChildren,
		numTotalChildren: issue.numTotalChildren,
		daysInColumn: issue.daysInColumn ?? undefined,
		cardColor: issue.cardColor,
		customFields: issue.extraFields?.filter((field) => !isEmptyField(field)),
		issueLinks: issue.issueLinks,
		hasScenarioChanges: issue.hasScenarioChanges,
		fixVersions: issue.fixVersions,
		originals: issue.originals,
		...labels,
	};

	const { devStatus } = issue;

	if (devStatus) {
		const deploymentDetails = devStatus.deploymentDetails
			? { deploymentDetails: devStatus.deploymentDetails }
			: {};
		card.devStatusField = {
			activity: devStatus.activity,
			count: devStatus.count,
			...deploymentDetails,
		};
	}

	return card;
};

export const updateIssueWithServerResponseData = (
	issue: Issue,
	response: IssueLoadResponsePayload | null,
): Issue => {
	if (!response) {
		return issue;
	}

	const {
		id,
		key,
		fields: {
			summary,
			status,
			project,
			assignee,
			issuetype,
			labels,
			resolutiondate,
			priority,
			duedate,
		},
	} = response;

	const newIssue: Issue = {
		...issue,
		id: Number(id),
		key,
		summary,
		statusId: Number(status.id),
		projectId: Number(project.id),
		isDone: !isNil(resolutiondate),
		priority: priority || null,
		dueDate: duedate || null,
	};

	if (assignee) {
		assign(newIssue, {
			assigneeAccountId: assignee.accountId,
			assigneeName: assignee.name,
		});
	}

	if (issuetype) {
		assign(newIssue, {
			typeId: issuetype.id,
			typeName: issuetype.name,
			typeUrl: issuetype.iconUrl,
		});
	}

	if (labels) {
		assign(newIssue, {
			labels,
		});
	}

	return newIssue;
};

const emptyObj = freeze({});

export const getIssueCreateOrUpdateFields = (
	values: SwimlaneValues,
): { assignee?: { id: string | null } } => {
	if (values.type === ASSIGNEE) {
		return {
			assignee: {
				id: values.assigneeAccountId,
			},
		};
	}
	return emptyObj;
};

export const getIssueAssignee = (
	swimlaneValues: SwimlaneValues,
): { assigneeAccountId?: string | null } =>
	swimlaneValues.type === ASSIGNEE
		? {
				assigneeAccountId: swimlaneValues.assigneeAccountId,
			}
		: emptyObj;
