export const CAN_MANAGE_LABS = 'CAN_MANAGE_LABS' as const;
export const CAN_RANK_COLUMNS = 'CAN_RANK_COLUMNS' as const;
export const CAN_EDIT_BOARD = 'CAN_EDIT_BOARD' as const;
export const CAN_EDIT_ISSUE = 'CAN_EDIT_ISSUE' as const;
export const CAN_RENAME_COLUMNS = 'CAN_RENAME_COLUMNS' as const;
export const CAN_CREATE_COLUMNS = 'CAN_CREATE_COLUMNS' as const;
export const CAN_CREATE_CARDS = 'CAN_CREATE_CARDS' as const;
export const CAN_DELETE_ISSUE = 'CAN_DELETE_ISSUE' as const;
export const CAN_MANAGE_SPRINT = 'CAN_MANAGE_SPRINT' as const;
export const CAN_MANAGE_AUTOMATION = 'CAN_MANAGE_AUTOMATION' as const;
export const CAN_RELEASE = 'CAN_RELEASE' as const;
export const CAN_ARCHIVE_ISSUE = 'CAN_ARCHIVE_ISSUE' as const;

export type Permissions = {
	CAN_RANK_COLUMNS?: boolean;
	CAN_EDIT_BOARD?: boolean;
	CAN_RENAME_COLUMNS?: boolean;
	CAN_CREATE_COLUMNS?: boolean;
	CAN_MANAGE_LABS?: boolean;
	CAN_CREATE_CARDS?: boolean;
	CAN_EDIT_ISSUE?: boolean;
	CAN_DELETE_ISSUE?: boolean;
	CAN_MANAGE_SPRINT?: boolean;
	CAN_MANAGE_AUTOMATION?: boolean;
	CAN_RELEASE?: boolean;
	CAN_ARCHIVE_ISSUE?: boolean;
};
