/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	zIndex,
	columnHeaderHeight as headerHeight,
} from '../../../common/constants/styles/index.tsx';

export default function SwimlaneWrapper({
	flexibleColumns,
	minHeight,
	isVirtualised,
	hideTickMask, // Hide tick mask when the swimlane header is not rendered
	children,
}: {
	flexibleColumns?: boolean;
	minHeight?: number;
	isVirtualised?: boolean;
	hideTickMask?: boolean;
	children: ReactNode;
}) {
	return (
		<Wrapper
			flexibleColumns={flexibleColumns}
			isVirtualised={isVirtualised}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={isVirtualised && minHeight ? { minHeight } : {}}
		>
			{!hideTickMask && (
				<SwimlaneTickMask
					isThemingEnabled={expVal('jira_theming_milestone_1', 'isEnabled', false)}
				/>
			)}
			{children}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{
	flexibleColumns?: boolean;
	isVirtualised?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isVirtualised }) => (isVirtualised ? 'block' : 'contents'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: ({ isVirtualised }) => (isVirtualised ? 'relative' : 'static'),
	willChange: 'transform',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ flexibleColumns }) => (flexibleColumns ? '100%' : 'auto'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SwimlaneTickMask = styled.div<{ isThemingEnabled: boolean }>(
	{
		position: 'sticky',
		top: 0,
		// marginTop needs to match header height

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		marginTop: `-${headerHeight}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		height: `${headerHeight}px`,

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: zIndex.swimlaneTickMask,
		marginLeft: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		!props.isThemingEnabled && {
			backgroundColor: token('elevation.surface', '#ffffff'),
		},
);
