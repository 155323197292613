/**
 * @generated SignedSource<<cec300618961c9d65790c4cb0784b3f2>>
 * @relayHash 907aa39da5c25390cd568618fe07bd66
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8ec4f82d159618ff8902e6d78d05a3b50c9ac3aec33f82a81632b89566ef80e0

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type boardFilterRefinementQuery$variables = {
  boardId: string;
  cloudId: string;
};
export type boardFilterRefinementQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"customFilter_softwareFilters_CustomFilterRelay" | "ui_filterRefinement_FilterRefinement">;
};
export type boardFilterRefinementQuery = {
  response: boardFilterRefinementQuery$data;
  variables: boardFilterRefinementQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = [
  {
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "kind": "ScalarField",
        "name": "accountId"
      },
      (v5/*: any*/),
      {
        "kind": "ScalarField",
        "name": "picture"
      },
      (v6/*: any*/)
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "ui_filterRefinement_FilterRefinement"
      },
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "customFilter_softwareFilters_CustomFilterRelay"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "boardFilterRefinementQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": (v7/*: any*/)
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraJqlBuilder",
            "kind": "LinkedField",
            "name": "jqlBuilder",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  },
                  {
                    "kind": "Literal",
                    "name": "jqlTerm",
                    "value": "assignee"
                  }
                ],
                "concreteType": "JiraJqlFieldValueConnection",
                "kind": "LinkedField",
                "name": "fieldValues",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraJqlFieldValueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v7/*: any*/),
                            "type": "JiraJqlUserFieldValue"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldValues(first:100,jqlTerm:\"assignee\")"
              }
            ]
          }
        ]
      },
      {
        "args": (v3/*: any*/),
        "concreteType": "BoardScope",
        "kind": "LinkedField",
        "name": "boardScope",
        "plural": false,
        "selections": [
          {
            "concreteType": "CustomFilter",
            "kind": "LinkedField",
            "name": "customFilters",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/)
            ]
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "8ec4f82d159618ff8902e6d78d05a3b50c9ac3aec33f82a81632b89566ef80e0",
    "metadata": {},
    "name": "boardFilterRefinementQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d7cf3fef8c50fd32f23374bf46b25ca5";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
