import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';
import {
	addBM3TimingsToUFO,
	type BM3Marks,
	type BM3TimingsConfig,
} from '@atlaskit/react-ufo/custom-timings';
import { addApdexToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { browserMetrics, metrics } from '@atlassian/browser-metrics';
import { PRODUCT_START_MARK } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { stopInitialPageLoadTimingFromPerformanceMarkStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { injectCallbackIntoGetBooleanFeatureFlag } from '../feature-flags/utils.tsx';
import type {
	ContextualAnalyticsData,
	NewContextualAnalyticsData,
	Profile,
	CustomProfile,
	FunctionMeasurement,
	FunctionMeasurements,
	TaskOptions,
	InitialRenderOptions,
	Timing,
	Task,
	Metrics,
	BrowserMetrics,
	ExecutionTimes,
	CollectCustomDataReport,
} from './types.tsx';
import { extractStartTime } from './utils.tsx';

export const SPA_APP_NAME = 'advanced-roadmaps' as const;
// https://hello.atlassian.net/wiki/spaces/AG/pages/751829585/Performance+model#Load
export const TTI_SLO = 4000;
export const TASK_SLO = 1000;

/* Performance instrumentation toolkit.
 *
 * It's designed as a stateful singleton to help keeping such cross-cutting concern non-invasive.
 * Consumer should be able to annotate start and end of the task, as well as functions to time,
 * without a need to pass any handlers/context around. Tasks should support nesting and re-entry.
 */

export const ARJ_EARLY_PRELOAD_PREFIX = 'jira.fe-spa.advanced-roadmaps.early-preload';
const INTERACTION_PREFIX = SPA_APP_NAME;
const ARJ_EARLY_PRELOAD = 'arj-early-preload';
const PRODUCT_PREFIX = 'jira.fe-spa.advanced-roadmaps.product';

const taskKeyToInteractionKey = (taskKey: string): string =>
	`${INTERACTION_PREFIX}.${taskKey.toLowerCase()}`;

export const INITIAL_RENDER_TASK_KEY = '__initial_render__';
const MAX_ENTRIES_PER_MARK = 5;

export class Monitor {
	browserMetrics: BrowserMetrics;

	metrics: Metrics;

	featureFlags: string[] = [];

	profiles = new Map<string, Profile>();

	timings: Timing[] = [];

	tasks = new Map<string, Task>();

	contextualAnalyticsData: ContextualAnalyticsData = { planId: '', scenarioId: '' };

	constructor(
		options?: {
			browserMetrics: BrowserMetrics | null | undefined;
			metrics: Metrics | null | undefined;
		} | null,
	) {
		this.browserMetrics = (options || {}).browserMetrics || browserMetrics;
		this.metrics = (options || {}).metrics || metrics;
		this.resetInitialRenderTask();
		injectCallbackIntoGetBooleanFeatureFlag((flag) => {
			this.registerBooleanFeatureFlag(flag);
		});
	}

	initializeContextualAnalyticsData(data: ContextualAnalyticsData) {
		this.contextualAnalyticsData = { ...data };
	}

	addContextualAnalyticsData(newData: NewContextualAnalyticsData) {
		this.contextualAnalyticsData = { ...this.contextualAnalyticsData, ...newData };
	}

	/**
	 * Add initial render stats to the ongoing UFO initial load metric
	 *
	 */
	finishInitialRender(options: InitialRenderOptions) {
		// Retrieve the task in which all the initial render stats are stored.
		const task = this.tasks.get(INITIAL_RENDER_TASK_KEY);
		if (!task) {
			return;
		}

		// Extract and record the early preload timings
		this.recordEarlyPreloadTimings();

		const bm3Marks: BM3Marks = {};
		this.timings.forEach(({ key, startTime, endTime }) => {
			bm3Marks[`${key}:start`] = startTime;
			bm3Marks[`${key}:end`] = endTime;
		});

		const bm3CustomTimings: BM3TimingsConfig[] = this.timings.map(({ key }) => ({
			key,
			startMark: `${key}:start`,
			endMark: `${key}:end`,
		}));

		addBM3TimingsToUFO(bm3Marks, bm3CustomTimings);

		// Record the product stop in the UFO
		stopInitialPageLoadTimingFromPerformanceMarkStart('product', PRODUCT_START_MARK, true);

		// Add custom data to UFO
		const { scalingFactors } = options;
		const customData = this.collectCustomData({ scalingFactors }, task.executionTimes, task.marks);
		addUFOCustomData(customData);

		/**
		 * Record the custom TTI value in UFO. UFO will use this TTI value. If this is not provided it, will use TTAI.
		 * The key is only used to create [bm3_tti] mark. The TTI value is recorded using `stopTime`.
		 * As we are using UFO metric names, we don't need seperate keys here.
		 * platform/packages/react-ufo/ufo-create-payload/src/index.ts#74
		 */

		addApdexToAll({ key: 'plans', stopTime: performance.now() });

		// Reset the initial render data.
		this.resetInitialRenderTask();
	}

	/**
	 * Start measuring the task and timed functions within.
	 *
	 * Task is a single report unit. Always pair `startTask` with `finishTask`, or the corresponding
	 * analytics event will not be sent. Tasks are re-entrant, starting the same task before it's
	 * finished has no effect, the monitor just continues to collect data. However, people looking
	 * at the data would most likely imply 1:1 start/finish correspondence, so please don't abuse
	 * re-entrance property.
	 *
	 * When timed function is executed within a task its timing is added to task's extra attributes,
	 * as well as provided scaleFactors and registered perf feature flags. Tasks support nesting,
	 * timed functions are reported for all running tasks.
	 *
	 * We track function timing in `executionTimes` instead of using `BrowserMetrics.setMark` as it
	 * gets ugly fast due to the need to keep track of start and end of multiple invocations encoded
	 * in mark keys.
	 *
	 * At the moment all the tasks are reported as `custom` metrics as it's a majority of our use-cases
	 * but we must add support for `interaction` metrics.
	 */
	startTask(taskKey: string, options?: TaskOptions | null, customStartTime?: number): void {
		if (this.tasks.has(taskKey)) {
			return;
		}
		const interaction = this.metrics.custom({
			key: taskKeyToInteractionKey(taskKey),
			featureFlags: this.featureFlags,
			slo: {
				threshold: TASK_SLO,
			},
		});
		customStartTime !== undefined
			? interaction.start({ startTime: customStartTime })
			: interaction.start();

		const justOptions = options || { scalingFactors: {} };
		const executionTimes = new Map<string, FunctionMeasurement[]>();
		const marks = new Set<string>();
		const task = {
			interaction,
			options: justOptions,
			executionTimes,
			marks,
		};
		this.tasks.set(taskKey, task);
		this.setMark(`${taskKey}_start`);
	}

	/**
	 * Finish measuring the task, prepare and send corresponding analytics event.
	 */
	finishTask(
		taskKey: string,
		options?:
			| ({
					sloSuccessThreshold?: number;
			  } & TaskOptions)
			| null,
	): void {
		const task = this.tasks.get(taskKey);
		if (!task) {
			return;
		}
		const { sloSuccessThreshold = TASK_SLO, scalingFactors } = options || {};
		// NOTE BM3 doesn't support setting SLO after interaction instantiation.
		// We violate its contract and set private field directly from here.
		// We do it only for the sake of Monitor's API backward compatibility.
		// We must move SLO into startTask ASAP and remove this abomination.
		if (typeof task.interaction.config !== 'undefined') {
			task.interaction.config.slo.threshold = sloSuccessThreshold;
		}
		if (scalingFactors) {
			// @ts-expect-error No overload matches this call.
			Object.assign(task.options.scalingFactors, scalingFactors);
		}
		const customData = this.collectCustomData(task.options, task.executionTimes, task.marks);
		task.interaction.stop({ customData });
		this.tasks.delete(taskKey);
		this.setMark(`${taskKey}_end`);
	}

	/**
	 * Decorate a function as a task, synchronously (task is finished as soon as the function returns).
	 */
	timeTask<A, R>(
		task: (...args: A[]) => R,
		taskKey: string,
		options?: TaskOptions | null,
	): (...args: A[]) => R {
		return (...args: A[]): R => {
			this.startTask(taskKey, options);
			const result = task(...args);
			this.finishTask(taskKey);
			return result;
		};
	}

	/**
	 * Mark a synchronous function as a timed one.
	 *
	 * When the function is executed in the presence of running tasks, its timing is reported to all of them.
	 *
	 * TODO Have a register of timed functions to warn on name clashes.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	timeFunction<A extends ReadonlyArray<any>, R>(
		fn: (...args: A) => R,
		fnKey: string,
	): (...args: A) => R {
		return (...args: A): R => {
			this.startProfile(fnKey);
			const result = fn(...args);
			this.finishProfile(fnKey);
			return result;
		};
	}

	/**
	 * Start measuring execution time from the current point to the corresponding `finishProfile` call.
	 *
	 * When the profile is finished in the presence of running tasks, its timing is reported to all of them.
	 * *Do not* use it if the same profile could be potentially started concurrently,
	 * use `startProfileConcurrent` instead.
	 */
	startProfile(profileKey: string) {
		if (this.profiles.has(profileKey)) {
			return;
		}
		this.profiles.set(profileKey, { start: performance.now() });
	}

	/**
	 * Finish profile and record its execution time to all running tasks.
	 *
	 * Use it only together with `startProfile`.
	 * To finish profile started by `startProfileConcurrent` use the returned function instead.
	 */
	finishProfile(profileKey: string) {
		const profile = this.profiles.get(profileKey);
		if (!profile) {
			return;
		}
		this.recordProfile(profileKey, profile);
		this.profiles.delete(profileKey);
	}

	/**
	 * Start measuring execution time from the current point to the call of the returned function.
	 *
	 * When the profile is finish in the presence of running tasks, its timing is reported to all of them.
	 * This method is safe for the potentially concurrent multiple entries by the cost of the need
	 * to pass finish function down to the profile finish point.
	 * *Do not* use `finishProfile` method for the profile started by `startProfileConcurrent`.
	 */
	startProfileConcurrent(profileKey: string): () => void {
		const profile = { start: performance.now() };
		return () => this.recordProfile(profileKey, profile);
	}

	/**
	 * Register a boolean flag to be added as an extra attribute to emitted events.
	 */
	registerBooleanFeatureFlag(flag: string) {
		this.featureFlags.push(flag);
	}

	/**
	 * Register a mark to be reported to all tasks running at that moment.
	 */
	setMark(markKey: string) {
		if (!performance.mark) {
			return;
		}
		performance.mark(markKey);

		for (const { marks } of this.tasks.values()) {
			marks.add(markKey);
		}
	}

	// Private.

	/**
	 * Create a fake task to track initial render info
	 */
	resetInitialRenderTask() {
		const executionTimes = new Map<string, FunctionMeasurement[]>();
		const marks = new Set<string>();
		const task = {
			interaction: {},
			options: { scalingFactors: {} },
			executionTimes,
			marks,
		};
		this.tasks.set(INITIAL_RENDER_TASK_KEY, task);
	}

	/**
	 * Record profile execution time to all running tasks.
	 */
	recordProfile(profileKey: string, profile: Profile) {
		const profileEnd = performance.now();
		const measurement = { start: profile.start, end: profileEnd };
		for (const { executionTimes } of this.tasks.values()) {
			const times = executionTimes.get(profileKey);
			if (times) {
				times.push(measurement);
			} else {
				executionTimes.set(profileKey, [measurement]);
			}
		}
	}

	/**
	 * Record custom profile execution time to all running tasks
	 */
	recordCustomProfile(profileKey: string, customProfile: CustomProfile) {
		const measurement = { ...customProfile };
		for (const { executionTimes } of this.tasks.values()) {
			const times = executionTimes.get(profileKey);
			if (times) {
				times.push(measurement);
			} else {
				executionTimes.set(profileKey, [measurement]);
			}
		}
	}

	/**
	 * Records the timing for preload requests
	 */
	recordEarlyPreloadTimings() {
		const resources = [
			'', // This entry represents the root entry
			'backlog',
			'info-metadata',
			'initial-checks-for-report',
			'plan-info-for-report',
		];

		this.timings.push(
			...resources.map((resource) => {
				/**
				 * Recording the performance information of the API before returning
				 * the response.
				 */
				const start = Math.floor(
					extractStartTime(`${ARJ_EARLY_PRELOAD_PREFIX}${resource ? `.${resource}` : ''}:start`),
				);
				const end = Math.ceil(
					extractStartTime(`${ARJ_EARLY_PRELOAD_PREFIX}${resource ? `.${resource}` : ''}:end`),
				);

				return {
					key: resource ? `${ARJ_EARLY_PRELOAD}/${resource}` : ARJ_EARLY_PRELOAD,
					startTime: start,
					endTime: end,
				};
			}),
		);
	}

	recordProductTimings() {
		const resources = ['parse-json'];
		this.timings.push(
			...resources.map((resource) => {
				/**
				 * Recording the performance information for preprocessing product data
				 */
				const start = Math.floor(extractStartTime(`${PRODUCT_PREFIX}.${resource}:start`));
				const end = Math.ceil(extractStartTime(`${PRODUCT_PREFIX}.${resource}:end`));

				return {
					key: `product/${resource}`,
					startTime: start,
					endTime: end,
				};
			}),
		);
	}

	/**
	 * Enrich task report with feature flags, scaling factors, and execution time stats.
	 *
	 * Reported stats: min, max, mean, standard deviation, number of runs.
	 *
	 * Note that dots in feature flag names (and any other attributes) will be replaced by underscores in Splunk.
	 * (TODO confirm if this is still true in BM3 case)
	 */
	collectCustomData(
		options: TaskOptions,
		executionTimes: FunctionMeasurements,
		marks: Set<string>,
	): {
		scalingFactors: {
			[key: string]: number | boolean | string;
		};
		executionTimes: ExecutionTimes;
		marks: {
			[key: string]: number;
		};
		planId: string;
		scenarioId: string;
	} {
		const pageStart = this.pageStart();
		const report: CollectCustomDataReport = {
			...this.contextualAnalyticsData,
			scalingFactors: {},
			executionTimes: {},
			marks: {},
		};
		const scalingFactors = options.scalingFactors || {};
		for (const factor of Object.keys(scalingFactors)) {
			report.scalingFactors[factor] = scalingFactors[factor];
		}
		for (const [fnKey, allTimes] of executionTimes) {
			// Skip leftovers from the previous route.
			const times = allTimes.filter(({ start }) => start >= pageStart);
			const n = times.length;
			if (n > 0) {
				const start = times[0].start;
				let lastEnd = start;
				let min = Infinity;
				let max = -Infinity;
				let sum = 0;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				for (const { start, end } of times) {
					const duration = end - start;
					sum += duration;
					min = Math.min(min, duration);
					max = Math.max(max, duration);
					lastEnd = Math.max(lastEnd, end);
				}
				const mean = sum / n;
				let devSum = 0;
				// eslint-disable-next-line @typescript-eslint/no-shadow
				for (const { start, end } of times) {
					const dev = end - start - mean;
					devSum += dev * dev;
				}
				const stddev = n > 1 ? Math.sqrt(devSum / (n - 1)) : 0;
				report.executionTimes[fnKey] = {
					start: start - pageStart,
					end: lastEnd - pageStart,
					min,
					max,
					mean,
					stddev,
					count: n,
					sum,
				};
			}
		}
		if (performance.getEntriesByName) {
			for (const mark of marks) {
				let i = 0;
				for (const m of performance.getEntriesByName(mark) || []) {
					if (i > MAX_ENTRIES_PER_MARK) {
						// No spam please.
						break;
					}
					const start = m.startTime - pageStart;
					// Skip leftovers from the previous route.
					if (start >= 0) {
						report.marks[`${mark}_${i}`] = start;
						i++;
					}
				}
			}
		}
		return report;
	}

	/** When the most recent page transition started.
	 *
	 * It will be 0 on initial load.
	 */
	pageStart(): number {
		return this.browserMetrics.getPageLoadMetric()?.startTime || 0;
	}
}

export const monitor = new Monitor();
