import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	enterFullScreen: {
		id: 'platform.fullscreen-button.enter-full-screen',
		defaultMessage: 'Enter full screen',
		description: 'Tooltip content for enter full screen button',
	},
	exitFullScreen: {
		id: 'platform.fullscreen-button.exit-full-screen',
		defaultMessage: 'Exit full screen',
		description: 'Tooltip content for exit full screen button',
	},
	fullScreenShortcut: {
		id: 'platform.fullscreen-button.full-screen-shortcut',
		defaultMessage: 'Z',
		description: 'Shortcut for entering or exiting full screen mode',
	},
});
