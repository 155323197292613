import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { Location } from '@atlassian/react-resource-router';

// This function should only be used with the NEW BoardSettings URL, as it assumes that the path
// is .../boards/4/settings/path-segment, and just drops the last path-segment and appends the view.
export function getBoardSettingsHref(view: string, location: Location) {
	if (getWillShowNav4() && fg('blu-6483-fe-add-board-settings-route')) {
		const basePath = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
		const query = location.search ? location.search : '';
		return `${basePath}/${view}${query}`;
	}
	return `${location.pathname}?config=${view}`;
}

export function isSelected(view: string, queryConfig = '', pathConfig = '') {
	if (getWillShowNav4() && fg('blu-6483-fe-add-board-settings-route')) {
		return pathConfig === view;
	}
	return queryConfig === view;
}

export function getBoardUrl(projectKey: string, boardId: string, isCMPBoard: boolean) {
	return `/jira/software${isCMPBoard ? '/c' : ''}/projects/${projectKey}/boards/${boardId}`;
}

export function getJSMBoardUrl(projectKey: string, boardId: string) {
	return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}`;
}

export function getJSMCalendarUrl(projectKey: string, boardId: string) {
	return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}/calendar`;
}

export function getUserBoardUrl(accountId: string, boardId: string) {
	return `/jira/people/${accountId}/boards/${boardId}`;
}

export function getBoardSettingsUrl(ordinaryBoardUrl: string) {
	return `${ordinaryBoardUrl}/settings`;
}
