/** @jsx jsx */
import React, { type SyntheticEvent, useEffect, useCallback, useState } from 'react';
import { css, jsx } from '@compiled/react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { SuccessAutoDismissFlag } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { getIssueDiscoveryUrl } from '../../common/utils.tsx';
import flagMessages from './messages.tsx';
import type { Sprint } from './types.tsx';

export type CardFilteredFlagProps = {
	cardKeys: string[];
	sprints?: Sprint[] | null;
	id: string | number;
	isAddedToSprintSuccess?: boolean;
	isAddedToSprintLoading?: boolean;
	issueMovedBetweenSwimlanes?: boolean;
	onDismissed?: (arg1: string | number) => void;
	onViewIssue?: (cardKey: string) => void;
	onAddToSprint?: (cardKey: string, sprintId?: number) => void;
};

export const CardFilteredFlag = ({
	cardKeys,
	sprints,
	onDismissed,
	onViewIssue,
	onAddToSprint,
	isAddedToSprintLoading,
	isAddedToSprintSuccess,
	issueMovedBetweenSwimlanes,
	id,
}: CardFilteredFlagProps) => {
	const { formatMessage } = useIntl();
	const [, setIssueKey] = useQueryParam('selectedIssue');

	// support for multiple active sprints
	const [selectedAddToSprintIndex, setSelectedAddToSprintIndex] = useState<number | null>(null);

	const numIssues = cardKeys.length;

	const getAddToSprintActionContent = useCallback(
		(selectedSprintIndex = 0): string => {
			if (selectedAddToSprintIndex === selectedSprintIndex && isAddedToSprintLoading) {
				return formatMessage(flagMessages.issueAddToSprintLoading);
			}

			if (selectedAddToSprintIndex === selectedSprintIndex && isAddedToSprintSuccess) {
				return formatMessage(flagMessages.issueAddToSprintSuccess);
			}

			// TODO Fail message

			return formatMessage(flagMessages.issueAddToSprint, {
				sprintName: sprints?.[selectedSprintIndex]?.name,
			});
		},
		[
			isAddedToSprintLoading,
			isAddedToSprintSuccess,
			selectedAddToSprintIndex,
			sprints,
			formatMessage,
		],
	);

	const onViewClick =
		numIssues === 1 && onViewIssue
			? (e: SyntheticEvent) => {
					e.preventDefault();
					onViewIssue(cardKeys[0]);
					setIssueKey(cardKeys[0]);
				}
			: undefined;

	const onAddToSprintClickNew = (e: SyntheticEvent, index: number) => {
		e.preventDefault();
		if (!onAddToSprint) {
			return;
		}

		setSelectedAddToSprintIndex(index);
		onAddToSprint(cardKeys[0], sprints?.length ? sprints[index]?.id : undefined);
	};

	const onAddToSprintClick = numIssues === 1 ? onAddToSprintClickNew : undefined;

	const descriptionMovedBetweenSwimlanesMessage = expVal(
		'issue-terminology-refresh-m2-replace',
		'isEnabled',
		false,
	)
		? flagMessages.descriptionMovedBetweenSwimlanesIssueTermRefresh
		: flagMessages.descriptionMovedBetweenSwimlanes;

	const descriptionMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? flagMessages.descriptionIssueTermRefresh
		: flagMessages.description;

	const flagDescriptionMessage = issueMovedBetweenSwimlanes
		? descriptionMovedBetweenSwimlanesMessage
		: descriptionMessage;

	const flagDescription =
		!issueMovedBetweenSwimlanes && numIssues === 1 ? (
			<FormattedI18nMessage
				message={formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? flagMessages.descriptionWithLinkIssueTermRefresh
						: flagMessages.descriptionWithLink,
					{
						linkStart: '{linkStart}',
						linkEnd: '{linkEnd}',
					},
				)}
				componentsMapping={{
					link: () => (
						<a
							css={[fg('jira-a11y-links-contrast-fix') && underlineLinkStyles]}
							href={getIssueDiscoveryUrl([cardKeys[0]])}
							onClick={onViewClick}
							target="_blank"
						>
							{cardKeys[0]}
						</a>
					),
				}}
			/>
		) : (
			formatMessage(flagDescriptionMessage, { numIssues })
		);

	const generateAddToSprintActions = () =>
		sprints?.length && onAddToSprintClick
			? sprints.map((sprint: Sprint, index: number) => ({
					content: getAddToSprintActionContent(index),
					onClick: (event: SyntheticEvent) => onAddToSprintClick(event, index),
				}))
			: [];

	const actions =
		numIssues > 0
			? [
					{
						content: formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? flagMessages.issueLinkIssueTermRefresh
								: flagMessages.issueLink,
							{ numIssues },
						),
						href: getIssueDiscoveryUrl(cardKeys),
						onClick: onViewClick,
						target: '_blank',
					},
					...generateAddToSprintActions(),
				]
			: [];

	useEffect(() => {
		getAddToSprintActionContent();
	}, [isAddedToSprintSuccess, isAddedToSprintLoading, getAddToSprintActionContent]);

	const titleMovedBetweenSwimlanesMessage = expVal(
		'issue-terminology-refresh-m2-replace',
		'isEnabled',
		false,
	)
		? flagMessages.titleMovedBetweenSwimlanesIssueTermRefresh
		: flagMessages.titleMovedBetweenSwimlanes;

	const titleMessage = expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
		? flagMessages.titleIssueTermRefresh
		: flagMessages.title;

	const flagTitle = issueMovedBetweenSwimlanes ? titleMovedBetweenSwimlanesMessage : titleMessage;

	return (
		<SuccessAutoDismissFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(flagTitle, { numIssues })}
			description={flagDescription}
			actions={actions}
		/>
	);
};

const underlineLinkStyles = css({
	textDecoration: 'underline',
});
