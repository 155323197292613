import type { ReactNode } from 'react';
import type { IssueId, IssueEntry } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../../../model/swimlane/swimlane-types.tsx';
import type { RenderCardCallback } from '../types.tsx';

export type FastVirtualListProps = {
	name?: string;
	issueEntries: IssueEntry[];
	issuesWithIssueLinksIds: IssueId[];
	renderCard: RenderCardCallback;
	footer: ReactNode | null;
	placeholder: ReactNode | null;
	showFooter: boolean;
	columnId: ColumnId;
	swimlaneId?: SwimlaneId | null;
	offsetTop: number;
	hasCTA?: boolean;
	isUnscheduledWorkColumnPanel?: boolean;
};

export const VIRTUAL_LIST_FOOTER_KEY = 'footer';
export const VIRTUAL_LIST_DND_KEY = 'virtual_list_dnd_key';
