/* eslint-disable react-hooks/exhaustive-deps, jira/wrm/no-load-bridge */

import React, {
	type ComponentType,
	useState,
	useCallback,
	useEffect,
	type SyntheticEvent,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import createEvent from '@atlassian/jira-common-util-create-event/src/index.tsx';
import { type FlagServiceSubscriberProps, useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import type { ShowFlagFn } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { asyncWrmRequire } from '@atlassian/jira-wrm/src/async-wrm.tsx';
import ViewWorkflowButton from '../../../common/ui/view-workflow/button/index.tsx';
import ErrorScreen from '../../../common/ui/view-workflow/error/index.tsx';
import messages from '../../../common/ui/view-workflow/messages.tsx';
import {
	ClassicViewWorkflowServiceWithAnalytics as ClassicViewWorkflowServiceDI,
	type Props as ClassicViewWorkflowServiceProps,
} from '../../../services/classic-view-workflow-service/main.tsx';
import type { ViewWorkflowData } from '../../../services/classic-view-workflow-service/types.tsx';
import errorFlags from './flags/errors/index.tsx';

export type Props = {
	isServiceLoading: boolean;
	isServiceRefetching: boolean;
	issueKey: IssueKey;
	hasPermission?: boolean;
	baseUrl: BaseUrl;
	ClassicViewWorkflowService?: ComponentType<ClassicViewWorkflowServiceProps>;
	// This will be cleaned up as a part of the injectIntl migration HOC clean up.
	// The rule being disabled perticularly needed here to avoid unnecessary conflict.
	FlagServiceSubscriber?: ComponentType<FlagServiceSubscriberProps>;
};

type ClassicProjectWorkflowModalApi = {
	showDialog: (
		workflowName: ViewWorkflowData['workflowName'],
		currentStepId: ViewWorkflowData['currentStepId'],
	) => void;
};

export const ViewWorkflowClassic = ({
	isServiceLoading,
	isServiceRefetching,
	issueKey,
	hasPermission,
	baseUrl,
	ClassicViewWorkflowService = ClassicViewWorkflowServiceDI,
}: Props) => {
	const { formatMessage } = useIntl();
	const [isModalMounted, setIsModalMounted] = useState(false);
	const [isLoadingModal, setIsLoadingModal] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);

	const { showFlag } = useFlagsService();

	const isModalMounting = isLoadingModal && !isModalMounted;
	const isDisabled = !hasPermission || isModalMounting;
	// we do not use || isClassicServiceLoading for isLoading because we do not want to show spinner while ClassicViewWorkflowService data loads
	const isLoading = isModalMounting || isServiceRefetching || isRefetching;

	let modalApi: Promise<ClassicProjectWorkflowModalApi>;

	useEffect(() => {
		const modalModuleName = 'workflow-designer/view-classic-project-workflow';

		// @ts-expect-error - TS2322 - Type 'Promise<unknown>' is not assignable to type 'Promise<ClassicProjectWorkflowModalApi>'.

		modalApi = loadBridge({
			// checking if module was loaded on page load
			name: modalModuleName,
			tentatives: 0,
			shouldLogError: false,
		}).catch(() => {
			asyncWrmRequire([
				// eslint-disable-next-line jira/wrm/no-wr-prefix
				'wr!com.atlassian.jira.plugins.jira-workflow-designer:view-issue-page-workflow-designer',
			]); // wrmRequire() does not fire catch() event

			return loadBridge({
				name: modalModuleName,
			});
		});
	}, []);

	const onClick = useCallback(
		(
			analyticsEvent: UIAnalyticsEvent,
			pShowFlag: ShowFlagFn,
			workflowName: ViewWorkflowData['workflowName'] | null,
			currentStepId: ViewWorkflowData['currentStepId'] | null,
		) => {
			fireUIAnalytics(analyticsEvent, 'viewWorkflowTrigger', {
				isSimplifiedProject: false,
			});

			if (!modalApi || !workflowName || !currentStepId) {
				return;
			}

			// modal code is being loaded only once, therefore there is no need to reset this to false
			setIsLoadingModal(true);
			setIsRefetching(false);

			modalApi
				.then((api) => {
					api.showDialog(workflowName, currentStepId);
					// since there is no way to close Ak dropdown programmatically, this is the only option
					// to avoid dropdown wrong position on View workflow modal opening in Bento modal (BENTO-4660)

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					window.dispatchEvent(createEvent('resize'));
					// @ts-expect-error - TS2345 - Argument of type '{ isModalMounted: boolean; }' is not assignable to parameter of type 'SetStateAction<boolean>'.
					setIsModalMounted({ isModalMounted: true });
				})
				.catch(() => {
					pShowFlag && pShowFlag(errorFlags.loadModalError());
					setIsLoadingModal(false);
				});
		},
		// @ts-expect-error - TS2454 - Variable 'modalApi' is used before being assigned.
		[modalApi, setIsModalMounted, setIsRefetching, setIsLoadingModal],
	);

	const onRetry = useCallback(
		(event: SyntheticEvent<HTMLElement>, retry: () => Promise<void>) => {
			event.stopPropagation();
			setIsRefetching(true);

			return retry().then(() => setIsRefetching(false));
		},
		[setIsRefetching],
	);

	const onMount = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
		(analyticsEvent) => {
			fireOperationalAnalytics(analyticsEvent, 'viewWorkflowTrigger mounted', {
				hasViewWorkflowPermission: hasPermission,
				isSimplifiedProject: false,
			});
		},
		[hasPermission],
	);

	const getTooltipMessage = useCallback(
		(isClassicServiceLoading: boolean) => {
			if (isServiceRefetching || isClassicServiceLoading) {
				return formatMessage(messages.loading);
			}

			if (!hasPermission) {
				return formatMessage(messages.noPermission);
			}
			return '';
		},
		[formatMessage, hasPermission, isServiceRefetching],
	);

	return (
		<ClassicViewWorkflowService
			issueKey={issueKey}
			baseUrl={baseUrl}
			shouldLoadOnMount={hasPermission}
		>
			{({ data, error, loading: isClassicServiceLoading, retry }) => {
				if (error) {
					return (
						<ErrorScreen
							onRetry={(event) => {
								onRetry(event, retry);
							}}
						/>
					);
				}

				const workflowName = data ? data.workflowName : null;
				const currentStepId = data ? data.currentStepId : null;

				return (
					<ViewWorkflowButton
						onMount={onMount}
						tooltipContent={getTooltipMessage(isServiceLoading || isClassicServiceLoading)}
						isDisabled={isDisabled || isClassicServiceLoading}
						isLoading={isLoading}
						isInitialLoading={isServiceLoading || isClassicServiceLoading}
						onClick={(_, analyticsEvent) =>
							onClick(analyticsEvent, showFlag, workflowName, currentStepId)
						}
					/>
				);
			}}
		</ClassicViewWorkflowService>
	);
};

export default ViewWorkflowClassic;
