import React from 'react';
import InlineDialog from '@atlaskit/inline-dialog'; // ignore-for-ENGHEALTH-17759
import type { InlineDialogProps } from '@atlaskit/inline-dialog/types';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { isMobileAndInViewPanelIterationExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { ChoreographerInlineDialog } from './choreographer-inline-dialog.tsx';

export type JiraInlineDialogProps = InlineDialogProps & MaybeChoreographedComponentProps;

export const JiraInlineDialog = (props: JiraInlineDialogProps) => {
	if (shouldBeChoreographed(props)) {
		if (isMobileAndInViewPanelIterationExperiment()) {
			return null;
		}
		const { messageType, ...rest } = props;
		return <ChoreographerInlineDialog {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <InlineDialog {...rest} />;
};
