import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { Priority } from '../../../../model/issue/issue-types.tsx';

export const SET_PRIORITY = 'state.actions.issue.SET_PRIORITY' as const;

export type SetPriorityAction = {
	type: typeof SET_PRIORITY;
	payload: {
		issueId: IssueId;
		priority: Priority | null;
	};
};

export const setPriority = (issueId: IssueId, priority: Priority | null): SetPriorityAction => ({
	type: SET_PRIORITY,
	payload: {
		issueId,
		priority,
	},
});

export type Action = SetPriorityAction;
