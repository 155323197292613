import React, { useCallback, useMemo } from 'react';
import noop from 'lodash/noop';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	ContextualAnalyticsData,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import { type IssueId, toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { ViewSetting } from '@atlassian/jira-software-view-settings/src/common/types/settings.tsx';
import { useViewSettingsActions } from '@atlassian/jira-software-view-settings/src/controllers/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import type { Parent, CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Action = 'set-value' | 'input-change' | 'input-blur' | 'menu-close';

export type Props = {
	isToggleChecked: boolean;
	isLoading?: boolean;
	isOpen?: boolean;
	// note that async search does not use AsyncSelect
	isAsyncSearch?: boolean;
	label: string;
	parents: Parent[];
	selectedParents: IssueId[];
	showPanelToggle: boolean;
	showMenuIfValuesAreEmpty: boolean;
	inputValue?: string;
	onChange: (selectedParents: IssueId[], attributes?: AnalyticsAttributes) => void;
	onInputChange: (
		input: string,
		arg2: {
			action: Action;
		},
	) => void;
	onMouseEnterToTriggerButton?: () => void;
	onTogglePanel: () => void;
	onOpenChange?: (isOpen: boolean) => void;
	intl: IntlShape;
	isNested?: boolean;
};

const ParentFilter = (props: Props) => {
	const {
		label,
		parents,
		selectedParents,
		isToggleChecked,
		onTogglePanel,
		onInputChange,
		onOpenChange,
		onMouseEnterToTriggerButton,
		intl: { formatMessage },
		showPanelToggle,
		showMenuIfValuesAreEmpty,
		inputValue,
		isLoading,
		isAsyncSearch,
		isNested,
		isOpen,
	} = props;

	const [boardId] = usePathParam('boardId');
	const { toggleViewSetting } = useViewSettingsActions();

	const values = useMemo(
		(): CheckboxSelectEntry[] =>
			parents.map((parent) => ({
				label: parent.name,
				value: parent.id,
				subLabel: parent.key,
			})),
		[parents],
	);

	const onChange = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			props.onChange(
				entries.map((entry) => toIssueId(entry.value)),
				analyticAttributes,
			);
		},
		[props, isNested],
	);

	const handleTogglePanel = useCallback(() => {
		if (boardId) {
			toggleViewSetting({
				boardId: Number(boardId),
				setting: ViewSetting.EPIC_PANEL,
			});
		}
		onTogglePanel();
	}, [boardId, onTogglePanel, toggleViewSetting]);

	return (
		<ContextualAnalyticsData
			sourceName="epicPanelToggle"
			attributes={{ isOpen: !isToggleChecked, filterType: label }}
		>
			<ListFilter
				label={label}
				toggleLabel={formatMessage(messages.toggleLabel, { parentName: label?.toLowerCase() })}
				values={values}
				isOpen={isOpen}
				showPanelToggle={showPanelToggle}
				selectedValues={selectedParents.map(String)}
				isPanelToggleChecked={isToggleChecked}
				onChange={onChange}
				inputValue={inputValue}
				isLoading={isLoading}
				onInputChange={onInputChange}
				onTogglePanel={handleTogglePanel}
				onOpenChange={onOpenChange}
				noOptionsMessage={formatMessage(messages.noParentIssues, { parentName: label })}
				showMenuIfValuesAreEmpty={showMenuIfValuesAreEmpty}
				isAsyncSearch={isAsyncSearch}
				isNested={isNested}
				onMouseEnterToTriggerButton={onMouseEnterToTriggerButton}
				placeholder={formatMessage(messages.placeholder, { parentName: label })}
			/>
		</ContextualAnalyticsData>
	);
};

ParentFilter.defaultProps = {
	selectedParents: [],
	isToggleChecked: false,
	onChange: noop,
	onInputChange: noop,
	onTogglePanel: noop,
	showPanelToggle: false,
	showMenuIfValuesAreEmpty: false,
	isLoading: false,
	isAsyncSearch: false,
	isNested: false,
};

export default injectIntl(ParentFilter);
