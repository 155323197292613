import type { TimeTrackingOptions } from './types.tsx';

export const SECONDS_PER_MINUTE = 60 as const;
const MINUTES_PER_HOUR = 60 as const;
export const SECONDS_PER_HOUR: number = MINUTES_PER_HOUR * SECONDS_PER_MINUTE;

const TIME_FORMAT_PRETTY = 'pretty' as const;
const TIME_FORMAT_DAYS = 'days' as const;
const TIME_FORMAT_HOURS = 'hours' as const;

export const TimeFormats = Object.freeze({
	PRETTY: TIME_FORMAT_PRETTY,
	DAYS: TIME_FORMAT_DAYS,
	HOURS: TIME_FORMAT_HOURS,
});

const TIME_UNIT_WEEK = 'week' as const;
const TIME_UNIT_DAY = 'day' as const;
const TIME_UNIT_HOUR = 'hour' as const;
const TIME_UNIT_MINUTE = 'minute' as const;

export const TimeUnits = Object.freeze({
	WEEK: TIME_UNIT_WEEK,
	DAY: TIME_UNIT_DAY,
	HOUR: TIME_UNIT_HOUR,
	MINUTE: TIME_UNIT_MINUTE,
});

export const defaultTimeTrackingOptions: TimeTrackingOptions = {
	workingHoursPerDay: 8,
	workingDaysPerWeek: 5,
	timeFormat: TimeFormats.PRETTY,
	defaultUnit: TimeUnits.MINUTE,
};
