// Copied over from `src/packages/issue/issue-view/src/model/change-type.js`
const FIELD_CHANGE_REQUESTED = 'FIELD_CHANGE_REQUESTED' as const;

const FIELD_CHANGED = 'FIELD_CHANGED' as const;
const FIELD_CHANGE_FAILED = 'FIELD_CHANGE_FAILED' as const;

export const ChangeEventTypes = {
	[FIELD_CHANGE_REQUESTED]: FIELD_CHANGE_REQUESTED,
	[FIELD_CHANGE_FAILED]: FIELD_CHANGE_FAILED,
	[FIELD_CHANGED]: FIELD_CHANGED,
} as const;
