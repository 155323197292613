import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

type ScrollInterceptorAnalyticsAttributes = {
	message?: string;
	error?: Error;
};

export const fireErrorAnalytic = (attributes: ScrollInterceptorAnalyticsAttributes) => {
	fireErrorAnalytics({
		meta: {
			id: 'scrollInterceptor',
			packageName: 'jiraScrollInterceptor',
			teamName: 'jlove-makkuro',
		},
		// @ts-expect-error - TS2322 - Type '{ message?: string | undefined; error?: Error | undefined; }' is not assignable to type 'AnalyticsAttributes'.
		attributes: {
			...attributes,
		},
	});
};
