export const MAX_NUMBER_OF_PULL_REQUESTS_TO_QUERY = 1000;

export const PULL_REQUESTS_UPDATED_FROM_NUMBER_OF_DAYS = 30;
export const DEPLOYMENTS_UPDATED_FROM_NUMBER_OF_DAYS = 30;

export const NUMBER_OF_SECURITY_CONTAINERS_TO_FETCH = 100;

export const NUMBER_OF_VULNERABILITIES_TO_FETCH = 40;

export const NUMBER_OF_DEPLOYMENTS_TO_FETCH = 50;

export const COMPASS_IPH_ARTICLE_ID = '5LjJ0SAhfmLf4P6ar5bNpC';
export const COMPASS_WAC_URL = 'https://www.atlassian.com/software/compass/features';

export const PERISCOPE_ANALYTICS = 'jira.development';

export const INSIGHT_TYPES = {
	OPEN_PULL_REQUESTS: 'OPEN_PULL_REQUESTS',
	CRITICAL_VULNERABILITIES: 'CRITICAL_VULNERABILITIES',
	DEPLOYMENT_CYCLE_TIME: 'DEPLOYMENT_CYCLE_TIME',
	DUE_ISSUES: 'DUE_ISSUES',
	DEPLOYMENT_FREQUENCY: 'DEPLOYMENT_FREQUENCY',
	PR_CYCLE_TIME: 'PR_CYCLE_TIME',
	ISSUE_COMPLETION_RATE: 'ISSUE_COMPLETION_RATE',
	COMPASS_SCORECARD: 'COMPASS_SCORECARD',
} as const;

export const CHART_TYPES = {
	BAR: 'BAR',
	LINE: 'LINE',
} as const;

export const INSIGHT_TARGET_URLS = {
	// TODO remove the open pull requests path with cleaning jira_periscope_code_tab_migration flag
	OPEN_PULL_REQUESTS: '/code',
	CRITICAL_VULNERABILITIES: '/security?severity=CRITICAL',
	DEPLOYMENTS: '/deployments',
	DUE_ISSUES: '/issues',
} as const;

// Work suggestions in Jira project
// https://customerfeedback.atlassian.net/jira/servicedesk/projects/WSIJ/queues/custom/1098
export const ENTRYPOINT_ID_WORK_SUGGESTION_IN_JIRA_PROJECT = '3ee47ec5-0b90-447e-87e3-137752a59f8c';

export const FEEDBACK = {
	PERISCOPE: 'jsw.periscope',
} as const;

export const SECONDS_IN_DAY = 86400;

export const SECONDS_IN_HOUR = 3600;

export const SECONDS_IN_MINUTE = 60;

export const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;

export const SPRINT_DISCOVERY_LIMIT = 10;

export const WORK_ITEMS_DEPLOYMENT_DEV_DETAILS_DIALOG_SCOPE_ID =
	'jira-development-work-items-deployments-tab';

export const NUMBER_OF_COMPASS_SCORECARDS = 2;

export const ON_CALL_TARGET_URL_PATH = '/on-call-schedule';

export const ON_CALL_METRIC = 'ON_CALL';

export const ISSUE_VIEW_SUGGESTIONS_TASK_TYPES = {
	PULL_REQUEST_REVIEW: 'PR_REVIEW',
	PR_MERGEABLE: 'PR_MERGEABLE',
} as const;

export type IssueViewSuggestionsTaskTypes =
	(typeof ISSUE_VIEW_SUGGESTIONS_TASK_TYPES)[keyof typeof ISSUE_VIEW_SUGGESTIONS_TASK_TYPES];

export const ANCHOR_PREFIX = 'periscope_';
