import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	disabledIssueTypeTooltip: {
		id: 'software.main.board-container.board.inline-card-create.disabled-issue-type-tooltip',
		defaultMessage:
			"Due to required fields, this issue type can only be created from the workflow's initial status",
		description: 'Tooltip content for a disabled issue type',
	},
	disabledIccTriggerTooltip: {
		id: 'software.main.board-container.board.inline-card-create.disabled-icc-trigger-tooltip',
		defaultMessage:
			'Due to required fields on all issue types, new issues can only be created from the workflow’s initial status',
		description: 'Tooltip content for a disabled inline card create trigger',
	},
	disabledIssueTypeTooltipIssueTermRefresh: {
		id: 'software.main.board-container.board.inline-card-create.disabled-issue-type-tooltip-issue-term-refresh',
		defaultMessage:
			"Due to required fields, this work type can only be created from the workflow's initial status",
		description: 'Tooltip content for a disabled issue type',
	},
	disabledIccTriggerTooltipIssueTermRefresh: {
		id: 'software.main.board-container.board.inline-card-create.disabled-icc-trigger-tooltip-issue-term-refresh',
		defaultMessage:
			'Due to required fields on all work types, new work items can only be created from the workflow’s initial status',
		description: 'Tooltip content for a disabled inline card create trigger',
	},
});
