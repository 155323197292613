import React, { useCallback, useEffect, useRef, type FocusEvent, type MouseEvent } from 'react';
import { styled } from '@compiled/react';

import noop from 'lodash/noop';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { CARD_CONTAINER_SELECTOR_NAME } from '../../common/constants/index.tsx';
import JiraCardContents from './jira-card-contents/index.tsx';
import Ripple from './ripple/index.tsx';
import type { CardProps } from './types.tsx';
import { useDraggableCard } from './use-draggable-card/index.tsx';

export const Card = ({
	id,
	issue,
	parent,
	assignee,
	status,
	menu,
	cover,
	dateFormat,
	draggableIndex,
	isDraggable,
	isDone = false,
	isSelected = false,
	isLoading = false,
	isFlagged = false,
	isFlexible = false,
	isPlaceholder = false,
	shouldShowParent = false,
	shouldShowRipple = false,
	shouldRenderCardType = true,
	shouldFocus = false,
	persistActions = false,
	highlightTextOnCard = '',
	highlightLabelsOnCard = [],
	onClick: onClickProp,
	onEnterPress: onEnterPressProp,
	onMouseDown: onMouseDownProp,
	onMouseUp = noop,
	onRippleEnd = noop,
	onSizeChange = noop,
	onFocus: onFocusProp,
	onBlur,
	renderEstimateField,
	renderIssueParentField,
	renderSummary,
	renderAssignee,
	renderIssueLinksIndicator,
	CustomContextMenu,
	renderCustomMenu,
	isCMPBoard = false,
	isIPBoard = false,
	selectedCardIds = [],
	cardRef: cardRefProp,
	CardPreview,
	hasCompactIssueType,
	isDroppableOnCard,
	renderIssueLinksStats,
	showDaysInColumn,
	showIssueKey,
	showEstimate,
	showPriority,
	showDevelopment,
	showDueDate,
	showAssignee,
	showLabels,
	showCardExtraFields,
	boardType,
	renderStatusField,
	renderPriorityField,
}: CardProps) => {
	const cardContainerRef = useRef<HTMLElement>(null);
	const internalCardRef = useRef<HTMLButtonElement>(null);
	const cardRef = cardRefProp || internalCardRef;

	const { dragPreview, closestEdge } = useDraggableCard({
		isDraggable: isDraggable ?? true,
		elementRef: fg('fix_jira_board_card_nested_interactive_elements') ? cardContainerRef : cardRef,
		cardId: id,
		cardIndex: draggableIndex,
		columnId: issue.columnId,
		swimlaneId: issue.swimlaneId,
		CardPreview,
		shouldShowRipple,
		isDroppable: isDroppableOnCard,
	});

	useEffect(() => {
		// In a virtualised environment we can't guarantee that these components
		// will exist when we focus them. As a result, we need to fire the focus
		// function on mount for scenarios where we focus an off screen element

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (shouldFocus && cardRef.current !== null && document.activeElement !== cardRef.current) {
			cardRef.current.focus();
		}
	}, [cardRef, shouldFocus]);

	let mouseDownTarget: EventTarget | null = null;
	const onClick = useCallback(
		(e: MouseEvent<HTMLElement>, externalAction?: ExternalAction) => {
			e.stopPropagation();

			// best practice to use for react-beautiful-dnd (https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/how-we-use-dom-events.md)
			if (e.defaultPrevented === true && e.currentTarget?.nodeName !== 'A') {
				return;
			}

			// Prevent (eg) mouse down on text-field within card, then mouse up on card firing onClick unexpectedly
			if (mouseDownTarget && e.target !== mouseDownTarget) {
				return;
			}

			const withCmd = e.ctrlKey || e.metaKey;
			const withShift = e.shiftKey;

			onClickProp?.(id, withCmd, withShift, externalAction);
		},
		[id, mouseDownTarget, onClickProp],
	);

	const onMouseDown = (e: React.MouseEvent) => {
		// Clear any text selection to prevent unexpected dragging behaviour

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.getSelection()?.removeAllRanges();

		mouseDownTarget = e.target;
		onMouseDownProp?.();
	};

	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			e.stopPropagation();
			onEnterPressProp?.(id);
		}
	};

	const onFocus = useCallback(
		(e: FocusEvent<HTMLElement>) => {
			// Ensure the focus event originated from the card container,
			// and didn't bubble up from an element within it
			if (!onFocusProp || cardRef.current === null || e.target !== cardRef.current) {
				return;
			}
			onFocusProp(e);
		},
		[cardRef, onFocusProp],
	);

	const cardProps = {
		id,
		issue,
		shouldShowParent,
		parent,
		assignee,
		isDone,
		isSelected,
		isFlagged,
		isFlexible,
		shouldRenderCardType,
		highlightTextOnCard,
		highlightLabelsOnCard,
		menu,
		cover,
		dateFormat,
		isLoading,
		onClick,
		onFocus,
		onBlur,
		cardRef,
		...(fg('fix_jira_board_card_nested_interactive_elements') && { cardContainerRef }),
		onSizeChange,
		isPlaceholder,
		renderEstimateField,
		renderIssueParentField,
		renderSummary,
		renderIssueLinksIndicator,
		renderAssignee,
		isCMPBoard,
		isIPBoard,
		CustomContextMenu,
		renderCustomMenu,
		selectedCardIds,
		status,
		hasCompactIssueType,
		renderIssueLinksStats,
		persistActions,
		showDaysInColumn,
		showIssueKey,
		showEstimate,
		showPriority,
		showDevelopment,
		showDueDate,
		showAssignee,
		showLabels,
		showCardExtraFields,
		boardType,
		...(isIPBoard &&
			fg('issue_cards_in_program_board') && { renderStatusField, renderPriorityField }),
	};

	// @ts-expect-error - Type '{ onSizeChange: () => void; isPlaceholder: boolean; renderEstimateField: (() => ReactNode) | undefined; renderIssueParentField: (() => ReactNode) | undefined; renderSummary: RenderSummary | undefined; ... 42 more ...; key: string; }' is not assignable to type 'Pick<Pick<Omit<{ ref?: unknown; }, "intl">, "ref"> & InexactPartial<Pick<Omit<{ ref?: unknown; }, "intl">, never>> & InexactPartial<Pick<{ isDragging: boolean; isLoading: boolean; ... 41 more ...; shouldShowLozengeForOptimisticParent: boolean; }, "status" | ... 42 more ... | "shouldShowLozengeForOptimisticParent">> ...'.
	const jiraCardContents = <JiraCardContents key={`card-${issue.key}-card`} {...cardProps} />;

	if (!isDraggable) {
		return (
			<CardContainer
				id={`card-${issue.key}`}
				key={`card-${issue.key}-container`}
				data-testid="platform-board-kit.ui.card.card"
				aria-describedby={`card-description-${issue.key}`}
				optimistic={false}
			>
				{jiraCardContents}
			</CardContainer>
		);
	}

	return (
		<CardContainer
			id={`card-${issue.key}`}
			key={`card-${issue.key}-container`}
			data-component-selector={CARD_CONTAINER_SELECTOR_NAME}
			optimistic={false}
			isProcessing={false}
			data-testid="platform-board-kit.ui.card.card"
			onClick={onClick}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onKeyDown={onKeyDown}
			aria-describedby={`card-description-${issue.key}`}
			tabIndex={-1}
		>
			{shouldShowRipple ? (
				<Ripple onAnimationEnd={onRippleEnd}>{jiraCardContents}</Ripple>
			) : (
				jiraCardContents
			)}

			{closestEdge && <CardDropIndicator edge={closestEdge} />}

			{dragPreview}
		</CardContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CardDropIndicator = styled.div<{ edge: Edge }>({
	position: 'absolute',
	display: 'flex',
	justifyContent: 'flex-start',
	left: 0,
	right: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	bottom: ({ edge }) => (edge === 'bottom' ? '-3px' : undefined),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ edge }) => (edge === 'top' ? '-3px' : undefined),
	height: '2px',
	zIndex: 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.icon.brand', colors.B300),
	pointerEvents: 'none',

	'&::before': {
		position: 'absolute',
		content: "''",
		display: 'flex',
		alignSelf: 'center',
		height: '4px',
		width: '4px',
		left: token('space.negative.100', '-8px'),
		borderRadius: '50%',
		borderWidth: '2px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border.brand', colors.B300),
	},
});

type CardContainerProps = {
	optimistic: boolean;
	isProcessing?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContainer = styled.div<CardContainerProps>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginTop: (props: CardContainerProps) => (props.optimistic ? 0 : ''),
	marginRight: 0,
	marginBottom: token('space.025', '2px'),
	marginLeft: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props: CardContainerProps) => (props.isProcessing ? 'none' : 'block'),
});
