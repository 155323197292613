import type { StringifiableValue } from '@atlassian/jira-common-bind-url-to-state/src/index.tsx';
import * as presets from '@atlassian/jira-common-url-transform-presets/src/index.tsx';
import { setJql } from '../../state/actions/jql/index.tsx';
import type { Dispatch, State } from '../../state/types.tsx';

export const jqlUrlBindings = () => [
	{
		urlKey: 'jql',
		transform: presets.string,
		getValueForUrl: (state: State) => state.ui.jql?.jql || undefined,
		setValueFromUrl: (dispatch: Dispatch, value: StringifiableValue) => {
			if (value) {
				dispatch(setJql(String(value)));
			}
		},
	},
];
