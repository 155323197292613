import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const PROJECT_ACTIVE = 'PROJECT_ACTIVE' as const;
export const PROJECT_ARCHIVED = 'PROJECT_ARCHIVED' as const;
export const PROJECT_TRASHED = 'PROJECT_TRASHED' as const;

export type ProjectStatus =
	| typeof PROJECT_ACTIVE
	| typeof PROJECT_ARCHIVED
	| typeof PROJECT_TRASHED;

type ProjectAvatarUrl = {
	key: string;
	value: string;
};

/**
 * Project data (associated to the issue) returned by the server.
 */
export type ServerProject = {
	id: string;
	key: string;
	name: string;
	avatarUrls: ProjectAvatarUrl[];
	projectTypeKey: ProjectType;
	simplified: boolean;
	archived: boolean | null;
	deleted: boolean | null;
};

/**
 * Project data used by the custom project picker field.
 */
export type ServerProjectOption = {
	id?: string;
	self?: string;
	key: ProjectKey;
	name: string;
	avatarUrls: {
		['48x48']: string;
		['32x32']?: string;
		['24x24']?: string;
		['16x16']?: string;
	};
	projectTypeKey?: ProjectType;
	simplified?: boolean;
	isAIEnabled?: boolean;
};

type FieldMeta = {
	required: boolean;
	hasDefaultValue: boolean;
};

export type ProjectIssueType = {
	id: number | string;
	iconUrl: string;
	name: string;
	subtask: boolean;
	fields?: {
		[key: string]: FieldMeta;
	};
};

/**
 * Defines the Bento-transformed `ServerProject`.
 */
export type ProjectContext = {
	projectId: string;
	projectName: string;
	projectKey: string;
	projectStatus: ProjectStatus;
	projectType: ProjectType;
	isSimplified: boolean;
	avatarUrl?: string | null;
	issueTypes?: ProjectIssueType[];
	boardId?: string | undefined;
};
