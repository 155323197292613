import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { SwimlaneId, SwimlaneValues } from '../../../model/swimlane/swimlane-types.tsx';
import type { IssuesNotOnBoardState } from '../../reducers/ui/column/issues-not-on-board/types.tsx';
import type { Action as AddLabelModalAction } from './add-label-modal/index.tsx';
import type { Action as AssignIssueParentAction } from './assign-issue-parent-modal/index.tsx';
import type { Action as AssignToMeAction } from './assign-to-me/index.tsx';
import type { Action as BulkUpdateAction } from './bulk-update/index.tsx';
import type { Action as IssueChangeTeamAction } from './change-team/index.tsx';
import type { Action as CreateIssueAction } from './create/index.tsx';
import type { Action as DeleteIssueModalAction } from './delete-modal/index.tsx';
import type { Action as DeleteIssueAction } from './delete/index.tsx';
import type { Action as DevStatusAction } from './dev-status/index.tsx';
import type { Action as EstimateAction } from './estimate/index.tsx';
import type { Action as FieldUpdateAction } from './field-update/index.tsx';
import type { Action as FlagWithCommentModalAction } from './flag-with-comment-modal/index.tsx';
import type { Action as FlagWithCommentAction } from './flag-with-comment/index.tsx';
import type { Action as IssueLinkAction } from './issue-link/index.tsx';
import type { Action as IssueTransitionModalAction } from './issue-transition-modal/index.tsx';
import type { Action as IssueMediaAction } from './media/index.tsx';
import type { Action as MissingParentResolveAction } from './missing-parent-resolve/index.tsx';
import type { Action as IssueModalAction } from './modal/index.tsx';
import type { Action as MoveToBacklogAction } from './move-to-backlog/index.tsx';
import type { Action as IssueParentAction } from './parent/index.tsx';
import type { Action as PriorityAction } from './priority/index.tsx';
import type { Action as IssueStatusAction } from './status/index.tsx';
import type { Action as IssueRankTeamDateAction } from './rank-team-date/index.tsx';
import type { Action as RankAndTransitionAction } from './rank-transition/index.tsx';
import type { Action as SummaryAction } from './summary/index.tsx';
import type { Action as UpdateIssueParentModalAction } from './update-issue-parent-modal/index.tsx';
import type { Action as UpdateIssueAction } from './update/index.tsx';

export const DONE_ISSUES_BUTTON_CLICK = 'state.actions.issue.DONE_ISSUES_BUTTON_CLICK' as const;

export type DoneIssuesButtonClickAction = {
	type: typeof DONE_ISSUES_BUTTON_CLICK;
};

export const doneIssuesButtonClick = (): DoneIssuesButtonClickAction => ({
	type: DONE_ISSUES_BUTTON_CLICK,
});

export const ISSUE_CHANGE_SWIMLANE = 'state.actions.issue.ISSUE_CHANGE_SWIMLANE' as const;

export type IssueChangeSwimlaneAction = {
	type: typeof ISSUE_CHANGE_SWIMLANE;
	payload: {
		issueIds: IssueId[];
		swimlaneId: SwimlaneId;
		swimlaneValues: SwimlaneValues;
	};
};

export const issueChangeSwimlane = (
	issueIds: IssueId[],
	swimlaneId: SwimlaneId,
	swimlaneValues: SwimlaneValues,
): IssueChangeSwimlaneAction => ({
	type: ISSUE_CHANGE_SWIMLANE,
	payload: {
		issueIds,
		swimlaneId,
		swimlaneValues,
	},
});

export const SET_ISSUES_NOT_ON_BOARD = 'state.actions.issue.SET_ISSUES_NOT_ON_BOARD' as const;

export type SetIssuesNotOnBoardAction = {
	type: typeof SET_ISSUES_NOT_ON_BOARD;
	payload: IssuesNotOnBoardState;
};

export const setIssuesNotOnBoard = (payload: IssuesNotOnBoardState): SetIssuesNotOnBoardAction => ({
	type: SET_ISSUES_NOT_ON_BOARD,
	payload,
});

export type Action =
	| AddLabelModalAction
	| AssignIssueParentAction
	| AssignToMeAction
	| BulkUpdateAction
	| CreateIssueAction
	| DeleteIssueAction
	| DeleteIssueModalAction
	| DevStatusAction
	| DoneIssuesButtonClickAction
	| EstimateAction
	| FieldUpdateAction
	| FlagWithCommentAction
	| FlagWithCommentModalAction
	| IssueChangeSwimlaneAction
	| IssueMediaAction
	| IssueModalAction
	| IssueParentAction
	| IssueTransitionModalAction
	| MoveToBacklogAction
	| RankAndTransitionAction
	| SetIssuesNotOnBoardAction
	| SummaryAction
	| UpdateIssueAction
	| UpdateIssueParentModalAction
	| MissingParentResolveAction
	| IssueRankTeamDateAction
	| IssueLinkAction
	| IssueChangeTeamAction
	| PriorityAction
	| IssueStatusAction;
