import React, { useCallback, type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = {
	canAccessCustomFilterSettings: boolean;
	id: string | number;
	customFiltersSettingsUrl: string;
	onDismissed: (id: number | string) => void;
};
const CustomFilterFailed = ({
	id,
	customFiltersSettingsUrl,
	canAccessCustomFilterSettings,
	onDismissed = noop,
}: Props) => {
	const { formatMessage } = useIntl();

	const dismissFlag = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			setReturnToProjectUrl();
			fireUIAnalytics(analyticsEvent, 'customFilterFailedFlagLink', {
				canAccessCustomFilterSettings,
			});
			onDismissed(id);
		},
		[canAccessCustomFilterSettings, id, onDismissed],
	);

	return canAccessCustomFilterSettings ? (
		<WarningFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(messages.adminUserTitle)}
			description={formatMessage(messages.adminUserDescription)}
			actions={[
				{
					content: formatMessage(messages.adminUserActionLink),
					href: customFiltersSettingsUrl,
					target: '_blank',
					onClick: dismissFlag,
				},
			]}
		/>
	) : (
		<WarningFlag
			id={id}
			onDismissed={onDismissed}
			title={formatMessage(messages.endUserTitle)}
			description={formatMessage(messages.endUserDescription)}
			actions={[
				{
					content: formatMessage(messages.endUserActionLink),
					onClick: dismissFlag,
				},
			]}
		/>
	);
};

export default CustomFilterFailed;
