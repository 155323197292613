import React, { useCallback } from 'react';
import { Anchor, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './types.tsx';

export default function IssueLink(props: Props) {
	const { projectKey, issueKey, issueId, xcssStyles, children } = props;
	const fullIssueKey = issueKey ? `${projectKey}-${issueKey}` : projectKey;
	const onClick = useCallback(
		(_: React.MouseEvent<HTMLAnchorElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'scopeIssueKey');
		},
		[],
	);

	return (
		<Anchor
			href={proxyContextSafeUrl(`/browse/${fullIssueKey}`)}
			rel="noopener noreferrer"
			id={issueId && `issue-link-${issueId}`}
			xcss={[anchorStyles, ...(xcssStyles ? [xcssStyles] : [])]}
			target="_blank"
			draggable={fg('allows_dragging_an_issue_from_issue_link') ? false : undefined}
			{...(fg('plan_timeline_core_action_analytics') ? { onClick } : {})}
		>
			{children || fullIssueKey}
		</Anchor>
	);
}

const anchorStyles = xcss({
	outlineOffset: 'space.0',
});
