import React, { useCallback, useMemo, memo } from 'react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type { Label, CheckboxSelectEntry } from '../../../common/types.tsx';
import ListFilter from '../../../common/ui/list/index.tsx';
import messages from './messages.tsx';

type Props = {
	labels: Label[];
	selectedLabels?: Label[];
	onChange: (labels: Label[], attributes?: AnalyticsAttributes) => void;
	isNested?: boolean;
	unassignedOption?: { label: string; value: string };
};

const LabelFilter = ({
	labels = [],
	selectedLabels = [],
	onChange = noop,
	isNested = false,
	unassignedOption,
}: Props) => {
	const { formatMessage } = useIntl();
	const values = useMemo((): CheckboxSelectEntry[] => {
		const options = labels.map((label) => ({
			label,
			value: label,
		}));

		unassignedOption && options.unshift(unassignedOption);

		return options;
	}, [labels, unassignedOption]);

	const onChangeMapValues = useCallback(
		(entries: CheckboxSelectEntry[]) => {
			const analyticAttributes = { isNested };
			onChange(
				entries.map((entry) => entry.value),
				analyticAttributes,
			);
		},
		[onChange, isNested],
	);

	return (
		<ListFilter
			label={formatMessage(messages.label)}
			values={values}
			selectedValues={selectedLabels}
			onChange={onChangeMapValues}
			isNested={isNested}
			placeholder={formatMessage(messages.placeholder)}
		/>
	);
};

const LabelFilterComponent = memo<Props>(LabelFilter);

LabelFilterComponent.displayName = 'LabelFilter';
// @ts-expect-error - TS2339 - Property 'whyDidYouRender' does not exist on type 'NamedExoticComponent<Props>'.
LabelFilterComponent.whyDidYouRender = true;

export default LabelFilterComponent;
