import { useMemo } from 'react';
import { useMatch } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-match/index.tsx';
import useServiceProjectFeatures from '@atlassian/jira-servicedesk-features-store/src/index.tsx';
import { ENABLED } from '@atlassian/jira-servicedesk-common/src/service-project-features/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getBoardUrl,
	getJSMBoardUrl,
	getJSMCalendarUrl,
	getUserBoardUrl,
} from '../../common/url-utils/index.tsx';
import { useBoardAndLocationContext } from '../use-board-and-location-context/index.tsx';
import { useIsJSMBoard } from '../../services/use-is-jsm-board/index.tsx';

export function useBoardUrl(isCMPBoard?: boolean): string {
	const match = useMatch();
	const { project, user } = useBoardAndLocationContext();
	const isJSMBoard = useIsJSMBoard();
	const isReallyCMPBoard = isCMPBoard !== undefined ? isCMPBoard : !project?.simplified;
	const projectKey = match.params.projectKey ?? '';
	const boardId = match.params.boardId ?? '';

	const { data: jsmFeaturesData } = fg('jsm_views_configuration_migration')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useServiceProjectFeatures()
		: { data: null };
	const isBoardEnabled =
		isJSMBoard && fg('jsm_views_configuration_migration')
			? jsmFeaturesData?.['jsm-board']?.status === ENABLED
			: true;

	return useMemo(() => {
		if (!projectKey && !boardId) {
			throw new Error('useBoardUrl() can only be used with board URLs');
		}

		if (user) {
			return getUserBoardUrl(user.accountId, boardId);
		}

		if (isJSMBoard) {
			return isBoardEnabled || !fg('jsm_views_configuration_migration')
				? getJSMBoardUrl(projectKey, boardId)
				: getJSMCalendarUrl(projectKey, boardId);
		}

		return getBoardUrl(projectKey, boardId, isReallyCMPBoard);
	}, [boardId, isJSMBoard, isReallyCMPBoard, projectKey, user, isBoardEnabled]);
}
