import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.quick.label',
		defaultMessage: 'Quick filters',
		description: 'The label of a dropdown menu button used to choose quick filters to apply.',
	},
	placeholder: {
		id: 'software-filters.filters.quick.placeholder',
		defaultMessage: 'Search quick filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
	maxSelectionReachedTooltip: {
		id: 'software-filters.filters.quick.max-selection-reached-tooltip',
		defaultMessage:
			"You can't select more than {maxSelectionAllowed, plural, one {# quick filter} other {# quick filters}}",
		description:
			'Tooltip shown on disabled unselected checklist item, when maximum allowed filters are selected',
	},
});
