import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.sprint.label',
		defaultMessage: 'Sprint',
		description: 'This is the label of a dropdown menu to filter Jira issues based on sprint(s).',
	},
	placeholder: {
		id: 'software-filters.filters.sprint.placeholder',
		defaultMessage: 'Search sprint filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
