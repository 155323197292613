import isNil from 'lodash/isNil';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/catch';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ORIGINAL_STATUS_TRANSITION } from '@atlassian/jira-portfolio-3-plan-issue-status/src/common/constant.tsx';
import { IP_BOARD_STATUS_FIELD_ID } from '../../../common/constants.tsx';
import {
	type IssueIncrementPlanningUpdateAction,
	ISSUE_INCREMENT_PLANNING_UPDATE,
	issueIncrementPlanningUpdateFailed,
	issueIncrementPlanningUpdateSuccess,
} from '../../../state/actions/issue/update/index.tsx';
import { setIssueStatusIncrementPlanning } from '../../../state/actions/issue/status/index.tsx';
import type { CustomRequestHandlers } from '../../../model/issue/issue-increment-planning-types.tsx';
import { getIssueById } from '../../../state/selectors/issue/issue-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../state/selectors/software/software-selectors.tsx';
import type {
	Action,
	ActionsObservable,
	BoardDependencies,
	MiddlewareAPI,
} from '../../../state/types.tsx';

const error = (action: IssueIncrementPlanningUpdateAction, err?: Error) => {
	log.safeErrorWithoutCustomerData(
		'issue.increment.planning.update',
		'Failed to update IP board issue',
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		err as Error,
	);

	if (action.promise && fg('issue_view_in_program_board')) {
		action.promise.reject(err);
	}
	return Observable.of(issueIncrementPlanningUpdateFailed());
};

const success = (action: IssueIncrementPlanningUpdateAction, response: unknown) => {
	if (action.promise && fg('issue_view_in_program_board')) {
		action.promise.resolve(response);
	}

	return Observable.of(issueIncrementPlanningUpdateSuccess());
};

function updateIssueStatus(
	action: IssueIncrementPlanningUpdateAction,
	store: MiddlewareAPI,
	updateIssue: NonNullable<CustomRequestHandlers['updateIssue']>,
	resetIssueStatus: NonNullable<CustomRequestHandlers['resetIssueStatus']>,
): Observable<Action> {
	const {
		payload: { issueId, fieldValue },
	} = action;
	const state = store.getState();

	if (
		typeof fieldValue === 'object' &&
		!isNil(fieldValue) &&
		!isNil(fieldValue.statusId) &&
		!isNil(fieldValue.statusTransitionId)
	) {
		if (fieldValue.statusTransitionId === ORIGINAL_STATUS_TRANSITION.id) {
			const originalIssueStatus = getIssueById(state, issueId)?.originals?.statusId;
			if (isNil(originalIssueStatus)) {
				return error(
					action,
					new Error('The original issue status cannot be undefined when reverting issue status'),
				);
			}
			return Observable.from(
				resetIssueStatus({
					issueId: String(issueId),
					originalIssueStatusId: Number(originalIssueStatus),
				}),
			)
				.flatMap((response) =>
					Observable.merge(
						success(action, response),
						Observable.of(setIssueStatusIncrementPlanning(issueId, fieldValue)),
					),
				)
				.catch((err) => error(action, err));
		}

		const props = {
			status: `${fieldValue.statusId}`,
			statusTransition: `${fieldValue.statusTransitionId}`,
		};

		return Observable.from(updateIssue({ ...props, id: String(issueId) }))
			.flatMap((response) =>
				Observable.merge(
					success(action, response),
					Observable.of(setIssueStatusIncrementPlanning(issueId, fieldValue)),
				),
			)
			.catch((err) => error(action, err));
	}
	return error(
		action,
		new Error(
			'must set the correct statusId and statusTransitionId when change the issue status in IP board',
		),
	);
}

function updateIssueParentEstimateSummary(
	action: IssueIncrementPlanningUpdateAction,
	updateIssue: NonNullable<CustomRequestHandlers['updateIssue']>,
) {
	const {
		payload: { issueId, fieldValue, fieldId },
	} = action;
	return Observable.from(updateIssue({ [fieldId]: fieldValue, id: String(issueId) }))
		.flatMap((response) => success(action, response))
		.catch((err) => error(action, err));
}

/**
 * this epic is used for inline updating the issue's parent, story point(estimate), status and summary in the IP board.
 * @param actions
 * @param store
 * @param customRequestHandlers
 * @returns
 */
export function issueIncrementPlanningUpdateEpic(
	actions: ActionsObservable,
	store: MiddlewareAPI,
	{ customRequestHandlers }: BoardDependencies,
) {
	return actions
		.ofType(ISSUE_INCREMENT_PLANNING_UPDATE)
		.mergeMap((action: IssueIncrementPlanningUpdateAction) => {
			const state = store.getState();
			const isIncrementPlanningBoard = getIsIncrementPlanningBoard(state);
			if (isIncrementPlanningBoard) {
				const {
					payload: { issueId, fieldValue, fieldId },
				} = action;

				// when clean up the FG issue_cards_in_program_board, remove this function
				const successOld = fg('issue_view_in_program_board')
					? (response: unknown) => {
							if (action.promise) {
								action.promise.resolve(response);
							}
							return Observable.of(issueIncrementPlanningUpdateSuccess());
						}
					: () => Observable.of(issueIncrementPlanningUpdateSuccess());

				// when clean up the FG issue_cards_in_program_board, remove this function
				const errorOld = (err?: Error) => {
					log.safeErrorWithoutCustomerData(
						'issue.increment.planning.update',
						'Failed to update IP board issue',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						err as Error,
					);
					if (action.promise && fg('issue_view_in_program_board')) {
						action.promise.reject(err);
					}
					return Observable.of(issueIncrementPlanningUpdateFailed());
				};

				if (fg('issue_cards_in_program_board')) {
					if (
						!customRequestHandlers ||
						!customRequestHandlers?.updateIssue ||
						!customRequestHandlers?.resetIssueStatus
					)
						return error(
							action,
							new Error('no request handler found for update issue or reset issue status'),
						);
				} else if (!customRequestHandlers || !customRequestHandlers?.updateIssue) {
					return errorOld(new Error('no request handler found for update issue'));
				}

				if (fg('issue_cards_in_program_board')) {
					if (fieldId === IP_BOARD_STATUS_FIELD_ID) {
						return updateIssueStatus(
							action,
							store,
							customRequestHandlers.updateIssue,
							// @ts-expect-error - when clean up the fg issue_cards_in_program_board, customRequestHandlers.resetIssueStatus will not be null or undefined, so please remove this comment
							customRequestHandlers.resetIssueStatus,
						);
					}

					return updateIssueParentEstimateSummary(action, customRequestHandlers.updateIssue);
				}
				// when clean up the issue_cards_in_program_board, remove this part until to the end
				return Observable.from(
					customRequestHandlers.updateIssue({
						id: String(issueId),
						[fieldId]: fieldValue,
					}),
				)
					.flatMap((response) => successOld(response))
					.catch((err) => errorOld(err));
			}
			return Observable.empty<never>();
		});
}
