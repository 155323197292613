import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import {
	BOARD_DETAIL_VIEW_MODE,
	DETAIL_VIEW_DEFAULT,
	type DETAIL_VIEW_SIDEBAR,
	type DETAIL_VIEW_MODAL,
	type SwimlaneModeId,
} from './constants.tsx';

const localStorage = createLocalStorageProvider('software');

const GROUP_BY_SELECTION = 'gh.groupBySelection.';

type DetailViewMode =
	| typeof DETAIL_VIEW_DEFAULT
	| typeof DETAIL_VIEW_SIDEBAR
	| typeof DETAIL_VIEW_MODAL;

export const setBoardDetailViewMode = (detailViewMode: DetailViewMode) =>
	localStorage.set(BOARD_DETAIL_VIEW_MODE, detailViewMode);

export const getBoardDetailViewMode = (): DetailViewMode =>
	localStorage.get(BOARD_DETAIL_VIEW_MODE) || DETAIL_VIEW_DEFAULT;

export const getSwimlaneModeSetting = (boardId: string): SwimlaneModeId | null | undefined => {
	const data = localStorage.get(`${GROUP_BY_SELECTION}${boardId}`);

	if (!data) {
		return null;
	}

	return data;
};

// Issue Entry related types and methods

export type IssueId = number | string;

export const getIsIssueIdValid = (issueId: IssueId) => {
	if (typeof issueId === 'number') {
		return issueId > 0;
	}

	return !!issueId;
};

export type IssueEntryIssue = {
	type: 'issue';
	issueId: IssueId;
};

export type IssueEntryGroup = {
	type: 'group';
	parentId: IssueId;
	issueIds: IssueId[];
	isAttachedToParent: boolean;
};

export type IssueEntry = IssueEntryIssue | IssueEntryGroup;

export const isIssueEntryIssue = (issueEntry: IssueEntry): issueEntry is IssueEntryIssue =>
	issueEntry.type === 'issue';

export const isIssueEntryGroup = (issueEntry: IssueEntry): issueEntry is IssueEntryGroup =>
	issueEntry.type === 'group';

export const getIssueEntryGroupKey = ({ parentId, issueIds }: IssueEntryGroup) =>
	`${parentId}::${issueIds.join(',')}`;

export type IssueLink = {
	id: string;
	linkTypeId: number;
	sourceId: IssueId;
	destinationId: IssueId;
	// when clean the FG dependency_visualisation_program_board_fe_and_be, remove this optional and make it required
	isOfftrack?: boolean;
};

export type IssueLinkType = {
	name: string;
	id: number;
	inward: string;
	outward: string;
	// isOutward only exists in Plan's Program Board
	isOutward?: boolean;
};

export type IssueOriginals = {
	statusId?: number;
};

/**
 * Software board card dimension constants
 */

export const DEFAULT_CARD_HEIGHT = 84;
// Left/right margin on card in its column
export const CARD_LEFT_RIGHT_MARGIN = 5;
