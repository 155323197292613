import React, { type ReactNode, useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { SHORTCUT_ELEMENT_TYPES } from '../../../../../constants.tsx';
import type { ShortcutSpecialChar } from '../../../../../types/index.tsx';
import { getShortcutSpecialCharacters } from '../../../../../utils.tsx';
import { FilledLozenge } from '../../../../custom-lozenge/index.tsx';
import {
	SHORTCUT_DIVIDER_TEST_ID,
	SHORTCUT_ICON_TEST_ID,
	SHORTCUT_WRAPPER_TEST_ID,
} from './constants.tsx';
import { messages } from './messages.tsx';
import type { AccumulatorType, ShortcutsIconsProps } from './types.tsx';

const KeyElement = ({ shortcut }: { shortcut?: string }) => (
	<ShortcutKeyWrapper>
		<FilledLozenge data-testid={SHORTCUT_ICON_TEST_ID}>{shortcut}</FilledLozenge>
	</ShortcutKeyWrapper>
);

const DivisorElement = ({ divisor }: { divisor: ReactNode[] | ReactNode }) => (
	<ShortcutDivider data-testid={SHORTCUT_DIVIDER_TEST_ID}>{divisor}</ShortcutDivider>
);

/**
 * Transform shortcut string into object
 */
const parseShortcut = (
	shortcut: string,
	replacers: ShortcutSpecialChar[],
	acc: AccumulatorType[] = [],
	types: SHORTCUT_ELEMENT_TYPES[] = [],
): [AccumulatorType[], SHORTCUT_ELEMENT_TYPES[]] => {
	const replacer = replacers.shift();

	if (!replacer) {
		acc.push({
			type: SHORTCUT_ELEMENT_TYPES.KEY,
			key: shortcut,
		});
		types.push(SHORTCUT_ELEMENT_TYPES.KEY);
		return [acc, types];
	}

	shortcut.split(replacer.char).forEach((str, index, self) => {
		parseShortcut(str, replacers.slice(0), acc, types);

		if (index < self.length - 1) {
			acc.push({ type: replacer.replacement });
			types.push(replacer.replacement);
		}
	});

	return [acc, types];
};

export const useShortcutIntl = () => useIntl();

export const Shortcuts = ({ shortcut }: ShortcutsIconsProps) => {
	const { formatMessage } = useShortcutIntl();

	const [shortcuts, types] = useMemo(
		() => parseShortcut(shortcut, getShortcutSpecialCharacters()),
		[shortcut],
	);

	const basicShortcutMessage = useMemo(() => {
		if (types.length === 3) {
			if (types[0] === SHORTCUT_ELEMENT_TYPES.KEY && types[2] === SHORTCUT_ELEMENT_TYPES.KEY) {
				if (types[1] === SHORTCUT_ELEMENT_TYPES.THEN) {
					return messages.shortcutThen;
				}
				if (types[1] === SHORTCUT_ELEMENT_TYPES.OR) {
					return messages.shortcutOr;
				}
				if (types[1] === SHORTCUT_ELEMENT_TYPES.PLUS) {
					return messages.shortcutPlus;
				}
			}
		}
		return null;
	}, [types]);

	const formatTypeToMessage = useCallback(
		(type: SHORTCUT_ELEMENT_TYPES) => {
			switch (type) {
				case SHORTCUT_ELEMENT_TYPES.THEN:
					return formatMessage(messages.divisorThen);
				case SHORTCUT_ELEMENT_TYPES.OR:
					return formatMessage(messages.divisorOr);
				default:
					return formatMessage(messages.divisorPlus);
			}
		},
		[formatMessage],
	);

	return (
		<ShortcutContainer data-testid={SHORTCUT_WRAPPER_TEST_ID} aria-hidden>
			{basicShortcutMessage
				? formatMessage(basicShortcutMessage, {
						shortcutA: <KeyElement shortcut={shortcuts[0].key} />,
						shortcutB: <KeyElement shortcut={shortcuts[2].key} />,
						divisor: (chunk) => <DivisorElement divisor={chunk} />,
					})
				: shortcuts.map((obj, index) => {
						if (obj.type === SHORTCUT_ELEMENT_TYPES.KEY) {
							return <KeyElement key={index} shortcut={obj.key} />;
						}

						return <DivisorElement key={index} divisor={formatTypeToMessage(obj.type)} />;
					})}
		</ShortcutContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortcutDivider = styled.div({
	marginTop: 0,
	marginRight: token('space.050', '4px'),
	marginBottom: 0,
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortcutContainer = styled.div({
	display: 'flex',
	flexGrow: 0,
	flexShrink: 1,
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ShortcutKeyWrapper = styled.div({
	width: '24px',
	height: '24px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
