import { useEffect } from 'react';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { PRIORITY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import { useDiffCheck } from '../../../../common/hooks/use-diff-check/index.tsx';
import type { Priority } from '../../../../model/issue/issue-types.tsx';
import { setPriority } from '../../../../state/actions/issue/priority/index.tsx';
import { useBoardActionCreator } from '../../../../state/index.tsx';
import { useIsIncrementPlanningBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import type { FieldSyncPriorityProps } from './types.tsx';

export const FieldSyncPriority = ({
	issue: { id, key, priority: issuePriority },
	projectKey,
}: FieldSyncPriorityProps) => {
	const setPriorityInBoard = useBoardActionCreator((issueId: IssueId, priority: Priority | null) =>
		setPriority(issueId, priority),
	);

	const isIPBoard = useIsIncrementPlanningBoard();
	// In the increment planning board, scenario issue keys do not yet have a numeric identifier as
	// they have not been created in Jira yet. In this case, the key is equal to the projectKey,
	// and thus not unique. Instead, we pass the issue id to the field config service (as it
	// requires a unique identifier for each issue).
	const uniqueKey = isIPBoard && key === projectKey ? `${id}` : key;
	const [priorityFromIssueField, { setFieldValue: setPriorityInExtractedFields }] = useFieldValue({
		issueKey: uniqueKey,
		fieldKey: PRIORITY_TYPE,
	});

	const priorityFromBoard = issuePriority ?? null;
	const priorityFromBoardChanged = useDiffCheck([priorityFromBoard?.id]);
	const priorityFromIssueFieldChanged = useDiffCheck([priorityFromIssueField?.id]);

	const storesNeedToSync = priorityFromBoard !== priorityFromIssueField;

	// PRIORITY SYNC: BOARD -> EXTRACTED FIELDS
	useEffect(() => {
		if (priorityFromBoardChanged && storesNeedToSync) {
			setPriorityInExtractedFields(uniqueKey, PRIORITY_TYPE, priorityFromBoard);
		}
	}, [
		uniqueKey,
		setPriorityInExtractedFields,
		storesNeedToSync,
		priorityFromBoard,
		priorityFromBoardChanged,
	]);

	// PRIORITY SYNC: EXTRACTED FIELDS -> BOARD
	useEffect(() => {
		if (!priorityFromBoardChanged && priorityFromIssueFieldChanged && storesNeedToSync) {
			setPriorityInBoard(id, priorityFromIssueField);
		}
	}, [
		id,
		setPriorityInBoard,
		storesNeedToSync,
		priorityFromBoardChanged,
		priorityFromIssueField,
		priorityFromIssueFieldChanged,
	]);

	return null;
};
