const REFRESH_ISSUE_PAGE_EVENT = 'legacy_refreshIssuePage';
const UPDATE_ISSUE_GLANCE_EVENT = 'legacy_updateIssueGlance';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addEventListener = (event: string, callback: any): void => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.addEventListener(event, callback);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeEventListener = (event: string, callback: any): void => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.removeEventListener(event, callback);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addRefreshIssueListener = (callback: any): void =>
	addEventListener(REFRESH_ISSUE_PAGE_EVENT, callback);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeRefreshIssueListener = (callback: any): void =>
	removeEventListener(REFRESH_ISSUE_PAGE_EVENT, callback);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addUpdateIssueGlanceListener = (callback: any): void =>
	addEventListener(UPDATE_ISSUE_GLANCE_EVENT, callback);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUpdateIssueGlanceListener = (callback: any): void =>
	removeEventListener(UPDATE_ISSUE_GLANCE_EVENT, callback);

export type Reason = 'ON_DEMAND' | 'SOFT_REFRESH' | 'REALTIME';

const refreshIssuePageThrowable = (
	issueKey: string,
	reason: Reason = 'ON_DEMAND',
): Promise<void> => {
	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.dispatchEvent(
			new CustomEvent<[string, { mergeIntoCurrent: boolean; reason: Reason }]>(
				REFRESH_ISSUE_PAGE_EVENT,
				{
					detail: [issueKey, { mergeIntoCurrent: false, reason }],
				},
			),
		);
		return Promise.resolve();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return Promise.reject(e);
	}
};

const refreshIssuePage = (issueKey: string): Promise<void> =>
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	refreshIssuePageThrowable(issueKey).catch(() => {});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	addRefreshIssueListener,
	removeRefreshIssueListener,
	addUpdateIssueGlanceListener,
	removeUpdateIssueGlanceListener,
	refreshIssuePageThrowable,
	refreshIssuePage,
};
