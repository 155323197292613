import { useMemo } from 'react';
import { ISSUE_VIEW_SUGGESTIONS_TASK_TYPES } from '../../common/constants.tsx';

const MARGIN = 0.5;

export const useIssueViewSuggestionsRandomly = () => {
	const randomizedValue = useMemo(
		() =>
			Math.random() < MARGIN
				? ISSUE_VIEW_SUGGESTIONS_TASK_TYPES.PULL_REQUEST_REVIEW
				: ISSUE_VIEW_SUGGESTIONS_TASK_TYPES.PR_MERGEABLE,
		[],
	);

	return randomizedValue;
};
