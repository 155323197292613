import React, { type ReactChild, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ChartTrendIcon from '@atlaskit/icon/core/migration/chart-trend--graph-line';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Show, Hide } from '@atlaskit/primitives/responsive';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	JIRA_INSIGHTS,
	INSIGHTS_BUTTON_SM,
	INSIGHTS_BUTTON_LG,
	INSIGHTS_BUTTON_RESPONSIVE,
	INSIGHTS_BUTTON,
} from '@atlassian/jira-insights-common-constants/src/common/constants/analytics.tsx';
import {
	BUTTON_LOCATION_BACKLOG,
	BUTTON_LOCATION_BACKLOG_VISUAL_REFRESH,
	BUTTON_LOCATION_KANBAN,
	BUTTON_LOCATION_KANBAN_VISUAL_REFRESH,
	BUTTON_LOCATION_SCRUM,
	BUTTON_LOCATION_SCRUM_VISUAL_REFRESH,
} from '@atlassian/jira-insights-common/src/common/constants.tsx';
import { useIsCurrentBrowserSupported } from '@atlassian/jira-insights-common/src/controllers/use-is-current-browser-supported/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	fireUIAnalytics,
	FireOperationalAnalytics,
	FireUiAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import type { InsightsButtonProps, TooltipWrapperProps } from './types.tsx';

const actionSubjectId = 'toggleInsightsPanel';

const TooltipWrapper = ({ children, render }: TooltipWrapperProps) =>
	render != null ? render({ children }) : <>{children}</>;

const buttonTestId = 'insights-show-insights-button.ui.insights-button';
const buttonForHiddenTestId = 'insights-show-insights-button.ui.button-test-id-hide';

export const UnsafeInsightsButton = ({
	clickEventAnalytics,
	iconAfter,
	isCompact = false,
	isHidden = false,
	isInsightsPanelOpen,
	isOnboardingDisabled = false,
	onToggle,
	renderTooltip,
	location,
}: InsightsButtonProps) => {
	const { formatMessage } = useIntl();
	const [, { register }] = useGlobalRefStoreActions();

	const onRefSmall = useCallback(
		(ref: HTMLDivElement | null) => {
			register(INSIGHTS_BUTTON_SM, ref);
		},
		[register],
	);

	const onRefButtonLarge = useCallback(
		(ref: HTMLDivElement | null) => {
			register(INSIGHTS_BUTTON_LG, ref);
		},
		[register],
	);

	const onRefButtonResponsive = useCallback(
		(ref: HTMLButtonElement | null) => {
			register(INSIGHTS_BUTTON_RESPONSIVE, ref);
		},
		[register],
	);

	const onRefButton = useCallback(
		(ref: HTMLButtonElement | null) => {
			register(INSIGHTS_BUTTON, ref);
		},
		[register],
	);

	const isSupported = useIsCurrentBrowserSupported();

	const isDisabled = isOnboardingDisabled;

	const onClick = useCallback(
		(_: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, actionSubjectId, {
				action: isInsightsPanelOpen ? 'close' : 'open',
				isDisabled,
				...(clickEventAnalytics?.attributes || {}),
			});

			onToggle(analyticsEvent);
		},
		[clickEventAnalytics, isDisabled, isInsightsPanelOpen, onToggle],
	);

	const buttonLabelMap = {
		[BUTTON_LOCATION_BACKLOG]: {
			open: messages.showInsightsBacklogTerminologyRefresh,
			closed: messages.hideInsightsBacklogTerminologyRefresh,
		},
		[BUTTON_LOCATION_KANBAN]: {
			open: messages.showInsightsKanbanTerminologyRefresh,
			closed: messages.hideInsightsKanbanTerminologyRefresh,
		},
		[BUTTON_LOCATION_SCRUM]: {
			open: messages.showInsightsScrumTerminologyRefresh,
			closed: messages.hideInsightsScrumTerminologyRefresh,
		},
		[BUTTON_LOCATION_BACKLOG_VISUAL_REFRESH]: {
			open: messages.backlogInsightsLabel,
			closed: messages.backlogInsightsLabel,
		},
		[BUTTON_LOCATION_KANBAN_VISUAL_REFRESH]: {
			open: messages.kanbanInsightsLabel,
			closed: messages.kanbanInsightsLabel,
		},
		[BUTTON_LOCATION_SCRUM_VISUAL_REFRESH]: {
			open: messages.scrumInsightsLabel,
			closed: messages.scrumInsightsLabel,
		},
		default: {
			open: messages.showInsights,
			closed: messages.hideInsights,
		},
	};

	const { open, closed } = buttonLabelMap[location ?? 'default'] || buttonLabelMap.default;
	const label = isInsightsPanelOpen ? formatMessage(open) : formatMessage(closed);

	if (!isSupported) {
		return null;
	}

	const showInsightsButton = !isHidden;

	if (!showInsightsButton) {
		return (
			<FireOperationalAnalytics
				action="hidden"
				actionSubject="button"
				actionSubjectId={actionSubjectId}
			/>
		);
	}

	const iconColor = isInsightsPanelOpen ? undefined : token('color.icon', colors.N500);

	return (
		<>
			<FireUiAnalytics action="rendered" actionSubject="button" actionSubjectId={actionSubjectId} />
			{getWillShowNav4() ? (
				<SpotlightTarget name="jira-insights-button">
					{isVisualRefreshEnabled() ? (
						<IconButton
							interactionName="show-insights-button"
							icon={ChartTrendIcon}
							label={label}
							isSelected={isInsightsPanelOpen}
							onClick={onClick}
							testId={buttonTestId}
							ref={onRefButton}
							isTooltipDisabled={false}
						/>
					) : (
						<ResponsiveButton
							interactionName="show-insights-button"
							icon={ChartTrendIcon}
							label={label}
							iconAfter={typeof iconAfter === 'function' ? iconAfter : undefined}
							isSelected={isInsightsPanelOpen}
							onClick={onClick}
							testId={buttonTestId}
							ref={onRefButtonResponsive}
						/>
					)}
				</SpotlightTarget>
			) : (
				<>
					{/* eslint-disable-next-line no-nested-ternary */}
					{isVisualRefreshEnabled() ? (
						<SpotlightTarget name="jira-insights-button">
							<TooltipWrapper render={renderTooltip}>
								<IconButton
									interactionName="show-insights-button"
									icon={ChartTrendIcon}
									label={label}
									isSelected={isInsightsPanelOpen}
									onClick={onClick}
									testId={buttonTestId}
									ref={onRefButton}
								/>
							</TooltipWrapper>
						</SpotlightTarget>
					) : isCompact ? (
						<SpotlightTarget name="jira-insights-button">
							<TooltipWrapper render={renderTooltip}>
								<Button
									interactionName="show-insights-button"
									appearance="subtle"
									spacing="none"
									isSelected={isInsightsPanelOpen}
									iconBefore={<ChartTrendIcon label={label} color={iconColor} />}
									onClick={onClick}
									testId={buttonTestId}
									ref={onRefSmall}
								/>
							</TooltipWrapper>
						</SpotlightTarget>
					) : (
						<SpotlightTarget name="jira-insights-button">
							{/* eslint-disable-next-line jira/react/no-empty-divs */}
							<div>
								<Show above="lg">
									<TooltipWrapper render={renderTooltip}>
										<Button
											interactionName="show-insights-button"
											isSelected={isInsightsPanelOpen}
											iconBefore={<ChartTrendIcon label="" color={iconColor} />}
											iconAfter={
												typeof iconAfter === 'object'
													? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
														(iconAfter as ReactChild)
													: undefined
											}
											onClick={onClick}
											testId={buttonTestId}
											ref={onRefButtonLarge}
										>
											{label}
										</Button>
									</TooltipWrapper>
								</Show>
								{/* Render without text on smaller screen sizes */}
								<Hide above="lg">
									<TooltipWrapper render={renderTooltip}>
										<Button
											interactionName="show-insights-button"
											isSelected={isInsightsPanelOpen}
											iconBefore={<ChartTrendIcon label={label} color={iconColor} />}
											onClick={onClick}
											testId={buttonForHiddenTestId}
											ref={onRefSmall}
										/>
									</TooltipWrapper>
								</Hide>
							</div>
						</SpotlightTarget>
					)}
				</>
			)}
		</>
	);
};

export const SafeInsightsButton = (props: InsightsButtonProps) => {
	const packageName = props?.packageName ? props.packageName : JIRA_INSIGHTS;

	return (
		<JSErrorBoundary
			id="button"
			packageName={packageName}
			teamName="helios-headcoach"
			fallback="flag"
		>
			<UnsafeInsightsButton {...props} />
		</JSErrorBoundary>
	);
};

export { SafeInsightsButton as InsightsButton };
