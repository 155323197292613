import { useCallback } from 'react';
import { useCommandPaletteSessionId } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { OpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/common/types.tsx';
import { useOpenIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { StatusKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { StatusTransition } from '../../../../common/types.tsx';
import type { onUpdateStatusMutationProps } from '../../../../services/status-service/types.tsx';
import { messages } from './messages.tsx';

export const useSaveStatusField = (
	issueKey: string,
	issueId: Ari,
	onUpdateStatusMutation?: (props: onUpdateStatusMutationProps) => Promise<void>,
) => {
	const { formatMessage } = useIntl();

	const [{ commandPaletteSessionId }] = useCommandPaletteSessionId();

	const { showFlag } = useFlagsService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey: StatusKey,
	});

	const onSaveSuccessfully = useCallback(
		(transition: StatusTransition, callback?: () => void) => {
			const action = 'commandPalette';
			const actionSubject = 'changeStatus';
			const eventActionAndSubject = `${actionSubject} ${action}`;

			const event = createAnalyticsEvent({
				action,
				actionSubject,
			});

			setFieldValue(issueKey, StatusKey, transition.to);
			callback?.();

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fireTrackAnalytics(event, eventActionAndSubject as string, {
				transitionId: transition.id,
				commandPaletteSessionId,
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'statusField transitioned', 'statusField');

			!fg('show-modernised-issue-transition-success-flag') &&
				showFlag({
					type: 'success',
					title: formatMessage(messages.successFlagMessage),
				});
		},
		[
			commandPaletteSessionId,
			createAnalyticsEvent,
			formatMessage,
			issueKey,
			setFieldValue,
			showFlag,
		],
	);

	const onFailSave = useCallback(
		(error: Error) => {
			fireErrorAnalytics({
				error,
				meta: {
					id: 'commandPaletteIssueStatus',
					packageName: 'jiraIssueFieldStatus',

					teamName: 'deliveroo',
				},
			});
			showFlag({
				type: 'error',
				title: formatMessage(messages.errorFlagTitle),
				description: formatMessage(messages.errorFlagDescription),
			});
		},
		[formatMessage, showFlag],
	);

	const openIssueTransitionModal: OpenIssueTransitionModal = useOpenIssueTransitionModal();
	const { showIssueTransitionSuccessFlag } = useShowFlag();

	const saveStatusField = useCallback(
		async (transition: StatusTransition, callback?: () => void) => {
			try {
				if (transition.hasScreen) {
					const handleDialogSuccess = () => {
						fg('show-modernised-issue-transition-success-flag') &&
							showIssueTransitionSuccessFlag(issueKey, transition.to.name);

						return onSaveSuccessfully(transition, callback);
					};
					const handleDialogError = (err?: Error | unknown) =>
						onFailSave(
							err instanceof Error
								? err
								: new Error(
										'Something went wrong trying to update the status with screen using the command palette',
									),
						);
					const transitionId = transition.id;

					openIssueTransitionModal({
						payload: {
							issueId,
							issueKey,
							transitionId,
						},
						triggerPointKey: 'issue-transition-command-palette-status-field-transition',
						onDialogSuccess: handleDialogSuccess,
						onDialogError: handleDialogError,
					});
				} else {
					if (onUpdateStatusMutation && fg('relay-migration-issue-fields-status')) {
						await onUpdateStatusMutation?.({
							transitionId: transition.id,
							newStatus: transition.to,
						});
					} else {
						await performPostRequest(`/rest/api/3/issue/${issueKey}/transitions`, {
							body: JSON.stringify({ transition }),
						});
					}
					onSaveSuccessfully(transition, callback);

					fg('show-modernised-issue-transition-success-flag') &&
						showIssueTransitionSuccessFlag(issueKey, transition.to.name);
				}
			} catch (err) {
				const error =
					err instanceof Error
						? err
						: new Error(
								'Something went wrong trying to update the status using the command palette',
							);

				onFailSave(error);
			}
		},
		[
			issueId,
			issueKey,
			onFailSave,
			onSaveSuccessfully,
			onUpdateStatusMutation,
			openIssueTransitionModal,
			showIssueTransitionSuccessFlag,
		],
	);

	return { saveStatusField };
};
