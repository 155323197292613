/**
 * Based on custom icon example:
 * https://atlassian.design/components/icon/custom-icon/examples
 */

import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps, IconProps } from '@atlaskit/icon/types';

const LightningGlyph = (props: CustomGlyphProps) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		data-testid={props['data-testid']}
		aria-label={props['aria-label']}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={props.className}
	>
		<path
			fill="currentColor"
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M10.5115 3.99997H15.1546C15.4191 3.99997 15.6335 4.22318 15.6335 4.49852C15.6335 4.57648 15.616 4.65335 15.5823 4.72296L13.6197 8.7755C13.5006 9.02137 13.5955 9.32116 13.8317 9.44512C13.8986 9.48021 13.9724 9.49849 14.0473 9.49849H17.521C17.7855 9.49849 17.9999 9.7217 17.9999 9.99704C17.9999 10.1411 17.9401 10.2781 17.8358 10.3728L7.9973 19.3057C7.79797 19.4867 7.49545 19.4652 7.3216 19.2577C7.20098 19.1137 7.16999 18.9107 7.24181 18.7349L9.20627 13.9262C9.30979 13.6728 9.19639 13.38 8.95299 13.2723C8.89372 13.246 8.82998 13.2325 8.76557 13.2325H6.47884C6.21435 13.2325 5.99994 13.0093 5.99994 12.7339C5.99994 12.6552 6.01784 12.5777 6.05218 12.5075L10.0849 4.2721C10.1667 4.1051 10.3315 3.99997 10.5115 3.99997Z"
		/>
	</svg>
);

const LightningBolt = (props: IconProps) => <Icon {...props} glyph={LightningGlyph} />;

export default LightningBolt;
