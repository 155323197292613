import React, { forwardRef } from 'react';
import { styled } from '@compiled/react';
import FocusLock from 'react-focus-lock';
import Button, { type ThemeProps, type ThemeTokens, CustomThemeButton } from '@atlaskit/button';
import { SpotlightCard } from '@atlaskit/onboarding';
import { colors } from '@atlaskit/theme';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { DEFAULT_NUDGE_WIDTH } from '@atlassian/jira-onboarding-quickstart-core/src/common/constants.tsx';
import type {
	NudgeAction as NudgeActionType,
	OnboardingSpotlightProps,
	Message,
} from './types.tsx';

const NudgeHeading = ({ formattedMessage }: { formattedMessage: Message }) => (
	<Title id="nudge-spot-light-card-heading">
		<CustomHeading>{formattedMessage}</CustomHeading>
	</Title>
);

const NudgeAction = ({ appearance, content, onClick }: NudgeActionType) => {
	// if appearance is primary, override styles with custom button
	// note CustomThemeButton is advised against and we need a better solution
	// (potentially even going with the blue button). Leaving this code for the experiment
	if (appearance === 'primary') {
		const customTheme = (
			currentTheme: (props: ThemeProps) => ThemeTokens,
			themeProps: ThemeProps,
		) => {
			const { buttonStyles, spinnerStyles } = currentTheme(themeProps);
			return {
				buttonStyles: {
					...buttonStyles,
					backgroundColor: `${token('color.icon.discovery', '#8270DB')}`,
					minWidth: '84px',
					margin: `0 ${token('space.050', '4px')}`,
					color: `${token('color.text.inverse', colors.N0)}`,
				},
				spinnerStyles,
			};
		};
		return (
			<CustomThemeButton appearance={appearance} onClick={onClick} theme={customTheme}>
				{content}
			</CustomThemeButton>
		);
	}

	return (
		<Button appearance={appearance} onClick={onClick}>
			{content}
		</Button>
	);
};

const NudgeActions = ({ actions }: { actions: NudgeActionType[] }) => (
	<Footer>
		{actions.map((action, index) => (
			<NudgeAction key={index} {...action} />
		))}
	</Footer>
);

export const createOnboardingSpotlight = ({
	actions,
	contentMessage,
	headingMessage,
	image,
	width = DEFAULT_NUDGE_WIDTH,
	CustomWrapper,
	actionsBeforeElement,
}: OnboardingSpotlightProps) => {
	const Wrapper = CustomWrapper || SpotlightCardWrapper;

	return forwardRef<HTMLDivElement>((_, ref) => (
		<Wrapper ref={ref}>
			<SpotlightCard
				image={image}
				heading={headingMessage ? <NudgeHeading formattedMessage={headingMessage} /> : null}
				width={width}
				actionsBeforeElement={actionsBeforeElement}
			>
				{contentMessage}
				<NudgeActions actions={actions} />
			</SpotlightCard>
		</Wrapper>
	));
};

export const createOnboardingSpotlightWithFocusLock = forwardRef<
	HTMLDivElement,
	OnboardingSpotlightProps & { focusLock?: boolean }
>(({ focusLock, ...rest }, ref) => {
	const Spotlight = createOnboardingSpotlight({
		...rest,
	});

	return (
		<FocusLock disabled={!focusLock}>
			<Spotlight ref={ref} />
		</FocusLock>
	);
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpotlightCardWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > div': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface.overlay', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text', colors.N500),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > div > span': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text', colors.N500),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// Cannot use atlaskit/heading due to automatic color setting that breaks due to overriding Spotlight background color above.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const CustomHeading = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.heading.small', fontFallback.heading.small),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N500),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	paddingTop: token('space.100', '8px'),
});
