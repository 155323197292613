import React from 'react';
import isNil from 'lodash/isNil';
import { Box, xcss, Text } from '@atlaskit/primitives';
import Button from '@atlaskit/button';
import ToolTip from '@atlaskit/tooltip';
import Lozenge from '@atlaskit/lozenge';
import { useIntl } from '@atlassian/jira-intl';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import {
	KANBAN_TEAM,
	SWIMLANE_TEAMLESS,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { TEAM } from '../../common/constants.tsx';
import type { SwimlaneByTeamValues } from '../../common/types.tsx';
import { appendUnscheduledWorkSwimlaneIdSuffix } from '../../common/utils.tsx';
import messages from './messages.tsx';

type TeamLozengeProps = {
	id: string;
	values?: SwimlaneByTeamValues;
};

export const TeamLozenge = ({ id, values }: TeamLozengeProps) => {
	const { formatMessage } = useIntl();

	if (values && values.type === TEAM) {
		const isExternal = !values.isPlanTeam;
		const isPlanOnly = isNil(values.externalId);
		const isTeamLessSwimlane =
			id === SWIMLANE_TEAMLESS || id === appendUnscheduledWorkSwimlaneIdSuffix(SWIMLANE_TEAMLESS);
		const isKanban = values.planningStyle === KANBAN_TEAM;
		const lozengeContents = {
			external: {
				lozenge: planCommonMessages.externalLozenge,
				tooltipTitle: planCommonMessages.externalTeamTooltipTitle,
				tooltipContent: planCommonMessages.externalTeamTooltipContent,
				testId: 'software-board.board-container.board.swimlane.external',
			},
			planOnly: {
				lozenge: planCommonMessages.planOnlyLozenge,
				tooltipTitle: planCommonMessages.planOnlyTeamTooltipTitle,
				tooltipContent: planCommonMessages.planOnlyTeamTooltipContent,
				testId: 'software-board.board-container.board.swimlane.plan-only',
			},
			kanban: {
				lozenge: planCommonMessages.kanbanLozenge,
				tooltipTitle: undefined,
				tooltipContent: undefined,
				testId: 'software-board.board-container.board.swimlane.kanban',
			},
		};

		let lozengeContent;
		if (isExternal) {
			lozengeContent = lozengeContents.external;
		} else if (isKanban) {
			lozengeContent = lozengeContents.kanban;
		} else if (isPlanOnly && !isTeamLessSwimlane) {
			lozengeContent = lozengeContents.planOnly;
		}

		if (lozengeContent) {
			const { tooltipTitle, tooltipContent, lozenge, testId } = lozengeContent;
			const lozengeComponent = <Lozenge testId={testId}>{formatMessage(lozenge)}</Lozenge>;

			if (tooltipTitle && tooltipContent) {
				const tooltip = (
					<Box padding="space.100" xcss={TeamTooltipStyles} role="tooltip">
						<Text as="p" color="inherit" id={`${id}-tooltip`}>
							<Text as="strong">{formatMessage(tooltipTitle)}</Text>
							<Text as="p">{formatMessage(tooltipContent)}</Text>
						</Text>
					</Box>
				);
				return fg('increment_board_a11y_fix') ? (
					<ToolTip content={tooltip}>
						<Button
							spacing="none"
							aria-label={formatMessage(messages.lozengeButtonLabel)}
							appearance="subtle"
							aria-describedby={`${id}-tooltip`}
						>
							{lozengeComponent}
						</Button>
					</ToolTip>
				) : (
					<ToolTip content={tooltip}>{lozengeComponent}</ToolTip>
				);
			}
			return lozengeComponent;
		}
	}
	return null;
};

const TeamTooltipStyles = xcss({
	textAlign: 'center',
});
