import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { formatDistance as distanceInWords } from 'date-fns';
import memoizeOne from 'memoize-one';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { Y500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { CommonProps, OnUnlinkCallback, OverlapWarningProps } from '../modal/types.tsx';
import Card from './card/index.tsx';
import Line from './line/index.tsx';
import messages from './messages.tsx';
import Separator from './separator/index.tsx';

const createUnlinkHandler = memoizeOne(
	(
		itemKey: string,
		type: number,
		fromId: IssueId,
		toId: IssueId,
		onUnlink: OnUnlinkCallback,
		callback?: () => void,
	) =>
		() => {
			onUnlink({ itemKey, type, sourceItemKey: fromId, targetItemKey: toId });
			if (callback) {
				callback();
			}
		},
);

const SimpleOverlapWarning = () => {
	const { formatMessage } = useIntl();
	return (
		<Warning>
			<WarningIconWrapper>
				<WarningIcon size="small" label="" primaryColor={token('color.icon.warning', Y500)} />
			</WarningIconWrapper>
			<Text weight="bold" color="inherit">
				{formatMessage(messages.offTrack)}
			</Text>
		</Warning>
	);
};

const OverlapWarning = ({ fromIssue, toIssue }: OverlapWarningProps) => {
	const { formatMessage } = useIntl();
	const locale = useLocale();
	if (
		!(
			fromIssue &&
			fromIssue.dueDate &&
			fromIssue.dueDate > 0 &&
			toIssue &&
			toIssue.startDate &&
			toIssue.startDate > 0
		)
	) {
		return null;
	}
	const leadTime = toIssue.startDate - fromIssue.dueDate;

	let overlapPeriod = null;
	// if "end date" of issue A is May 4, 2021 and "start date" of issue B is May 5, 2021, then leadTime = 1
	if (leadTime === 1) {
		// we display a leadTimeMessage "less than a day"
		// note: we don't use distanceInWords as it would return 'less than a minute'
		overlapPeriod = formatMessage(messages.lessThanOneDayLeadTime);
	} else {
		overlapPeriod = distanceInWords(fromIssue.dueDate, toIssue.startDate, {
			locale: getLocale(locale),
		});
	}
	return overlapPeriod ? (
		<Warning>
			<WarningIconWrapper>
				<WarningIcon size="small" label="" primaryColor={token('color.icon.warning', Y500)} />
			</WarningIconWrapper>
			<Text weight="bold" color="inherit">
				{`${formatMessage(messages.overlapDatesMessage)} (${overlapPeriod})`}{' '}
			</Text>
		</Warning>
	) : null;
};

const ModalContent = (props: CommonProps) => {
	const { links, onUnlink, onClose, canDelete, isOverlapping, id, simpleOverlap = false } = props;

	const { formatMessage } = useIntl();

	if (links.length === 0) {
		return null;
	}

	// Each element in the array is a link with the same source and target
	const { fromIssue, toIssue, isOfftrack } = links[0];

	let isDependenciesDatesOverlapping = false;

	if (simpleOverlap && fg('dependency_visualisation_program_board_fe_and_be')) {
		isDependenciesDatesOverlapping = isOfftrack ?? false;
	} else if (isOverlapping) {
		isDependenciesDatesOverlapping = isOverlapping(fromIssue.indexOnScope, toIssue.indexOnScope);
	}

	const defaultOutwardDependencyDescription = formatMessage(messages.blocks);
	const shouldCloseOnUnlink = links.length <= 1;

	const dependenciesBlock = links.map((link, index) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { fromIssue, toIssue, issueLinkType, itemKey } = link;

		const renderOverlapWarning = () => {
			if (simpleOverlap) {
				return <SimpleOverlapWarning />;
			}
			return <OverlapWarning fromIssue={fromIssue} toIssue={toIssue} />;
		};

		return (
			<div key={`${issueLinkType.id}_${fromIssue.id}_${toIssue.id}`}>
				<Card {...fromIssue} />
				<Separator
					label={issueLinkType.outward ?? defaultOutwardDependencyDescription}
					isOverlapping={isDependenciesDatesOverlapping}
					onUnlink={
						canDelete
							? createUnlinkHandler(
									itemKey,
									issueLinkType.id,
									fromIssue.id,
									toIssue.id,
									onUnlink,
									shouldCloseOnUnlink ? onClose : undefined,
								)
							: undefined
					}
				/>
				<Card {...toIssue} />
				{isDependenciesDatesOverlapping &&
					fg('dependency_visualisation_program_board_fe_and_be') &&
					renderOverlapWarning()}
				{isDependenciesDatesOverlapping &&
					!fg('dependency_visualisation_program_board_fe_and_be') && (
						<OverlapWarning fromIssue={fromIssue} toIssue={toIssue} />
					)}

				{index < links.length - 1 ? <Line /> : null}
			</div>
		);
	});

	return (
		<Container id={id} data-testid="portfolio-3-dependency-line-detail.common.ui.content.container">
			<Content>{dependenciesBlock}</Content>
		</Container>
	);
};

ModalContent.defaultProps = {
	onUnlink: noop,
	onClose: noop,
	canDelete: false,
};

export default ModalContent;

const TOOLTIP_Z_INDEX = 13;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.200', '16px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.200', '16px'),
	paddingLeft: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface.raised', colors.N0),
	width: '240px',
	boxShadow: token('elevation.shadow.raised', '0 2px 4px 0 rgba(0, 0, 0, 0.25)'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	maxHeight: '300px',
	overflow: 'auto',
	zIndex: TOOLTIP_Z_INDEX,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Warning = styled.div({
	display: 'flex',
	marginTop: token('space.150', '12px'),
	gap: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningIconWrapper = styled.div({
	display: 'flex',
	alignContent: 'center',
	flexWrap: 'wrap',
});
