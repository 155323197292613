import { useCallback } from 'react';
import { freeze } from 'icepick';
import get from 'lodash/get';
import { fg } from '@atlassian/jira-feature-gating';
import { getRandomColor } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import {
	CHILD_HIERARCHY_TYPE,
	BASE_HIERARCHY_TYPE,
	PARENT_HIERARCHY_TYPE,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import { TEAM } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { CreateCallback } from '@atlassian/jira-platform-board-kit/src/ui/inline-card-create/types.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { ColumnId } from '../../../../../../model/column/column-types.tsx';
import { SUBTASK_DND_TYPE } from '../../../../../../model/constants.tsx';
import {
	type Issue,
	type Estimate,
	INCREMENT_PLANNING_ESTIMATE_FIELD,
} from '../../../../../../model/issue/issue-types.tsx';
import type { Status } from '../../../../../../model/software/software-types.tsx';
import { SWIMLANE_BY_JQL } from '../../../../../../model/swimlane/swimlane-modes.tsx';
import { ASSIGNEE, PARENT, STORY } from '../../../../../../model/swimlane/swimlane-types.tsx';
import { getIssueAssignee } from '../../../../../../services/issue/issue-data-transformer.tsx';
import { generateId } from '../../../../../../services/issue/temporary-id-generator.tsx';
import { closeIccInUnscheduledColumn } from '../../../../../../state/actions/card/index.tsx';
import { openGlobalIssueCreate } from '../../../../../../state/actions/global-issue-create/index.tsx';
import { issueCreateRequest } from '../../../../../../state/actions/issue/create/index.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../../../state/index.tsx';
import {
	getInitialTransitionsForColumn as getInitialTransitionsForColumnSelector,
	getParentIdForIssueCreation,
} from '../../../../../../state/selectors/card-create/card-create-selectors.tsx';
import {
	columnStatusMappingSelector,
	getColumnIndex,
	getColumns,
	getStatusColumn,
	getStatusIdsByColumnId as getStatusIdsByColumnIdSelector,
} from '../../../../../../state/selectors/column/column-selectors.tsx';
import {
	getInlineCardCreateConfig,
	getInlineCreateSessionId,
} from '../../../../../../state/selectors/inline-create/inline-create-selectors.tsx';
import {
	getAppliedLabelFilters,
	getAssigneeId,
	getLastFilteredSprintId,
} from '../../../../../../state/selectors/issue/issue-create-selectors.tsx';
import { getDefaultPriorityByProjectId } from '../../../../../../state/selectors/issue/issue-priority-selectors.tsx';
import {
	getIsIncrementPlanningBoard,
	getMissingParents,
	getScenarioColor,
	getIncrementPlanningEstimateFieldId,
} from '../../../../../../state/selectors/software/software-selectors.tsx';
import { activeSprintsSelector } from '../../../../../../state/selectors/sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../../../../../../state/selectors/swimlane/swimlane-mode-selectors.tsx';
import { makeSwimlaneValuesSelector } from '../../../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import { getCardDndType as getCardDndTypeSelector } from '../../../../../../state/selectors/work/work-selectors.tsx';
import { isPartialIccMode } from '../../utils/index.tsx';
import type { VisibleIccProps } from '../types.tsx';

export type PartialVisibleIccProps = Pick<
	VisibleIccProps,
	| 'swimlaneId'
	| 'groupParentId'
	| 'cardTypes'
	| 'columnId'
	| 'projectId'
	| 'insertBefore'
	| 'cardIndex'
	| 'isLastSwimlane'
>;

export type UseOnCreateParams = PartialVisibleIccProps & {
	isUnscheduledColumn: boolean;
};

const emptyObj = freeze({});
const emptyCallback = (_columnId: ColumnId): number[] => [];
const emptyArray: Status[] = [];

export const useOnCreate = ({
	cardIndex,
	cardTypes,
	columnId,
	insertBefore,
	projectId: projectIdArg,
	swimlaneId,
	groupParentId,
	isLastSwimlane,
	isUnscheduledColumn,
}: UseOnCreateParams) => {
	const dispatch = useBoardDispatch();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isIPBoard = useBoardSelector(getIsIncrementPlanningBoard);

	const programBoardEstimateFieldId = useBoardSelector(getIncrementPlanningEstimateFieldId);

	const getSwimlaneValues = useBoardSelector(makeSwimlaneValuesSelector);

	const inlineCreateSessionId = useBoardSelector(getInlineCreateSessionId);

	const getInitialTransitionsForColumn = useBoardSelector(getInitialTransitionsForColumnSelector);

	const config = useBoardSelector(getInlineCardCreateConfig);

	const getParentId = useBoardSelector(getParentIdForIssueCreation);

	const assigneeAccountId = useBoardSelector(getAssigneeId);

	const getStatusIdsByColumnId = useBoardSelector(
		fg('reduce_board_icc_render_times') ? () => emptyCallback : getStatusIdsByColumnIdSelector,
	);
	const columnStatuses = useBoardSelector((state) =>
		fg('reduce_board_icc_render_times')
			? getStatusColumn(getColumns(state)[String(columnId)])?.statuses ?? emptyArray
			: emptyArray,
	);

	const scenarioColor = useBoardSelector(getScenarioColor);

	const labels = useBoardSelector(getAppliedLabelFilters);

	const lastFilteredSprintId = useBoardSelector(getLastFilteredSprintId);

	const columnStatusMapping = useBoardSelector(columnStatusMappingSelector);

	const columnIndex = useBoardSelector((state) => getColumnIndex(state, columnId));

	const getCardDndType = useBoardSelector(getCardDndTypeSelector);

	const missingParents = useBoardSelector(getMissingParents);

	const swimlaneMode = useBoardSelector(getSwimlaneMode);

	const activeSprints = useBoardSelector(activeSprintsSelector);

	const getDefaultPriority = useBoardSelector(getDefaultPriorityByProjectId);

	const onCreate = useCallback<CreateCallback>(
		// TODO-BOARD-REFACTOR-TYPES
		// @ts-expect-error inconsistent first argument
		(
			partialIssues: {
				summary: string;
				cardType: string | null | undefined;
				transition: number | undefined;
				projectId: number | undefined;
			}[],
		) => {
			const analyticsEvent = createAnalyticsEvent({
				action: 'created',
			});

			let swimlaneFields: {
				parentId?: IssueId | null;
				assigneeAccountId?: string | null;
				teamId?: string | null;
			} = emptyObj;

			const swimlaneValues = swimlaneId ? getSwimlaneValues(swimlaneId) : null;

			const parentId = getParentId(projectIdArg);

			const cardDndType = groupParentId ? SUBTASK_DND_TYPE : getCardDndType(isLastSwimlane);

			const isSubtaskMissingParent = groupParentId ? !!missingParents[groupParentId] : false;

			if (swimlaneValues && swimlaneValues.type === ASSIGNEE) {
				swimlaneFields = getIssueAssignee(swimlaneValues);
			}
			// parent is epic
			if (swimlaneValues && swimlaneValues.type === PARENT) {
				swimlaneFields = { parentId: swimlaneValues.parentId };
			}
			// story is subtask
			if (swimlaneValues && swimlaneValues.type === STORY && swimlaneValues.parentId != null) {
				swimlaneFields = { parentId: swimlaneValues.parentId };
			}

			// in IP board
			if (swimlaneValues && swimlaneValues.type === TEAM) {
				swimlaneFields = { teamId: swimlaneId };
			}

			// Create subtask in issue group. It has to override the swimlane value.
			if (groupParentId) {
				swimlaneFields = { ...swimlaneFields, parentId: groupParentId };
			}

			const { cardType } = partialIssues[0];
			const cardTypeEntity = cardTypes.find(({ id }) => id === cardType);
			const isSubtask = cardTypeEntity?.hierarchyLevelType === CHILD_HIERARCHY_TYPE;

			let transitionEntity;

			const { transition } = partialIssues[0];

			if (cardTypeEntity) {
				const issueTypeTransitions = !isIPBoard ? getInitialTransitionsForColumn(columnId) : null;

				transitionEntity = issueTypeTransitions?.[cardTypeEntity.id]?.find(
					({ id }) => id === transition,
				);
			}

			let sprintId = '';
			if (activeSprints) {
				if (activeSprints.length === 1) {
					sprintId = activeSprints[0].id.toString();
				} else if (activeSprints.length > 1) {
					sprintId = lastFilteredSprintId ?? '';
				}
			}

			if (isPartialIccMode(columnId, cardTypeEntity?.id ?? '', config)) {
				const statusIdsOld = !isIPBoard ? getStatusIdsByColumnId(columnId) : undefined;
				const statusIds = !isIPBoard ? columnStatuses.map(({ id }) => id) : undefined;

				dispatch(
					openGlobalIssueCreate({
						cardSummary: partialIssues[0].summary,
						cardTypeId: Number(cardType),
						rankBeforeCardId: insertBefore || null,
						cardParentId: swimlaneFields.parentId || parentId,
						assigneeId: swimlaneFields.assigneeAccountId || assigneeAccountId,
						isSubtask,
						sessionId: inlineCreateSessionId,
						statusId:
							transitionEntity?.destinationStatusId ||
							(fg('reduce_board_icc_render_times') ? statusIds : statusIdsOld)?.[0],
						...(sprintId ? { sprintId } : {}),
						...(swimlaneId ? { swimlaneId } : {}),
					}),
				);
				return;
			}

			const issues: Issue[] = partialIssues.map((item) => {
				const issueType = cardTypes.filter((c) => Number(c.id) === Number(item.cardType));
				// only set the default issue priority in the IP board
				const priority =
					isIPBoard && fg('issue_cards_in_program_board')
						? getDefaultPriority(`${item.projectId}`) ?? null
						: null;
				const issueParentId = (() => {
					if (
						isIPBoard &&
						issueType.length > 0 &&
						issueType[0].hierarchyLevelType !== BASE_HIERARCHY_TYPE
					) {
						return null;
					}
					return parentId;
				})();

				const cardColor = isIPBoard
					? {
							cardColor: scenarioColor,
						}
					: {};

				const hasScenarioChanges = isIPBoard
					? {
							hasScenarioChanges: true,
						}
					: {};

				const color =
					isIPBoard &&
					issueType.length > 0 &&
					issueType[0].hierarchyLevelType === PARENT_HIERARCHY_TYPE
						? {
								color: getRandomColor(),
							}
						: {};
				const projectId = (isIPBoard ? item.projectId : projectIdArg) || 0;
				const estimate: Estimate =
					isIPBoard && programBoardEstimateFieldId
						? {
								fieldId: programBoardEstimateFieldId,
								type: INCREMENT_PLANNING_ESTIMATE_FIELD,
								value: '',
							}
						: null;

				const issue: Issue = {
					id: generateId(),
					key: '',
					summary: item.summary,
					columnId,
					projectId,
					typeId: Number(item.cardType),
					statusId: 0,
					assigneeAccountId,
					typeName: cardTypeEntity?.name,
					typeUrl: cardTypeEntity?.iconUrl,
					parentId: issueParentId,
					labels,
					isDone: get(columnStatusMapping, [String(columnId), 'isDone'], false),
					estimate,
					priority,
					dueDate: null,
					numCompleteChildren: 0,
					numTotalChildren: 0,
					sprintId,
					...cardColor,
					...hasScenarioChanges,
					...swimlaneFields,
					...color,
				};
				return issue;
			});

			const issueIds = issues.map((issue) => issue.id);
			const action = issueCreateRequest({
				temporaryIssueIds: issueIds,
				issues,
				insertBefore: insertBefore || null,
				columnIndex,
				cardIndex,
				swimlaneId,
				type: cardDndType,
				isSubtaskMissingParent,
				analyticsEvent: analyticsEvent.update({
					swimlaneMode,
					swimlaneId,
					sessionId: inlineCreateSessionId,
				}),
				transitionInfo: transitionEntity
					? {
							transitionId: String(transitionEntity.id),
							statusId: transitionEntity.destinationStatusId,
						}
					: null,
				isSwimlaneByJql: swimlaneMode === SWIMLANE_BY_JQL.id,
			});

			dispatch(action);

			if (isIPBoard && isUnscheduledColumn) {
				dispatch(closeIccInUnscheduledColumn());
			}
		},
		[
			createAnalyticsEvent,
			swimlaneId,
			getSwimlaneValues,
			getParentId,
			projectIdArg,
			groupParentId,
			getCardDndType,
			isLastSwimlane,
			missingParents,
			cardTypes,
			activeSprints,
			columnId,
			config,
			insertBefore,
			columnIndex,
			cardIndex,
			swimlaneMode,
			dispatch,
			isIPBoard,
			getInitialTransitionsForColumn,
			lastFilteredSprintId,
			getStatusIdsByColumnId,
			columnStatuses,
			assigneeAccountId,
			inlineCreateSessionId,
			scenarioColor,
			programBoardEstimateFieldId,
			labels,
			columnStatusMapping,
			isUnscheduledColumn,
			getDefaultPriority,
		],
	);

	return onCreate;
};
