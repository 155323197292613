import React from 'react';
import { components, type OptionProps, type OptionType } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { Box } from '@atlaskit/primitives';

type OptionContainerProps = Partial<{
	onHover: () => void;
	testId: string;
}>;
export const OptionWithButtonStyle = <T = OptionType,>({
	children,
	onHover,
	testId,
	...optionProps
}: OptionProps<T> & OptionContainerProps) => {
	const customisedGetStyles: typeof optionProps.getStyles = (propertyName, props) => {
		return {
			...optionProps.getStyles(propertyName, props),
			boxShadow: 'none',
			cursor: 'pointer',
			padding: `${token('space.100')} ${token('space.150')}`,
		};
	};

	return (
		<Box onMouseOver={onHover} testId={testId}>
			<components.Option {...optionProps} getStyles={customisedGetStyles}>
				{children}
			</components.Option>
		</Box>
	);
};
