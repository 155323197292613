import React, { useCallback, forwardRef, memo, useState, useEffect, useMemo } from 'react';

import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem, MenuGroup, Section, type ButtonItemProps } from '@atlaskit/menu';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import {
	useForgeApps,
	useIsAppLoading,
} from '@atlassian/jira-forge-action-apps-controller/src/index.tsx';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import type { ActionExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import { isExtensionVisible } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { ForgeIcon } from '../forge-icon/index.tsx';
import { useActionMenuAnalyticsComponents } from './analytics.tsx';

const stopPropagation = (e: React.UIEvent) => e.stopPropagation();

const useExtensions = (data: ActionExtension[]) => {
	return useMemo(
		() =>
			data
				.filter(isExtensionVisible)
				.sort(({ properties: { title: titleA } }, { properties: { title: titleB } }) =>
					titleA.localeCompare(titleB),
				),
		[data],
	);
};

export const ForgeActionMenuItems: React.FC<{
	extensions: ActionExtension[];
	extensionData: Record<string, unknown>;
	testId?: string;
	analyticsSource: AnalyticsSource;
	module: ExtensionPointModule;
}> = memo(({ extensions: rawExtensions, extensionData, testId, analyticsSource, module }) => {
	const extensions = useExtensions(rawExtensions);

	const [, { closeApps }] = useForgeApps();

	// Close all Forge apps when the data is re-fetched
	useEffect(() => {
		closeApps();
	}, [extensions, closeApps]);

	return (
		<Section hasSeparator testId={testId}>
			{extensions.map((extension) => {
				const actions = extension.properties.actions ?? [];
				if (actions.length > 0) {
					return (
						<ForgeActionMenuGroupItem
							key={extension.id}
							extension={extension}
							extensionData={extensionData}
							analyticsSource={analyticsSource}
							module={module}
						/>
					);
				}
				return (
					<ForgeActionMenuItem
						key={extension.id}
						extension={extension}
						extensionData={extensionData}
						icon={<ForgeIcon url={extension.properties.icon} />}
						title={
							<ExtensionTitle
								extensionName={extension.properties.title}
								extensionEnvType={extension.environmentType}
								extensionEnvKey={extension.environmentKey}
								autoTruncate
							/>
						}
						tooltip={extension.properties.tooltip}
						analyticsSource={analyticsSource}
						module={module}
					/>
				);
			})}
		</Section>
	);
});

type ForgeActionMenuItemProps = {
	title: string | React.ReactNode;
	tooltip?: string;
	actionKey?: string;
	extension: ActionExtension;
	extensionData: Record<string, unknown>;
	icon?: React.ReactNode;
	analyticsSource: AnalyticsSource;
	module: ExtensionPointModule;
};

export const ForgeActionMenuItem = ({
	extension,
	extensionData,
	title,
	actionKey,
	tooltip,
	icon,
	analyticsSource,
	module,
}: ForgeActionMenuItemProps) => {
	const { fireUiTriggerClickedEvent, TriggerAnalyticsWrapper, ForgeScreenEvent } =
		useActionMenuAnalyticsComponents({ extension, analyticsSource, module });
	const [, { openApp }] = useForgeApps();
	const { id } = extension;

	const handleClick = useCallback(() => {
		fireUiTriggerClickedEvent();
		openApp(id, extension, actionKey, extensionData);
	}, [fireUiTriggerClickedEvent, openApp, id, extension, actionKey, extensionData]);

	const [isAppLoading] = useIsAppLoading({ appId: id, action: actionKey });

	return (
		<TriggerAnalyticsWrapper>
			<TooltipEnabledButtonItem
				iconBefore={icon}
				iconAfter={<LoadingIndicator isLoading={isAppLoading} />}
				onClick={handleClick}
				tooltip={tooltip}
			>
				{title}
				<ForgeScreenEvent />
			</TooltipEnabledButtonItem>
		</TriggerAnalyticsWrapper>
	);
};

type ForgeActionMenuGroupItemProps = {
	extension: ActionExtension;
	extensionData: Record<string, unknown>;
	analyticsSource: AnalyticsSource;
	module: ExtensionPointModule;
};

export const ForgeActionMenuGroupItem = ({
	extension,
	extensionData,
	analyticsSource,
	module,
}: ForgeActionMenuGroupItemProps) => {
	const { fireUiTriggerClickedEvent, TriggerAnalyticsWrapper, ForgeScreenEvent } =
		useActionMenuAnalyticsComponents({ extension, analyticsSource, module });
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
	const toggleMenu = useCallback(() => {
		fireUiTriggerClickedEvent();
		setIsOpen((opened) => !opened);
	}, [setIsOpen, fireUiTriggerClickedEvent]);

	const title = extension.properties?.title ?? '';
	const icon = extension.properties?.icon;
	const tooltip = extension.properties?.tooltip;

	const popupTrigger = useCallback(
		(triggerProps: TriggerProps) => (
			<TriggerAnalyticsWrapper>
				<TooltipEnabledButtonItem
					{...triggerProps}
					tooltip={tooltip}
					iconBefore={<ForgeIcon url={icon} />}
					iconAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
					onClick={toggleMenu}
				>
					<ExtensionTitle
						extensionName={title}
						extensionEnvType={extension.environmentType}
						extensionEnvKey={extension.environmentKey}
						autoTruncate
					/>
					<ForgeScreenEvent />
				</TooltipEnabledButtonItem>
			</TriggerAnalyticsWrapper>
		),
		[
			TriggerAnalyticsWrapper,
			tooltip,
			icon,
			toggleMenu,
			title,
			extension.environmentType,
			extension.environmentKey,
			ForgeScreenEvent,
		],
	);

	const popupContent = useCallback(
		() => (
			<MenuGroup maxWidth={240} onClick={stopPropagation}>
				<Section>
					{(extension.properties.actions ?? []).map((action) => (
						<ForgeActionMenuItem
							key={action.key}
							title={action.title}
							tooltip={action.tooltip}
							actionKey={action.key}
							extension={extension}
							extensionData={extensionData}
							analyticsSource={analyticsSource}
							module={module}
						/>
					))}
				</Section>
			</MenuGroup>
		),
		[extension, extensionData, analyticsSource, module],
	);

	return (
		<Popup
			isOpen={isOpen}
			onClose={handleClose}
			placement="left-start"
			trigger={popupTrigger}
			content={popupContent}
			shouldRenderToParent
		/>
	);
};

const LoadingIndicator: React.FC<{ isLoading: boolean }> = memo(({ isLoading }) => {
	return <Box xcss={indicatorStyle}>{isLoading && <Spinner size="small" />}</Box>;
});

const TooltipEnabledButtonItem = forwardRef<HTMLElement, ButtonItemProps & { tooltip?: string }>(
	({ tooltip, ...props }, ref) => {
		return tooltip ? (
			<Tooltip content={tooltip}>
				<ButtonItem {...props} ref={ref} />
			</Tooltip>
		) : (
			<ButtonItem {...props} ref={ref} />
		);
	},
);

const indicatorStyle = xcss({
	width: token('space.150'),
});
