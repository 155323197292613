import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.status-category.label',
		defaultMessage: 'Status category',
		description:
			'The label of a dropdown menu button used to choose status category to filter issues by.',
	},
	placeholder: {
		id: 'software-filters.filters.status-category.placeholder',
		defaultMessage: 'Search status category filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
