import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dependencyLinesSection: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.dependency-lines-section',
		defaultMessage: 'Dependency lines',
		description: 'Section title for group of dependency lines settings',
	},
	showOfftrackDependencyLines: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.show-offtrack-dependency-lines',
		defaultMessage: 'Show lines for all off-track dependencies',
		description: 'A setting to toggle the visibility of off-track dependency lines',
	},
	offtrackDependenciesHelpLink: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.offtrack-dependencies-help-link',
		defaultMessage: 'What are off-track dependencies?',
		description: 'A link to learn more about off-track dependencies',
	},
	showDependenciesOnIssues: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.show-dependencies-on-issues',
		defaultMessage: 'Show lines for dependencies linked to',
		description: 'A setting to toggle the visibility of dependency lines on the selected issues',
	},
	chooseIssue: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.choose-issue',
		defaultMessage: 'Choose issue...',
		description: 'Placeholder for issue select to show dependencies',
	},
	onlyShowScheduledIssueDependencyLinesTooltip: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.only-show-scheduled-issue-dependency-lines-tooltip',
		defaultMessage: 'Lines only show once an issue has been scheduled',
		description: "Tooltip for the only showing the scheduled issues' dependency lines",
	},
	chooseIssueIssueTermRefresh: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.choose-issue-issue-term-refresh',
		defaultMessage: 'Choose work item...',
		description: 'Placeholder for issue select to show dependencies',
	},
	onlyShowScheduledIssueDependencyLinesTooltipIssueTermRefresh: {
		id: 'software-view-settings.view-settings-menu.views.increment-planning-board.only-show-scheduled-issue-dependency-lines-tooltip-issue-term-refresh',
		defaultMessage: 'Lines only show once a work item has been scheduled',
		description: "Tooltip for the only showing the scheduled issues' dependency lines",
	},
});
