// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import { CustomThemeButton } from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import { xcss, Pressable, Box } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	statusCategoryToThemeMap,
	bgColor,
	bgHoverColor,
	textColor,
	textColorOld,
	textHoverColor,
	disabledColor,
} from '@atlassian/jira-common-styles/src/status.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { isTabularGridFieldsEnabled } from '../../../feature-flags.tsx';
import { Lozenge } from './lozenge/index.tsx';
import { LozengeOld } from './lozenge/main.tsx';
import type { Props as LozengeProps } from './lozenge/types.tsx';
import type { Props } from './types.tsx';

export const TEST_ID_PREFIX = 'issue.fields.status.common.ui.status-lozenge.';

const CustomChevronDownGlyph = (props: ComponentProps<'svg'>) => (
	<svg {...props} width={12} height={12} viewBox="5 5 13 13">
		<path
			d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
			fill="currentColor"
			fillRule="evenodd"
		/>
	</svg>
);

const StatusLozengeNew = ({
	status: { name, statusCategory },
	isEditable,
	hasHoverState,
	shouldShowDropdownIndication,
	isDisabled = false,
}: Props) => (
	<>
		<StatusContentWrapper data-testid={`${TEST_ID_PREFIX}${statusCategory.id}`}>
			{isEditable === true || shouldShowDropdownIndication === true ? (
				<Lozenge statusCategoryId={statusCategory.id} isDisabled={isDisabled}>
					<StyledLozengeContentWrapper>
						{name}
						<Icon glyph={CustomChevronDownGlyph} label="" />
					</StyledLozengeContentWrapper>
				</Lozenge>
			) : (
				<Lozenge statusCategoryId={statusCategory.id} isDisabled={isDisabled}>
					{name}
				</Lozenge>
			)}
		</StatusContentWrapper>
		{hasHoverState && <Box xcss={hoverOverlayStyles} />}
	</>
);

const WrappedStatusLozengeNew = (props: Props) => {
	const { ariaLabel, isEditable, isOpen, targetRef, ...others } = props;

	return (
		<Pressable
			xcss={pressableStyles}
			ref={targetRef}
			aria-label={ariaLabel}
			aria-expanded={isOpen}
			isDisabled={!isEditable}
			// When the popup is open we don't want to track the click on this button as it solely closes
			// the popup.
			// The logic here may look incorrect, but it turns out that when clicking this button the
			// `isOpen` toggle happens before the `onClick` handler that sends the press tracing.
			// We need to disable the `jira/ufo/valid-labels` lint rule
			// because for this particular interaction we only want to track
			// opening the status popup.

			interactionName={isOpen ? 'issue.fields.status.popup.open' : undefined}
		>
			<StatusLozengeNew {...others} isEditable={isEditable} />
		</Pressable>
	);
};

const EditableLozenge = ({ children }: LozengeProps) => (
	<LozengeOld>
		<StyledLozengeContentWrapper>
			{children}
			<Icon glyph={CustomChevronDownGlyph} label="" />
		</StyledLozengeContentWrapper>
	</LozengeOld>
);

const StatusLozengeOld = ({
	status: { name, statusCategory },
	isEditable,
	hasHoverState,
	targetRef,
	shouldShowDropdownIndication,
	isDisabled = false,
}: Props) => (
	<StyledLozenge
		hasHoverState={hasHoverState}
		statusCategoryId={statusCategory.id}
		isDisabled={isDisabled}
	>
		<StatusContentWrapper ref={targetRef} data-testid={`${TEST_ID_PREFIX}${statusCategory.id}`}>
			{isEditable === true || shouldShowDropdownIndication === true ? (
				<EditableLozenge>{name}</EditableLozenge>
			) : (
				<LozengeOld>{name}</LozengeOld>
			)}
		</StatusContentWrapper>
	</StyledLozenge>
);

const WrappedStatusLozengeOld = (props: Props) => {
	const { ariaLabel, error, isEditable, isOpen, targetRef, ...other } = props;

	return (
		<CustomThemeButton
			ref={targetRef}
			appearance="subtle"
			aria-label={ariaLabel}
			aria-expanded={isOpen}
			isDisabled={!isEditable}
			theme={(currentTheme, themeProps) => {
				const { buttonStyles, spinnerStyles } = currentTheme(themeProps);

				return {
					buttonStyles: {
						...buttonStyles,
						backgroundColor: 'transparent',
						margin: 0,
						width: 'auto',
						height: 'auto',
						padding: 0,
						border: `2px solid ${
							error ? token('color.border.danger', colors.R300) : 'transparent'
						}`,
						borderRadius: '5px',
						...(isEditable === true ? undefined : { cursor: 'auto' }),
						outline: 0,
						boxShadow: `0 0 0 2px ${
							isOpen === true ? token('color.border.focused', colors.B200) : 'transparent'
						}`,
						'& > span': { margin: 0 },
						'&:focus, &:focus-within': {
							boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
						},
					},
					spinnerStyles,
				};
			}}
			// When the popup is open we don't want to track the click on this button as it solely closes
			// the popup.
			// The logic here may look incorrect, but it turns out that when clicking this button the
			// `isOpen` toggle happens before the `onClick` handler that sends the press tracing.
			// We need to disable the `jira/ufo/valid-labels` lint rule
			// because for this particular interaction we only want to track
			// opening the status popup.
			// eslint-disable-next-line jira/ufo/valid-labels
			interactionName={isOpen ? 'issue.fields.status.popup.open' : undefined}
		>
			<StatusLozengeOld {...other} isEditable={isEditable} />
		</CustomThemeButton>
	);
};

export const StatusLozenge = componentWithCondition(
	isVisualRefreshEnabled,
	StatusLozengeNew,
	StatusLozengeOld,
);

export const WrappedStatusLozenge = componentWithCondition(
	isVisualRefreshEnabled,
	WrappedStatusLozengeNew,
	WrappedStatusLozengeOld,
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLozenge = styled.span<{
	isDisabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	statusCategoryId: any;
	hasHoverState?: boolean;
}>`
	display: flex;
	align-items: center;

	> div {
		display: flex;
		line-height: initial;
		outline: 0;

		> span {
			padding: ${token('space.025', '2px')} 0;
			vertical-align: text-bottom;
			color: ${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) =>
					// eslint-disable-next-line no-nested-ternary
					props.isDisabled
						? disabledColor.text
						: !isVisualRefreshEnabled()
							? textColor[statusCategoryToThemeMap[props.statusCategoryId]]
							: textColorOld[statusCategoryToThemeMap[props.statusCategoryId]]
			};
			background-color: ${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) =>
					props.isDisabled
						? disabledColor.bg
						: bgColor[statusCategoryToThemeMap[props.statusCategoryId]]
			};
			${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) =>
					props.hasHoverState
						? `&:hover {
                        color: ${
													props.isDisabled
														? disabledColor.text
														: textHoverColor[statusCategoryToThemeMap[props.statusCategoryId]]
												};
                        background-color: ${
													props.isDisabled
														? disabledColor.bg
														: bgHoverColor[statusCategoryToThemeMap[props.statusCategoryId]]
												};
                    }`
						: ''
			};
			${
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(props: any) =>
					props.isDisabled &&
					css({
						pointerEvents: 'none',
					})
			}
		}
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledLozengeContentWrapper = styled2.div(
	{
		alignItems: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isTabularGridFieldsEnabled()
			? {
					display: 'contents',
				}
			: {
					display: 'flex',
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusContentWrapper = styled2.div(
	{
		display: 'flex',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isTabularGridFieldsEnabled()
			? {
					width: '100%',
				}
			: {
					width: 'auto',
				},
);

const pressableStyles = xcss({
	border: 'none',
	padding: 'space.0',
	borderRadius: '3px',
	background: 'transparent',
	position: 'relative',
	maxWidth: '100%',
	marginLeft: 'space.025',
});

const hoverOverlayStyles = xcss({
	position: 'absolute',
	inset: '0',
	borderRadius: '3px',
	marginRight: 'space.025',
	':hover': {
		opacity: 0.3,
		backgroundColor: 'color.background.inverse.subtle',
	},
});
