import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.custom.label',
		defaultMessage: 'Custom filters',
		description: 'The label of a dropdown menu button used to choose custom filters to apply.',
	},
	placeholder: {
		id: 'software-filters.filters.custom.placeholder',
		defaultMessage: 'Search custom filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
	labelCmp: {
		id: 'software-filters.filters.custom.label-cmp',
		defaultMessage: 'Quick filters',
		description: 'The label of a dropdown menu button used to choose custom filters to apply.',
	},
	placeholderCmp: {
		id: 'software-filters.filters.custom.placeholder-cmp',
		defaultMessage: 'Search quick filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
	manageLinkTitle: {
		id: 'software-filters.filters.custom.manage-link-title',
		defaultMessage: 'Manage custom filters',
		description: 'Dropdown item which opens up Manage custom filters page',
	},
	manageLinkTitleCmp: {
		id: 'software-filters.filters.custom.manage-link-title-cmp',
		defaultMessage: 'Manage quick filters',
		description: 'Dropdown item which opens up board settings quick filters page',
	},
	maxSelectionReachedTooltip: {
		id: 'software-filters.filters.custom.max-selection-reached-tooltip',
		defaultMessage: "You can't select more than {maxSelectionAllowed} custom filters",
		description:
			'Tooltip shown on disabled unselected checklist item, when maximum allowed filters are selected',
	},
	toggleLabelCmp: {
		id: 'software-filters.filters.custom.toggle-label-cmp',
		defaultMessage: 'Show quick filters',
		description: 'The label of toggle to enable and disable the filter selection bar',
	},
});
