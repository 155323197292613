import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Observable } from 'rxjs/Observable';
import { empty as observableEmpty } from 'rxjs/observable/empty';
import { from as observableFrom } from 'rxjs/observable/from';
import { fromPromise as observableFromPromise } from 'rxjs/observable/fromPromise';
import { merge as observableMerge } from 'rxjs/observable/merge';
import { of as observableOf } from 'rxjs/observable/of';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	jswTransformCriticalDataStartMark,
	jswTransformCriticalDataEndMark,
} from '@atlassian/jira-providers-spa-apdex-analytics/src/marks.tsx';
import type { MobileRestApiResponse } from '@atlassian/jira-software-board-uif-types/src/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { EDIT_BOARD_CONFIG } from '../../model/board/constants.tsx';
import { SWIMLANE_BY_JQL } from '../../model/swimlane/swimlane-modes.tsx';
import { transformMobileResponse } from '../../services/board-scope-graphql/non-critical/mobile-rest/transformer/index.tsx';
import { transformCriticalData as transformBoardScopeCriticalData } from '../../services/board-scope-graphql/transformer/index.tsx';
import { getSwimlaneModeSetting } from '../../services/software/software-storage.tsx';
import { boardDeferredDataLoad } from '../../state/actions/board/board-deferred-data/index.tsx';
import { devStatusLoad } from '../../state/actions/issue/dev-status/index.tsx';
import {
	SOFTWARE_APP_INITIAL_STATE_LOADED,
	SOFTWARE_APP_LOADED,
	type SoftwareAppLoadedAction,
} from '../../state/actions/software/index.tsx';
import {
	loadBoardFailure,
	loadBoardFailureNoColumn,
	workDataCriticalSet,
	workDataSet,
} from '../../state/actions/work/index.tsx';
import { getActiveCustomFilterIds } from '../../state/selectors/filter/custom-filter-selectors.tsx';
import { boardOrderedIssueIdsSelector } from '../../state/selectors/issue/board-issue-selectors.tsx';
import { rapidViewIdSelector } from '../../state/selectors/software/software-selectors.tsx';
import { isBoardConfigLoaded } from '../../state/selectors/work/work-selectors.tsx';
import type { Action, State, ActionsObservable, MiddlewareAPI } from '../../state/types.tsx';
import { handleCMPBoardDataSet } from '../work/handle-work-data-set.tsx';
// This epic will trigger when server side rendering provides a initial state
const onReadyWithInitializeStateEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(SOFTWARE_APP_INITIAL_STATE_LOADED)
		.map(() => devStatusLoad(boardOrderedIssueIdsSelector(store.getState())));

export const handleCmpBoardData = (
	cmpBoardData: Promise<UIFBoardCachedDataResult | null>,
	store: MiddlewareAPI,
): Observable<Action> => {
	const setDataFromCmpBoard = (response: MobileRestApiResponse) => {
		const action = workDataSet(transformMobileResponse(response));

		const state = store.getState();
		const customFilterIds = getActiveCustomFilterIds(state);
		const isCustomFiltersOn = customFilterIds.length > 0;
		const rapidViewId = rapidViewIdSelector(state);
		const swimlaneModeIdLocalStorage = getSwimlaneModeSetting(rapidViewId.toString());

		if (!swimlaneModeIdLocalStorage) {
			return handleCMPBoardDataSet(action, isCustomFiltersOn);
		}
		// For JQL swimlanes in CMP boards, use admin/backend settings as only admins can configure them
		if (swimlaneModeIdLocalStorage === SWIMLANE_BY_JQL.id) {
			return handleCMPBoardDataSet(action, isCustomFiltersOn);
		}
		return handleCMPBoardDataSet(
			{
				...action,
				payload: {
					...action.payload,
					swimlaneModeId: swimlaneModeIdLocalStorage,
				},
			},
			isCustomFiltersOn,
		);
	};

	return observableFromPromise(cmpBoardData).flatMap(
		(cachedDataResult: UIFBoardCachedDataResult | null) => {
			if (!cachedDataResult) {
				return observableEmpty();
			}

			const { result, refresh } = cachedDataResult;
			const refresh$: Observable<MobileRestApiResponse> = refresh
				? observableFromPromise(refresh)
				: observableEmpty<MobileRestApiResponse>();
			const refreshActions$ = refresh$.flatMap(setDataFromCmpBoard);

			if (isBoardConfigLoaded(store.getState()) && refresh) {
				return observableMerge(Observable.of(boardDeferredDataLoad()), refreshActions$);
			}

			return observableMerge(
				setDataFromCmpBoard(result),
				refreshActions$,
				Observable.of(boardDeferredDataLoad()),
			);
		},
	);
};

const onBoardScopeLoadEpic = (action$: ActionsObservable, store: MiddlewareAPI) =>
	action$
		.ofType(SOFTWARE_APP_LOADED)
		.switchMap((action: SoftwareAppLoadedAction): Observable<Action> => {
			if (action.payload.cmpBoardData) {
				return handleCmpBoardData(action.payload.cmpBoardData, store);
			}

			const boardScope = action.payload.prefetchData;

			// No boardScope is an error for TMP boards (means the prefetch has
			// failed). For CMP and Incremental Planning we will just no-op.
			if (!boardScope) {
				const { isCMPBoard, isIncrementPlanningBoard } = store.getState().configuration;
				return isCMPBoard || isIncrementPlanningBoard
					? observableFrom([])
					: observableOf(loadBoardFailure());
			}

			if (boardScope && isEmpty(boardScope.board.columns)) {
				log.safeErrorWithoutCustomerData(
					'board.transform.critical.data',
					'Board does not have any columns',
					{
						message: 'Columns does not exist for Board',
					},
				);

				// data wont be in state, need to retrieve from payload.
				const canConfigureBoard = boardScope.currentUser.permissions.includes(EDIT_BOARD_CONFIG);
				return observableOf(loadBoardFailureNoColumn(true, canConfigureBoard));
			}
			// Used to gather data to see impact of transformer on performance of large boards.
			// Will be removed once enough data is gathered.
			setMark(jswTransformCriticalDataStartMark);
			const criticalData = transformBoardScopeCriticalData(boardScope);
			setMark(jswTransformCriticalDataEndMark);
			const actions: Action[] = [workDataCriticalSet(criticalData)];

			// Async actions to kick off after the first load only
			const issues = get(criticalData, ['issues'], null);
			if (issues) {
				const issueIds = issues.map((issue) => issue.id);
				actions.push(devStatusLoad(issueIds));
			}

			return observableFrom(actions);
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'board.scope.on.ready.failure',
				'Failure on board scope on ready',
				error,
			);
			return observableOf(loadBoardFailure());
		});

export default combineEpics<Action, State>(onReadyWithInitializeStateEpic, onBoardScopeLoadEpic);
