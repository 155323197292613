/** @jsx jsx */
import { useCallback, type RefObject, type SyntheticEvent, type ReactNode } from 'react';
import { cssMap, jsx } from '@compiled/react';
import TeamsIcon from '@atlaskit/icon/core/teams';
import PeopleIconOld from '@atlaskit/icon/glyph/people';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { token } from '@atlaskit/tokens';
import Avatar from '@atlaskit/avatar';
import { Box, xcss, Grid, Pressable } from '@atlaskit/primitives';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	swimlaneHeaderHeight,
	unscheduledColumnHeaderHeight,
	zIndex,
} from '@atlassian/jira-platform-board-kit/src/common/constants/styles/index.tsx';
import type { SwimlaneByTeamValues } from '../../common/types.tsx';
import { TeamLozenge } from '../team-lozenge/index.tsx';

// These styles are unbounded as they use custom values not tokenised in the design system
const unboundedStyles = cssMap({
	SwimlaneHeader: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		zIndex: zIndex.stickySwimlaneBar,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: `${swimlaneHeaderHeight}px`,
		paddingTop: token('space.025'),
		marginTop: token('space.negative.025'),
		marginBottom: token('space.025'),
		backgroundColor: token('elevation.surface'),
		borderRadius: '3px',
		cursor: 'pointer',
		position: 'sticky',
	},
	SwimlaneHeaderUnscheduled: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		top: `${unscheduledColumnHeaderHeight}px`,
		minWidth: '100%',
		maxWidth: '100%',
	},
	SwimlaneHeaderScheduled: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		top: `${swimlaneHeaderHeight}px`,
		minWidth: '700px',
		maxWidth: `min(100%, calc(var(--board-scroll-element-width, 2000) * 1px - ${token('space.500', '40px')})))`,
	},
	SwimlaneNameText: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		font: token('font.body'),
	},
});

type IncrementPlanningSwimlaneHeaderContentProps = {
	id: string;
	name: string;
	collapsed: boolean;
	onToggle: (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	values?: SwimlaneByTeamValues;
	iconImageUrl?: string | null;
	innerRef?: RefObject<HTMLDivElement>;
	customLeftContent: ReactNode;
	customRightContent: ReactNode;
	top: number;
	isUnscheduledWorkColumnPanel: boolean;
	children: ReactNode;
};

export const IncrementPlanningSwimlaneHeaderContent = ({
	id,
	name,
	collapsed,
	onToggle,
	values,
	iconImageUrl,
	innerRef,
	customLeftContent,
	customRightContent,
	top,
	isUnscheduledWorkColumnPanel,
	children,
}: IncrementPlanningSwimlaneHeaderContentProps) => {
	const getTeamIcon = () =>
		iconImageUrl ? (
			<Avatar size="small" src={iconImageUrl} />
		) : (
			<Box xcss={TeamIconWrapperStyles}>
				<TeamsIcon
					label=""
					LEGACY_size="small"
					LEGACY_fallbackIcon={PeopleIconOld}
					color={token('color.icon')}
				/>
			</Box>
		);

	const renderChevronIcon = () => {
		if (collapsed) {
			return (
				<Box xcss={isVisualRefreshEnabled() && IconSpacing}>
					<ChevronRightIcon
						LEGACY_fallbackIcon={ChevronRightIconOld}
						LEGACY_primaryColor={token('color.icon', '#44546F')}
						color={token('color.icon')}
						label=""
					/>
				</Box>
			);
		}

		return (
			<Box xcss={isVisualRefreshEnabled() && IconSpacing}>
				<ChevronDownIcon
					LEGACY_fallbackIcon={ChevronDownIconOld}
					LEGACY_primaryColor={token('color.icon', '#44546F')}
					color={token('color.icon')}
					label=""
				/>
			</Box>
		);
	};

	const toggleColumns = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			onToggle(event, analyticsEvent);
		},
		[onToggle],
	);

	return (
		<Box
			xcss={SwimlaneWrapperStyles}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ top: `${top}px` }}
			ref={innerRef}
			testId="portfolio-3-plan-increment-common.ui.swimlane-header-content.swimlane-wrapper"
		>
			<div
				css={[
					unboundedStyles.SwimlaneHeader,
					isUnscheduledWorkColumnPanel && unboundedStyles.SwimlaneHeaderUnscheduled,
					!isUnscheduledWorkColumnPanel && unboundedStyles.SwimlaneHeaderScheduled,
				]}
			>
				<Grid xcss={SwimlaneGridStyles}>
					<Box xcss={SwimlaneHeaderChevronTeamIconStyles}>
						{renderChevronIcon()} {getTeamIcon()}
					</Box>
					<Pressable
						xcss={SwimlaneHeaderToggleButtonStyles}
						onClick={toggleColumns}
						aria-expanded={!collapsed}
						// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/no-imported-style-values
						style={{ height: `${swimlaneHeaderHeight}px` }}
					>
						<Box
							testId="portfolio-3-plan-increment-common.ui.swimlane-header-content.summary-section"
							xcss={IncrementPlanningBoardSummaryWrapperStyle}
						>
							<h1 // Use h1 here instead of Text primitive to support truncation on unscheduled work column
								// Due to accessibility ordering of headings we have to use a styled h1
								css={[unboundedStyles.SwimlaneNameText]}
							>
								{name}
							</h1>
						</Box>
					</Pressable>
					<Box xcss={LeftContentStyles}>
						<TeamLozenge id={id} values={values} />
						{customLeftContent}
					</Box>
					<Box xcss={RightContentStyles}>{customRightContent}</Box>
				</Grid>
			</div>
			{!collapsed && children}
		</Box>
	);
};

const SwimlaneGridStyles = xcss({
	position: 'sticky',
	display: 'grid',
	gridTemplateColumns: 'minmax(0, auto) minmax(0, auto) 1fr',
	gridTemplateRows: '1fr',
	alignItems: 'center',
	width: '100%',
});

const SwimlaneHeaderToggleButtonStyles = xcss({
	gridTemplateColumns: 'subgrid',
	display: 'grid',
	alignItems: 'center',
	gridColumn: '1/-1',
	gridRow: '1',
	position: 'relative',
	appearance: 'none',
	border: 'none',
	background: 'none',
	outline: 'none',
	gap: 'space.100',
	':focus': {
		// @ts-expect-error We need a negative outline offset on the outline of the swimlane header when it's focused.
		outlineOffset: token('space.negative.025'),
	},
});

const SwimlaneHeaderChevronTeamIconStyles = xcss({
	gridColumn: '1',
	gridRow: '1',
	display: 'flex',
	paddingLeft: 'space.050',
	gap: 'space.050',
	alignItems: 'center',
});

const IncrementPlanningBoardSummaryWrapperStyle = xcss({
	gridColumn: '2',
	minWidth: '0',
	display: 'block',
});

const IconSpacing = xcss({ margin: 'space.050' });

const TeamIconWrapperStyles = xcss({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '24px',
	width: '24px',
	margin: 'space.025',
	borderRadius: '50%',
	backgroundColor: 'color.background.neutral',
});

const SwimlaneWrapperStyles = xcss({
	position: 'absolute',
	width: '100%',
});

const LeftContentStyles = xcss({
	gridColumn: '3',
	gridRow: '1',
	display: 'flex',
	alignItems: 'center',
	paddingLeft: 'space.150',
	gap: 'space.150',
});

const RightContentStyles = xcss({
	gridColumn: '4',
	gridRow: '1',
});
