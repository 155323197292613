import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerAriaLabel: {
		id: 'software.main.board-container.board.columns-container.column.footer.trigger-aria-label',
		defaultMessage: 'Create issue in {columnName}',
		description: 'Aria label used for the footer trigger element',
	},
	triggerAriaLabelIssueTermRefresh: {
		id: 'software.main.board-container.board.columns-container.column.footer.trigger-aria-label-issue-term-refresh',
		defaultMessage: 'Create in {columnName}',
		description: 'Aria label used for the footer trigger element',
	},
});
