import floor from 'lodash/floor';
import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import type {
	AnalyticsIssue,
	AnalyticsCardType,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import { SPRINT_STATES } from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';
import type {
	IssueId,
	IssueLink,
	IssueLinkType,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import { SECONDS_PER_HOUR } from '@atlassian/jira-time-tracking-formatter/src/constants.tsx';
import {
	IP_BOARD_DAYS_PLANNING_UNIT,
	IP_BOARD_HOURS_PLANNING_UNIT,
	SINGLE_CUSTOM_FIELD_TYPE_KEY,
	MULTI_CUSTOM_FIELD_TYPE_KEY,
	SYSTEM_LABEL_FIELD_ID,
	BOARD_SCOPE_FILTER_NONE,
	BOARD_SCOPE_FILTER_LABEL,
	BOARD_SCOPE_FILTER_SINGLE_SELECT,
	BOARD_SCOPE_FILTER_MULTI_SELECT,
	UNSCHEDULED_WORK_COLUMN_SWIMLANE_ID_SUFFIX,
} from './constants.tsx';
import messages from './messages.tsx';
import type { PlanningUnit, BoardScopeFilterType, BoardScopeFilter } from './types.tsx';

export const getBoardScopeFilterType = (
	boardScopeFilter: BoardScopeFilter | undefined,
): BoardScopeFilterType => {
	if (boardScopeFilter === undefined) {
		return BOARD_SCOPE_FILTER_NONE;
	}
	if (boardScopeFilter.filterFieldId === SYSTEM_LABEL_FIELD_ID) {
		return BOARD_SCOPE_FILTER_LABEL;
	}
	if (boardScopeFilter.filterFieldTypeKey === SINGLE_CUSTOM_FIELD_TYPE_KEY) {
		return BOARD_SCOPE_FILTER_SINGLE_SELECT;
	}
	if (boardScopeFilter.filterFieldTypeKey === MULTI_CUSTOM_FIELD_TYPE_KEY) {
		return BOARD_SCOPE_FILTER_MULTI_SELECT;
	}
	return BOARD_SCOPE_FILTER_NONE;
};
const PLAN_PARAMS = {
	PLAN: 'plans',
	SCENARIO: 'scenarios',
	BOARD: 'program',
	ROADMAP: 'backlog',
};

const parsePathParams = (path: string, param: string): string => {
	const regex = new RegExp(`/${param}/(\\w+)`, 'g');
	const matches = Array.from(path.matchAll(regex), (match) => match[1]);

	return matches[0];
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getPathParams = (param: string): string => parsePathParams(window.location.pathname, param);

export const getPlanId = (): number | null | undefined =>
	+getPathParams(PLAN_PARAMS.PLAN) || undefined;

export const getScenarioId = (): number | null | undefined =>
	+getPathParams(PLAN_PARAMS.SCENARIO) || undefined;

export const urls = {
	managePortfolio: '/jira/plans/',
};

export const getBoardUrl = (boardId: number, isEmbed = false) =>
	`/jira/${PLAN_PARAMS.PLAN}/${getPlanId()}/${PLAN_PARAMS.SCENARIO}/${getScenarioId()}/${PLAN_PARAMS.BOARD}/${boardId}${isEmbed && fg('smart_links_for_plans') ? '/embed' : ''}`;

export const getManageBoardSettingsUrl = (
	planId: string | undefined,
	scenarioId: string | undefined,
) => `/jira/plans/${planId}/scenarios/${scenarioId}/settings/program-boards`;

export const convertCapacity = (
	capacity: number,
	planningUnit: PlanningUnit,
	workingHoursPerDay: number,
): number => {
	if (planningUnit === IP_BOARD_DAYS_PLANNING_UNIT) {
		return floor(capacity / workingHoursPerDay / SECONDS_PER_HOUR, 2);
	}
	if (planningUnit === IP_BOARD_HOURS_PLANNING_UNIT) {
		return floor(capacity / SECONDS_PER_HOUR, 2);
	}
	return capacity;
};

export const getCapacityUnitMessage = (
	planningUnit: PlanningUnit,
	formatMessage: (message: MessageDescriptor, value?: Record<string, string | number>) => string,
	originalCapacity?: number,
) => {
	// if the originalCapacity is undefined, assign the capacity to 2 to get the plural unit
	const capacity = isNil(originalCapacity) ? 2 : originalCapacity;
	switch (planningUnit) {
		case IP_BOARD_DAYS_PLANNING_UNIT: {
			return formatMessage(messages.dayUnit, { days: capacity });
		}
		case IP_BOARD_HOURS_PLANNING_UNIT: {
			return formatMessage(messages.hourUnit, { hours: capacity });
		}
		default: {
			return formatMessage(messages.storyPointUnit, { points: capacity });
		}
	}
};

export const getSprintStateLabel = (
	sprintState: string,
	formatMessage: (messageDescriptor: MessageDescriptor) => string,
) => {
	switch (sprintState) {
		case SPRINT_STATES.ACTIVE:
			return formatMessage(messages.activeSprint);
		case SPRINT_STATES.CLOSED:
			return formatMessage(messages.closedSprint);
		case SPRINT_STATES.FUTURE:
		default:
			return formatMessage(messages.futureSprint);
	}
};

export const convertCapacityFromPlanningUnitToSeconds = ({
	capacity,
	planningUnit,
	workingHours,
}: {
	capacity: number;
	planningUnit: string;
	workingHours: number;
}): number => {
	switch (planningUnit) {
		case IP_BOARD_DAYS_PLANNING_UNIT:
			return capacity * SECONDS_PER_HOUR * workingHours;
		case IP_BOARD_HOURS_PLANNING_UNIT:
			return capacity * SECONDS_PER_HOUR;
		default:
			return capacity;
	}
};

export const isScenarioPlanonlyTeamCheck = (teamId: string) => Number.isNaN(parseInt(teamId, 10));

export const getIssueLinksAnalytics = (
	issueLinks?: IssueLink[] | null,
	id?: IssueId,
	issueLinkTypes?: IssueLinkType[],
	issuesWithLinksById?: { [id: string]: AnalyticsIssue },
	issueTypes?: Record<string, AnalyticsCardType>,
) => {
	if (!issueLinks || !id || !issueLinkTypes || !issuesWithLinksById || !issueTypes) {
		return {};
	}

	const attributes = issueLinks.reduce(
		(acc, link) => {
			const linkType = issueLinkTypes.find((l) => l.id === link.linkTypeId);
			const isSource = link.sourceId === id;
			const linkedIssueId = isSource ? link.destinationId : link.sourceId;
			const issueTypeId = issuesWithLinksById[linkedIssueId]?.typeId;
			const isBaseLevel = issueTypes[issueTypeId]?.hierarchyLevelType === 'BASE';

			if (isSource) {
				if (linkType?.isOutward) {
					isBaseLevel ? acc.BlockingStoriesCount++ : acc.BlockingEpicsCount++;
				} else {
					isBaseLevel ? acc.BlockedByStoriesCount++ : acc.BlockedByEpicsCount++;
				}
			} else if (linkType?.isOutward) {
				isBaseLevel ? acc.BlockedByStoriesCount++ : acc.BlockedByEpicsCount++;
			} else {
				isBaseLevel ? acc.BlockingStoriesCount++ : acc.BlockingEpicsCount++;
			}

			return acc;
		},
		{
			BlockedByStoriesCount: 0,
			BlockedByEpicsCount: 0,
			BlockingStoriesCount: 0,
			BlockingEpicsCount: 0,
		},
	);

	return attributes;
};

export const truncateUnscheduledWorkSwimlaneId = (
	swimlaneId: string,
	isUnscheduledColumn?: boolean,
) =>
	isUnscheduledColumn && swimlaneId.endsWith(UNSCHEDULED_WORK_COLUMN_SWIMLANE_ID_SUFFIX)
		? swimlaneId.slice(0, -UNSCHEDULED_WORK_COLUMN_SWIMLANE_ID_SUFFIX.length)
		: swimlaneId;

export const appendUnscheduledWorkSwimlaneIdSuffix = (swimlaneId: string) =>
	`${swimlaneId}${UNSCHEDULED_WORK_COLUMN_SWIMLANE_ID_SUFFIX}`;
