import { useMemo } from 'react';
import type { OptionType } from '@atlaskit/select';
import type { GroupBase, OptionsOrGroups } from '@atlaskit/react-select';
import { STICKY_FOOTER_GROUP_TYPE } from './constant.tsx';

export type FooterOption = {
	label: string;
	type: string;
};

export const useAppendStickyFooterOptions = (
	options: OptionsOrGroups<OptionType, GroupBase<OptionType>>,
	footerOptions: FooterOption[],
): OptionsOrGroups<OptionType, GroupBase<OptionType>> => {
	return useMemo(
		() => [
			...options,
			{
				options: footerOptions.map((footerOption) => {
					return {
						...footerOption,
						value: footerOption.type,
						groupType: STICKY_FOOTER_GROUP_TYPE,
					};
				}),
			},
		],
		[footerOptions, options],
	);
};

export function scrollIntoView(
	menuEl: HTMLElement,
	focusedEl: HTMLElement,
	bottomOffset = 0,
): void {
	const menuRect = menuEl.getBoundingClientRect();
	const focusedRect = focusedEl.getBoundingClientRect();
	const overScroll = focusedEl.offsetHeight / 3;

	if (focusedRect.bottom + overScroll > menuRect.bottom - bottomOffset) {
		// eslint-disable-next-line no-param-reassign
		menuEl.scrollTop = Math.min(
			focusedEl.offsetTop +
				focusedEl.clientHeight -
				menuEl.offsetHeight +
				bottomOffset +
				overScroll,
			menuEl.scrollHeight - bottomOffset,
		);
	} else if (focusedRect.top - overScroll < menuRect.top) {
		// eslint-disable-next-line no-param-reassign
		menuEl.scrollTop = Math.max(focusedEl.offsetTop - overScroll, 0);
	}
}
