import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { zIndex, layout } from '../../../common/constants/styles/index.tsx';
import { isStickySupported } from '../../../common/utils/is-sticky-supported/index.tsx';
import {
	useBoardHasScrollLeft,
	useBoardScrollIsAtRightEdge,
} from '../../../controllers/board-scroll/index.tsx';

const shadowWidth = 7;

type BoardBorderLeftStyleProps = {
	offsetLeftPaddingByWidth?: boolean;
	isVisualRefresh?: boolean;
};

type BoardBorderRightStyleProps = {
	offsetRightPaddingByWidth?: boolean;
};

export type LeftBorderProps = {
	offsetLeftPaddingByWidth?: boolean;
};

export const LeftBorder = ({ offsetLeftPaddingByWidth }: LeftBorderProps) => {
	const [hasScrollLeft] = useBoardHasScrollLeft();

	return (
		<BoardBorderLeftStyle
			isShadowVisible={hasScrollLeft}
			offsetLeftPaddingByWidth={offsetLeftPaddingByWidth}
			isVisualRefresh={isVisualRefreshEnabled()}
			isThemingEnabled={expVal('jira_theming_milestone_1', 'isEnabled', false)}
		/>
	);
};

export type RightBorderProps = {
	offsetRightPaddingByWidth?: boolean;
};

export const RightBorder = ({ offsetRightPaddingByWidth }: RightBorderProps) => {
	const [isAtRightScrollEdge] = useBoardScrollIsAtRightEdge();

	return (
		<BoardBorderRightStyle
			isShadowVisible={!isAtRightScrollEdge}
			offsetRightPaddingByWidth={offsetRightPaddingByWidth}
			isVisualRefresh={isVisualRefreshEnabled()}
			isThemingEnabled={expVal('jira_theming_milestone_1', 'isEnabled', false)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderStyle = styled.div<{
	isShadowVisible: boolean;
	isVisualRefresh: boolean;
	isThemingEnabled: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		display: !isStickySupported ? 'none' : '',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		width: ({ isVisualRefresh }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			isVisualRefresh ? token('space.300') : `${layout.boardBorderWidth}px`,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		opacity: ({ isShadowVisible }) => (isShadowVisible ? 1 : 0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		pointerEvents: ({ isShadowVisible }) => (isShadowVisible ? 'auto' : 'none'),
		transition: 'opacity 0.2s',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		zIndex: zIndex.boardBorders,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	(props) =>
		!props.isThemingEnabled && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
			backgroundColor: token('elevation.surface', colors.N0),
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderLeftStyle = styled(BoardBorderStyle)<BoardBorderLeftStyleProps>({
	left: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: (props: BoardBorderLeftStyleProps) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		props.isVisualRefresh ? token('space.negative.300') : `${-layout.boardBorderWidth}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	boxShadow: `0 ${layout.boardBorderWidth * 2}px 0 ${token('elevation.shadow.overflow.spread', '#091e4229')}, 5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}`,
	clipPath: 'inset(0px -12px 0px 0)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props: BoardBorderLeftStyleProps) =>
		props.offsetLeftPaddingByWidth ? `${shadowWidth}px;` : '',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoardBorderRightStyle = styled(BoardBorderStyle)<BoardBorderRightStyleProps>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		boxShadow: `0 ${layout.boardBorderWidth * 2}px 0 ${token('elevation.shadow.overflow.spread', '#091e4229')}, -5px -2px ${shadowWidth}px ${token('elevation.shadow.overflow.perimeter', '#091e421f')}`,
		clipPath: 'inset(0px 0px 0px -12px)',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: BoardBorderRightStyleProps) =>
		props.offsetRightPaddingByWidth
			? {
					width: `${shadowWidth}px`,
					marginLeft: `${-shadowWidth}px`,
					right: `${-shadowWidth}px`,
				}
			: {
					right: 0,
				},
);
