import { ActionsObservable as ReduxActionsObservable } from 'redux-observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import type ApolloClient from 'apollo-client';
import { Observable } from 'rxjs/Observable';
import { v4 as uuid } from 'uuid';
import { getNormalisedPerformanceFFs } from '@atlassian/jira-common-long-task-metrics/src/common/util/collectors.tsx';
import { assignToMe } from '@atlassian/jira-software-keyboard-shortcuts/src/controllers/assign-to-me/main.tsx';
import { scheduleAfterRender } from '@atlassian/jira-software-react-scheduler/src/index.tsx';
import { assignToMeInteraction } from '../../services/utils/performance-analytics/index.tsx';
import {
	ASSIGN_TO_ME_REQUEST,
	ASSIGN_TO_ME_REQUEST_THROTTLED,
	assignToMeFailure,
	type AssignToMeFailureAction,
	assignToMeRequest,
	type AssignToMeRequestAction,
	type AssignToMeRequestThrottledAction,
	assignToMeSucess,
	type AssignToMeSucessAction,
} from '../../state/actions/issue/assign-to-me/index.tsx';
import type {
	Action,
	ActionsObservable,
	BoardDependencies,
	MiddlewareAPI,
} from '../../state/types.tsx';

export interface AssignToMeHooks {
	onStart?: (action: AssignToMeRequestAction) => void;
	onSuccess?: (action: AssignToMeRequestAction) => void;
	onFailure?: (action: AssignToMeRequestAction) => void;
}

export const defaultHooks: AssignToMeHooks = {
	onStart: (action) => {
		const { concurrentId, assigneeAccountId, fireShortcutAnalyticsFn } = action.payload;
		scheduleAfterRender(() => {
			assignToMeInteraction(String(concurrentId)).mark('feedback');
		});
		if (fireShortcutAnalyticsFn) {
			fireShortcutAnalyticsFn({
				id: 'assignToMeKeyboardShortcut',
				key: 'i',
				attributes: {
					assignStatus: assigneeAccountId !== null ? 'assign' : 'unassign',
				},
			});
		}
	},
	onSuccess: (action) => {
		const { concurrentId } = action.payload;
		scheduleAfterRender(() => {
			assignToMeInteraction(String(concurrentId)).stop({
				customData: getNormalisedPerformanceFFs(),
			});
		});
	},
};

export const handleAssignToMeRequest = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	swagClient: ApolloClient<any>,
	action: AssignToMeRequestAction,
	hooks: AssignToMeHooks = defaultHooks,
): Observable<AssignToMeSucessAction | AssignToMeFailureAction> => {
	const { issueId, assigneeAccountId } = action.payload;
	const {
		optimistic: { id: optimisticId },
	} = action.meta;

	if (hooks.onStart) {
		hooks.onStart(action);
	}

	const requestObservable = Observable.fromPromise(
		assignToMe(swagClient, { issueId: `${issueId}`, accountId: assigneeAccountId }),
	);

	return requestObservable
		.map(() => {
			if (hooks.onSuccess) {
				hooks.onSuccess(action);
			}
			return assignToMeSucess(optimisticId);
		})
		.catch(() => {
			if (hooks.onFailure) {
				hooks.onFailure(action);
			}
			return ReduxActionsObservable.of(assignToMeFailure(optimisticId));
		});
};

export const cardAssignToMeEpic = (
	action$: ActionsObservable,
	state: MiddlewareAPI,
	dependencies?: BoardDependencies,
) =>
	dependencies
		? action$
				.ofType(ASSIGN_TO_ME_REQUEST)
				.flatMap((action: AssignToMeRequestAction) =>
					handleAssignToMeRequest(dependencies.swagClient, action),
				)
		: Observable.empty<Action>();

export const cardAssignToMeThrottledEpic = (action$: ActionsObservable) =>
	action$
		.ofType(ASSIGN_TO_ME_REQUEST_THROTTLED)
		.throttleTime(500)
		.flatMap((action: AssignToMeRequestThrottledAction) => {
			const { issueId, assigneeAccountId, fireShortcutAnalyticsFn } = action.payload;
			const concurrentId = uuid();

			assignToMeInteraction(concurrentId).start();

			return Observable.of(
				assignToMeRequest({
					issueId,
					assigneeAccountId,
					concurrentId,
					fireShortcutAnalyticsFn,
				}),
			);
		});
