import {
	DEPLOYMENT_PANEL_DATA_TYPE,
	BUILD_PANEL_DATA_TYPE,
} from '@atlassian/jira-development-details/src/common/model/constants.tsx';
import { INSIGHTS_PACKAGE } from '@atlassian/jira-insights-common-constants/src/common/constants/package.tsx';

const TASK_TYPES = {
	PULL_REQUEST_REVIEW: 'PR_REVIEW',
	PULL_REQUEST_NEEDS_WORK: 'PR_NEEDS_WORK',
	FAILED_DEPLOYMENT: 'DEPLOYMENT_FAILED',
	FAILED_BUILD: 'BUILD_FAILED',
	CRIT_VULN: 'CRIT_VULN',
	BLOCKING_ISSUE: 'BLOCKING_ISSUE',
	PR_DRAFT: 'PR_DRAFT',
	PR_STALE: 'PR_STALE',
	PR_MERGEABLE: 'PR_MERGEABLE',
	PR_COMMENTS: 'PR_COMMENTS',
} as const;

type TASK_TYPES = (typeof TASK_TYPES)[keyof typeof TASK_TYPES];

export const WORK_SUGGESTIONS_TASK_TYPES: Record<string, TASK_TYPES> = {
	WorkSuggestionsBuildTask: 'BUILD_FAILED',
	WorkSuggestionsDeploymentTask: 'DEPLOYMENT_FAILED',
	WorkSuggestionsPullRequestReviewTask: 'PR_REVIEW',
	WorkSuggestionsPullRequestNeedsWorkTask: 'PR_NEEDS_WORK',
	WorkSuggestionsCriticalVulnerabilityTask: 'CRIT_VULN',
	WorkSuggestionsBlockingIssueTask: 'BLOCKING_ISSUE',
	WorkSuggestionsPullRequestDraftTask: 'PR_DRAFT',
	WorkSuggestionsPullRequestInactiveTask: 'PR_STALE',
	WorkSuggestionsPRMergeableTask: 'PR_MERGEABLE',
	WorkSuggestionsPRCommentsTask: 'PR_COMMENTS',
} as const;

export const HydrateInfoItems = {
	REPO: 'repo',
	APPROVALS: 'approvals',
	COMMENT: 'comment',
	ISSUEKEY: 'issuekey',
	ENVIRONMENT: 'environment',
	UPDATED: 'updated',
	SECURITY_CONTAINER_NAME: 'securityContainerName',
	STATUS: 'status',
	TITLE: 'title',
	INTRODUCED_DATE: 'introducedDate',
} as const;

export type HydrateInfoItems = (typeof HydrateInfoItems)[keyof typeof HydrateInfoItems];

export const PACKAGE_NAME = 'jira.next-best-task';

export const ITEMS_PER_BATCH = 3;

const NEXT_BEST_TASK_ID = 'nextBestTask';

export const NEXT_BEST_TASK_ANALYTICS = `${INSIGHTS_PACKAGE}.${NEXT_BEST_TASK_ID}`;

const LOGGING_KEY = 'jira.next-best-task';
export const ERROR_BOUNDARY_PACKAGE = LOGGING_KEY;

export const PANEL_WIDTH = 272;

// Adding 2 pixels for the border
export const PANEL_WIDTH_WITH_MARGIN = PANEL_WIDTH + 2;

const panelHeaderHeight = 49;

export const PANEL_HEADER_HEIGHT = panelHeaderHeight;
export const ANIMATION_TIMEOUT = 600;
export const ARTICLE_ID = '2HtqtVTfYoilHIuYJIGD1T';

export const DEV_DETAILS_DIALOG_SCOPE_ID = 'jira-insights-next-best-task';
export const DEV_DETAILS_DEPLOYMENT_PANEL_TYPE = DEPLOYMENT_PANEL_DATA_TYPE;
export const DEV_DETAILS_BUILD_PANEL_TYPE = BUILD_PANEL_DATA_TYPE;

export const NEXT_BEST_TASK_USER_PROPERTIES_SCOPE = 'jira.insights.next-best-task.user-properties';

export const WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_PANEL_NAME =
	'workSuggestionsViewSettingsSpotlightPanel';

export const WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME =
	'workSuggestionsViewSettingsSpotlightViewSettings';

export const GITHUB_ONBOARDING_LOCAL_STORAGE_FINISHED_NAMESPACE =
	'work-suggestions-github-onboarding-finished';

export const GITHUB_ONBOARDING_FINISHED_LOCAL_STORAGE_TTL_IN_MS = 1000 * 60 * 60 * 24 * 3; // 3 days

export const WORK_SUGGESTIONS_SPOTLIGHT_MESSAGE_ID = 'jira-work-suggestions-spotlight';
