import { createSelector } from 'reselect';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { BOARD_ACTION_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ACTIVE_SPRINT_STATE } from '../../../common/constants.tsx';
import { getBoardTypeForREST } from '../../../common/utils/get-board-type-for-rest/index.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type { SwimlaneId } from '../../../model/issue/issue-types.tsx';
import { CAN_CREATE_CARDS } from '../../../model/permission/permission-types.tsx';
import {
	NO_SWIMLANE,
	SWIMLANE_BY_JQL,
	SWIMLANE_BY_SUBTASK,
} from '../../../model/swimlane/swimlane-modes.tsx';
import { ChildlessSwimlane } from '../../../model/swimlane/swimlane-types.tsx';
import type { JQLSwimlaneData } from '../../../model/work/work-types.tsx';
import type { State } from '../../reducers/types.tsx';
import { getColumnIdToKeepICCOpen } from '../card/card-selectors.tsx';
import {
	getAppConfiguration,
	getBoardConfig,
	getBoardTypeForAnalytics,
	getEntities,
	getIsCMPBoard,
	getIsIncrementPlanningBoard,
	getOrderedColumnIds,
	projectIdSelector,
	projectKeySelector,
	projectTypeSelector,
	rapidViewIdSelector,
} from '../software/software-selectors.tsx';
import { activeSprintsSelector } from '../sprint/sprint-selectors.tsx';
import { getSwimlaneMode } from '../swimlane/swimlane-mode-selectors.tsx';
import { getSwimlanes, isDefaultSwimlane } from '../swimlane/swimlane-selectors.tsx';
import {
	workIssuesSelector,
	isInlineColumnEditEnabled,
	getAppliedIssueTypeFilters,
} from '../work/work-selectors.tsx';
import { getPermissionsSelector } from './board-permissions-selectors.tsx';

export const isPollingEnabled = (state: State): boolean => state.ui.board.isPollingEnabled;

export const isDragging = (state: State): boolean => state.ui.board.isDragging;

export const isIpBoardFailed = (state: State): boolean => state.ui.board.isIpBoardFailed;

export const getPreventInlineEditing = (state: State): boolean =>
	state.ui.board.displayOptions.preventInlineEditing;

export const getIsHeaderCompactMode = createSelector(
	[
		getIsIncrementPlanningBoard,
		(state: State): boolean => state.ui.board.displayOptions.isHeaderCompactMode,
	],
	(isIncrementPlanningBoard, isHeaderCompactMode) => {
		if (isIncrementPlanningBoard) {
			return true;
		}
		return isHeaderCompactMode;
	},
);

export const isBoardEmptySelector = createSelector(
	[workIssuesSelector],
	(allIssues) => allIssues.length === 0,
);

export const isBacklogEnabled = (state: State): boolean =>
	getBoardConfig(state).isBacklogEnabled || false;

export const isManageRulesEnabled = (state: State): boolean =>
	getBoardConfig(state).isManageRulesEnabled;

export const operationsSelector = createSelector(
	[getAppConfiguration],
	(configuration) => configuration.extension.operations,
);

// Checking if it's a JSW TMP project,
// and if it's not an IP board
export const shouldAutoOpenIccAndShowCreateButton = (state: State): boolean =>
	!getIsCMPBoard(state) &&
	projectTypeSelector(state) === SOFTWARE_PROJECT &&
	!getIsIncrementPlanningBoard(state);

// only have add issue type feature
// if it is not a subtask swimlane, board is editable, and issue type filter is not applied
export const shouldHaveAddIssueTypeFeature = createSelector(
	[getSwimlaneMode, isInlineColumnEditEnabled, getAppliedIssueTypeFilters],
	(swimlaneMode, inlineColumnEditEnabled, issueTypeFilters) =>
		(swimlaneId?: SwimlaneId | null, isAddingSubtaskToGroup?: boolean): boolean => {
			const isSubtaskSwimlane =
				(swimlaneMode === SWIMLANE_BY_SUBTASK.id &&
					swimlaneId !== ChildlessSwimlane.id &&
					swimlaneId !== null) ||
				isAddingSubtaskToGroup;

			const hasIssueTypeFilters = issueTypeFilters ? issueTypeFilters.length > 0 : false;

			return inlineColumnEditEnabled && !isSubtaskSwimlane && !hasIssueTypeFilters;
		},
);

export const isUserAtLeastMemberOfProject = (
	state: State,
): boolean => // This is a proxy for the DW idea of Project 'Member'. (We don't currently call APIs that check
	// this directly). If a user can create issues in this project, they must be at least a Member,
	// and enjoy all the privileges of a Project Member (e.g. creating and ranking issues).
	!!getPermissionsSelector(state)[CAN_CREATE_CARDS];

export const getOnlyHasDefaultSwimlane = createSelector(
	[getIsCMPBoard, getSwimlaneMode, getSwimlanes, (state: State) => getEntities(state).jqlSwimlanes],
	(isCMPBoard, swimlaneMode, swimlanes, jqlSwimlanes) => {
		if (!isCMPBoard) {
			return false;
		}

		if (swimlaneMode === SWIMLANE_BY_JQL.id) {
			const nonEmptySwimlanes = jqlSwimlanes.swimlanes?.filter(
				(swimlane) => swimlane.issueIds.length > 0,
			);
			return nonEmptySwimlanes?.length === 1 && nonEmptySwimlanes[0].isDefaultSwimlane;
		}

		return swimlanes.length === 1 && isDefaultSwimlane(swimlanes[0].id);
	},
);

export const getJQLSwimlanesData = createSelector(
	[getIsCMPBoard, getSwimlaneMode, (state: State) => getEntities(state).jqlSwimlanes],
	(isCMPBoard, swimlaneMode, jqlSwimlanes): JQLSwimlaneData[] | undefined => {
		// is only available on CMP boards
		if (isCMPBoard && swimlaneMode === SWIMLANE_BY_JQL.id) {
			return jqlSwimlanes.swimlanes;
		}
		return undefined;
	},
);

/**
 * Unify the look and feel of empty CMP board. In another word,
 * an empty CMP board should always display as it is in none swimlane mode.
 *
 * Also, on CMP if there's only 1 swimlane and it is the default ('unassigned'
 * or 'everything else') we don't render any swimlanes.
 */
export const shouldRenderSwimlaneSelector = createSelector(
	[getSwimlaneMode, isBoardEmptySelector, getIsCMPBoard, getOnlyHasDefaultSwimlane],
	(swimlaneMode, isBoardEmpty, isCMPBoard, onlyHasDefaultSwimlane): boolean => {
		const hasSwimlanes = swimlaneMode !== NO_SWIMLANE.id;
		return isCMPBoard ? hasSwimlanes && !isBoardEmpty && !onlyHasDefaultSwimlane : hasSwimlanes;
	},
);

export const addToSprintSuccessSelector = (state: State): boolean | undefined =>
	state.ui.board.boardAddNewIssueToSprint?.isSuccess;

export const addToSprintLoadingSelector = (state: State): boolean | undefined =>
	state.ui.board.boardAddNewIssueToSprint?.isLoading;

export const shouldColumnIccRemainOpen = createSelector(
	[isBoardEmptySelector, getOrderedColumnIds, getColumnIdToKeepICCOpen],
	(isBoardEmpty, orderedIds, columnIdToKeepOpen) => (columnId: ColumnId) =>
		isBoardEmpty && orderedIds.indexOf(columnId) === 0 && columnIdToKeepOpen === null,
);

export const isFavouriteSelector = (state: State) =>
	getEntities(state).board.boardFavourite.isFavourite;

export const getBoardActionExtensionData = createSelector(
	[
		rapidViewIdSelector,
		getBoardTypeForAnalytics,
		projectIdSelector,
		projectKeySelector,
		projectTypeSelector,
		activeSprintsSelector,
	],
	(boardId, boardType, projectId, projectKey, projectType, activeSprints) => ({
		board: {
			id: boardId,
			type: getBoardTypeForREST(boardType),
		},
		project: {
			id: projectId.toString(),
			key: projectKey,
			type: projectType,
		},
		sprints: activeSprints?.map(({ id }) => ({
			id: id.toString(),
			state: ACTIVE_SPRINT_STATE,
		})),
		type: BOARD_ACTION_MODULE,
	}),
);
