import React, { useCallback, useEffect, useState, useRef, type MouseEvent, useMemo } from 'react';
import { styled } from '@compiled/react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useCommandPalette } from '../../../../controllers/command-palette/index.tsx';
import type { ListedCommand, CommandPaletteBodyProps } from '../../../types/commands/index.tsx';
import {
	getAnalyticsAttributesFromResult,
	getRegistryData,
	isSecondaryTrigger,
} from '../../../utils.tsx';
import { CommandPaletteSearchResultGroupItem } from '../../command-palette-search-dropdown/command-palette-search-result-group-item/index.tsx';
import { CommandPaletteSearchResultListItem } from '../../command-palette-search-dropdown/command-palette-search-result-list-item/index.tsx';
import { CommandPaletteSearchResultListWrapper } from '../../command-palette-search-dropdown/command-palette-search-result-list-wrapper/index.tsx';
import { CommandRowErrorBoundary } from './command-row-error-boundary/index.tsx';
import { CommandRow } from './command-row/index.tsx';
import { GROUP_TEST_ID, NO_RESULTS_TEST_ID, SCROLL_DIRECTION } from './constants.tsx';
import { getScrollToIndex, isElementVisibleInContainer, scrollResultIntoView } from './utils.tsx';

type CommandPaletteChildrenProps = {
	results: CommandPaletteBodyProps['results'];
	noResultsFallback?: string | JSX.Element | null | undefined;
	renderAfterCommands?: CommandPaletteBodyProps['renderAfterCommands'];
	resultsRefs: React.MutableRefObject<HTMLDivElement[]>;
};

const CommandPaletteChildren = ({
	results,
	noResultsFallback,
	renderAfterCommands,
	resultsRefs,
}: CommandPaletteChildrenProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [
		{ isSearchFieldFocused, loadingList, registry, activeRegistry },
		{ setActiveIndex, removeCommandsById, executeCommand },
	] = useCommandPalette();

	const onHoverCommand = (index?: number) => {
		if (index === undefined) return;
		setActiveIndex(index);
	};

	const onMouseDownCommand = useCallback((event: MouseEvent) => {
		event.preventDefault();
	}, []);

	const onClickCommand = useCallback(
		(event: MouseEvent | React.KeyboardEvent, result: ListedCommand) => {
			executeCommand(
				result.command,
				{
					method: 'mouse',
					createAnalyticsEvent,
					attributes: {
						...getAnalyticsAttributesFromResult(result),
						...getRegistryData(registry, activeRegistry, result?.command.id),
					},
				},
				isSecondaryTrigger(event),
			);
		},
		[activeRegistry, createAnalyticsEvent, executeCommand, registry],
	);
	const localResultRefs = resultsRefs.current;

	return (
		<>
			{!loadingList && noResultsFallback && (
				<NoResultsContainer data-testid={NO_RESULTS_TEST_ID}>
					{noResultsFallback}
				</NoResultsContainer>
			)}
			{results.map((result, index) => {
				if (result.isGroup) {
					return (
						<CommandPaletteSearchResultGroupItem
							ref={(commandRef) => {
								if (commandRef) {
									localResultRefs[index] = commandRef;
								}
							}}
							group={result.group}
							key={index}
						>
							<GroupContainerComponent data-testid={GROUP_TEST_ID} isFirstResult={index === 0}>
								{result.renderGroup ? result.renderGroup() : result.group}
							</GroupContainerComponent>
						</CommandPaletteSearchResultGroupItem>
					);
				}
				return (
					<CommandRowErrorBoundary
						key={index}
						teamName={typeof registry?.teamName === 'string' ? registry.teamName : 'deliveroo'}
						onError={() => removeCommandsById([result.command.id])}
						attributes={getRegistryData(registry, activeRegistry, result?.command.id)}
					>
						<CommandPaletteSearchResultListItem
							ref={(commandRef) => {
								if (commandRef) {
									localResultRefs[index] = commandRef;
								}
							}}
							id={result.command.id}
							active={result.active}
							key={index}
						>
							<CommandRow
								index={index}
								isSearchFieldFocused={isSearchFieldFocused}
								result={result}
								onHoverCommand={(ev) => {
									// Safari is weird and likes to fire mouseover events when the mouse is not moving
									if (ev.movementX === 0 && ev.movementY === 0) return;
									onHoverCommand(result.index);
								}}
								onClickCommand={(event) => onClickCommand(event, result)}
								onMouseDownCommand={onMouseDownCommand}
							/>
						</CommandPaletteSearchResultListItem>
					</CommandRowErrorBoundary>
				);
			})}
			{renderAfterCommands?.({})}
		</>
	);
};

export const CommandPaletteBody = ({
	results,
	total,
	fallback,
	renderAfterCommands,
}: CommandPaletteBodyProps) => {
	const [{ activeIndex, isSearchFieldFocused, expandedCommand, search }, { setActiveIndex }] =
		useCommandPalette();

	const [prevActiveIndex, setPrevActiveIndex] = useState(activeIndex);

	const resultsRefs = useRef<HTMLDivElement[]>([]);
	const modalBodyRef = useRef<HTMLDivElement>(null);

	const onArrowScroll = useCallback(
		(newActiveIndex: number) => {
			setActiveIndex(newActiveIndex);
		},
		[setActiveIndex],
	);

	useEffect(() => {
		if (!modalBodyRef.current || prevActiveIndex === activeIndex) return;
		setPrevActiveIndex(activeIndex);

		const scrollTo: SCROLL_DIRECTION =
			activeIndex > prevActiveIndex ? SCROLL_DIRECTION.END : SCROLL_DIRECTION.START;

		const scrollToIndex = getScrollToIndex(results, activeIndex, scrollTo);
		const scrolledToResult = resultsRefs.current[scrollToIndex];
		if (scrolledToResult && !isElementVisibleInContainer(scrolledToResult, modalBodyRef.current)) {
			const lastIndex = results.length - 1;
			const firstIndex = results.findIndex((result) => !result.isGroup);

			if (lastIndex === scrollToIndex) {
				modalBodyRef.current.scrollTo(0, modalBodyRef.current.scrollHeight);
			} else if (firstIndex === scrollToIndex) {
				modalBodyRef.current.scrollTo(0, 0);
			} else {
				scrollResultIntoView({
					target: scrolledToResult,
					direction: scrollTo,
					scrollContainer: modalBodyRef.current,
				});
			}
		}
	}, [activeIndex, prevActiveIndex, results]);

	useEffect(() => {
		if (!modalBodyRef.current?.scrollTo) return;
		modalBodyRef.current.scrollTo(0, 0);
	}, [expandedCommand, search]);

	useEffect(() => {
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!isSearchFieldFocused) return () => {};

		const handleArrowScroll = (ev: KeyboardEvent) => {
			if (ev.key === 'ArrowUp') {
				ev.preventDefault();
				activeIndex > 0 && onArrowScroll(activeIndex - 1);
			}
			if (ev.key === 'ArrowDown') {
				ev.preventDefault();
				activeIndex < total - 1 && onArrowScroll(activeIndex + 1);
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('keydown', handleArrowScroll);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handleArrowScroll);
		};
	}, [isSearchFieldFocused, total, activeIndex, setActiveIndex, onArrowScroll]);

	const noResultsFallback = useMemo(
		() => fallback?.onNoResults?.renderLabel?.() || fallback?.onNoResults?.label,
		[fallback],
	);

	return (
		<CommandPaletteSearchResultListWrapper ref={modalBodyRef} noResultsFallback={noResultsFallback}>
			<CommandPaletteChildren
				results={results}
				noResultsFallback={noResultsFallback}
				renderAfterCommands={renderAfterCommands}
				resultsRefs={resultsRefs}
			/>
		</CommandPaletteSearchResultListWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupContainerComponent = styled.div<{ isFirstResult?: boolean }>({
	font: token('font.body'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isFirstResult }) =>
		`${isFirstResult ? token('space.100', '8px') : token('space.150', '12px')} ${token(
			'space.300',
			'24px',
		)} 0 ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NoResultsContainer = styled.div({
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N100),
	paddingTop: token('space.400', '32px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.400', '32px'),
	paddingLeft: token('space.200', '16px'),
});
