import React, { useCallback, useMemo, useState, useRef } from 'react';
import isNil from 'lodash/isNil';
import noop from 'lodash/noop';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { ERROR_REPORTING_TEAM } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	isIssueEntryGroup,
	getIsIssueIdValid,
} from '@atlassian/jira-software-board-common/src/index.tsx';
import { PACKAGE_NAME } from '../../../../../model/constants.tsx';
import { useBoardSelector } from '../../../../../state/index.tsx';
import {
	NO_ACTIVE_SPRINT_PLACEHOLDER,
	NO_PLACEHOLDER,
} from '../../../../../state/selectors/column/column-inline-card-create-selectors.tsx';
import { getIsIncrementPlanningBoard } from '../../../../../state/selectors/software/software-selectors.tsx';
import { getIssuesWithIssueLinksInBoardIds } from '../../../../../state/selectors/work/work-selectors.tsx';
import { useIsIncrementPlanningBoard } from '../../../../../state/state-hooks/capabilities/index.tsx';
import CardContainer from '../../card-container/index.tsx';
import CardGroup from '../../card-group/index.tsx';
import EmptyCMPKanbanBoardMessage from '../../column/empty-cmp-kanban-dialog/index.tsx';
import Header from '../../column/header/index.tsx';
import Menu from '../../column/header/menu/index.tsx';
import ColumnCore from '../../column/index.tsx';
import NoActiveSprintDialog from '../../column/no-active-sprint-dialog/index.tsx';
import ProgramSwimlaneColumnHeader from '../../column/program-swimlane-column-header/index.tsx';
import { FastVirtualList } from '../../virtual-board/fast-virtual-list/view.tsx';
import type { RenderCardCallback } from '../../virtual-board/types.tsx';
import Footer from './footer/index.tsx';
import type { Props } from './types.tsx';

const EMPTY_ARRAY: string[] = [];

const Column = ({
	column,
	columnIndex,
	swimlaneId,
	isLastSwimlane,
	isSprintsEnabled,
	isBoardEmpty,
	isDoneCardsButtonVisible,
	isUserAtLeastMember,
	canEditBoard,
	useVirtualisation,
	issueEntries = [],
	dropZoneType,
	offsetTop,
	isCMPKanbanBoard,
	isCMPBoard,
	isColumnIccEnabled,
	placeholderType,
	isUnscheduledWorkColumnPanel,
}: Props) => {
	// We use the ref of the header to make sure ICC doesn't render if it's under the header.
	const headerRef = useRef(null);
	const { id, isInitial, isDone, limitJql, name } = column;
	const [menusShown, setMenusShowns] = useState<Record<number, boolean>>({});
	const setMenuVisibility = (columnId: number) => (visible: boolean) => {
		setMenusShowns({ [columnId]: visible });
	};
	const MAX_CARDS_TO_SHOW_ICC_NUDGE = 3;

	const isIncrementPlanningBoard = useBoardSelector(getIsIncrementPlanningBoard);
	const issuesWithIssueLinksIds = useBoardSelector((state) =>
		isIncrementPlanningBoard && fg('dependency_visualisation_program_board_fe_and_be')
			? getIssuesWithIssueLinksInBoardIds(state)
			: EMPTY_ARRAY,
	);

	const openInitialColumnIcc = isInitial && isBoardEmpty && isColumnIccEnabled;

	const showDoneCardsButtonCMP = isCMPKanbanBoard && isLastSwimlane && !isNil(limitJql);
	const showDoneCardsButtonTMP =
		isDone && !isSprintsEnabled && isLastSwimlane && isDoneCardsButtonVisible;

	const showDoneCardsButton = isCMPBoard ? showDoneCardsButtonCMP : showDoneCardsButtonTMP;

	const canShowIccNudgeInBoard = isInitial && issueEntries.length > MAX_CARDS_TO_SHOW_ICC_NUDGE;
	const isIPBoard = useIsIncrementPlanningBoard();

	const renderCardFunction: RenderCardCallback = useCallback(
		(issueEntry, index, isScrolling, onResizeCallback = noop, cardOffsetTop) => {
			const userCanViewMenu = canEditBoard || isUserAtLeastMember;

			if (isIssueEntryGroup(issueEntry)) {
				return (
					<CardGroup
						key={issueEntry.issueIds.toString()}
						index={index}
						issueIdGroup={issueEntry}
						canShowIccNudge={false}
						columnId={id}
						isColumnIccEnabled={isColumnIccEnabled}
						swimlaneId={swimlaneId}
						isLastSwimlane={isLastSwimlane}
						userCanViewMenu={userCanViewMenu}
						onIssueChange={onResizeCallback}
						isScrolling={isScrolling}
						headerRef={headerRef}
						offsetTop={cardOffsetTop}
					/>
				);
			}
			const isIssueIdValid =
				getIsIssueIdValid(issueEntry.issueId) || !fg('avoid_invalid_issueid_in_board_context_menu');
			return (
				<CardContainer
					key={issueEntry.issueId}
					id={issueEntry.issueId}
					canShowIccNudge={canShowIccNudgeInBoard}
					columnId={id}
					index={index}
					isColumnIccEnabled={isColumnIccEnabled}
					swimlaneId={swimlaneId}
					isLastSwimlane={isLastSwimlane}
					userCanViewMenu={userCanViewMenu && isIssueIdValid}
					onIssueChange={onResizeCallback}
					isScrolling={isScrolling}
					headerRef={headerRef}
				/>
			);
		},
		[
			canEditBoard,
			id,
			isColumnIccEnabled,
			isLastSwimlane,
			isUserAtLeastMember,
			swimlaneId,
			canShowIccNudgeInBoard,
		],
	);

	const header = useMemo(() => {
		if (isNil(swimlaneId)) {
			return (
				<Header
					id={id}
					isMenuShown={menusShown[id]}
					menu={<Menu columnId={id} onVisibilityChange={setMenuVisibility(id)} />}
					showShadowOnScroll
				/>
			);
		}
		if (isIPBoard) {
			return (
				<ReportErrors
					id="program-swimlane-column-header"
					packageName={PACKAGE_NAME}
					sendToPrivacyUnsafeSplunk
					teamName={ERROR_REPORTING_TEAM}
				>
					<ProgramSwimlaneColumnHeader
						swimlaneId={swimlaneId}
						columnId={id}
						count={issueEntries.length}
						isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
						ref={headerRef}
					/>
				</ReportErrors>
			);
		}
		return null;
	}, [swimlaneId, id, menusShown, isIPBoard, isUnscheduledWorkColumnPanel, issueEntries.length]);

	const footer = useMemo(
		() => (
			<Footer
				columnId={id}
				isInitialColumn={isInitial}
				canShowIccNudge={isInitial && issueEntries.length <= MAX_CARDS_TO_SHOW_ICC_NUDGE}
				showDoneCardsButton={showDoneCardsButton}
				isColumnIccEnabled={isColumnIccEnabled}
				isLastSwimlane={isLastSwimlane}
				cardIndex={issueEntries.length}
				openInitialColumnIcc={openInitialColumnIcc}
				swimlaneId={swimlaneId}
				limitJql={limitJql}
				{...(fg('jfp-a11y-team_board_identical-create-issue-button') && { columnName: name })}
			/>
		),
		[
			id,
			isColumnIccEnabled,
			isInitial,
			isLastSwimlane,
			issueEntries.length,
			openInitialColumnIcc,
			showDoneCardsButton,
			swimlaneId,
			limitJql,
			name,
		],
	);

	const virtualListFooter = useMemo(
		() => (
			<>
				{footer}
				{placeholderType === NO_ACTIVE_SPRINT_PLACEHOLDER && <NoActiveSprintDialog />}
			</>
		),
		[footer, placeholderType],
	);

	const placeHolder = useMemo(() => {
		if (placeholderType === NO_PLACEHOLDER) {
			return null;
		}

		return placeholderType === NO_ACTIVE_SPRINT_PLACEHOLDER ? (
			<NoActiveSprintDialog />
		) : (
			<EmptyCMPKanbanBoardMessage />
		);
	}, [placeholderType]);

	if (!__SERVER__ && useVirtualisation) {
		return (
			<ColumnCore
				id={id}
				key={id}
				header={header}
				swimlaneId={swimlaneId ?? undefined}
				draggableIndex={columnIndex}
				footer={footer}
				isLastSwimlane={isLastSwimlane}
				dropZoneType={dropZoneType}
			>
				{() => (
					<FastVirtualList
						{...(ff('backlog-active-sprints-list-markup-accessibility-fix_imavd') && {
							name,
						})}
						showFooter={isColumnIccEnabled || showDoneCardsButton}
						issueEntries={issueEntries}
						issuesWithIssueLinksIds={issuesWithIssueLinksIds}
						footer={virtualListFooter}
						renderCard={renderCardFunction}
						placeholder={placeHolder}
						columnId={id}
						swimlaneId={swimlaneId}
						offsetTop={offsetTop}
						isUnscheduledWorkColumnPanel={
							fg('dependency_visualisation_program_board_fe_and_be')
								? isUnscheduledWorkColumnPanel
								: undefined
						}
					/>
				)}
			</ColumnCore>
		);
	}
	return (
		<ColumnCore
			id={id}
			key={id}
			header={
				isNil(swimlaneId) ? (
					<Header id={id} menu={<Menu columnId={id} />} showShadowOnScroll />
				) : null
			}
			swimlaneId={swimlaneId ?? undefined}
			draggableIndex={columnIndex}
			footer={footer}
			isLastSwimlane={isLastSwimlane}
		>
			{issueEntries.map((issueEntry, index) => {
				if (isIssueEntryGroup(issueEntry)) {
					return (
						<CardGroup
							key={issueEntry.issueIds.toString()}
							index={index}
							issueIdGroup={issueEntry}
							canShowIccNudge={false}
							columnId={id}
							isColumnIccEnabled={isColumnIccEnabled}
							swimlaneId={swimlaneId}
							isLastSwimlane={isLastSwimlane}
							userCanViewMenu={canEditBoard || isUserAtLeastMember}
							onIssueChange={noop}
							isScrolling={false}
							offsetTop={0}
							headerRef={headerRef}
						/>
					);
				}
				return (
					<CardContainer
						key={issueEntry.issueId}
						id={issueEntry.issueId}
						columnId={id}
						canShowIccNudge={canShowIccNudgeInBoard}
						index={index}
						isColumnIccEnabled={isColumnIccEnabled}
						swimlaneId={swimlaneId}
						isLastSwimlane={isLastSwimlane}
						userCanViewMenu={canEditBoard || isUserAtLeastMember}
						onIssueChange={noop}
						isScrolling={false}
						headerRef={headerRef}
					/>
				);
			})}
			{placeholderType === NO_ACTIVE_SPRINT_PLACEHOLDER && <NoActiveSprintDialog />}
		</ColumnCore>
	);
};

export default Column;
