import { v4 as uuid } from 'uuid';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';
import type { BulkOpsFlow } from '../../common/types.tsx';

export class DefaultBulkOpsFlow implements BulkOpsFlow {
	private bulkOpsSessionID?: string;

	private bulkOperationTypeValue?: string;

	private numBulkActionsCompleted = 0;

	private analyticsEventPublishers = new Set<(sessionID: string, index: number) => void>();

	isInFlow() {
		return this.bulkOpsSessionID !== undefined;
	}

	get sessionID() {
		return this.bulkOpsSessionID;
	}

	get bulkActionsCompleted() {
		return this.numBulkActionsCompleted;
	}

	get bulkOperationType() {
		return this.bulkOperationTypeValue;
	}

	private notifyAnalyticsEventPublishers(sessionID: string) {
		let index = 0;
		this.analyticsEventPublishers.forEach((listener) => {
			listener(sessionID, index);
			index += 1;
		});
		this.analyticsEventPublishers.clear();
	}

	start(_attributes?: AnalyticsAttributes) {
		if (this.isInFlow()) {
			return;
		}
		this.bulkOpsSessionID = uuid();
		this.notifyAnalyticsEventPublishers(this.bulkOpsSessionID);
	}

	end(_attributes?: AnalyticsAttributes) {
		this.bulkOpsSessionID = undefined;
		this.numBulkActionsCompleted = 0;
	}

	executeAction() {
		if (!this.isInFlow()) {
			this.start();
		}
		this.numBulkActionsCompleted += 1;
	}

	setBulkOperationType(bulkOperationType?: string) {
		this.bulkOperationTypeValue = bulkOperationType;
	}

	getFlowAttributes() {
		if (this.bulkOpsSessionID && this.numBulkActionsCompleted !== 0) {
			return {
				bulkOpsSessionID: this.bulkOpsSessionID,
				numBulkActionsCompleted: this.numBulkActionsCompleted,
			};
		}

		return this.bulkOpsSessionID ? { bulkOpsSessionID: this.bulkOpsSessionID } : {};
	}

	registerAnalyticsEventPublisher(
		analyticsEventPublisher: (sessionID: string, index: number) => void,
	) {
		this.analyticsEventPublishers.add(analyticsEventPublisher);

		return () => {
			this.analyticsEventPublishers.delete(analyticsEventPublisher);
		};
	}
}
