import type { Filters as SoftwareFilters } from '@atlassian/jira-software-filters/src/common/types.tsx';

export const TEXT = 'TEXT';
export const ASSIGNEE = 'ASSIGNEE';
export const LABEL = 'LABEL';
export const ISSUE_PARENT = 'ISSUE_PARENT';
export const ISSUE_PROJECT = 'ISSUE_PROJECT';
export const ISSUE_TYPE = 'ISSUE_TYPE';
export const REQUEST_TYPE = 'REQUEST_TYPE';
export const STATUS = 'STATUS';
export const CUSTOM_FILTER = 'CUSTOM_FILTER';
export const SPRINT = 'SPRINT';
export const DEPENDENCIES = 'DEPENDENCIES';

export type FilterType =
	| 'TEXT'
	| 'ASSIGNEE'
	| 'LABEL'
	| 'ISSUE_PARENT'
	| 'ISSUE_PROJECT'
	| 'ISSUE_TYPE'
	| 'REQUEST_TYPE'
	| 'STATUS'
	| 'VERSION'
	| 'CUSTOM_FILTER'
	| 'SPRINT'
	| 'DEPENDENCIES';

export type FilterListItem = {
	id: string;
	name: string;
};

export type IssueType = {
	id: string;
	name: string;
	iconUrl: string;
};

export type IssueTypeGroup = {
	key: string;
	name: string;
	iconUrl: string;
	issueTypes: string[];
};

export type Filters = SoftwareFilters;
