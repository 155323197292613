import { useCallback } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-19531
import { useIntl } from '@atlassian/jira-intl';
import { getTransitionSuccessFlag, getTransitionFailureFlag } from './utils.tsx';

/**
 *
 * @returns a set of methods that can be used to shw flags.
 * The idea of this hook was to contain all the flags that we will use across the issue transition.
 * Keeps all the flags and their test can be separeted.
 */
export const useShowFlag = () => {
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const showIssueTransitionSuccessFlag = useCallback(
		(issueKey: string, status: string) => {
			try {
				showFlag(getTransitionSuccessFlag(issueKey, status, formatMessage));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics({
					meta: {
						id: 'showSuccessFlag',
						packageName: 'jiraIssueTransitionUseShowFlag',
						teamName: 'gryffindor',
					},
					error,
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		},
		[formatMessage, showFlag],
	);
	const showIssueTransitionFailureFlag = useCallback(
		(issueKey: string) => showFlag(getTransitionFailureFlag(issueKey, formatMessage)),
		[formatMessage, showFlag],
	);

	return {
		showIssueTransitionSuccessFlag,
		showIssueTransitionFailureFlag,
	};
};
