import React, { useLayoutEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { useEvent } from '@atlassian/jira-software-react-use-event/src/index.tsx';
import { appendUnscheduledWorkSwimlaneIdSuffix } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import type { SwimlaneId } from '../../../../model/issue/issue-types.tsx';
import { useIsIncrementPlanningBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import ColumnsContainer from '../columns-container/index.tsx';
import { SwimlaneMoveToDoneButton } from '../swimlane-move-to-done/index.tsx';
import CreateUnscheduledIssueButton from '../swimlane/increment-planning-buttons/create-unscheduled-issue-button/index.tsx';
import { IncrementPlanningSwimlane } from '../increment-planning-swimlane/index.tsx';
import Swimlane from '../swimlane/index.tsx';

const VirtualizedSwimlane = ({
	id,
	index,
	top,
	measure,
	forceRemeasure,
	isLastSwimlane,
	isUnscheduledWorkColumnPanel,
}: {
	id: SwimlaneId;
	index: number;
	top: number;
	measure: (el: Element | null) => void;
	forceRemeasure: (el?: Element | null) => void;
	isLastSwimlane: boolean;
	isUnscheduledWorkColumnPanel: boolean;
}) => {
	const [isCreateButtonVisible, setIsCreateButtonVisible] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const onResize = useEvent(() => {
		forceRemeasure(wrapperRef.current);
	});
	useLayoutEffect(() => {
		if (wrapperRef.current) {
			measure(wrapperRef.current);
		}
	}, [wrapperRef, measure]);

	useResizeObserver({
		ref: wrapperRef,
		onResize,
	});
	const isIPBoard = useIsIncrementPlanningBoard();

	const showCreateButton = useCallback(() => {
		setIsCreateButtonVisible(true);
	}, [setIsCreateButtonVisible]);

	const hideCreateButton = useCallback(() => {
		setIsCreateButtonVisible(false);
	}, [setIsCreateButtonVisible]);

	const swimlaneId = isUnscheduledWorkColumnPanel ? appendUnscheduledWorkSwimlaneIdSuffix(id) : id;

	const customRightContent = useMemo(() => {
		if (!isIPBoard) {
			return <SwimlaneMoveToDoneButton id={id} />;
		}
		if (isUnscheduledWorkColumnPanel && !fg('refactor_program_board_swimlane_header')) {
			return (
				<Box
					xcss={isCreateButtonVisible ? visibleButton : hiddenButton}
					testId="software-board.board-container.board.virtualized-swimlane.create-unscheduled-issue-button"
				>
					<CreateUnscheduledIssueButton teamId={swimlaneId} />
				</Box>
			);
		}
		return null;
	}, [id, swimlaneId, isCreateButtonVisible, isIPBoard, isUnscheduledWorkColumnPanel]);

	const swimlane =
		isIPBoard && fg('refactor_program_board_swimlane_header') ? (
			<IncrementPlanningSwimlane
				id={swimlaneId}
				innerRef={wrapperRef}
				isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
				top={top}
			>
				<ColumnsContainer
					swimlaneId={id}
					isLastSwimlane={isLastSwimlane}
					offsetTop={top}
					isUnscheduledWorkColumn={isUnscheduledWorkColumnPanel}
				/>
			</IncrementPlanningSwimlane>
		) : (
			<Swimlane
				id={swimlaneId}
				key={swimlaneId}
				isFirstSwimlane={index === 0}
				wrapperStyle={{
					position: 'absolute',
					width: '100%',
					top,
				}}
				innerRef={wrapperRef}
				customRightContent={customRightContent}
				hasShadow={fg('refactor_program_board_swimlane_header') ? true : !isIPBoard}
				isUnscheduledWorkColumnPanel={
					!fg('refactor_program_board_swimlane_header') ? isUnscheduledWorkColumnPanel : undefined
				}
			>
				<ColumnsContainer
					swimlaneId={id}
					isLastSwimlane={isLastSwimlane}
					offsetTop={top}
					isUnscheduledWorkColumn={isUnscheduledWorkColumnPanel}
				/>
			</Swimlane>
		);
	return isUnscheduledWorkColumnPanel && !fg('refactor_program_board_swimlane_header') ? (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			onMouseEnter={showCreateButton}
			onMouseLeave={hideCreateButton}
			onFocus={showCreateButton}
			onBlur={hideCreateButton}
		>
			{swimlane}
		</div>
	) : (
		swimlane
	);
};

const visibleButton = xcss({
	opacity: 1,
});

const hiddenButton = xcss({
	opacity: 0,
});

export default VirtualizedSwimlane;
