import React, { useCallback } from 'react';
import { graphql, usePreloadedQuery, type PreloadedQuery } from 'react-relay';
import { Inline } from '@atlaskit/primitives';
import FilterRefinement from '@atlassian/jira-filter-refinement/src/async.tsx';
import type { boardFilterRefinementQuery } from '@atlassian/jira-relay/src/__generated__/boardFilterRefinementQuery.graphql';
import { useIntl } from '@atlassian/jira-intl';
import CustomFilterRelay from '@atlassian/jira-software-filters/src/ui/custom-filter/index.tsx';
import type { CustomFilterId } from '@atlassian/jira-filters/src/common/types.tsx';
import { filteredCardsSuccess } from '../../../../state/actions/card/filtered-cards/index.tsx';
import { boardIssuesSelector } from '../../../../state/selectors/issue/board-issue-selectors.tsx';
import { useBoardDispatch, useBoardSelector } from '../../../../state/index.tsx';
import { setJql } from '../../../../state/actions/jql/index.tsx';
import { getJql } from '../../../../state/selectors/jql/index.tsx';
import { setAllFilters } from '../../../../state/actions/filter/index.tsx';
import { getSelectedCustomFilters } from '../../../../state/selectors/work/work-selectors.tsx';
import {
	projectIdSelector,
	projectKeySelector,
	projectNameSelector,
} from '../../../../state/selectors/software/software-selectors.tsx';
import messages from './messages.tsx';

type Props = {
	boardFilterRefinementQueryRef: PreloadedQuery<boardFilterRefinementQuery>;
};

export const BoardFilterRefinement = ({ boardFilterRefinementQueryRef }: Props) => {
	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();

	const issues = useBoardSelector(boardIssuesSelector);
	const jql = useBoardSelector(getJql);
	const selectedCustomFilters = useBoardSelector(getSelectedCustomFilters);
	const projectId = useBoardSelector(projectIdSelector);
	const projectKey = useBoardSelector(projectKeySelector);
	const projectName = useBoardSelector(projectNameSelector);

	const onSearch = useCallback(
		(newJql: string) => {
			const issueIds = Object.keys(issues);

			dispatch(filteredCardsSuccess(issueIds));
			dispatch(setJql(newJql));
		},
		[dispatch, issues],
	);

	const onCustomFilterChange = useCallback(
		(customFilters: CustomFilterId[]) => {
			return dispatch(
				setAllFilters(
					{
						CUSTOM_FILTER: customFilters,
					},
					'CUSTOM_FILTER',
				),
			);
		},
		[dispatch],
	);

	const boardFilterRefinementQueryData = usePreloadedQuery(
		graphql`
			query boardFilterRefinementQuery($cloudId: ID!, $boardId: ID!) @preloadable {
				...ui_filterRefinement_FilterRefinement @arguments(cloudId: $cloudId)
				...customFilter_softwareFilters_CustomFilterRelay @arguments(boardId: $boardId)
			}
		`,
		boardFilterRefinementQueryRef,
	);

	return (
		<Inline>
			<FilterRefinement
				defaultJql={jql || ''}
				onSearch={onSearch}
				fragmentRef={boardFilterRefinementQueryData}
				searchFieldPlaceholder={formatMessage(messages.filterRefinementSearchFieldPlaceholder)}
				projectName={projectName}
				projectId={projectId}
				projectKey={projectKey}
			/>
			<CustomFilterRelay
				screenName=""
				onChange={onCustomFilterChange}
				selectedFilters={selectedCustomFilters}
				customFiltersFragmentRef={boardFilterRefinementQueryData}
			/>
		</Inline>
	);
};
