import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.issue-project.label',
		defaultMessage: 'Project',
		description: 'The label of a dropdown menu button used to choose projects to filter issues by.',
	},
	placeholder: {
		id: 'software-filters.filters.issue-project.placeholder',
		defaultMessage: 'Search project filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
