import React, { useRef, useEffect } from 'react';
import { AsyncLocationlessDialog } from '@atlassian/jira-board-location-dialogs-locationless/src/async.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toFlagId, useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import RedirectIfProjectArchived from '@atlassian/jira-redirect-archived-project/src/ui/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import type { UIFBoardCachedDataResult } from '@atlassian/jira-software-uif-early-script/src/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { Breadcrumbs } from '@atlassian/jira-spa-apps-software-breadcrumbs/src/ui/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	useResource,
	useRouterActions,
	useQueryParam,
	type RouteResourceResponse,
	usePathParam,
} from '@atlassian/react-resource-router';
import { updatePageloadName } from '@atlassian/react-ufo/trace-pageload';
import { useIsUserBoardRoute } from '../../../common/util.tsx';

import {
	ERROR_MESSAGE_BOARD_HAS_NO_COLUMN_MAPPED_TO_STATUS,
	ERROR_MESSAGE_BOARD_IS_LOCATIONLESS,
} from '../../../model/constants.tsx';
import { renderBoardApp } from '../board-app/index.tsx';
import { NoSoftwareLicense } from '../no-software-license/index.tsx';
import messages from './messages.tsx';

export const BoardWithRouterCMP = ({ hasSoftwareLicense }: { hasSoftwareLicense: boolean }) => {
	const isUserBoard = useIsUserBoardRoute();
	const [paramBoardId] = usePathParam('boardId');
	const [projectKey] = usePathParam('projectKey');
	const cmpBoardData: RouteResourceResponse<UIFBoardCachedDataResult | null> =
		useResource<UIFBoardCachedDataResult | null>(uifBoardResource);

	const tenantContext = useTenantContext();
	const boardId = Number(paramBoardId);

	const { loading, data, error } = cmpBoardData;
	const boardLocation = data?.result?.boardLocation;
	const { key, id, name } = boardLocation ?? {};

	const projectDetails = { key, id, name };
	const projectKeyFromContext = projectDetails.key || projectKey || '';

	const history = useRouterActions();

	// for locationless boards with old style links like: https://domain.com/secure/RapidBoard.jspa?rapidView=10&useStoredSettings=true&locationless=true
	const [rapidViewBoardIdString] = useQueryParam('rapidView');
	const rapidViewBoardId = Number(rapidViewBoardIdString);
	const [locationlessFromRapidBoardLink] = useQueryParam('locationless');
	const isLocationlessRapidBoardLink =
		rapidViewBoardId && locationlessFromRapidBoardLink === 'true';

	const isCacheHit = Boolean(data?.isCacheHit);
	const hasShownBoardWithUnmappedErrorFlag = useRef(false);
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();

	const [selectedIssue] = useQueryParam('selectedIssue');
	useEffect(() => {
		if (selectedIssue && fg('split_out_board_ufo_metric_with_issue_view')) {
			updatePageloadName('classic-board-with-issue-view');
		}
	}, [selectedIssue]);

	const showBoardWithUnmappedColumnWarningFlag = () => {
		showFlag({
			id: toFlagId('board_with_unmapped_column_flag'),
			type: 'warning',
			title: formatMessage(messages.boardWithUnmappedColumnFlagTitle),
			isAutoDismiss: false,
			description: tenantContext.isAdmin
				? formatMessage(messages.boardWithUnmappedColumnFlagDescriptionForAdmin)
				: formatMessage(messages.boardWithUnmappedColumnFlagDescriptionForUser),
			actions: tenantContext.isAdmin
				? [
						{
							content: formatMessage(messages.navigateToBoardSettingsCta),
							href: '?config=columns',
							target: '_self',
						},
					]
				: [],
		});
		hasShownBoardWithUnmappedErrorFlag.current = true;
	};

	if (!hasSoftwareLicense) {
		return <NoSoftwareLicense />;
	}

	if (!loading && error && error.message === ERROR_MESSAGE_BOARD_IS_LOCATIONLESS) {
		return <AsyncLocationlessDialog rapidViewId={boardId} />;
	}

	if (
		!loading &&
		error &&
		error.message === ERROR_MESSAGE_BOARD_HAS_NO_COLUMN_MAPPED_TO_STATUS &&
		!hasShownBoardWithUnmappedErrorFlag.current
	) {
		showBoardWithUnmappedColumnWarningFlag();
	}

	if (isLocationlessRapidBoardLink) {
		return (
			<AsyncLocationlessDialog
				rapidViewId={rapidViewBoardId}
				classicLocationless={isLocationlessRapidBoardLink}
			/>
		);
	}

	return (
		<>
			<Breadcrumbs />
			<SubProductUpdater subProduct="software" />

			{renderBoardApp({
				rapidViewId: boardId,
				projectKey: projectKeyFromContext,
				projectType: SOFTWARE_PROJECT,
				context: { tenantContext },
				cmpBoardData,
				boardData: null,
				history,
				isCacheHit,
				isCMPBoard: true,
				isUserBoard,
			})}

			<RedirectIfProjectArchived projectKey={projectKey || projectKeyFromContext || null} />
		</>
	);
};
