import React, { useState } from 'react';
import noop from 'lodash/noop';
import { fg } from '@atlassian/jira-feature-gating';
import JiraFullscreenButton from './main.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useIsFullscreen } from './common/utils/hooks/use-is-fullscreen/index.tsx';

const FullscreenButton = ({
	testId,
	onToggle,
	getCustomRefs,
	analyticsAttributes,
	withContainerStyles = true,
	defaultFullscreen = false,
	isFullscreen: isFullscreenProp,
	renderAsResponsiveButton = false,
	shouldRenderContent = true,
	isSubtle,
	isHidden,
}: Props) => {
	const [isFullscreenState, setIsFullscreenState] = useState(isFullscreenProp ?? defaultFullscreen);
	const isFullscreen = isFullscreenProp ?? isFullscreenState;

	const handleToggle = (wasFullscreen: boolean, globalStyle?: HTMLElement) => {
		onToggle(wasFullscreen, globalStyle);
		setIsFullscreenState(!wasFullscreen);
	};

	return (
		<JiraFullscreenButton
			testId={testId}
			onToggle={handleToggle}
			getCustomRefs={getCustomRefs}
			analyticsAttributes={analyticsAttributes}
			withContainerStyles={withContainerStyles}
			defaultFullscreen={defaultFullscreen}
			isFullscreen={isFullscreen}
			renderAsResponsiveButton={renderAsResponsiveButton}
			shouldRenderContent={shouldRenderContent}
			isSubtle={isSubtle}
			{...(fg('jsw_roadmaps_timeline-fix-a11y-rain') ? { isHidden } : {})}
		/>
	);
};

FullscreenButton.defaultProps = {
	onToggle: noop,
	getCustomRefs: () => [],
	analyticsAttributes: {},
};

export default FullscreenButton;
