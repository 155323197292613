import { v4 as uuid } from 'uuid';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ExternalAction } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import type { IssueId } from '@atlassian/jira-software-board-common/src/index.tsx';
import type { FireAnalyticsFn as FireShortcutAnalyticsFn } from '@atlassian/jira-software-keyboard-shortcuts/src/utils/analytics/index.tsx';
import type { ColumnId } from '../../../model/column/column-types.tsx';
import type { IssueMediaCollection } from '../../../model/issue/issue-media-types.tsx';
import type { Issue } from '../../../model/issue/issue-types.tsx';
import { type IssueUpdateOrigin, OTHER } from '../../../model/issue/issue-update-origin.tsx';
import type { Person } from '../../../model/people/people-types.tsx';
import type { OptimisticId, TransitionId } from '../../../model/software/software-types.tsx';
import type { SwimlaneId } from '../../../model/swimlane/swimlane-types.tsx';
import type { ColumnCardTransitions } from '../../reducers/entities/issue-transitions/types.tsx';
import type { InlineEditing } from '../../reducers/ui/cards/inline-editing/types.tsx';
import {
	beginOptimisticUiMeta,
	commitOptimisticUiMeta,
	revertOptimisticUiMeta,
	type OptimisticUiMeta,
} from '../meta/optimistic-ui-meta.tsx';
import type { Action as CardAutoScrollAction } from './card-auto-scroll/index.tsx';
import type { Action as CardMoveAction } from './card-move/index.tsx';
import type { Action as FilteredCardsAction } from './filtered-cards/index.tsx';

export const CARD_DATA_SET = 'state.actions.card.CARD_DATA_SET';

export type CardDataSetAction = {
	type: typeof CARD_DATA_SET;
	payload: {
		issue: Issue;
		person?: Person | null;
		origin?: IssueUpdateOrigin;
	};
};

export const cardDataSet = (
	issue: Issue,
	person: Person | null | undefined,
	origin: IssueUpdateOrigin = OTHER,
): CardDataSetAction => ({
	type: CARD_DATA_SET,
	payload: {
		issue,
		person,
		origin,
	},
});

export const CARD_MEDIA_DATA_SET = 'state.actions.card.CARD_MEDIA_DATA_SET';

export type CardMediaDataSetAction = {
	type: typeof CARD_MEDIA_DATA_SET;
	payload: {
		issueMedia: IssueMediaCollection;
	};
};

export const cardMediaDataSet = (issueMedia: IssueMediaCollection): CardMediaDataSetAction => ({
	type: CARD_MEDIA_DATA_SET,
	payload: { issueMedia },
});

export const CARD_REFRESH_REQUEST = 'state.actions.card.CARD_REFRESH_REQUEST';

export type CardRefreshRequestAction = {
	type: typeof CARD_REFRESH_REQUEST;
	payload: {
		issueIds: IssueId[];
		origin?: IssueUpdateOrigin;
		/**
		 * If true, the refresh action will not update the transaction ID.
		 * The source action should then be added to the card/transactions/reducer so that its refresh
		 * isn't ignored.
		 */
		skipTransactionUpdate: boolean;
		/**
		 * Transaction ID to compare with card/transactions/reducer
		 */
		transactionId: string;
	};
};

export type CardRefreshOptions = {
	issueIds: IssueId[];
	origin: IssueUpdateOrigin;
	transactionId: string;
	skipTransactionUpdate: boolean;
};

export const cardRefreshRequestWith = ({
	issueIds,
	origin,
	transactionId,
	skipTransactionUpdate,
}: CardRefreshOptions): CardRefreshRequestAction => ({
	type: CARD_REFRESH_REQUEST,
	payload: {
		issueIds,
		origin,
		skipTransactionUpdate,
		transactionId,
	},
});

export const cardRefreshRequest = (
	issueIds: IssueId[] = [],
	origin: IssueUpdateOrigin = OTHER,
): CardRefreshRequestAction =>
	cardRefreshRequestWith({
		issueIds,
		origin,
		transactionId: uuid(),
		skipTransactionUpdate: false,
	});

export const CARD_CLICK = 'state.actions.card.CARD_CLICK';

export type CardClickAction = {
	type: typeof CARD_CLICK;
	payload: {
		issueId: IssueId;
		withCmd: boolean;
		withShift: boolean;
		externalAction?: ExternalAction;
	};
};

export const cardClick = (
	issueId: IssueId,
	withCmd: boolean,
	withShift: boolean,
	externalAction?: ExternalAction,
): CardClickAction => ({
	type: CARD_CLICK,
	payload: {
		issueId,
		withCmd,
		withShift,
		externalAction,
	},
});

export const CARD_TOGGLE_SELECTION = 'state.actions.card.CARD_TOGGLE_SELECTION';

export type CardToggleSelectionAction = {
	type: typeof CARD_TOGGLE_SELECTION;
	payload: {
		issueId: IssueId;
	};
};

export const CARD_MULTI_SELECT = 'state.actions.card.CARD_MULTI_SELECT';

export type CardMultiSelectAction = {
	type: typeof CARD_MULTI_SELECT;
	payload: {
		issueIds: IssueId[];
	};
};

export const cardMultiSelect = (issueIds: IssueId[]): CardMultiSelectAction => ({
	type: CARD_MULTI_SELECT,
	payload: {
		issueIds,
	},
});

export const cardToggleSelection = (issueId: IssueId): CardToggleSelectionAction => ({
	type: CARD_TOGGLE_SELECTION,
	payload: {
		issueId,
	},
});

export const CARD_SINGLE_SELECT = 'state.actions.card.CARD_SINGLE_SELECT';

export type CardSingleSelectAction = {
	type: typeof CARD_SINGLE_SELECT;
	payload: {
		issueId: IssueId;
	};
};

export const cardSingleSelect = (issueId: IssueId): CardSingleSelectAction => ({
	type: CARD_SINGLE_SELECT,
	payload: {
		issueId,
	},
});

export const CARD_CANCEL_SELECTION = 'state.actions.card.CARD_CANCEL_SELECTION';

export type CardCancelSelectionAction = {
	type: typeof CARD_CANCEL_SELECTION;
};

export const cardCancelSelection = (): CardCancelSelectionAction => ({
	type: CARD_CANCEL_SELECTION,
});

export const CARD_CLEAR = 'state.actions.card.CARD_CLEAR';

export type CardClearAction = {
	type: typeof CARD_CLEAR;
	payload: {
		cardIds: IssueId[];
		childCardIds: IssueId[];
	};
	meta: OptimisticUiMeta;
};

export const cardClear = (cardIds: IssueId[], childCardIds: IssueId[]): CardClearAction => ({
	type: CARD_CLEAR,
	payload: {
		cardIds,
		childCardIds,
	},
	meta: beginOptimisticUiMeta(),
});

export const CARD_CLEAR_SUCCESS = 'state.actions.card.CARD_CLEAR_SUCCESS';

export type CardClearSuccessAction = {
	type: typeof CARD_CLEAR_SUCCESS;
	meta: OptimisticUiMeta;
};

export const cardClearSuccess = (optimisticId: OptimisticId): CardClearSuccessAction => ({
	type: CARD_CLEAR_SUCCESS,
	meta: commitOptimisticUiMeta(optimisticId),
});

export const CARD_CLEAR_FAILURE = 'state.actions.card.CARD_CLEAR_FAILURE';

export type CardClearFailureAction = {
	type: typeof CARD_CLEAR_FAILURE;
	meta: OptimisticUiMeta;
};

export const cardClearFailure = (optimisticId: OptimisticId): CardClearFailureAction => ({
	type: CARD_CLEAR_FAILURE,
	meta: revertOptimisticUiMeta(optimisticId),
});

export const CARD_CLEAR_REVERT = 'state.actions.card.CARD_CLEAR_REVERT';

export type CardClearRevertAction = {
	type: typeof CARD_CLEAR_REVERT;
	meta: OptimisticUiMeta;
};

export const cardClearRevert = (optimisticId: OptimisticId): CardClearRevertAction => ({
	type: CARD_CLEAR_REVERT,
	meta: revertOptimisticUiMeta(optimisticId),
});

export const CARD_CLEAR_UNDO = 'state.actions.card.CARD_CLEAR_UNDO';

export type CardClearUndoAction = {
	type: typeof CARD_CLEAR_UNDO;
	payload: {
		undoPromise: Promise<unknown>;
		retry: boolean;
		// optimisticId of the card clear operation
		optimisticId: OptimisticId;
	};
};

export const cardClearUndo = (
	undoPromise: Promise<unknown>,
	retry: boolean,
	optimisticId: OptimisticId,
): CardClearUndoAction => ({
	type: CARD_CLEAR_UNDO,
	payload: {
		undoPromise,
		retry,
		optimisticId,
	},
});

export const CARD_DELETE = 'state.actions.card.CARD_DELETE';

export type CardDeleteAction = {
	type: typeof CARD_DELETE;
	payload: {
		issueId: IssueId;
		parentId: number | null;
		isDone: boolean;
	};
};

export const cardDelete = (
	issueId: IssueId,
	parentId: number | null = null,
	isDone = false,
): CardDeleteAction => ({
	type: CARD_DELETE,
	payload: {
		issueId,
		parentId,
		isDone,
	},
});

export const CARD_DELETE_OPTIMISTIC = 'state.actions.card.CARD_DELETE_OPTIMISTIC';

export type CardDeleteOptimisticAction = {
	type: typeof CARD_DELETE_OPTIMISTIC;
	payload: {
		issueId: IssueId;
	};
};

export const cardDeleteOptimistic = (issueId: IssueId): CardDeleteOptimisticAction => ({
	type: CARD_DELETE_OPTIMISTIC,
	payload: { issueId },
});

export const CARD_DRAG_OVER_TRANSITION = 'state.actions.card.CARD_DRAG_OVER_TRANSITION';

export type CardDragOverTransitionAction = {
	type: typeof CARD_DRAG_OVER_TRANSITION;
	payload: {
		transitionId: TransitionId | null;
		toColumnId: ColumnId;
		hoverIntent: boolean;
	};
};

export const cardDragOverTransition = (
	transitionId: number | null,
	toColumnId: number,
	hoverIntent: boolean,
): CardDragOverTransitionAction => ({
	type: CARD_DRAG_OVER_TRANSITION,
	payload: {
		transitionId,
		toColumnId,
		hoverIntent,
	},
});

export const CARD_DRAG_OVER_TRANSITION_REQUEST =
	'state.actions.card.CARD_DRAG_OVER_TRANSITION_REQUEST';

export type CardDragOverTransitionRequestAction = {
	type: typeof CARD_DRAG_OVER_TRANSITION_REQUEST;
	payload: {
		transitionId: TransitionId | null;
		toColumnId: ColumnId;
		hoverIntent: boolean;
	};
};

export const cardDragOverTransitionRequest = (
	transitionId: number | null,
	toColumnId: number,
	hoverIntent: boolean,
): CardDragOverTransitionRequestAction => ({
	type: CARD_DRAG_OVER_TRANSITION_REQUEST,
	payload: {
		transitionId,
		toColumnId,
		hoverIntent,
	},
});

export const CARD_DRAG_SELECTED_TRANSITION = 'state.actions.card.CARD_DRAG_SELECTED_TRANSITION';

export type CardDragSelectedTransitionAction = {
	type: typeof CARD_DRAG_SELECTED_TRANSITION;
	payload: {
		transitionId: TransitionId;
		toColumnId: ColumnId;
		toSwimlaneId?: SwimlaneId;
	};
};

export const cardDragSelectedTransition = (
	transitionId: TransitionId,
	toColumnId: ColumnId,
	toSwimlaneId?: SwimlaneId,
): CardDragSelectedTransitionAction => ({
	type: CARD_DRAG_SELECTED_TRANSITION,
	payload: {
		transitionId,
		toColumnId,
		toSwimlaneId,
	},
});

export const CARD_GET_ISSUE_TRANSITIONS = 'state.actions.card.CARD_GET_ISSUE_TRANSITIONS';

export type CardGetIssueTransitionsAction = {
	type: typeof CARD_GET_ISSUE_TRANSITIONS;
	payload: {
		issueId: IssueId;
	};
	meta: {
		analytics: UIAnalyticsEvent;
	};
};

export const cardGetIssueTransitions = (
	issueId: IssueId,
	analyticsEvent: UIAnalyticsEvent,
): CardGetIssueTransitionsAction => ({
	type: CARD_GET_ISSUE_TRANSITIONS,
	payload: {
		issueId,
	},
	meta: {
		analytics: analyticsEvent,
	},
});

export const CARD_GET_ISSUE_TRANSITIONS_FAILURE =
	'state.actions.card.CARD_GET_ISSUE_TRANSITIONS_FAILURE';

export type CardGetIssueTransitionsFailureAction = {
	type: typeof CARD_GET_ISSUE_TRANSITIONS_FAILURE;
	payload: {
		issueId: IssueId;
	};
};

export const cardGetIssueTransitionsFailure = (
	issueId: IssueId,
): CardGetIssueTransitionsFailureAction => ({
	type: CARD_GET_ISSUE_TRANSITIONS_FAILURE,
	payload: {
		issueId,
	},
});

export const CARD_SET_ISSUE_TRANSITIONS = 'state.actions.card.CARD_SET_ISSUE_TRANSITIONS';

export type CardSetIssueTransitionsAction = {
	type: typeof CARD_SET_ISSUE_TRANSITIONS;
	payload: {
		issueId: IssueId;
		columnTransitions: ColumnCardTransitions;
	};
};

export const cardSetIssueTransitions = (
	issueId: IssueId,
	columnTransitions: ColumnCardTransitions,
): CardSetIssueTransitionsAction => ({
	type: CARD_SET_ISSUE_TRANSITIONS,
	payload: {
		issueId,
		columnTransitions,
	},
});

export const CARD_FOCUS = 'state.actions.card.CARD_FOCUS';

export type CardFocusAction = {
	type: typeof CARD_FOCUS;
	payload: {
		issueId: IssueId;
	};
};

export const cardFocus = (issueId: IssueId): CardFocusAction => ({
	type: CARD_FOCUS,
	payload: {
		issueId,
	},
});

export const CARD_BLUR = 'state.actions.card.CARD_BLUR';

export type CardBlurAction = {
	type: typeof CARD_BLUR;
};

export const cardBlur = (): CardBlurAction => ({
	type: CARD_BLUR,
});

export const CARD_ADD_SHOW_RIPPLE = 'state.actions.card.CARD_ADD_SHOW_RIPPLE';

export type CardAddShowRippleAction = {
	type: typeof CARD_ADD_SHOW_RIPPLE;
	payload: {
		rippleIdsToShow: IssueId[];
	};
};

export const cardAddShowRipple = (issueIds: IssueId[]): CardAddShowRippleAction => ({
	type: CARD_ADD_SHOW_RIPPLE,
	payload: {
		rippleIdsToShow: issueIds,
	},
});

export const CARD_REMOVE_SHOW_RIPPLE = 'state.actions.card.CARD_REMOVE_SHOW_RIPPLE';

export type CardRemoveShowRippleAction = {
	type: typeof CARD_REMOVE_SHOW_RIPPLE;
	payload: {
		issueId: IssueId;
	};
};

export const cardRemoveShowRipple = (issueId: IssueId): CardRemoveShowRippleAction => ({
	type: CARD_REMOVE_SHOW_RIPPLE,
	payload: {
		issueId,
	},
});

type CardNavAction<T> = {
	type: T;
	payload: {
		fireShortcutAnalyticsFn: FireShortcutAnalyticsFn;
	};
};

export const CARD_NEXT = 'state.actions.card.CARD_NEXT';

export type CardNextAction = CardNavAction<typeof CARD_NEXT>;

export const cardNext = (fireShortcutAnalyticsFn: FireShortcutAnalyticsFn) => ({
	type: CARD_NEXT,
	payload: {
		fireShortcutAnalyticsFn,
	},
});

export const CARD_PREV = 'state.actions.card.CARD_PREV';

export type CardPrevAction = CardNavAction<typeof CARD_PREV>;

export const cardPrev = (fireShortcutAnalyticsFn: FireShortcutAnalyticsFn) => ({
	type: CARD_PREV,
	payload: {
		fireShortcutAnalyticsFn,
	},
});

export type CardRowNavigationAction = CardNextAction | CardPrevAction;

export const CARD_NEXT_COLUMN = 'state.actions.card.CARD_NEXT_COLUMN';

export type CardNextColumnAction = CardNavAction<typeof CARD_NEXT_COLUMN>;

export const cardNextColumn = (fireShortcutAnalyticsFn: FireShortcutAnalyticsFn) => ({
	type: CARD_NEXT_COLUMN,
	payload: {
		fireShortcutAnalyticsFn,
	},
});

export const CARD_PREV_COLUMN = 'state.actions.card.CARD_PREV_COLUMN';

export type CardPrevColumnAction = CardNavAction<typeof CARD_PREV_COLUMN>;

export const cardPrevColumn = (fireShortcutAnalyticsFn: FireShortcutAnalyticsFn) => ({
	type: CARD_PREV_COLUMN,
	payload: {
		fireShortcutAnalyticsFn,
	},
});

export type CardColumnNavigationAction = CardNextColumnAction | CardPrevColumnAction;

export const SET_ACTIVE_ISSUE_WITH_ICC = 'state.actions.card.SET_ACTIVE_ISSUE_WITH_ICC';

export type SetActiveIssueWithIccAction = {
	type: typeof SET_ACTIVE_ISSUE_WITH_ICC;
	payload: {
		issueId: IssueId | null;
	};
};

export const setActiveIssueWithIcc = (issueId: IssueId | null): SetActiveIssueWithIccAction => ({
	type: SET_ACTIVE_ISSUE_WITH_ICC,
	payload: {
		issueId,
	},
});

export const SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN =
	'state.actions.card.SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN';

export type SetIssueIdWithAboveICCOpen = {
	type: typeof SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN;
	payload: {
		issueId: IssueId | null | undefined;
		columnId: ColumnId | null;
	};
};

export const setIssueIdWithAboveICCOpen = (
	issueId: IssueId | null | undefined,
	columnId: ColumnId | null,
): SetIssueIdWithAboveICCOpen => ({
	type: SET_ISSUE_ID_WITH_ABOVE_ICC_OPEN,
	payload: {
		issueId,
		columnId,
	},
});

export const INITIATE_ICC_IN_UNSCHEDULED_COLUMN =
	'state.actions.card.INITIATE_ICC_IN_UNSCHEDULED_COLUMN';

export type InitiateIccInUnscheduledColumnAction = {
	type: typeof INITIATE_ICC_IN_UNSCHEDULED_COLUMN;
	payload: {
		swimlaneId: SwimlaneId;
		shouldToggleSwimlane: boolean;
	};
};

export const initiateIccInUnscheduledColumn = (
	swimlaneId: SwimlaneId,
	shouldToggleSwimlane: boolean,
): InitiateIccInUnscheduledColumnAction => ({
	type: INITIATE_ICC_IN_UNSCHEDULED_COLUMN,
	payload: {
		swimlaneId,
		shouldToggleSwimlane,
	},
});

export const OPEN_ICC_IN_UNSCHEDULED_COLUMN = 'state.actions.card.OPEN_ICC_IN_UNSCHEDULED_COLUMN';

export type OpenIccInUnscheduledColumnAction = {
	type: typeof OPEN_ICC_IN_UNSCHEDULED_COLUMN;
	payload: {
		swimlaneId: SwimlaneId;
	};
};

export const openIccInUnscheduledColumn = (
	swimlaneId: SwimlaneId,
): OpenIccInUnscheduledColumnAction => ({
	type: OPEN_ICC_IN_UNSCHEDULED_COLUMN,
	payload: {
		swimlaneId,
	},
});

export const CLOSE_ICC_IN_UNSCHEDULED_COLUMN = 'state.actions.card.CLOSE_ICC_IN_UNSCHEDULED_COLUMN';

export type CloseIccInUnscheduledColumnAction = {
	type: typeof CLOSE_ICC_IN_UNSCHEDULED_COLUMN;
};

export const closeIccInUnscheduledColumn = (): CloseIccInUnscheduledColumnAction => ({
	type: CLOSE_ICC_IN_UNSCHEDULED_COLUMN,
});

export const SET_INLINE_EDITING = 'state.actions.card.SET_INLINE_EDITING' as const;

export type CardInlineEditing = {
	type: typeof SET_INLINE_EDITING;
	payload: {
		field: keyof InlineEditing[number];
		issueId: IssueId;
		value: boolean;
	};
};

export const setInlineEditing = (
	field: keyof InlineEditing[number],
	issueId: IssueId,
	value: boolean,
): CardInlineEditing => ({
	type: SET_INLINE_EDITING,
	payload: {
		field,
		issueId,
		value,
	},
});

export type Action =
	| CardClickAction
	| CardToggleSelectionAction
	| CardSingleSelectAction
	| CardDataSetAction
	| CardMediaDataSetAction
	| CardRefreshRequestAction
	| CardDeleteAction
	| CardDeleteOptimisticAction
	| CardClearAction
	| CardClearSuccessAction
	| CardClearFailureAction
	| CardClearRevertAction
	| CardClearUndoAction
	| CardGetIssueTransitionsAction
	| CardGetIssueTransitionsFailureAction
	| CardSetIssueTransitionsAction
	| CardFocusAction
	| CardBlurAction
	| CardAddShowRippleAction
	| CardRemoveShowRippleAction
	| CardNextAction
	| CardPrevAction
	| CardNextColumnAction
	| CardPrevColumnAction
	| SetActiveIssueWithIccAction
	| SetIssueIdWithAboveICCOpen
	| InitiateIccInUnscheduledColumnAction
	| OpenIccInUnscheduledColumnAction
	| CloseIccInUnscheduledColumnAction
	| CardDragOverTransitionAction
	| CardDragOverTransitionRequestAction
	| CardDragSelectedTransitionAction
	| FilteredCardsAction
	| CardMoveAction
	| CardCancelSelectionAction
	| CardMultiSelectAction
	| CardInlineEditing
	| CardAutoScrollAction;
