export const getManageCustomFiltersUrl = (
	projectKey: string,
	boardId: string,
	isCompanyManaged: boolean,
	isUserBoard: boolean,
	isJSMBoard?: boolean,
	// For testing purposes

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	pathname = window.location.pathname,
): string => {
	if (isJSMBoard) {
		return `/jira/servicedesk/projects/${projectKey}/boards/${boardId}?config=quickFilters`;
	}

	if (isCompanyManaged) {
		if (isUserBoard) {
			return `${pathname}?config=quickFilters`;
		}

		return `/jira/software/c/projects/${projectKey}/boards/${boardId}${
			pathname.endsWith('backlog') ? '/backlog' : ''
		}?config=quickFilters`;
	}

	return `/jira/software/projects/${projectKey}/settings/boards/${boardId}/custom-filters`;
};
