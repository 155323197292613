import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { StatusTransition } from '../common/types.tsx';
import { ORIGINAL_STATUS_TRANSITION } from '../common/constant.tsx';
import type { PlanStatusIssue } from '../types.tsx';

const isGlobalSelfTransition = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.isGlobal && transition.to.id === currentStatusId;

const isInitialSelfTransition = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.isInitial && transition.to.id === currentStatusId;

const isLoopedTransitionToSelf = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.to.id === currentStatusId && transition.isLooped === true;

const filterLoopedTransitionsToSelf = (
	transitions: StatusTransition[],
	currentStatusId: string,
): StatusTransition[] =>
	transitions.filter(
		(transition) =>
			!isLoopedTransitionToSelf(transition, currentStatusId) &&
			!isGlobalSelfTransition(transition, currentStatusId) &&
			!isInitialSelfTransition(transition, currentStatusId),
	);

// transitions are already ordered by the back-end, so we just need to apply filters preserving the order
export const getFilteredOrderedTransitions = (
	transitions: StatusTransition[] = [],
	currentStatusId: string | undefined,
): StatusTransition[] => {
	if (currentStatusId) {
		// filter out the transition without target status or the statusCategory of target status is null and
		// filter out looped invalid transitions
		return filterLoopedTransitionsToSelf(
			transitions.filter((transition) => !!transition.to && !!transition.to.statusCategory) || [],
			currentStatusId,
		);
	}
	return transitions || [];
};

// The original status of issue A is 'Todo', and then change status to 'In progress', but this change is scenario
// if the user click the status field again, we should filter out 'In progress' and 'To do' from the options
export const removeTransitionsToCurrentAndOriginalStatus = (
	issue: PlanStatusIssue,
	serverTransitions: StatusTransition[],
	issueStatusesById: IssueStatusesById,
) => {
	const isCurrentIssueStatusExisted =
		isDefined(issue.statusId) && isDefined(issueStatusesById[issue.statusId]);
	const isOriginalIssueStatusExisted =
		isDefined(issue.originalStatusId) && isDefined(issueStatusesById[issue.originalStatusId]);

	if (isOriginalIssueStatusExisted || isCurrentIssueStatusExisted) {
		const result = serverTransitions.filter(
			(transition) =>
				transition.to.id !== issue.statusId && transition.to.id !== issue.originalStatusId,
		);
		return result;
	}
	return serverTransitions;
};
// In the ARJ, we store the status change in ARJ, commit to jira after user click the commit change,
// So if the user want to go back to the original status before commit change to jira,
// we should manually add the original issue status which has the uniq id ORIGINAL_STATUS_TRANSITION. so user can revert back to the original state
export const addOriginalTransitions = (
	issue: PlanStatusIssue,
	serverTransitions: StatusTransition[],
	issueStatusesById: IssueStatusesById,
	originalTransitionName: string,
): StatusTransition[] => {
	if (isDefined(issue.originalStatusId) && isDefined(issueStatusesById[issue.originalStatusId])) {
		const originalTransition = {
			id: ORIGINAL_STATUS_TRANSITION.id,
			isConditional: false,
			isGlobal: true, // set the isGloabal true, so it will use default global transition name(like 'transition to')
			isInitial: false,
			hasScreen: false,
			name: originalTransitionName,
			to: {
				id: issue.originalStatusId,
				name: issueStatusesById[issue.originalStatusId].name,
				statusCategory: {
					id: issueStatusesById[issue.originalStatusId].categoryId,
				},
			},
		};
		return serverTransitions.concat(originalTransition);
	}
	return serverTransitions;
};
