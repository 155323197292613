import React, {
	useState,
	useCallback,
	type RefObject,
	type ReactNode,
	type SyntheticEvent,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useIntl } from '@atlassian/jira-intl';
import { truncateUnscheduledWorkSwimlaneId } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/utils.tsx';
import { TEAM } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import type { SwimlaneByTeamValues } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import { IncrementPlanningSwimlaneHeaderContent } from '@atlassian/jira-portfolio-3-plan-increment-common/src/ui/swimlane-header-content/index.tsx';
import type { SwimlaneId } from '../../../../model/swimlane/swimlane-types.tsx';
import { useBoardSelector, useBoardDispatch } from '../../../../state/index.tsx';
import { swimlaneToggle } from '../../../../state/actions/swimlane/index.tsx';
import { platformSwimlaneSelector } from '../../../../state/selectors/work/work-selectors.tsx';
import { getIsSwimlaneCollapsed } from '../../../../state/selectors/swimlane/swimlane-selectors.tsx';
import { isIncrementPlanningReadOnly } from '../../../../state/selectors/board/board-permissions-selectors.tsx';
import SprintAssociationButton from '../swimlane/increment-planning-buttons/sprint-association-btn/index.tsx';
import CreateUnscheduledIssueButton from '../swimlane/increment-planning-buttons/create-unscheduled-issue-button/index.tsx';

export const IncrementPlanningSwimlane = ({
	id,
	innerRef,
	isUnscheduledWorkColumnPanel,
	top,
	children,
}: {
	id: SwimlaneId;
	innerRef: RefObject<HTMLDivElement>;
	isUnscheduledWorkColumnPanel: boolean;
	top: number;
	children: ReactNode;
}) => {
	const [isCreateButtonVisible, setIsCreateButtonVisible] = useState(false);

	const { formatMessage } = useIntl();
	const dispatch = useBoardDispatch();

	const showCreateButton = useCallback(() => {
		setIsCreateButtonVisible(true);
	}, [setIsCreateButtonVisible]);

	const hideCreateButton = useCallback(() => {
		setIsCreateButtonVisible(false);
	}, [setIsCreateButtonVisible]);

	const swimlaneId = truncateUnscheduledWorkSwimlaneId(id, isUnscheduledWorkColumnPanel);

	const { imageUrl, name, values, message } =
		useBoardSelector(platformSwimlaneSelector)(swimlaneId) || {};
	const isIncrementPlanningBoardReadOnly = useBoardSelector(isIncrementPlanningReadOnly);
	const collapsed = useBoardSelector(getIsSwimlaneCollapsed)(id);

	const getSprintAssociationButton = () =>
		!isUnscheduledWorkColumnPanel &&
		!isIncrementPlanningBoardReadOnly && <SprintAssociationButton teamId={swimlaneId} />;

	const getCustomRightContent = () => (
		<Box
			xcss={[
				SwimlaneRightContentContainerStyles,
				isCreateButtonVisible ? visibleButton : hiddenButton,
			]}
			testId="software-board.board-container.board.increment-planning-swimlane.create-unscheduled-issue-button"
		>
			<CreateUnscheduledIssueButton teamId={swimlaneId} />
		</Box>
	);

	const onToggle = useCallback(
		(event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			dispatch(swimlaneToggle(id));
			fireUIAnalytics(analyticsEvent, 'IPBoardSwimlaneBar');
		},
		[dispatch, id],
	);

	const swimlaneName = name || (message ? formatMessage(message) : '');
	const swimlaneValues: SwimlaneByTeamValues | undefined =
		values && values.type === TEAM ? values : undefined;

	const swimlane = (
		<IncrementPlanningSwimlaneHeaderContent
			id={swimlaneId}
			name={swimlaneName}
			collapsed={collapsed}
			onToggle={onToggle}
			isUnscheduledWorkColumnPanel={isUnscheduledWorkColumnPanel}
			values={swimlaneValues}
			iconImageUrl={imageUrl}
			innerRef={innerRef}
			customLeftContent={getSprintAssociationButton()}
			customRightContent={getCustomRightContent()}
			top={top}
		>
			{children}
		</IncrementPlanningSwimlaneHeaderContent>
	);

	return isUnscheduledWorkColumnPanel ? (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			onMouseEnter={showCreateButton}
			onMouseLeave={hideCreateButton}
			onFocus={showCreateButton}
			onBlur={hideCreateButton}
		>
			{swimlane}
		</div>
	) : (
		swimlane
	);
};

const visibleButton = xcss({
	opacity: 1,
});

const hiddenButton = xcss({
	opacity: 0,
});

const SwimlaneRightContentContainerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingRight: 'space.100',
});
