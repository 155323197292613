import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warning: {
		id: 'software.main.flags.warning',
		defaultMessage: 'Warning',
		description: '',
	},
	boardLoadedFailureTitle: {
		id: 'software.main.flags.board-loaded-failure-title',
		defaultMessage: 'Board data failed to load',
		description: '',
	},
	boardLoadedFailureDescription: {
		id: 'software.main.flags.board-loaded-failure-description',
		defaultMessage: 'Try refreshing the page, or contact your admin',
		description: '',
	},
	logBackInTitle: {
		id: 'software.main.flags.log-back-in-title',
		defaultMessage: 'Anyone there?',
		description: '',
	},
	logBackInAnchorText: {
		id: 'software.main.flags.log-back-in-anchor-text',
		defaultMessage: 'Log back in',
		description: '',
	},
	logBackInDescription: {
		id: 'software.main.flags.log-back-in-description',
		defaultMessage: "You've been inactive for a while, so we've logged you out.",
		description: '',
	},
	genericTitle: {
		id: 'software.main.flags.generic-title',
		defaultMessage: "Something's gone wrong",
		description: '',
	},
	genericDescription: {
		id: 'software.main.flags.generic-description',
		defaultMessage: 'An error occurred on our end. Refresh the page and try again',
		description: '',
	},
	warningLabel: {
		id: 'software.main.flags.warning-label',
		defaultMessage: 'An error occurred',
		description: '',
	},
	errorLabel: {
		id: 'software.main.flags.error-label',
		defaultMessage: 'An error occurred',
		description: '',
	},
	failureDescription: {
		id: 'software.main.flags.failure-description',
		defaultMessage: '{error}',
		description: '',
	},
	columnRemoveDoneFlagTitle: {
		id: 'software.main.flags.column-remove-done-flag-title',
		defaultMessage: 'Your board has no completion columns',
		description: '',
	},
	columnRemoveDoneFlagDescription: {
		id: 'software.main.flags.column-remove-done-flag-description',
		defaultMessage:
			"Issues on this board can't be treated as done until you set a completion column.",
		description: '',
	},
	columnRemoveDoneFlagDescriptionIssueTermRefresh: {
		id: 'software.main.flags.column-remove-done-flag-description-issue-term-refresh',
		defaultMessage:
			"Work items on this board can't be treated as done until you set a completion column.",
		description: '',
	},
	createdIssueIsFilteredTitle: {
		id: 'software.main.flags.created-issue-is-filtered-title',
		defaultMessage: '{issueKey} has been created.',
		description: '',
	},
	createdIssueIsFilteredDescription: {
		id: 'software.main.flags.created-issue-is-filtered-description',
		defaultMessage: "It's hidden by your current filter.",
		description: '',
	},
	createdIssueIsFilteredDescriptionIssueTermRefresh: {
		id: 'software.main.flags.created-issue-is-filtered-description-issue-term-refresh',
		defaultMessage: "It's hidden by your current filter.",
		description: '',
	},
	createdIssueIsFilteredLink: {
		id: 'software.main.flags.created-issue-is-filtered-link',
		defaultMessage: 'View issue',
		description: '',
	},
	createdIssueIsFilteredLinkIssueTermRefresh: {
		id: 'software.main.flags.created-issue-is-filtered-link-issue-term-refresh',
		defaultMessage: 'View work item',
		description: '',
	},
	issueRankFailTitle: {
		id: 'software.main.flags.issue-rank-fail-title',
		defaultMessage: "We couldn't update the issue",
		description: 'Title for the error message shown when either a transtion or a rank fails',
	},
	issueRankFailTitleIssueTermRefresh: {
		id: 'software.main.flags.issue-rank-fail-title-issue-term-refresh',
		defaultMessage: "We couldn't update the work item",
		description: 'Title for the error message shown when either a transtion or a rank fails',
	},
	issueRankFailDescription: {
		id: 'software.main.flags.issue-rank-fail-description',
		defaultMessage:
			'We failed to reorder the issue or your admin has restricted how you can move it. Try moving the issue again or move it to a different drop zone.',
		description: 'Error message shown when either a transtion or a rank fails',
	},
	issueRankFailDescriptionIssueTermRefresh: {
		id: 'software.main.flags.issue-rank-fail-description-issue-term-refresh',
		defaultMessage:
			"We couldn't reorder the work item. Try moving it again or move it to a different drop zone. Your admin may have restricted how you can move it.",
		description: 'Error message shown when either a transtion or a rank fails',
	},
	issueRankFailFieldRequiredTitle: {
		id: 'software.main.flags.issue-rank-fail-field-required-title',
		defaultMessage: "Issue {issueKey} couldn't be moved.",
		description:
			'Title for the warning message shown when transition or rank fails due to field required',
	},
	issueRankFailFieldRequiredTitleIssueTermRefresh: {
		id: 'software.main.flags.issue-rank-fail-field-required-title-issue-term-refresh',
		defaultMessage: "Work item {issueKey} couldn't be moved.",
		description:
			'Title for the warning message shown when transition or rank fails due to field required',
	},
	issueRankFailFieldRequiredDescription: {
		id: 'software.main.flags.issue-rank-fail-field-required-description',
		defaultMessage: '{errorMessage}',
		description: 'Warning message shown when transition or rank fails due to field required',
	},
	bulkIssueRankFailTitle: {
		id: 'software.main.flags.bulk-issue-rank-fail-title',
		defaultMessage: "Issue {issueKey} couldn't be moved.",
		description: 'Title for the warning message shown when rank fails for multiple issues',
	},
	bulkIssueRankFailTitleIssueTermRefresh: {
		id: 'software.main.flags.bulk-issue-rank-fail-title-issue-term-refresh',
		defaultMessage: "Work item {issueKey} couldn't be moved.",
		description: 'Title for the warning message shown when rank fails for multiple issues',
	},
	devStatusFetchFailedTitle: {
		id: 'software.main.flags.dev-status-fetch-failed-title',
		defaultMessage: "Something's gone wrong",
		description: '',
	},
	devStatusFetchFailedDescription: {
		id: 'software.main.flags.dev-status-fetch-failed-description',
		defaultMessage: "Your cards' development statuses have failed to update. We'll keep trying.",
		description: '',
	},
	boardDeferredDataFetchFailedTitle: {
		id: 'software.main.flags.board-deferred-data-fetch-failed-title',
		defaultMessage: "Something's gone wrong",
		description: '',
	},
	boardDeferredDataFetchFailedDescription: {
		id: 'software.main.flags.board-deferred-data-fetch-failed-description',
		defaultMessage: "We couldn't load some of your board data. Refresh the page and try again.",
		description:
			'Error message that shows when fetch for non-critical board data fails (sprint permissions, JQL swimlanes, etc.)',
	},
	issueLabelsSaveFailed: {
		id: 'software.main.flags.issue-labels-save-failed',
		defaultMessage: "We couldn't update some of your issues. Try again.",
		description: '',
	},
	issueLabelsSaveFailedIssueTermRefresh: {
		id: 'software.main.flags.issue-labels-save-failed-issue-term-refresh',
		defaultMessage: "We couldn't update some of your work items. Try again.",
		description: '',
	},
	newPeopleAddedSuccessTitle: {
		id: 'software.main.flags.new-people-added-success-title',
		defaultMessage: 'New people added',
		description: '',
	},
	noColumnFailureTitle: {
		id: 'software.main.flags.no-column-failure-title',
		defaultMessage: 'Boards must have at least one column',
		description: 'Error Flag title for boards without any column',
	},
	noColumnFailureAdminDescription: {
		id: 'software.main.flags.no-column-failure-admin-description',
		defaultMessage: 'Add at least one column to continue.',
		description: 'Flag content for admin',
	},
	noColumnFailureNonAdminDescription: {
		id: 'software.main.flags.no-column-failure-non-admin-description',
		defaultMessage: 'Your project admin must add a column to continue.',
		description: 'Flag content for non-admin',
	},
	manageStatusesLink: {
		id: 'software.main.flags.manage-statuses-link',
		defaultMessage: 'Manage columns and statuses',
		description: 'Link to column status mapping page',
	},
	deleteFailureTitle: {
		id: 'software.main.flags.delete-failure-title',
		defaultMessage: 'Failed to delete issue',
		description: 'Title of flag shown when there is an error deleting an issue on a board',
	},
	deleteFailureTitleIssueTermRefresh: {
		id: 'software.main.flags.delete-failure-title-issue-term-refresh',
		defaultMessage: "We couldn't delete the work item",
		description: 'Title of flag shown when there is an error deleting an issue on a board',
	},
	teamSwimlaneMoveInvalidTitle: {
		id: 'software.main.flags.team-swimlane-move-invalid-title',
		defaultMessage: 'Couldn’t assign issue to {teamName}',
		description: 'Title of flag shown when move an issue to external team',
	},
	teamSwimlaneMoveInvalidTitleIssueTermRefresh: {
		id: 'software.main.flags.team-swimlane-move-invalid-title-issue-term-refresh',
		defaultMessage: 'Couldn’t assign work item to {teamName}',
		description: 'Title of flag shown when move an issue to external team',
	},
	teamSwimlaneMoveInvalidMessage: {
		id: 'software.main.flags.team-swimlane-move-invalid-message',
		defaultMessage: 'Issues can only be assigned to teams that are part of your plan.',
		description: 'Message of flag shown when move an issue to external team',
	},
	teamSwimlaneMoveInvalidMessageIssueTermRefresh: {
		id: 'software.main.flags.team-swimlane-move-invalid-message-issue-term-refresh',
		defaultMessage: 'Work items can only be assigned to teams that are part of your plan.',
		description: 'Message of flag shown when move an issue to external team',
	},
	swimlaneColumnClosedSprintInvalidMoveTitle: {
		id: 'software.main.flags.swimlane-column-closed-sprint-invalid-move-title',
		defaultMessage: 'Couldn’t assign issue to {sprintName}',
		description:
			'Title of flag shown when moving an issue to a swimlane column which is associated to a closed sprint',
	},
	swimlaneColumnClosedSprintInvalidMoveTitleIssueTermRefresh: {
		id: 'software.main.flags.swimlane-column-closed-sprint-invalid-move-title-issue-term-refresh',
		defaultMessage: 'Couldn’t assign work item to {sprintName}',
		description:
			'Title of flag shown when moving an issue to a swimlane column which is associated to a closed sprint',
	},
	swimlaneColumnClosedSprintInvalidMoveMessage: {
		id: 'software.main.flags.swimlane-column-closed-sprint-invalid-move-message',
		defaultMessage: 'Issues can’t be assigned to a closed sprint.',
		description:
			'Message of flag shown when moving an issue to a swimlane column which is associated to a closed sprint',
	},
	swimlaneColumnClosedSprintInvalidMoveMessageIssueTermRefresh: {
		id: 'software.main.flags.swimlane-column-closed-sprint-invalid-move-message-issue-term-refresh',
		defaultMessage: 'Work items can’t be assigned to a closed sprint.',
		description:
			'Message of flag shown when moving an issue to a swimlane column which is associated to a closed sprint',
	},
	cardMoveFailureInIpBoardTitleNoTranslate: {
		id: 'software.main.flags.card-move-failure-in-ip-board-title-no-translate',
		defaultMessage: "We can't update the issue",
		description: 'Title of flag shown when move an issue to external team',
	},
	cardMoveFailureInIpBoardTitleNoTranslateIssueTermRefresh: {
		id: 'software.main.flags.card-move-failure-in-ip-board-title-no-translate-issue-term-refresh',
		defaultMessage: "We can't update the work item",
		description: 'Title of flag shown when move an issue to external team',
	},
	cardMoveFailureInIpBoardMessageNoTranslate: {
		id: 'software.main.flags.card-move-failure-in-ip-board-message-no-translate',
		defaultMessage:
			'We failed to reorder the issue. Try moving the issue again or move it to a different drop zone.',
		description: 'Message of flag shown when fail to move the issue in the IP board',
	},
	cardMoveFailureInIpBoardMessageNoTranslateIssueTermRefresh: {
		id: 'software.main.flags.card-move-failure-in-ip-board-message-no-translate-issue-term-refresh',
		defaultMessage:
			"We couldn't reorder the issue. Try moving it again, or move it to a different drop zone.",
		description: 'Message of flag shown when fail to move the issue in the IP board',
	},
	customFieldOptionMissedTitle: {
		id: 'software.main.flags.custom-field-option-missed-title',
		defaultMessage: 'Issue couldn’t be created',
		description:
			'Title of the flag when the custom field option is not applicable for the issue creation',
	},
	customFieldOptionMissedTitleIssueTermRefresh: {
		id: 'software.main.flags.custom-field-option-missed-title-issue-term-refresh',
		defaultMessage: 'Work item couldn’t be created',
		description:
			'Title of the flag when the custom field option is not applicable for the issue creation',
	},
	customFieldOptionMissedDescription: {
		id: 'software.main.flags.custom-field-option-missed-description',
		defaultMessage:
			'{fieldValue} can’t be applied to {issueTypeName} type issues in {projectName}. Your admin needs to add the {fieldValue} option to the {fieldName} custom field.',
		description:
			'Description of the flag when the custom field option is not applicable for the issue creation',
	},
	customFieldOptionMissedDescriptionIssueTermRefresh: {
		id: 'software.main.flags.custom-field-option-missed-description-issue-term-refresh',
		defaultMessage:
			'{fieldValue} can’t be applied to {issueTypeName} type work items in {projectName}. Your admin needs to add the {fieldValue} option to the {fieldName} custom field.',
		description:
			'Description of the flag when the custom field option is not applicable for the issue creation',
	},
	reachDependencyLineLimitTitle: {
		id: 'software.main.flags.reach-dependency-line-limit-title',
		defaultMessage:
			'You can only show dependencies for {limit, plural, one {# issue} other {# issues}} at one time',
		description: 'Description of the flag when reach the limit of the dependency lines',
	},
	reachDependencyLineLimitTitleIssueTermRefresh: {
		id: 'software.main.flags.reach-dependency-line-limit-title-issue-term-refresh',
		defaultMessage:
			'You can only show dependencies for {limit, plural, one {# work item} other {# work items}} at one time',
		description: 'Description of the flag when reach the limit of the dependency lines',
	},
});
