/**
 * @generated SignedSource<<502aa471f5d0893ece5715d9f8b2453a>>
 * @relayHash 96311ac8cd1b2e15ed7072740a91fd80
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 24e8667d902ba1fdadfcd6a7137edc6ca942d8be00bc0084996320ad25442808

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleLineTextFieldInput = {
  id: string;
  operation: JiraSingleLineTextFieldOperationInput;
};
export type JiraSingleLineTextFieldOperationInput = {
  operation: JiraSingleValueFieldOperations;
  text?: string | null | undefined;
};
export type useSaveSummaryFieldMutation$variables = {
  input: JiraUpdateSingleLineTextFieldInput;
};
export type useSaveSummaryFieldMutation$data = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSaveSummaryFieldMutation$rawResponse = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldConfig: {
          readonly isRequired: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly id: string;
        readonly isEditableInIssueView: boolean | null | undefined;
        readonly name: string;
        readonly text: string | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSaveSummaryFieldMutation = {
  rawResponse: useSaveSummaryFieldMutation$rawResponse;
  response: useSaveSummaryFieldMutation$data;
  variables: useSaveSummaryFieldMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useSaveSummaryFieldMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleLineTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleLineTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSaveSummaryFieldMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleLineTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleLineTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isEditableInIssueView"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "text"
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isRequired"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "24e8667d902ba1fdadfcd6a7137edc6ca942d8be00bc0084996320ad25442808",
    "metadata": {},
    "name": "useSaveSummaryFieldMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d7d58a3dc9f48f40fffb61682948b25d";

export default node;
