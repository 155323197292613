import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/observable/defer';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import get from 'lodash/get';
import isError from 'lodash/isError';
import { Observable, type Observable as ObservableType } from 'rxjs/Observable';
import { v4 as uuid } from 'uuid';
import { metrics } from '@atlassian/browser-metrics';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { TRACE_ID_HEADER } from '@atlassian/jira-fetch/src/utils/constants.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import type { RawSwagError } from '../../common/types.tsx';
import { SwagError } from '../../common/utils/error/index.tsx';
import {
	logRestError,
	logRestNetworkError,
	logRestParsingError,
	logRestRetry,
} from '../log-error/index.tsx';

type Response<Data> = {
	data: Data;
	errors: RawSwagError[];
};

export type Variables = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export type ConcurrentMetricDefinition = (concurrentId: string) => {
	start: () => void;
	stop: () => void;
};

export type MetricOptions = {
	analyticKey: string;
	/**
	 * @deprecated use concurrentMetricDefinition
	 */
	slo?: number;
	/**
	 * @deprecated use concurrentMetricDefinition
	 */
	useBm3Interaction?: boolean;
	concurrentMetricDefinition?: ConcurrentMetricDefinition | undefined;
};

const sendOperationalEvent = (analyticKey: string, statusCode: string | number | null): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();

		// SSR doesn't have an analytics client
		if (!analyticsClient) {
			return;
		}

		let statusCodeFamily = 'unknown';
		if (statusCode !== null) {
			const statusCodeAsStr = String(statusCode);
			if (statusCodeAsStr === '0' || statusCodeAsStr === '429') {
				// special status codes
				statusCodeFamily = statusCodeAsStr;
			} else if (statusCodeAsStr.match(/^[12345][0-9]{2}$/)) {
				statusCodeFamily = `${statusCodeAsStr[0]}xx`;
			}
		}

		analyticsClient.sendOperationalEvent({
			action: 'fetch',
			actionSubject: 'swag operation',
			attributes: {
				key: analyticKey,
				statusCode,
				statusCodeFamily,
			},
			source: 'swagOperation',
		});
	});
};

const onErrorSendOpEventAndCancelMark = (
	analyticKey: string | null | undefined,
	slo: number | null | undefined,
	statusCode: string | number | null,
	concurrentId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	bm3Interaction: any,
): void => {
	if (analyticKey !== undefined) {
		// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
		sendOperationalEvent(analyticKey, statusCode);

		if (bm3Interaction) {
			bm3Interaction.cancel();
		}
	}
};

const callSwag$ = ({
	baseUrl,
	operationName,
	query,
	variables,
	action,
	headersProcessor,
}: {
	baseUrl: string;
	operationName: string;
	query: string;
	variables?: Variables;
	action: 'query' | 'mutation';
	headersProcessor: (arg1: Headers) => void;
}) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let headers: Record<string, any> = {};
	// set empty xsrfToken for SSR as cookies are unavailable
	const xsrfToken = __SERVER__ ? '' : getXsrfToken();

	if (action === 'mutation') {
		if (!xsrfToken) {
			log.safeErrorWithoutCustomerData(
				'board.xsrf-token.load.failure',
				'XSRF Token failed to load',
			);
		}

		headers = {
			'atl-xsrf-token': xsrfToken,
		};
	}

	return fetchJson$(`${baseUrl}/jsw2/graphql?operation=${operationName}`, {
		method: 'POST',
		body: JSON.stringify({
			operationName,
			query,
			variables,
		}),
		headers,
		headersProcessor,
	});
};

export const operation$ = <Data,>(
	action: 'query' | 'mutation',
	baseUrl: string,
	operationName: string,
	query: string,
	variables?: Variables,
	analyticOptions?: MetricOptions,
): ObservableType<Data> => {
	const { analyticKey, slo, useBm3Interaction, concurrentMetricDefinition } = analyticOptions || {};

	let traceId = '';
	const headersProcessor = (headers: Headers) => {
		traceId = headers.get(TRACE_ID_HEADER) || '';
	};

	const concurrentId = uuid();

	let bm3Interaction: ReturnType<typeof metrics.interaction> | null = null;
	if (analyticKey && useBm3Interaction) {
		bm3Interaction = metrics.interaction({
			key: analyticKey,
			// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string; }' but required in type 'InteractionHistogramConfig'.
			histogram: {
				result: '1000_2000_3000_4000_5000_6000',
			},
		});
	}

	return (
		Observable.defer(() => {
			if (bm3Interaction) {
				bm3Interaction.start();
			}
			if (concurrentMetricDefinition !== undefined) {
				concurrentMetricDefinition(concurrentId).start();
			}
			return callSwag$({
				baseUrl,
				operationName,
				query,
				variables,
				action,
				headersProcessor,
			});
		})
			.catch((error) => {
				const statusCode = get(error, ['statusCode'], null);
				const isRetryStatusCode = [502, 503, 504].includes(Number(statusCode));

				if (isRetryStatusCode && action === 'query') {
					return callSwag$({
						baseUrl,
						operationName,
						query,
						variables,
						action,
						headersProcessor,
					}).do(
						() => {
							logRestRetry({
								queryName: operationName,
								statusCode,
								isRetrySuccessful: true,
								retryStatusCode: null,
							});
						},
						(retryError) => {
							const retryStatusCode = get(retryError, ['statusCode'], null);

							logRestRetry({
								queryName: operationName,
								statusCode,
								isRetrySuccessful: false,
								retryStatusCode,
							});
						},
					);
				}

				// throw exception to the next level
				return Observable.throw(error);
			})
			// catch network error
			.catch((error) => {
				const e = isError(error) ? error.message : `[unknown error] = ${JSON.stringify(error)}`;
				const statusCode = get(error, ['statusCode'], null);
				const errorName = get(error, ['name'], null);
				const swagError = new SwagError({ networkError: error, traceId });
				const hash = getErrorHash(swagError);

				logRestNetworkError(e, statusCode, operationName, errorName, hash);
				onErrorSendOpEventAndCancelMark(analyticKey, slo, statusCode, concurrentId, bm3Interaction);

				return Observable.throw(swagError);
			})
			.flatMap((response: Response<Data>) => {
				if (!response || !response.data) {
					logRestParsingError('empty response.data', operationName);
				}

				try {
					// SWAG errors from response
					if (response.errors) {
						if (response.errors.length > 100) {
							const genericMessage = `Too many Errors in response for ${operationName}`;
							logRestError([genericMessage], null, operationName, 'too-many-errors');
							onErrorSendOpEventAndCancelMark(analyticKey, slo, null, concurrentId, bm3Interaction);
							return Observable.throw(
								new SwagError({
									graphQLErrors: [
										{
											message: genericMessage,
											path: [genericMessage],
											extensions: {
												statusCode: null,
												errorType: 'too-many-errors',
											},
										},
									],
									traceId,
								}),
							);
						}
						const payload = response.errors.map((e: RawSwagError) => {
							const { path, message } = e;
							const statusCode = get(e, ['extensions', 'statusCode'], null);
							const errorType = get(e, ['extensions', 'errorType'], undefined);
							const userMessage = get(e, ['extensions', 'userMessage'], undefined);

							logRestError(path, statusCode, operationName, errorType);
							onErrorSendOpEventAndCancelMark(
								analyticKey,
								slo,
								statusCode,
								concurrentId,
								bm3Interaction,
							);

							return {
								message,
								path,
								extensions: {
									statusCode: statusCode ? Number(statusCode) : null,
									errorType,
									userMessage,
								},
							};
						});
						return Observable.throw(new SwagError({ graphQLErrors: payload, traceId }));
					}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					const e = isError(error) ? error.message : `[unknown error] = ${JSON.stringify(error)}`;
					logRestParsingError(e, operationName);
					onErrorSendOpEventAndCancelMark(
						analyticKey,
						slo,
						'unknown',
						concurrentId,
						bm3Interaction,
					);

					throw error;
				}

				if (analyticKey !== undefined) {
					// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Response<Data>'.
					sendOperationalEvent(analyticKey, response.statusCode ?? '200');
					if (bm3Interaction) {
						bm3Interaction.stop();
					}
					if (concurrentMetricDefinition !== undefined) {
						concurrentMetricDefinition(concurrentId).stop();
					}
				}

				return Observable.of(response.data);
			})
	);
};
