import { useEffect, useCallback, useMemo, useState } from 'react';
import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { getGraphQlUrl } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-graphql-data/index.tsx';
import { IssueViewFetchOperations } from '@atlassian/jira-issue-fetch-services-common/src/types.tsx';
import {
	sendExperienceAnalytics,
	type ExperienceDescription,
} from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { useIsMounted } from '@atlassian/jira-platform-react-hooks-use-is-mounted/src/common/utils/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useFieldConfigStore,
	useFieldConfigStoreActions,
	useIssueFieldConfigStore,
	useFieldOverridesStore,
	useIssueOverridesStore,
} from './context.tsx';
import type {
	FieldConfigHook,
	IssueConfiguration,
	IssueFieldConfigHook,
	IssueFieldConfigWithoutRefetchHook,
	FieldConfigurationWithOptionalKeys,
	FieldConfigHookWithoutRefetch,
	IssueFieldConfigServiceOptions,
	FieldConfigurationExperienceDescription,
	FieldConfigurationExperienceTrackingEvent,
} from './types.tsx';

/*
This query will return attributes for all the fields that meet the following criteria:
 	- Fields that are available for the context of the issue (i.e. project & issue type)
 	- Fields that are present on the VIEW issue screen for the context of the issue ("always visible" system fields exempt)

"Always visible" system fields are fields that are not necessarily present on the screen (i.e they have been manally removed from the screen configuration), 
but should be considered as present if they're associated to the context. Examples of "always visible" system fields include:
	- assignee
	- reporter
	- summary
	- status
	- issueType

Please note that such fields that have been removed from the configuration may have unreliable data returned.
The query points to JiraIssueFieldsDataFetcher and JisVisibilityService in the backend
 */
export const fieldConfigurationQuery = () => `
  query fieldConfigurationQuery($issueKey: String) {
    issue(issueIdOrKey: $issueKey, latestVersion: true, screen: "view") {
      fields {
        key
        schema {
          type
          custom
          customId
          system
          renderer
        }
        autoCompleteUrl
        allowedValues
        operations
        required
        editable
        title
        description
      }
    }
  }`;

const fieldConfigurationQueryExperienceDescription = ({
	wasExperienceSuccessful,
	errorMessage,
	traceId,
}: FieldConfigurationExperienceDescription): ExperienceDescription => {
	const errorMessageItem: { errorMessage: string } | {} =
		errorMessage != null ? { errorMessage } : {};
	const traceIdItem: { traceId: string } | {} = traceId != null ? { traceId } : {};

	return {
		experience: IssueViewFetchOperations.FIELD_CONFIGURATION,
		wasExperienceSuccesful: wasExperienceSuccessful,
		analyticsSource: 'Issue View Field Configuration Query',
		additionalAttributes: { method: 'GET', ...errorMessageItem, ...traceIdItem },
		application: null,
		edition: null,
	};
};

const fieldConfigurationQueryExperienceTracking = ({
	wasExperienceSuccessful,
	error,
}: FieldConfigurationExperienceTrackingEvent) => {
	sendExperienceAnalytics(
		fieldConfigurationQueryExperienceDescription({
			wasExperienceSuccessful,
			...(error?.message != null ? { errorMessage: error.message } : {}),
			...(error instanceof FetchError && error.traceId != null ? { traceId: error.traceId } : {}),
		}),
	);
};

const fetchData = async (issueKey: IssueKey): Promise<IssueConfiguration | null> => {
	try {
		const response = await performPostRequest(
			getGraphQlUrl('', IssueViewFetchOperations.FIELD_CONFIGURATION),
			{
				body: JSON.stringify({
					query: fieldConfigurationQuery(),
					variables: { issueKey },
				}),
			},
		);

		if (response?.data?.issue?.fields) {
			const fieldConfigData = response.data.issue.fields.reduce(
				// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type. | TS7006 - Parameter 'field' implicitly has an 'any' type.
				(result, field) => ({
					// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
					...result,
					[field.key]: {
						autoCompleteUrl: field.autoCompleteUrl,
						allowedValues: field.allowedValues,
						isEditable: field.editable,
						isRequired: field.required,
						schema: field.schema,
						title: field.title,
						...(field.description ? { description: field.description } : {}),
					},
				}),
				{},
			);
			fieldConfigurationQueryExperienceTracking({
				wasExperienceSuccessful: true,
			});
			return fieldConfigData;
		}
		fieldConfigurationQueryExperienceTracking({
			wasExperienceSuccessful: false,
			error: new Error('Unexpected field configuration response'),
		});
		return null;
	} catch (error) {
		fieldConfigurationQueryExperienceTracking({
			wasExperienceSuccessful: false,
			error: error instanceof FetchError ? error : undefined,
		});
		return null;
	}
};

const requestSet: Set<IssueKey> = new Set();

const issueFieldConfigServiceDefaultOptions = {
	skipRefresh: false,
};

const useIssueFieldConfigRefetch = (
	issueKey: IssueKey,
	value: unknown,
	{ skipRefresh }: IssueFieldConfigServiceOptions = issueFieldConfigServiceDefaultOptions,
) => {
	const isMounted = useIsMounted();
	const [, { setIssueConfig }] = useFieldConfigStoreActions();
	const [loading, setLoading] = useState(
		() => !skipRefresh && value === undefined && !requestSet.has(issueKey),
	);

	const getData = useCallback(async () => {
		setLoading(true);
		requestSet.add(issueKey);
		const result = await fetchData(issueKey);
		setIssueConfig(issueKey, result);
		requestSet.delete(issueKey);
		if (isMounted.current) {
			setLoading(false);
		}
	}, [isMounted, issueKey, setIssueConfig]);

	useEffect(() => {
		const shouldGetData = fg('jiv-19312-fix-fieldconfigurationquery-refetch')
			? !skipRefresh && !requestSet.has(issueKey) && value === undefined && issueKey !== ''
			: !skipRefresh && !requestSet.has(issueKey) && value === undefined;
		if (shouldGetData) {
			getData();
		}
	}, [issueKey, value, getData, skipRefresh]);

	return [{ loading }] as const;
};

const useIssueFieldConfigStoreWithUimOverrides = ({ issueKey }: { issueKey: IssueKey }) => {
	const [value] = useIssueFieldConfigStore({ issueKey });
	const [overrides] = useIssueOverridesStore({ issueKey });

	return useMemo(() => {
		if (
			// When fields configurations are not valid, we don't want overrides
			!isNil(value) &&
			// We should not override if UI modifications is not available in general or for the field
			overrides !== undefined
		) {
			// Iteration over the base fields (`value`) prevents creation of partial configs with just UIM overrides.
			const issueWithOverrides = Object.entries(value).reduce<IssueConfiguration>(
				(acc, [fieldKey, fieldValues]) => {
					acc[fieldKey] = { ...fieldValues, ...overrides[fieldKey] };

					return acc;
				},
				{},
			);

			return [issueWithOverrides];
		}

		return [value];
	}, [value, overrides]);
};

const useFieldConfigStoreWithUimOverrides = ({
	issueKey,
	fieldKey,
}: {
	issueKey: IssueKey;
	fieldKey: string;
}) => {
	const [value] = useFieldConfigStore({ issueKey, fieldKey });
	const [overrides] = useFieldOverridesStore({ issueKey, fieldKey });

	if (
		// When fields configurations are not valid, we don't want overrides
		!isNil(value) &&
		// We should not override if UI modifications is not available in general or for the field
		overrides !== undefined
	) {
		return [{ ...value, ...overrides }];
	}

	return [value];
};

export const useIssueFieldConfig = (issueKey: IssueKey): IssueFieldConfigHook => {
	const [value] = useIssueFieldConfigStoreWithUimOverrides({ issueKey });
	const [{ loading }] = useIssueFieldConfigRefetch(issueKey, value);

	return [{ value, loading }];
};

export const useIssueFieldConfigWithoutRefetch = (
	issueKey: IssueKey,
): IssueFieldConfigWithoutRefetchHook => {
	const [value] = useIssueFieldConfigStoreWithUimOverrides({ issueKey });

	return [{ value }];
};

/**
 * Use useFieldConfig one unless you have good reason to bypass UI modifications (UIM).
 *
 * UI modifications is a Forge extension point providing apps JS API to override field's properties: name, description, isEditable.
 * Hence it's important to take useFieldConfig into new usages to not introduce a gap in UI modifications flow.
 * This function is required for UIM itself to get initial issue config without overrides to avoid circular loop.
 * If in doubt, please contact Unbox team members.
 */
export const useIssueFieldConfigWithoutUimOverrides = ({ issueKey }: { issueKey: IssueKey }) => {
	const [value] = useIssueFieldConfigStore({ issueKey });
	const [{ loading }] = useIssueFieldConfigRefetch(issueKey, value);

	return [{ value, loading }];
};

export const useFieldConfig = (
	issueKey: IssueKey,
	fieldKey: string,
	options: IssueFieldConfigServiceOptions = issueFieldConfigServiceDefaultOptions,
): FieldConfigHook => {
	const [value] = useFieldConfigStoreWithUimOverrides({ issueKey, fieldKey });
	const [{ loading }] = useIssueFieldConfigRefetch(issueKey, value, options);

	return [{ value, loading }];
};

export const useFieldConfigWithoutRefetch = (
	issueKey: IssueKey,
	fieldKey: string,
): FieldConfigHookWithoutRefetch => {
	const [value] = useFieldConfigStoreWithUimOverrides({ issueKey, fieldKey });

	return [{ value }];
};

export const useFieldConfigActions = () => {
	const [, setIssueConfigActions] = useFieldConfigStoreActions();

	const actions = useMemo(() => {
		const setIssueConfig = (issueKey: IssueKey, issueConfig: IssueConfiguration | null) =>
			setIssueConfigActions.setIssueConfig(issueKey, issueConfig);

		const setIssuesConfig = (issuesWithConfigurations: Record<IssueKey, IssueConfiguration>[]) =>
			setIssueConfigActions.setIssuesConfig(issuesWithConfigurations);

		const mergeIssueConfig = (issueKey: IssueKey, issueConfig: IssueConfiguration | null) =>
			setIssueConfigActions.mergeIssueConfig(issueKey, issueConfig);

		const mergeIssuesConfig = (issuesWithConfigurations: Record<IssueKey, IssueConfiguration>[]) =>
			setIssueConfigActions.mergeIssuesConfig(issuesWithConfigurations);

		const setFieldConfigValue = (
			issueKey: IssueKey,
			fieldKey: string,
			fieldConfigValue: FieldConfigurationWithOptionalKeys<unknown>,
		) => setIssueConfigActions.setFieldConfigValue(issueKey, fieldKey, fieldConfigValue);

		return {
			setIssueConfig,
			setIssuesConfig,
			mergeIssueConfig,
			mergeIssuesConfig,
			setFieldConfigValue,
		};
	}, [setIssueConfigActions]);

	return [null, actions] as const;
};
