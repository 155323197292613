import React from 'react';
import { Section } from '@atlaskit/menu';
import { fg } from '@atlassian/jira-feature-gating';
import { BoardConnectMenuItems } from '@atlassian/jira-software-connect-toolbar/src/ui/board-connect-menu-items/index.tsx';
import { useIsCMPBoard, useIsJSMBoard } from '../../../../state/state-hooks/capabilities/index.tsx';
import Operations from '../../extension/index.tsx';

type BoardConnectItemsProps = {
	projectKey: string;
	boardId: string;
	onClickWebItem?: () => void;
	isTabNavigation?: boolean;
};

export const BoardConnectItems = ({
	projectKey,
	boardId,
	onClickWebItem,
	isTabNavigation = false,
}: BoardConnectItemsProps) => {
	const isCMPBoard = useIsCMPBoard();
	const isJSMBoard = useIsJSMBoard();

	const onClick = fg('make_board_menu_popup') ? onClickWebItem : undefined;

	return (
		<Section
			hasSeparator={
				(!isJSMBoard && fg('jsm_views_connect_apps_bugfix')) || !fg('jsm_views_connect_apps_bugfix')
			}
		>
			{isCMPBoard && (
				<>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="board-tools-1"
						onClickWebItem={onClickWebItem}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-work"
						onClickWebItem={onClick}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						location="view-actions-print"
						onClickWebItem={onClickWebItem}
						mode="work"
						isTabNavigation={isTabNavigation}
					/>
					<BoardConnectMenuItems
						boardId={boardId}
						projectKey={projectKey}
						mode="work"
						onClickWebItem={onClick}
						isTabNavigation={isTabNavigation}
					/>
				</>
			)}
			<Operations isTabNavigation={isTabNavigation} />
		</Section>
	);
};
