import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'software.main.document-title.page-title',
		defaultMessage: '{boardName} - Agile board',
		description: 'Main document title',
	},
	pageTitleWithSprintOld: {
		id: 'software.main.document-title.page-title-with-sprint',
		defaultMessage:
			'{sprintCount, plural, =0 {{boardName} - Agile Board} one {{sprintName} - {boardName} - Agile Board} other {# sprints - {boardName} - Agile Board}}',
		description: 'Main document title with a sprint',
	},
	pageTitleWithSprintNew: {
		id: 'software.main.document-title.page-board-title-with-sprint',
		defaultMessage:
			'{isKanbanBoard, select, true {{boardName} - Kanban Board} other {{sprintCount, plural, =0 {{boardName} - Scrum Board} one {{sprintName} - {boardName} - Scrum Board} other {# sprints - {boardName} - Scrum Board} }} }',
		description: 'Main document title with a sprint, updated for dynamic title',
	},
});
