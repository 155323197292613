import { expValEquals } from '@atlassian/jira-feature-experiments';
import type { JiraSoftwareNavData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type { Features } from '../../../types.tsx';

export const extractData = (navData: JiraSoftwareNavData): Features => {
	const {
		permissionInfo,
		hasBacklog,
		hasBoard,
		hasCode,
		hasGoals,
		hasGetStarted,
		hasSecurity,
		hasDeployments,
		hasList,
		hasOpsgenieSchedule,
		hasPages,
		hasReleases,
		hasRoadmap,
		hasReports,
		hasRequests,
		hasIssues,
		hasSprint,
		hasIncidents,
		hasCalendar,
		hasForms,
		hasSummaryPage,
		aiInfo,
		hasArchivedIssues = false,
	} = navData;
	const isProjectOrJiraAdmin =
		permissionInfo.isAdmin || permissionInfo.projectPermissionsQueried.ADMINISTER_PROJECTS === true;

	return {
		hasArchivedIssues,
		hasBacklog,
		hasBoard,
		hasRoadmap,
		hasCode,
		hasGoals,
		hasGetStarted: isProjectOrJiraAdmin && hasGetStarted,
		hasSecurity,
		hasDeployments,
		hasList: expValEquals('jsw_list_view', 'cohort', 'variation') ? hasList : false,
		hasReleases,
		hasSIN: hasIssues,
		hasReports,
		hasRequests,
		hasPages,
		hasOpsgenie: hasOpsgenieSchedule,
		hasShortcutsAdd: isProjectOrJiraAdmin,
		hasSettings: isProjectOrJiraAdmin,
		hasSprint,
		hasIncidents,
		hasCalendar,
		hasForms: expValEquals('forms_for_jsw_experiment', 'isEnabled', true) && hasForms,
		hasSummaryPage,
		hasAIInNIN: hasIssues && aiInfo?.isEnabledInIssueNavigator === true,
	};
};
