import { useBoardUrl } from '../use-board-url/index.tsx';
import { getBoardSettingsUrl } from '../../common/url-utils/index.tsx';

/**
 * Returns the settings URL for a JSW board.
 *
 * DO NOT USE for JSM as servicedesk uses `?config=` query path to access board settings.
 *
 * @returns URL for board settings
 */
export function useBoardSettingsUrl(): string {
	return getBoardSettingsUrl(useBoardUrl());
}
