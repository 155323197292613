import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import type { OverlayIssueItemWithScopeIndex } from '../../modal/types.tsx';
import Icon from '../common/components/icon/index.tsx';
import messages from './messages.tsx';

type Props = OverlayIssueItemWithScopeIndex & {
	intl: IntlShape;
};

const DependencyCard = (props: Props) => {
	const {
		title,
		issueKey,
		projectKey,
		iconUrl,
		typeName,
		intl: { formatMessage },
	} = props;

	return (
		<Container>
			<Content>
				<ContentLeft>
					<TitleContainer>
						<TypeIconContainer>
							<Icon
								src={iconUrl || ''}
								alt={formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.iconAltTextIssueTermRefresh
										: messages.iconAltText,
									{ typeName },
								)}
							/>
						</TypeIconContainer>
						<IssueLinkContainer>
							<IssueLink issueKey={issueKey} projectKey={projectKey} />
						</IssueLinkContainer>
						<Title title={title}>{title}</Title>
					</TitleContainer>
				</ContentLeft>
			</Content>
		</Container>
	);
};

DependencyCard.displayName = 'DependencyCard';

export default injectIntl(DependencyCard);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	position: 'relative',
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.200', '16px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentLeft = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	display: 'flex',
	position: 'relative',
	flexDirection: 'row',
	alignItems: 'center',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TypeIconContainer = styled.div({
	display: 'flex',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.span({
	font: token('font.body'),
	lineHeight: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueLinkContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> a': {
		whiteSpace: 'nowrap',
		marginRight: token('space.075', '6px'),
	},
});
