import isNil from 'lodash/isNil';
import { SCENARIO_ISSUE_ID_PREFIX } from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/constants.tsx';
import {
	type IncrementConfig,
	FieldType,
} from '../../../model/increment-config/increment-config-type.tsx';
import type {
	UpdateValue,
	UpdateValues,
} from '../../../model/issue/issue-increment-planning-types.tsx';
import type { Dispatch, Action } from '../../../state/types.tsx';

/**
 * Transform date value into empty object if value is 0 or null or undefined. Otherwise return object with value key
 */
export const transformDateValue = (value: number | null | undefined) =>
	isNil(value) || value === 0 ? null : value;

/**
 * Transform date range values to an object to be used on increment plannning scenario endpoints
 * @param incrementConfig
 * @param startDate null startDate won't be set in the returned object
 * @param endDate endDate will always be set in the returned object
 * @returns
 */
export const transformDateRangeUpdateValues = (
	incrementConfig: IncrementConfig,
	startDate: UpdateValue | null | undefined,
	endDate: UpdateValue,
): UpdateValues => {
	let dateFields: UpdateValues = {};
	if (startDate !== undefined) {
		const startDateValue = {
			[incrementConfig.startDateField.key]: startDate,
		};
		if (incrementConfig.startDateField.type === FieldType.Custom) {
			dateFields.customFields = startDateValue;
		} else {
			dateFields = startDateValue;
		}
	}

	const endDateValue = {
		[incrementConfig.endDateField.key]: endDate,
	};
	if (incrementConfig.endDateField.type === FieldType.Custom) {
		if (dateFields.customFields) {
			dateFields.customFields = {
				...dateFields.customFields,
				...endDateValue,
			};
		} else {
			dateFields.customFields = endDateValue;
		}
	} else {
		dateFields = {
			...dateFields,
			...endDateValue,
		};
	}
	return dateFields;
};

export const wrapActionWithPromise = (dispatch: Dispatch, action: Action) => {
	const promise = new Promise((resolve, reject) => {
		dispatch({
			...action,
			promise: {
				resolve,
				reject,
			},
		});
	});
	return promise;
};

export const isScenarioIssue = (issueId: string | null | undefined) =>
	!isNil(issueId) && issueId.startsWith(SCENARIO_ISSUE_ID_PREFIX);
