import React, { useMemo } from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { components, type GroupProps, type OptionType } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { STICKY_FOOTER_GROUP_TYPE, STICKY_FOOTER_CLASS_NAME } from './constant.tsx';

export const GroupComponentWithStickyFooter = ({
	children,
	...groupProps
}: GroupProps<OptionType>) => {
	const containStickyFooterGroup = useMemo(() => {
		return groupProps.data.options.find((option) => option.groupType === STICKY_FOOTER_GROUP_TYPE);
	}, [groupProps.data.options]);

	if (containStickyFooterGroup) {
		return (
			<Box xcss={stickyFooterGroupWrapper}>
				<components.Group
					{...groupProps}
					innerProps={{
						...(groupProps.innerProps ?? {}),
						style: {
							...(groupProps.innerProps?.style ?? {}),
							paddingTop: 0,
						},
						className: STICKY_FOOTER_CLASS_NAME,
					}}
				>
					{children}
				</components.Group>
			</Box>
		);
	}

	return <components.Group {...groupProps}>{children}</components.Group>;
};

const stickyFooterGroupWrapper = xcss({
	borderTop: `2px solid ${token('color.border')}`,
	backgroundColor: 'elevation.surface.overlay',
	borderBottomLeftRadius: 'border.radius.200',
	borderBottomRightRadius: 'border.radius.200',
	position: 'sticky',
	bottom: 'space.negative.100',
	marginBottom: 'space.negative.100',
	paddingTop: 'space.050',
});
