import type { ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	layout,
	columnFixedWidth,
	collapsedColumnFixedWidth,
	columnMinWidth,
} from '../../constants/styles/index.tsx';
import { ColumnTheme } from '../../types.tsx';

export type ColumnThemeType = {
	backgroundNew: string; // value to be changed to background on cleanup - jira_theming_milestone_1
	background: string;
	headerHover: string;
	btnHover: string;
	btnActive: string;
	btnIconHover: string;
	btnIconActive: string;
	titleEditHover: string;
};

export const columnThemes: { [key: string]: ColumnThemeType } = {
	[ColumnTheme.Default]: {
		backgroundNew: Tokens.COLOR_ELEVATION_SURFACE_SUNKEN,
		background: token('elevation.surface.sunken', colors.N20),
		headerHover: token('color.background.neutral.hovered', colors.N40),
		btnHover: token('color.background.neutral.hovered', colors.N40),
		btnActive: token('color.background.neutral.pressed', colors.N50),
		btnIconHover: token('color.background.neutral.hovered', colors.N500),
		btnIconActive: token('color.text.inverse', colors.N0),
		titleEditHover: token('color.background.neutral.subtle.hovered', colors.N20),
	},
	[ColumnTheme.Warning]: {
		backgroundNew: token('color.background.accent.yellow.subtlest', '#fff7d6'),
		background: token('color.background.accent.yellow.subtlest', '#fff7d6'),
		headerHover: token('color.background.neutral.hovered', colors.Y100),
		btnHover: token('color.background.warning.hovered', colors.Y75),
		btnActive: token('color.background.warning.pressed', colors.Y300),
		btnIconHover: token('color.background.neutral.bold', colors.N500),
		btnIconActive: token('color.text.inverse', colors.N0),
		titleEditHover: token('color.background.warning.hovered', colors.Y50),
	},
	[ColumnTheme.Danger]: {
		backgroundNew: token('color.background.accent.red.subtlest', '#ffedeb'),
		background: token('color.background.accent.red.subtlest', '#ffedeb'),
		headerHover: token('color.background.neutral.hovered', colors.Y100),
		btnHover: token('color.background.warning.hovered', colors.Y75),
		btnActive: token('color.background.warning.pressed', colors.Y300),
		btnIconHover: token('color.background.neutral.bold', colors.N500),
		btnIconActive: token('color.text.inverse', colors.N0),
		titleEditHover: token('color.background.warning.hovered', colors.Y50),
	},
} as const;

export type ColumnWrapperProps = {
	isCollapsed?: boolean;
	isFlexible?: boolean;
	children: ReactNode;
	appearance: ColumnTheme;
};

const getBackgroundColor = (): string => {
	if (expVal('jira_theming_milestone_1', 'isEnabled', false)) {
		return columnThemes[ColumnTheme.Default]?.backgroundNew
			? columnThemes[ColumnTheme.Default]?.backgroundNew
			: token('elevation.surface.sunken', colors.N20);
	}
	return columnThemes[ColumnTheme.Default]?.background
		? columnThemes[ColumnTheme.Default]?.background
		: token('elevation.surface.sunken', colors.N20);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnWrapper = styled2.div<ColumnWrapperProps>(
	{
		// Column background will always be default (unlike column header)
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		backgroundColor: getBackgroundColor(),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${layout.cardListRadius}px`,
		/* needed to contain the drop zone */
		/* position: relative for footer */
		position: 'relative',
		alignSelf: 'stretch',
		flex: '1 1 auto',
		transition: 'opacity 0.2s, min-width 300ms, max-width 300ms ease-in',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minWidth: `${columnFixedWidth}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxWidth: `${columnFixedWidth}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isCollapsed, isFlexible }: ColumnWrapperProps) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed && {
			backgroundColor: 'transparent',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${collapsedColumnFixedWidth}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			maxWidth: `${collapsedColumnFixedWidth}px`,
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isFlexible && {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			minWidth: `${columnMinWidth}px`,
			maxWidth: 'none',
		}),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		...(isCollapsed &&
			isFlexible && {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				minWidth: `${collapsedColumnFixedWidth}px`,
				maxWidth: 'none',
			}),
	}),
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ColumnWrapperControl = styled.div<{ isCollapsed?: boolean; isFlexible?: boolean }>`
	background-color: ${(props) => {
		if (props.isCollapsed) {
			return 'transparent';
		}

		return columnThemes[ColumnTheme.Default].background;
	}};
	border-radius: ${layout.cardListRadius}px;

	/* needed to contain the drop zone */
	/* position: relative for footer */
	position: relative;
	align-self: stretch;
	flex: 1 1 auto;
	transition:
		opacity 0.2s,
		min-width 300ms,
		max-width 300ms ease-in;

	min-width: ${(props) => {
		if (props.isCollapsed) {
			return `${collapsedColumnFixedWidth}px`;
		}

		return props.isFlexible ? `${columnMinWidth}px` : `${columnFixedWidth}px`;
	}};
	max-width: ${(props) => {
		if (props.isFlexible) {
			return 'none';
		}
		return props.isCollapsed ? `${collapsedColumnFixedWidth}px` : `${columnFixedWidth}px`;
	}};
`;
