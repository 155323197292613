import React, { useCallback } from 'react';
import { ButtonItem, MenuGroup as PopupGroup, Section as PopupSection } from '@atlaskit/menu';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { isFedRamp } from '@atlassian/atl-context';
import { expVal } from '@atlassian/jira-feature-experiments';
import { WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME } from '@atlassian/jira-insights-next-best-task/src/common/constants.tsx';
import { ViewSettingsTogglePulse } from '@atlassian/jira-insights-next-best-task/src/ui/view-settings-toggle-pulse/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIssueContextActions,
	useViewMode,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { boardViewTestIds } from '../../../../common/constants.tsx';
import messages from '../../../../common/messages.tsx';
import { ActionSubject, ToggleKey } from '../../../../common/types/analytics.tsx';
import { ViewAs, ExpandableState, ViewLocation, View } from '../../../../common/types/constant.tsx';
import type { BoardViewProps } from '../../../../common/types/menu.tsx';
import { ViewSetting } from '../../../../common/types/settings.tsx';
import { PanelGroup } from '../../../../common/ui/group/index.tsx';
import { PanelSection } from '../../../../common/ui/section/index.tsx';
import { ViewSettingsToggle } from '../../../../common/ui/view-settings-toggle/index.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../../../../common/utils/index.tsx';
import { useViewSettings } from '../../../../controllers/index.tsx';

export const BoardMenu = ({
	viewAs,
	boardId,
	showFilterBarToggle = false,
	showLinkedIssueStatsToggle = false,
	isSwimlanesEnabled = false,
	showCardDetails = true,
	epicCustomName = 'Epic',
	onHandleChange,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onToggleWorkSuggestions = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onLinkedIssuesStatsToggledOn = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onShowCardCoversToggledOn = () => {},
	isClassic = false,
	isDaysInColumnEnabled = false,
	isEstimateEnabled = false,
	isDueDateEnabled = false,
	isLabelsEnabled = false,
	isPriorityEnabled = false,
	isDevelopmentEnabled = false,
	isWorkSuggestionsEnabled = false,
	showWorkSuggestions = false,
	cardExtraFields = [],
}: BoardViewProps) => {
	const { formatMessage } = useIntl();
	const [state, actions] = useViewSettings();
	const { view } = state;
	const {
		toggleViewSetting,
		toggleShowEpics,
		toggleShowLinkedIssuesStats,
		toggleShowDaysInColumn,
		toggleShowDueDate,
		toggleExtraField,
		toggleShowLabels,
	} = actions;

	const viewMode = useViewMode();
	const [, { setDetailViewAsSidebar, setDetailViewAsModal }] = useIssueContextActions();

	const Section = viewAs === ViewAs.PANEL ? PanelSection : PopupSection;
	const Group = viewAs === ViewAs.PANEL ? PanelGroup : PopupGroup;
	const cardDetailsTitle =
		viewAs === ViewAs.PANEL
			? formatMessage(messages.cardFields)
			: formatMessage(messages.cardDetails);

	const handleBoardViewModeClick = () => {
		if (viewMode === 'SIDEBAR') {
			setDetailViewAsModal();
		} else {
			setDetailViewAsSidebar();
		}
	};

	const hasCardExtraFields = cardExtraFields.length > 0;

	const handleLinkedIssuesStatsToggleChange = useCallback(() => {
		const showIssueLinksStatsState = state.showIssueLinksStats;
		toggleShowLinkedIssuesStats({ boardId, view: View.BOARD });
		onHandleChange(ActionSubject.TOGGLE, {
			key: ToggleKey.SHOW_ISSUE_LINKS_STATS,
			state: !showIssueLinksStatsState,
		});
		// start fetching issue links stats if the user turned on toggle
		if (!showIssueLinksStatsState) {
			onLinkedIssuesStatsToggledOn();
		}
	}, [
		boardId,
		onHandleChange,
		onLinkedIssuesStatsToggledOn,
		state.showIssueLinksStats,
		toggleShowLinkedIssuesStats,
	]);

	const handleShowCardCoversToggleChange = useCallback(() => {
		const showCardCoversState = state.showCardCovers;
		toggleViewSetting({
			boardId,
			view: View.BOARD,
			setting: ViewSetting.SHOW_CARD_COVERS,
		});
		onHandleChange(ActionSubject.TOGGLE, {
			key: ToggleKey.SHOW_CARD_COVERS,
			state: !state.showCardCovers,
		});
		// start fetching card cover data if the user turned on toggle
		if (!showCardCoversState) {
			onShowCardCoversToggledOn();
		}
	}, [boardId, onHandleChange, onShowCardCoversToggledOn, state.showCardCovers, toggleViewSetting]);

	const fields = (
		<>
			{/* Card cover images only available for cmp projects */}
			{view === View.BOARD &&
				isClassic &&
				expVal('jsw_cmp_card_cover_images_experiment', 'isCardCoverFeatureEnabled', false) && (
					<ViewSettingsToggle
						testId={boardViewTestIds.showCardCoversToggle}
						title={formatMessage(messages.cardCovers)}
						onChange={handleShowCardCoversToggleChange}
						isChecked={state.showCardCovers}
						viewAs={viewAs}
					/>
				)}
			{view === View.BOARD && isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
				<>
					<ViewSettingsToggle
						testId={boardViewTestIds.showIssueTypeToggle}
						title={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.cardDetailIssueTypeIssueTermRefresh
								: messages.cardDetailIssueType,
						)}
						onChange={() => {
							toggleViewSetting({
								boardId,
								view: View.BOARD,
								setting: ViewSetting.SHOW_ISSUE_TYPE,
							});
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_ISSUE_TYPE,
								state: !state.showIssueType,
							});
						}}
						isChecked={state.showIssueType}
						viewAs={viewAs}
					/>
					<ViewSettingsToggle
						testId={boardViewTestIds.showIssueKeyToggle}
						title={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.cardDetailIssueKeyIssueTermRefresh
								: messages.cardDetailIssueKey,
						)}
						onChange={() => {
							toggleViewSetting({
								boardId,
								view: View.BOARD,
								setting: ViewSetting.SHOW_ISSUE_KEY,
							});
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_ISSUE_KEY,
								state: !state.showIssueKey,
							});
						}}
						isChecked={state.showIssueKey}
						viewAs={viewAs}
					/>
				</>
			)}
			<ViewSettingsToggle
				testId={boardViewTestIds.showEpicsToggle}
				title={epicCustomName}
				onChange={() => {
					toggleShowEpics({ boardId, view: View.BOARD });
					onHandleChange(ActionSubject.TOGGLE, {
						key: ToggleKey.SHOW_EPICS_TOGGLE,
						state: !state.showEpics,
					});
				}}
				isChecked={state.showEpics}
				viewAs={viewAs}
			/>
			{/* Enabling due date for CMP boards with jsw_cmp_show_due_date_by_default */}
			{isDueDateEnabled &&
				view === View.BOARD &&
				isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<ViewSettingsToggle
						testId={boardViewTestIds.showDueDateToggle}
						title={formatMessage(messages.dueDate)}
						onChange={() => {
							toggleShowDueDate({ boardId, view: View.BOARD });
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_DUE_DATE,
								state: !state.showDueDate,
							});
						}}
						isChecked={state.showDueDate}
						viewAs={viewAs}
					/>
				)}
			{/* Labels available only for tmp projects */}
			{isLabelsEnabled &&
				view === View.BOARD &&
				isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<ViewSettingsToggle
						testId={boardViewTestIds.showLabelsToggle}
						title={formatMessage(messages.labels)}
						onChange={() => {
							toggleShowLabels({ boardId, view: View.BOARD });
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_LABELS,
								state: !state.showLabels,
							});
						}}
						isChecked={state.showLabels}
						viewAs={viewAs}
					/>
				)}
			{isEstimateEnabled &&
				view === View.BOARD &&
				isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<ViewSettingsToggle
						testId={boardViewTestIds.showEstimateToggle}
						title={formatMessage(messages.cardDetailEstimate)}
						onChange={() => {
							toggleViewSetting({
								boardId,
								view: View.BOARD,
								setting: ViewSetting.SHOW_ESTIMATE,
							});
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_ESTIMATE,
								state: !state.showEstimate,
							});
						}}
						isChecked={state.showEstimate}
						viewAs={viewAs}
					/>
				)}
			{/* Days in column available only for jsw board */}
			{isDaysInColumnEnabled &&
				view === View.BOARD &&
				isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<ViewSettingsToggle
						testId={boardViewTestIds.showDaysInColumnToggle}
						title={formatMessage(messages.daysInColumn)}
						onChange={() => {
							toggleShowDaysInColumn({ boardId, view: View.BOARD });
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_DAYS_IN_COLUMN,
								state: !state.showDaysInColumn,
							});
						}}
						isChecked={state.showDaysInColumn}
						viewAs={viewAs}
					/>
				)}
			{showLinkedIssueStatsToggle && (
				<ViewSettingsToggle
					testId={boardViewTestIds.showLinkedIssueStatsToggle}
					title={formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.linkedIssuesIssueTermRefresh
							: messages.linkedIssues,
					)}
					onChange={handleLinkedIssuesStatsToggleChange}
					isChecked={state.showIssueLinksStats}
					viewAs={viewAs}
				/>
			)}
			{view === View.BOARD && isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
				<>
					{isDevelopmentEnabled && (
						<ViewSettingsToggle
							testId={boardViewTestIds.showDevelopmentToggle}
							title={formatMessage(messages.cardDetailDevelopment)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BOARD,
									setting: ViewSetting.SHOW_DEVELOPMENT,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.SHOW_DEVELOPMENT,
									state: !state.showDevelopment,
								});
							}}
							isChecked={state.showDevelopment}
							viewAs={viewAs}
						/>
					)}
					{isPriorityEnabled && (
						<ViewSettingsToggle
							testId={boardViewTestIds.showPriorityToggle}
							title={formatMessage(messages.cardDetailPriority)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BOARD,
									setting: ViewSetting.SHOW_PRIORITY,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.SHOW_PRIORITY,
									state: !state.showPriority,
								});
							}}
							isChecked={state.showPriority}
							viewAs={viewAs}
						/>
					)}
					<ViewSettingsToggle
						testId={boardViewTestIds.showAssigneeToggle}
						title={formatMessage(messages.cardDetailAssignee)}
						onChange={() => {
							toggleViewSetting({
								boardId,
								view: View.BOARD,
								setting: ViewSetting.SHOW_ASSIGNEE,
							});
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.SHOW_ASSIGNEE,
								state: !state.showAssignee,
							});
						}}
						isChecked={state.showAssignee}
						viewAs={viewAs}
					/>
				</>
			)}
		</>
	);

	return (
		<>
			<Group data-testId={boardViewTestIds.viewSettingsBoardMenu}>
				<Section>
					<ViewSettingsToggle
						testId={boardViewTestIds.openIssuesInSidebarToggle}
						title={formatMessage(
							expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.openIssuesInSidebarIssueTermRefresh
								: messages.openIssuesInSidebar,
						)}
						onChange={() => {
							handleBoardViewModeClick();
							onHandleChange(ActionSubject.TOGGLE, {
								key: ToggleKey.OPEN_ISSUES_IN_SIDEBAR_TOGGLE,
								state: viewMode === 'SIDEBAR' ? ViewLocation.MODAL : ViewLocation.SIDEBAR,
							});
						}}
						isChecked={viewMode === 'SIDEBAR'}
						viewAs={viewAs}
					/>
					{showFilterBarToggle && (
						<ViewSettingsToggle
							testId={boardViewTestIds.showQuickFiltersToggle}
							title={formatMessage(messages.quickFilters)}
							onChange={() => {
								toggleViewSetting({
									boardId,
									view: View.BOARD,
									setting: ViewSetting.QUICK_FILTERS,
								});
								onHandleChange(ActionSubject.TOGGLE, {
									key: ToggleKey.QUICK_FILTERS_TOGGLE,
									state: !state.showQuickFilters,
								});
							}}
							isChecked={state.showQuickFilters}
							viewAs={viewAs}
						/>
					)}
					{!isFedRamp() && showWorkSuggestions && (
						<WorkSuggestionsSpotlightTarget>
							<ViewSettingsTogglePulse isPanelView={viewAs === ViewAs.PANEL}>
								<ViewSettingsToggle
									testId={boardViewTestIds.showWorkSuggestionsToggle}
									title={formatMessage(messages.workSuggestions)}
									onChange={(_, analyticsEvent) => {
										onToggleWorkSuggestions(analyticsEvent);
										onHandleChange(ActionSubject.TOGGLE, {
											key: ToggleKey.WORK_SUGGESTIONS_TOGGLE,
											state: !isWorkSuggestionsEnabled,
										});
									}}
									isChecked={isWorkSuggestionsEnabled}
									viewAs={viewAs}
								/>
							</ViewSettingsTogglePulse>
						</WorkSuggestionsSpotlightTarget>
					)}
				</Section>
				{isSwimlanesEnabled && viewAs !== ViewAs.PANEL && (
					<Section title={formatMessage(messages.swimlanes)} hasSeparator>
						<ButtonItem
							data-testid={boardViewTestIds.swimlaneExpandAll}
							onClick={() => {
								onSwimlaneExpandAll();
								onHandleChange(ActionSubject.BUTTON, {
									key: ToggleKey.SWIMLANE_EXPAND_ALL,
									state: ExpandableState.EXPANDED,
								});
							}}
						>
							{formatMessage(messages.expandAll)}
						</ButtonItem>
						<ButtonItem
							data-testid={boardViewTestIds.swimlaneCollapseAll}
							onClick={() => {
								onSwimlaneCollapseAll();
								onHandleChange(ActionSubject.BUTTON, {
									key: ToggleKey.SWIMLANE_COLLAPSE_ALL,
									state: ExpandableState.COLLAPSED,
								});
							}}
						>
							{formatMessage(messages.collapseAll)}
						</ButtonItem>
					</Section>
				)}
				{showCardDetails && (
					<Section
						testId={boardViewTestIds.cardDetailsSection}
						title={cardDetailsTitle}
						hasSeparator
					>
						<fieldset>
							<legend>
								<VisuallyHidden>{cardDetailsTitle}</VisuallyHidden>
							</legend>
							{fields}
						</fieldset>
					</Section>
				)}
				{hasCardExtraFields && isViewSettingsPanelAndTailoredViewExperimentEnabled() && (
					<Section testId={boardViewTestIds.customFieldsSection} hasSeparator>
						{cardExtraFields.map((field) => (
							<ViewSettingsToggle
								key={field.id}
								testId={`software-view-settings.ui.view-settings-menu.views.board.show-${field.id}-toggle-board`}
								title={field.label}
								onChange={() => {
									toggleExtraField({
										boardId,
										view: View.BOARD,
										fieldId: field.id,
									});
									onHandleChange(ActionSubject.TOGGLE, {
										key: `${ToggleKey.SHOW_EXTRA_FIELD}_${field.id}`,
										state: !state.cardExtraFields[field.id],
									});
								}}
								isChecked={state.cardExtraFields[field.id]}
								viewAs={viewAs}
							/>
						))}
					</Section>
				)}
				{isSwimlanesEnabled && viewAs === ViewAs.PANEL && (
					<Section title={formatMessage(messages.swimlanes)} hasSeparator>
						<Box xcss={buttonItemContainerPanelModeStyles}>
							<ButtonItem
								data-testid={boardViewTestIds.swimlaneExpandAll}
								onClick={() => {
									onSwimlaneExpandAll();
									onHandleChange(ActionSubject.BUTTON, {
										key: ToggleKey.SWIMLANE_EXPAND_ALL,
										state: ExpandableState.EXPANDED,
									});
								}}
							>
								<Box paddingInline="space.100">{formatMessage(messages.expandAll)}</Box>
							</ButtonItem>
							<ButtonItem
								data-testid={boardViewTestIds.swimlaneCollapseAll}
								onClick={() => {
									onSwimlaneCollapseAll();
									onHandleChange(ActionSubject.BUTTON, {
										key: ToggleKey.SWIMLANE_COLLAPSE_ALL,
										state: ExpandableState.COLLAPSED,
									});
								}}
							>
								<Box paddingInline="space.100">{formatMessage(messages.collapseAll)}</Box>
							</ButtonItem>
						</Box>
					</Section>
				)}
			</Group>
			<FireScreenAnalytics
				attributes={{
					viewSettingsState: state,
				}}
			/>
		</>
	);
};

const WorkSuggestionsSpotlightTarget = ({ children }: { children: JSX.Element }) => (
	<SpotlightTarget name={WORK_SUGGESTIONS_VIEW_SETTINGS_SPOTLIGHT_VIEW_SETTINGS_STEP_NAME}>
		{children}
	</SpotlightTarget>
);

const buttonItemContainerPanelModeStyles = xcss({
	position: 'relative',
	left: '-24px',
	width: 'calc(100% + 48px)',
});
