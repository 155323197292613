import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag'; // ignore-for-ENGHEALTH-17759
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import message from './messages.tsx';

type Props = {
	onRetry: () => void;
	onDismissFlag: () => void;
	intl: IntlShape;
};

function UndoErrorFlag({ intl: { formatMessage }, onRetry, onDismissFlag }: Props) {
	return (
		<ErrorFlag
			id="undo-clear-error"
			title={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? message.titleIssueTermRefresh
					: message.title,
			)}
			description={formatMessage(message.description)}
			actions={[
				{
					content: formatMessage(message.tryAgain),
					onClick: () => {
						onRetry();
						onDismissFlag();
					},
				},
			]}
		/>
	);
}

export default injectIntl(UndoErrorFlag);
