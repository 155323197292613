import React, {
	type ComponentType,
	memo,
	useState,
	type ReactNode,
	useCallback,
	useMemo,
} from 'react';
import isNil from 'lodash/isNil';
import withEngagement from '@atlassian/jira-engagement/src/ui/with-engagement/index.tsx';
import {
	FULL,
	VISIBLE,
} from '@atlassian/jira-platform-inline-card-create/src/common/constants.tsx';
import type { Project } from '@atlassian/jira-platform-inline-card-create/src/common/types.tsx';
import type {
	TriggerAppearance,
	StatusTransition,
	CreatePayload,
} from '@atlassian/jira-platform-inline-card-create/src/index.tsx';
import { FIELD_KEY_STATUS_TRANSITIONS } from '@atlassian/jira-platform-inline-card-create/src/ui/form/status-select/index.tsx';
import type { Props as TypeSelectProps } from '@atlassian/jira-platform-inline-card-create/src/ui/form/type-select/index.tsx';
import InlineIssueCreate from '@atlassian/jira-platform-inline-card-create/src/ui/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { HiddenButtonWrapper } from './button/index.tsx';
import type {
	CreateCallback,
	CardTypeShape,
	ChangeFormVisibleCallback,
	TriggerClickCallback,
	CancelCallback,
	BlurCallback,
	ChangeProjectCallback,
	AutoOpenCallback,
} from './types.tsx';

export type Props = {
	shouldStayOpen: boolean;
	isFormVisible?: boolean;
	appliedFilterCount: number;
	cardTypes: CardTypeShape[];
	showOnHover: boolean;
	triggerAppearance: TriggerAppearance;
	triggerAriaLabel?: string;
	buttonSpotlightTargetId?: string;
	manageIssueTypesLink: string | null;
	selectedCardTypeId?: number | undefined;
	defaultCardTypeId?: string | number | undefined;
	renderAdditionalTypeSelectOptionContent?: (cardType: CardTypeShape) => ReactNode;
	onCreate: CreateCallback;
	onChangeFormVisible: ChangeFormVisibleCallback;
	onTriggerClick: TriggerClickCallback;
	onCancel: CancelCallback;
	onBlur: BlurCallback;
	statusTransitions: StatusTransition[];
	TypeSelect?: ComponentType<TypeSelectProps>;
	/* shouldKeepFormOpenOnClickOutside: For issue type create in context experimentation only, prevent icc to be closed on click outside */
	shouldKeepFormOpenOnClickOutside?: boolean;
	shouldShowCreateButton?: boolean;
	// Start of props for Increment Planning board
	isIPBoard?: boolean;
	projects?: Project[];
	initialProjectId?: number;
	onChangeProject?: ChangeProjectCallback;
	// End of props for Increment Planning board
	shouldScrollIntoView?: boolean;
	onAutoOpen?: AutoOpenCallback;
};

export const InlineCardCreateWithEngagement = withEngagement(InlineIssueCreate);

const InlineCardCreate = (props: Props) => {
	const [isFormOpen, setIsFormOpen] = useState(props.shouldStayOpen);

	const {
		shouldStayOpen,
		isFormVisible: isFormVisibleProp,
		cardTypes,
		showOnHover,
		triggerAppearance,
		triggerAriaLabel,
		buttonSpotlightTargetId,
		manageIssueTypesLink,
		appliedFilterCount,
		selectedCardTypeId,
		defaultCardTypeId,
		onTriggerClick,
		onChangeFormVisible,
		onCancel,
		onBlur,
		onCreate,
		onChangeProject,
		renderAdditionalTypeSelectOptionContent,
		statusTransitions,
		isIPBoard,
		projects,
		initialProjectId,
		TypeSelect,
		shouldKeepFormOpenOnClickOutside,
		shouldShowCreateButton,
		shouldScrollIntoView,
		onAutoOpen,
	} = props;

	const onCreateHandler = useCallback(
		(createPayload: CreatePayload) => {
			const createCallbackPayload = createPayload.map(
				({ summary, cardTypeId, fields, projectId }) => {
					const project = isIPBoard ? { projectId } : {};
					return {
						summary,
						cardType: String(cardTypeId),
						transition: fields?.get(FIELD_KEY_STATUS_TRANSITIONS),
						...project,
					};
				},
			);

			onCreate(createCallbackPayload);
		},
		[isIPBoard, onCreate],
	);

	const onChangeFormVisibleHandler = useCallback(
		(isFormVisible: boolean) => {
			onChangeFormVisible(isFormVisible);
			setIsFormOpen(isFormVisible);
		},
		[onChangeFormVisible, setIsFormOpen],
	);

	const extraItems = useMemo(
		() =>
			isNil(manageIssueTypesLink)
				? []
				: [
						{
							url: manageIssueTypesLink,
						},
					],
		[manageIssueTypesLink],
	);

	if (__SERVER__) {
		return null;
	}

	const IICComponent = (
		<InlineCardCreateWithEngagement
			cardTypes={cardTypes}
			shouldKeepFormOpenOnClickOutside={shouldKeepFormOpenOnClickOutside}
			extraItems={extraItems}
			TypeSelect={TypeSelect}
			numFilters={appliedFilterCount}
			onCreate={onCreateHandler}
			appearance={FULL}
			triggerAppearance={triggerAppearance}
			{...(fg('jfp-a11y-team_board_identical-create-issue-button') && { triggerAriaLabel })}
			// @ts-expect-error - TS2322 - Type '{ cardTypes: CardTypeShape[]; extraItems: { url: string; }[]; numFilters: number; onCreate: (createPayload: CreatePayload) => void; appearance: "full"; triggerAppearance: TriggerAppearance; ... 8 more ...; onBlur: BlurCallback; }' is not assignable to type 'IntrinsicAttributes & WithEngagementProps & { children?: ReactNode; }'.
			areActionButtonsVisible
			isFormVisibleDefault={shouldStayOpen}
			isFormVisible={isFormVisibleProp}
			targetId={buttonSpotlightTargetId}
			selectedCardTypeId={selectedCardTypeId}
			defaultCardTypeId={defaultCardTypeId}
			onChangeFormVisible={onChangeFormVisibleHandler}
			onTriggerClick={onTriggerClick}
			onCancel={onCancel}
			onBlur={onBlur}
			renderAdditionalTypeSelectOptionContent={renderAdditionalTypeSelectOptionContent}
			statusTransitions={statusTransitions}
			projects={projects}
			initialProjectId={initialProjectId}
			onChangeProject={onChangeProject}
			shouldShowCreateButton={shouldShowCreateButton}
			onAutoOpen={onAutoOpen}
			shouldScrollIntoView={shouldScrollIntoView}
		/>
	);

	return showOnHover ? (
		<HiddenButtonWrapper isICCFormOpen={isFormOpen}>{IICComponent}</HiddenButtonWrapper>
	) : (
		IICComponent
	);
};

InlineCardCreate.defaultProps = {
	shouldStayOpen: false,
	appliedFilterCount: 0,
	cardTypes: [],
	statusTransitions: [],
	showOnHover: false,
	triggerAppearance: VISIBLE,
	buttonSpotlightTargetId: '',
	manageIssueTypesLink: null,
	selectedCardTypeId: undefined,
	defaultCardTypeId: undefined,
};

export default memo<Props>(InlineCardCreate);
