import React, { useEffect, useState, useRef, useCallback } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Popup } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Box } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useBoardWorkSuggestionsSpotlightContainer } from '@atlassian/jira-insights-next-best-task/src/services/spotlight-container/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type {
	AnalyticsIssue,
	AnalyticsCardType,
} from '@atlassian/jira-portfolio-3-plan-increment-common/src/common/types.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useGlobalRef, useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';
import type { IssueLinkType } from '@atlassian/jira-software-board-common/src/index.tsx';
import {
	VIEW_SETTINGS_BUTTON_LG,
	VIEW_SETTINGS_BUTTON_RESPONSIVE,
	VIEW_SETTINGS_BUTTON_SM,
	VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID,
} from '../common/constants.tsx';
import { AnalyticsEvent } from '../common/types/analytics.tsx';
import { ViewAs, type View } from '../common/types/constant.tsx';
import type { ExtraField } from '../common/types/menu.tsx';
import type { Issue } from '../common/types/state.tsx';
import { Panel } from '../common/ui/panel/index.tsx';
import { useSendAnalyticsEvent } from '../common/utils/hooks/use-send-analytics-event/index.tsx';
import { isViewSettingsPanelAndTailoredViewExperimentEnabled } from '../common/utils/index.tsx';
import { useCardDetailsInitialState } from '../controllers/hooks/use-card-details-initial-state/index.tsx';
import { useOnBacklog } from '../controllers/hooks/use-on-backlog/index.tsx';
import { useOnIncrementPlanningBoard } from '../controllers/hooks/use-on-increment-planning-board/index.tsx';
import { useSyncFromLocalStorage } from '../controllers/hooks/use-sync-from-local-storage/index.tsx';
import { ViewSettingsContainer } from '../controllers/index.tsx';
import messages from './messages.tsx';
import { ViewSettingsButton } from './view-settings-button/index.tsx';
import { ViewSettingsMenu } from './view-settings-menu/index.tsx';
import { VIEW_SETTINGS_PANEL_TARGET } from './view-settings-panel-onboarding/constants.tsx';

export interface Props {
	view: (typeof View)[keyof typeof View];
	boardId: number;
	isClassic: boolean;
	showEpicAsPanel?: boolean;
	epicCustomName?: string;
	showFilterBarToggle?: boolean;
	showCardDetails?: boolean;
	isVersionsEnabled?: boolean;
	areSubtasksEnabled?: boolean;
	onSubtasksExpandAll?: () => void;
	onSubtasksCollapseAll?: () => void;
	onToggleWorkSuggestions?: (analyticsEvent: UIAnalyticsEvent) => void;
	onLinkedIssuesStatsToggledOn?: () => void;
	onShowCardCoversToggledOn?: () => void;
	areAllSubtasksExpanded?: boolean;
	areAllSubtasksCollapsed?: boolean;
	isSwimlanesEnabled?: boolean;
	onSwimlaneExpandAll?: () => void;
	onSwimlaneCollapseAll?: () => void;
	viewAs?: (typeof ViewAs)[keyof typeof ViewAs];
	isViewPanelOpen?: boolean;
	onViewPanelClose?: () => void;
	isDaysInColumnEnabled?: boolean;
	showLinkedIssueStatsToggle?: boolean;
	cardExtraFields?: ExtraField[];
	isEstimateEnabled?: boolean;
	isDueDateEnabled?: boolean;
	isLabelsEnabled?: boolean;
	isPriorityEnabled?: boolean;
	isDevelopmentEnabled?: boolean;
	isWorkSuggestionsEnabled?: boolean;
	showWorkSuggestions?: boolean;
	isAddPeopleButtonEnabled?: boolean;
	dependencyIssueOptions?: Record<string, Issue>;
	analyticsData?: {
		issuesWithLinksById: { [id: string]: AnalyticsIssue };
		issueLinkTypes: IssueLinkType[];
		issueTypes: Record<string, AnalyticsCardType>;
	};
}

const ViewSettings = ({
	view,
	boardId,
	isClassic,
	epicCustomName,
	showEpicAsPanel = false,
	showFilterBarToggle = false,
	showCardDetails = true,
	isVersionsEnabled = false,
	viewAs = ViewAs.POPUP,
	isViewPanelOpen = false,
	onViewPanelClose = noop,
	areSubtasksEnabled,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksExpandAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSubtasksCollapseAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onToggleWorkSuggestions = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onLinkedIssuesStatsToggledOn = () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onShowCardCoversToggledOn = () => {},
	areAllSubtasksExpanded = false,
	areAllSubtasksCollapsed = false,
	isSwimlanesEnabled = false,
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneExpandAll = () => {},
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onSwimlaneCollapseAll = () => {},
	isDaysInColumnEnabled = false,
	showLinkedIssueStatsToggle = false,
	cardExtraFields = [],
	isEstimateEnabled = false,
	isDueDateEnabled = false,
	isLabelsEnabled = false,
	isPriorityEnabled = false,
	isDevelopmentEnabled = false,
	isWorkSuggestionsEnabled = false,
	showWorkSuggestions = false,
	dependencyIssueOptions = {},
	analyticsData,
}: Props) => {
	const { formatMessage } = useIntl();
	const sendAnalyticsEvent = useSendAnalyticsEvent();
	const [isOpen, setIsOpen] = useState(false);
	const panelRef = useRef<HTMLDivElement>(null);

	const [{ activeViewSettingsSpotlight }, { setOpenViewSettings, onViewSettingsSpotlightEnd }] =
		useBoardWorkSuggestionsSpotlightContainer();

	const handleViewPanelClose = () => {
		onViewPanelClose();
	};

	const handleClose = () => {
		setIsOpen(false);

		if (activeViewSettingsSpotlight === 1 && onViewSettingsSpotlightEnd) {
			// To end the spotlight if the popup is closed
			onViewSettingsSpotlightEnd();
		}
	};
	const handleClick = () => {
		setIsOpen(!isOpen);
		sendAnalyticsEvent(
			'clicked',
			'button',
			AnalyticsEvent.UI_EVENT,
			{ isClassic, app: view },
			viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
				? ViewAs.PANEL
				: 'popupMenu',
		);
	};

	const openViewSettings = useCallback(() => {
		if (isOpen) {
			return;
		}
		setIsOpen(true);
		sendAnalyticsEvent(
			'clicked',
			'button',
			AnalyticsEvent.UI_EVENT,
			{ isClassic, app: view },
			viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
				? ViewAs.PANEL
				: 'popupMenu',
		);
	}, [sendAnalyticsEvent, isClassic, view, viewAs, isOpen]);

	useEffect(() => {
		if (setOpenViewSettings) {
			setOpenViewSettings(openViewSettings);
		}
	}, [openViewSettings, setOpenViewSettings]);

	const viewSettingsBtnLG = useGlobalRef(VIEW_SETTINGS_BUTTON_LG)[0];
	const viewSettingsBtnSM = useGlobalRef(VIEW_SETTINGS_BUTTON_SM)[0];
	const viewSettingsBtnResponsive = useGlobalRef(VIEW_SETTINGS_BUTTON_RESPONSIVE)[0];
	const [, { register }] = useGlobalRefStoreActions();

	useEffect(() => {
		// register view settings panel global ref
		register(VIEW_SETTINGS_PANEL_TARGET, panelRef.current);

		const focusOnCloseButton = () => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const closeButton = panelRef.current?.querySelector(
				`[data-testid="${VIEW_SETTINGS_PANEL_CLOSE_BUTTON_TEST_ID}"]`,
			) as HTMLButtonElement;
			closeButton?.focus();
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.activeElement === viewSettingsBtnLG) {
			focusOnCloseButton();
			return () => viewSettingsBtnLG?.focus();
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		if (document.activeElement === viewSettingsBtnSM) {
			focusOnCloseButton();
			return () => viewSettingsBtnSM?.focus();
		}
		if (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.activeElement === viewSettingsBtnResponsive &&
			fg('view_settings_focus_for_responsive_button')
		) {
			focusOnCloseButton();
			return () => viewSettingsBtnResponsive?.focus();
		}
	}, [register, viewSettingsBtnLG, viewSettingsBtnResponsive, viewSettingsBtnSM]);

	const conditionallyRenderViewSettingsButtonWithAria = (props: {
		'aria-controls'?: string;
		'aria-expanded'?: boolean;
		'aria-haspopup'?: boolean | 'dialog';
	}) => <ViewSettingsButton isSelected={isOpen} onClick={handleClick} {...props} />;

	const viewAsPopup = (
		<Popup
			data-testid="software-view-settings.ui.popup"
			isOpen={isOpen}
			onClose={handleClose}
			placement="bottom-end"
			content={(props) => {
				return (
					<ViewSettingsMenu
						view={view}
						viewAs={ViewAs.POPUP}
						boardId={boardId}
						isClassic={isClassic}
						showFilterBarToggle={showFilterBarToggle}
						showEpicAsPanel={showEpicAsPanel}
						epicCustomName={epicCustomName}
						showCardDetails={showCardDetails}
						isWorkSuggestionsEnabled={isWorkSuggestionsEnabled}
						showWorkSuggestions={showWorkSuggestions}
						isVersionsEnabled={isVersionsEnabled}
						areSubtasksEnabled={areSubtasksEnabled}
						onSubtasksExpandAll={onSubtasksExpandAll}
						onSubtasksCollapseAll={onSubtasksCollapseAll}
						areAllSubtasksExpanded={areAllSubtasksExpanded}
						areAllSubtasksCollapsed={areAllSubtasksCollapsed}
						isSwimlanesEnabled={isSwimlanesEnabled}
						onSwimlaneExpandAll={onSwimlaneExpandAll}
						onSwimlaneCollapseAll={onSwimlaneCollapseAll}
						onToggleWorkSuggestions={onToggleWorkSuggestions}
						showLinkedIssueStatsToggle={showLinkedIssueStatsToggle}
						onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
						onShowCardCoversToggledOn={onShowCardCoversToggledOn}
						dependencyIssueOptions={dependencyIssueOptions}
						onUpdate={props.update}
						onClose={props.onClose}
						analyticsData={analyticsData}
					/>
				);
			}}
			trigger={({
				ref,
				'aria-controls': ariaControls,
				'aria-expanded': ariaExpanded,
				'aria-haspopup': ariaHasPopup,
			}) => (
				<Box ref={ref}>
					{conditionallyRenderViewSettingsButtonWithAria({
						'aria-controls': ariaControls,
						'aria-expanded': ariaExpanded,
						'aria-haspopup': ariaHasPopup,
					})}
				</Box>
			)}
		/>
	);

	const viewAsPanel = isViewPanelOpen && (
		<div ref={panelRef}>
			<Panel title={formatMessage(messages.panelTitle)} onClose={handleViewPanelClose}>
				<ViewSettingsMenu
					view={view}
					viewAs={ViewAs.PANEL}
					boardId={boardId}
					isClassic={isClassic}
					showFilterBarToggle={showFilterBarToggle}
					showEpicAsPanel={showEpicAsPanel}
					epicCustomName={epicCustomName}
					showCardDetails={showCardDetails}
					isVersionsEnabled={isVersionsEnabled}
					isWorkSuggestionsEnabled={isWorkSuggestionsEnabled}
					showWorkSuggestions={showWorkSuggestions}
					areSubtasksEnabled={areSubtasksEnabled}
					onSubtasksExpandAll={onSubtasksExpandAll}
					onSubtasksCollapseAll={onSubtasksCollapseAll}
					areAllSubtasksExpanded={areAllSubtasksExpanded}
					areAllSubtasksCollapsed={areAllSubtasksCollapsed}
					isSwimlanesEnabled={isSwimlanesEnabled}
					onSwimlaneExpandAll={onSwimlaneExpandAll}
					onSwimlaneCollapseAll={onSwimlaneCollapseAll}
					onToggleWorkSuggestions={onToggleWorkSuggestions}
					isDaysInColumnEnabled={isDaysInColumnEnabled}
					showLinkedIssueStatsToggle={showLinkedIssueStatsToggle}
					cardExtraFields={cardExtraFields}
					isEstimateEnabled={isEstimateEnabled}
					isDueDateEnabled={isDueDateEnabled}
					isLabelsEnabled={isLabelsEnabled}
					isPriorityEnabled={isPriorityEnabled}
					isDevelopmentEnabled={isDevelopmentEnabled}
					onLinkedIssuesStatsToggledOn={onLinkedIssuesStatsToggledOn}
					onShowCardCoversToggledOn={onShowCardCoversToggledOn}
					dependencyIssueOptions={dependencyIssueOptions}
					analyticsData={analyticsData}
				/>
			</Panel>
		</div>
	);

	useOnBacklog(boardId, view, showEpicAsPanel, isVersionsEnabled);

	useSyncFromLocalStorage(
		boardId,
		view,
		cardExtraFields,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);

	useCardDetailsInitialState(
		boardId,
		view,
		isDaysInColumnEnabled,
		isDueDateEnabled,
		isLabelsEnabled,
		isEstimateEnabled,
		isPriorityEnabled,
	);

	// Initialise local storage for Increment Planning board
	useOnIncrementPlanningBoard(boardId, view, dependencyIssueOptions);

	return (
		<ContextualAnalyticsData
			sourceName={viewAs === ViewAs.PANEL ? 'viewSettingsPanel' : 'viewSettingsMenu'}
			sourceType={SCREEN}
		>
			<ViewSettingsContainer view={view} isGlobal>
				{viewAs === ViewAs.PANEL && isViewSettingsPanelAndTailoredViewExperimentEnabled()
					? viewAsPanel
					: viewAsPopup}
			</ViewSettingsContainer>
		</ContextualAnalyticsData>
	);
};

const ViewSettingsWithErrorBoundary = (props: Props) => (
	<JSErrorBoundary id="errorBoundary" packageName="jiraSoftwareViewSettings" fallback="flag">
		<ViewSettings {...props} />
	</JSErrorBoundary>
);

export default ViewSettingsWithErrorBoundary;
