import type { ModifierList, PopupSelectProps, OptionType } from '@atlaskit/select';
import type { ServerRestStatus } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';

export type Appearance = 'lozenge' | 'button';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ServerRestStatus as StatusValue };

export type StatusTransition = {
	id: string;
	isConditional: boolean;
	isGlobal: boolean;
	isInitial: boolean;
	hasScreen: boolean;
	isLooped?: boolean;
	name: string;
	to: ServerRestStatus;
	isDisabled?: boolean;
	unavailableReasonText?: string;
};

export type StatusTransitionOption = {
	transition: StatusTransition;
	label: string;
	value: string;
};

export const CREATE_STATUS_OPTION_TYPE = 'createStatus';
export const MANAGE_WORKFLOW_OPTION_TYPE = 'manageWorkflow';

export type TargetRefSetter = (el: HTMLElement | null) => void;

export type PopperProps<Modifier> = PopupSelectProps<OptionType, false, Modifier>['popperProps'];

export type ModifierExtended = ModifierList | 'zIndex';
