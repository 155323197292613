import React, { useEffect } from 'react';
import Button from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import Integration from '@atlassian/jira-illustrations/src/ui/adg4/brand/heroes/components/integration/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import commonMessages from '../../../../common/messages.tsx';
import { usePlanPathParams } from '../../../../hooks/use-increments-link/index.tsx';
import { useRestoreCustomField } from '../../../../services/restore-custom-field/index.tsx';
import BoardSwitcher from '../../../board-switcher/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ProgramBoardCustomFieldNotInPlan = ({
	planId,
	customFieldId,
	customFieldName,
	isReadOnly,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const customFieldIdRegex = /\d+/g;
	const customFieldIdMatch = customFieldId.match(customFieldIdRegex);
	const {
		isLoading: isRestoringField,
		error,
		isSuccessful,
		restoreCustomField,
	} = useRestoreCustomField({
		planId,
		customFieldId:
			customFieldIdMatch && customFieldIdMatch.length > 0 ? customFieldIdMatch[0] : undefined,
	});
	const { incrementId } = usePlanPathParams();
	const flagId = 'ip-board-custom-field-not-in-plan-error-flag';

	useEffect(() => {
		if (isSuccessful) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.reload();
		}
	}, [isSuccessful]);

	useEffect(() => {
		if (error) {
			showFlag({
				id: flagId,
				description: formatMessage(messages.errorFlagDescription),
				title: formatMessage(messages.errorFlagTitle),
				isAutoDismiss: true,
				appearance: 'error',
				type: 'error',
			});
		}
	}, [error, showFlag, formatMessage]);

	const renderPrimaryAction = () => {
		if (isReadOnly) {
			return null;
		}
		return (
			<Button
				appearance="primary"
				isDisabled={isRestoringField}
				onClick={() => {
					restoreCustomField();
				}}
			>
				{formatMessage(messages.restoreField)}
			</Button>
		);
	};

	const renderSecondaryAction = () => (
		<BoardSwitcher
			boardId={Number(incrementId)}
			triggerLabel={formatMessage(commonMessages.backToBoards)}
			isReadOnly={isReadOnly}
		/>
	);

	return (
		<EmptyState
			renderImage={getAkEmptyStateRenderImageFn(Integration)}
			header={formatMessage(messages.title, { fieldName: customFieldName })}
			description={formatMessage(messages.description)}
			maxImageWidth={300}
			primaryAction={renderPrimaryAction()}
			secondaryAction={renderSecondaryAction()}
		/>
	);
};

export default ProgramBoardCustomFieldNotInPlan;
