import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	iconAltText: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.card.icon-alt-text',
		defaultMessage: '{typeName} issue type',
		description:
			'Image alt text. Describes what issue type an icon represents. Shown in an issue list next to the issue summary.',
	},
	iconAltTextIssueTermRefresh: {
		id: 'portfolio-3-dependency-line-detail.common.ui.content.card.icon-alt-text-issue-term-refresh',
		defaultMessage: '{typeName} work type',
		description:
			'Image alt text. Describes what issue type an icon represents. Shown in an issue list next to the issue summary.',
	},
});
