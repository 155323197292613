import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.dependencies.label',
		defaultMessage: 'Dependencies',
		description:
			'The label of a dropdown menu button used to filter board by dependency criteria, eg. on-track, off-track.',
	},
	placeholder: {
		id: 'software-filters.filters.dependencies.placeholder',
		defaultMessage: 'Search dependencies filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
	dependencyType: {
		id: 'software-filters.filters.dependencies.dependency-type',
		defaultMessage: 'Dependency type',
		description: 'The label of dependency type group in the dependencies filter.',
	},
	offTrack: {
		id: 'software-filters.filters.dependencies.off-track',
		defaultMessage: 'Off-track',
		description:
			'The label of a dropdown menu option to only shows off-track dependencies in the board.',
	},
	onTrack: {
		id: 'software-filters.filters.dependencies.on-track',
		defaultMessage: 'On-track',
		description:
			'The label of a dropdown menu option to only shows on-track dependencies in the board.',
	},
});
