import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'software-filters.filters.label.label',
		defaultMessage: 'Label',
		description: 'The label of a dropdown menu button used to choose label(s) to filter issues by.',
	},
	placeholder: {
		id: 'software-filters.filters.label.placeholder',
		defaultMessage: 'Search label filters...',
		description: 'The placeholder label used for screen readers to announce the type of the filter',
	},
});
