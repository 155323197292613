import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noPermission: {
		id: 'issue-field-status.status-view.no-permission',
		defaultMessage: "You don't have permission to transition this issue",
		description:
			"Message shown to the user when they have tried to load a list of transitions and they don't have permission",
	},
	cannotLoadTransitions: {
		id: 'issue-field-status.status-view.cannot-load-transitions',
		defaultMessage: "We couldn't load the statuses and transitions",
		description: 'Message shown when we failed to load transitions from backend',
	},
	changeStatus: {
		id: 'issue-field-status.status-view.change-status',
		defaultMessage: 'Change status',
		description: 'Label for change status button',
	},
	selectStatus: {
		id: 'issue-field-status.status-view.select-status',
		defaultMessage: 'Select status',
		description: 'Label for select status dropdown',
	},
	transitionTo: {
		id: 'issue-field-status.status-view.transition-to',
		defaultMessage: 'Transition to {statusName}',
		description: 'Transition label for status options for screen readers',
	},
	noPermissionIssueTermRefresh: {
		id: 'issue-field-status.status-view.no-permission-issue-term-refresh',
		defaultMessage: "You don't have permission to transition this work item",
		description:
			"Message shown to the user when they have tried to load a list of transitions and they don't have permission",
	},
	createStatus: {
		id: 'issue-field-status.status-view.create-status',
		defaultMessage: 'Create status',
		description: 'Create status button visible text',
	},
	manageWorkflow: {
		id: 'issue-field-status.status-view.manage-workflow',
		defaultMessage: 'Manage workflow',
		description: 'Label of manage workflow button',
	},
});
