export type MigrationId = string;

export const Status = {
	READY: 'READY',
	IN_PROGRESS: 'IN_PROGRESS',
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
} as const;

export type Status = (typeof Status)[keyof typeof Status];

export type DetailedProgress = {
	dataTransformStatus: Status;
	userImportStatus: Status;
	projectCreateStatus: Status;
	queueCreationStatus?: Status;
};

export type MigrationStatus = {
	overallProgress: number;
	overallStatus: Status;
	detailedProgress: DetailedProgress;
	createdIssues: number;
	totalIssues: number;
	hasErrorLogs?: boolean;
};
